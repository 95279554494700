/* eslint-disable max-len */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { round, forEach } from 'lodash';
import { MdClose } from 'react-icons/md';
import tzLookup from 'tz-lookup';

import OrderTracking from './OrderTracking';
import AddressView from './AddressView';

const Container = styled.form`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 0px);
      position: absolute;
      right: 0;
      width: 35rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      justify-content: center;
      border-bottom: 0px;
      padding: 1rem 2rem;
      padding-top: 1.5rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: center;
      background: white;
      border-top: 0px;
      .button {
        font-weight: 600;
      }
    }
    .full-width {
      width: 100%;
    }
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  left: 1rem;
  top: 1.2rem;
`;

const DetailsFormWrapper = styled.div`
  padding: 1rem 2rem;
`;

const SpecialText = styled.p`
  color: ${({ theme }) => theme.coolGrey};
  font-size: 0.8rem;
`;

const MenuNameWrapper = styled.div`
  padding-left: 5px;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: center;
`;

const NewOrdersButton = styled.button`
  &&& {
    position: absolute;
    right: 2rem;
    top: 1.4rem;
    background-color: ${({ color }) => color};
    font-weight: 600;
  }
`;

const SpecialSpacing = styled.div`
  padding: 0.2rem 0rem;
  display: flex;
  justify-content: space-between;
  &&.time-line {
    align-items: flex-end;
  }
  .separator {
    padding: 0rem 1rem;
    padding-top: 7px;
  }
`;

const FlexItem = styled.div`
  display: flex;
  padding: 5px 0rem;
`;

const Form = ({ order, isActive, onClose }) => {
  useEffect(() => {
    const lat = parseFloat(order.place.latitude).toFixed(5);
    const long = parseFloat(order.place.longitude).toFixed(5);
    const timeZone = tzLookup(lat, long);
    moment.tz.setDefault(timeZone);
  }, []);
  const handleTimeLineTime = status => {
    const getStatus = () => {
      if (status === 'RECEIVED') {
        return 'received';
      }
      if (status === 'CONFIRMED') {
        return 'confirmed';
      }
      if (status === 'READY') {
        return 'ready';
      }
      if (status === 'COMPLETE') {
        return 'complete';
      }
      if (status === 'CANCEllED') {
        return 'cancelled';
      }
      return 'received';
    };
    const orderStatus = order[getStatus];
    const time = orderStatus
      ? moment(orderStatus.created_at).format('LT')
      : null;
    return time;
  };

  const calculateColor = status => {
    if (status === 'CONFIRMED') {
      return '#FFC841';
    }
    if (status === 'READY') {
      return '#FFC841';
    }
    if (status === 'COMPLETE') {
      return 'green';
    }
    if (status === 'CANCELLED') {
      return '#ee2a7b';
    }
    return '#00AEEF';
  };

  const getTimeDifference = (confTime, recTime) => {
    const confirmedTime = moment(confTime);
    const receivedTime = moment(recTime);

    console.log({ confirmedTime, receivedTime });
    return confirmedTime.diff(receivedTime, 'minutes');
  };

  const getConfirmedMinutes = status => {
    const getStatus = () => {
      if (status === 'RECEIVED') {
        return 'received';
      }
      if (status === 'CONFIRMED') {
        return 'confirmed';
      }
      if (status === 'READY') {
        return 'ready';
      }
      if (status === 'COMPLETE') {
        return 'complete';
      }
      if (status === 'CANCEllED') {
        return 'cancelled';
      }
      return 'received';
    };
    const orderStatus = order[getStatus()];
    const receivedStatus = order.received;
    const receivedAt = receivedStatus && receivedStatus.created_at;
    const createdAt = orderStatus && orderStatus.created_at;
    // const minutes = getMinutes(createdAt);
    const timeInMis = getTimeDifference(createdAt, receivedAt);
    if (timeInMis < 1) {
      return 'less than 1';
    }
    return `${round(timeInMis)}`;
  };

  const handleStatus = () => {
    if (order.status === 'DRAFT') {
      return 'Draft';
    }
    if (order.status === 'RECEIVED') {
      return 'New Order';
    }
    if (order.status === 'CONFIRMED') {
      return 'Confirmed';
    }
    if (order.status === 'READY') {
      return 'Ready';
    }
    if (order.status === 'CANCELLED') {
      return 'CANCELLED';
    }
    if (order.status === 'COMPLETE') {
      if (order.service_type === 'Pickup') {
        return 'Picked Up';
      }
      if (order.service_type === 'Dinein') {
        return 'Completed';
      }
      if (order.service_type === 'Delivery') {
        return 'Delivered';
      }
    }
    return null;
  };

  const trackPath = () => {
    if (order.service_type === 'Pickup') {
      return 'Pickedup';
    }
    if (order.service_type === 'Dinein') {
      return 'Complete';
    }
    if (order.service_type === 'Delivery') {
      return 'Delivered';
    }
    return null;
  };

  // const promoDiscount =
  //   order.billing.sub_total +
  //   order.billing.delivery_fee -
  //   order.billing.order_total;
  const notesArr = order.received;
  const notes = notesArr ? notesArr.note : null;

  const receivedStatusHistory = order.received;

  const receivedTime =
    receivedStatusHistory.length !== 0
      ? receivedStatusHistory[0].created_at
      : null;

  const calculateMinutes = estimates => {
    let min = 0;
    forEach(estimates, item => {
      min += item.value_in_mins;
    });
    return min;
  };

  const calculateSchedule = (estimates, serviceType, createdAt) => {
    if (serviceType === 'Delivery') {
      return `Scheduled ${moment(createdAt)
        .add(calculateMinutes(estimates), 'minutes')
        .format('LT')}`;
    }

    return `Scheduled ${moment(createdAt)
      .add(calculateMinutes(estimates), 'minutes')
      .format('LT')} `;
  };

  const cancelledBy = () => {
    const orderStatus = order.cancelled;
    const user = orderStatus ? orderStatus.user_type : null;
    const time = orderStatus
      ? moment(orderStatus.created_at).format('LLL')
      : null;
    return { user, time };
  };

  return (
    <>
      <Container
        className={`modal ${isActive && 'is-active'}`}
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <CloseButtonWrapper>
              <MdClose
                size={30}
                type="button"
                onClick={() => {
                  onClose();
                }}
              />
            </CloseButtonWrapper>
            <HeaderContent>
              <p className="modal-card-title">{order.service_type}</p>
            </HeaderContent>

            <NewOrdersButton
              type="button"
              className="button is-primary is-uppercase"
              color={calculateColor(order.status)}
            >
              {handleStatus()}
            </NewOrdersButton>
          </header>

          <section className="modal-card-body">
            <DetailsFormWrapper>
              <>
                {order.status !== 'CANCELLED' && (
                  <OrderTracking>
                    <SpecialSpacing className="time-line">
                      <p className="is-size-4 has-text-weight-medium">
                        <p className="is-size-4 has-text-weight-bold">
                          {handleTimeLineTime('RECEIVED') && (
                            <>
                              <i className="fa fa-check" />{' '}
                              {handleTimeLineTime('RECEIVED')}
                            </>
                          )}
                        </p>
                        <span>Placed</span>
                      </p>
                      <p className="is-size-4 has-text-weight-medium">
                        <p className="is-size-4 has-text-weight-bold">
                          {handleTimeLineTime('CONFIRMED') && (
                            <>
                              <i className="fa fa-check" />{' '}
                              {handleTimeLineTime('CONFIRMED')}
                            </>
                          )}
                        </p>
                        <span>Confirmed</span>
                      </p>
                      <p className="is-size-4 has-text-weight-medium">
                        <p className="is-size-4 has-text-weight-bold">
                          {handleTimeLineTime('READY') && (
                            <>
                              <i className="fa fa-check" />
                              {handleTimeLineTime('READY')}
                            </>
                          )}
                        </p>
                        <span>Ready</span>
                      </p>
                      <p className="is-size-4 has-text-weight-medium">
                        <p className="is-size-4 has-text-weight-bold">
                          {handleTimeLineTime('COMPLETE') && (
                            <>
                              <i className="fa fa-check" />{' '}
                              {handleTimeLineTime('COMPLETE')}
                            </>
                          )}
                        </p>
                        <span>{trackPath()}</span>
                      </p>
                    </SpecialSpacing>
                  </OrderTracking>
                )}
                {order.status === 'CANCELLED' && (
                  <OrderTracking>
                    <SpecialSpacing className="time-line">
                      <p className="is-size-4 has-text-weight-medium">
                        <p className="is-size-4 has-text-weight-bold">
                          {handleTimeLineTime('RECEIVED') && (
                            <>
                              <i className="fa fa-check" />{' '}
                              {handleTimeLineTime('RECEIVED')}
                            </>
                          )}
                        </p>
                        <span>Placed</span>
                      </p>
                      <p className="is-size-4 has-text-weight-medium">
                        <p className="is-size-4 has-text-weight-bold">
                          {handleTimeLineTime('CANCELLED') && (
                            <>
                              <i className="fa fa-check" />{' '}
                              {handleTimeLineTime('CANCELLED')}
                            </>
                          )}
                        </p>
                        <span>Cancelled</span>
                      </p>
                    </SpecialSpacing>
                  </OrderTracking>
                )}
                {order.status === 'CANCELLED' && (
                  <>
                    <p className="is-size-3">
                      Cancelled on {cancelledBy().time}
                    </p>
                    <p className="is-size-3">
                      Cancelled by{' '}
                      {cancelledBy().user === 'USER' ? 'Customer' : 'KRAVEIN'}
                    </p>
                    <br />
                  </>
                )}

                <SpecialSpacing>
                  <p className="is-size-3">{order.menu && order.menu.name}</p>
                  {order.payment && (
                    <MenuNameWrapper
                      className="is-size-3 has-text-weight-semibold"
                      style={{ color: '#ee2a7b' }}
                    >
                      {order.payment.payment_method === 'cash'
                        ? 'CASH ORDER'
                        : order.payment.payment_method}
                    </MenuNameWrapper>
                  )}
                </SpecialSpacing>
                <SpecialSpacing>
                  <p className="is-size-4">{order.order_reference}</p>
                  <p className="is-size-4">
                    {order.overall_quantity} items | $
                    {order.billing.sub_total.toFixed(2)}
                  </p>
                </SpecialSpacing>
                {receivedTime && (
                  <SpecialSpacing>
                    <p className="is-size-4">
                      Received {moment(receivedTime).format('LT')}{' '}
                      {moment(receivedTime).format('DD/MM/YY')}
                      {moment().format('DD/MM') ===
                        moment(order.audit.created_at).format('DD/MM') &&
                        ` ( Today ) `}
                    </p>
                    <p className="is-size-4">
                      {calculateSchedule(
                        order.estimates,
                        order.service_type,
                        receivedTime,
                      )}
                    </p>
                  </SpecialSpacing>
                )}
                <SpecialSpacing>
                  <p className="is-size-4 is-capitalized">
                    {order.contact && order.contact.contact_name}
                  </p>
                  <div>
                    {order.status !== 'COMPLETE' && (
                      <p className="is-size-4" style={{ color: '#ee2a7b' }}>
                        {order.status === 'CONFIRMED' &&
                          `Confirmed in ${getConfirmedMinutes(
                            'CONFIRMED',
                          )} min.`}{' '}
                        Due in {calculateMinutes(order.estimates)} mins
                      </p>
                    )}
                    {order.status === 'COMPLETE' && (
                      <p className="is-size-4" style={{ color: '#ee2a7b' }}>
                        {handleStatus()} at {handleTimeLineTime('COMPLETE')}
                      </p>
                    )}
                    {order.service_type === 'Dinein' && (
                      <p className="is-size-4" style={{ float: 'right' }}>
                        {order.no_of_attendees &&
                          `${order.no_of_attendees} Attendees`}
                      </p>
                    )}
                  </div>
                </SpecialSpacing>
                <AddressView order={order} />
                <p className="is-size-4">
                  {order.contact && order.contact.contact_phone}
                </p>
              </>
              <p
                className="is-size-1 has-text-weight-semibold "
                style={{ color: '#ee2a7b', paddingTop: '0.3rem' }}
              >
                {order.large_order && 'LARGE ORDER | '}
                {order.payment &&
                  order.payment.payment_method === 'cash' &&
                  'CASH ORDER - CHARGE THE CUSTOMER'}
              </p>
              {notes && notes.value && (
                <>
                  <br />
                  <div className="has-text-weight-semibold">
                    Special instructions
                  </div>
                  <SpecialText>{notes.value}</SpecialText>
                </>
              )}
              <hr />

              {order.line_item.map(item => (
                <FlexItem className="d-flex">
                  <div style={{ paddingTop: '2.5px' }}>
                    <p className="is-size-3 has-text-weight-semibold mr-4">
                      {item.quantity}
                    </p>
                  </div>
                  <div className="full-width">
                    <SpecialSpacing>
                      <p className="is-size-3 has-text-weight-semibold">
                        {item.name}
                      </p>
                      <p className="is-size-3 has-text-weight-semibold">
                        ${item.overall_price.toFixed(2)}
                      </p>
                    </SpecialSpacing>
                    {item.variant_name && (
                      <SpecialText>
                        {item.variant_name} ($ {item.price} )
                      </SpecialText>
                    )}
                    {item.modifier &&
                      item.modifier.map(modifier => (
                        <SpecialText>
                          {modifier.name} ($
                          {modifier.price_per_item.toFixed(2)})
                        </SpecialText>
                      ))}
                    {item.special_request && (
                      <SpecialText>
                        Special Request: {item.special_request}
                      </SpecialText>
                    )}
                  </div>
                </FlexItem>
              ))}

              <hr />

              <SpecialSpacing>
                <p className="is-size-3 has-text-weight-semibold">Subtotal</p>
                <p className="is-size-3 has-text-weight-semibold">
                  ${order.billing.sub_total.toFixed(2)}
                </p>
              </SpecialSpacing>
              {order.service_type === 'Delivery' && (
                <SpecialSpacing>
                  <p className="is-size-3 has-text-weight-semibold">
                    Delivery Fee
                  </p>
                  <p className="is-size-3 has-text-weight-semibold">
                    ${order.billing.delivery_fee.toFixed(2)}
                  </p>
                </SpecialSpacing>
              )}
              {order.billing.deal_discount &&
                order.billing.deal_discount !== 0 && (
                  <SpecialSpacing>
                    <p className="is-size-3 has-text-weight-semibold has-text-primary">
                      Promo
                    </p>
                    <p className="is-size-3 has-text-weight-semibold has-text-primary">
                      - $
                      {parseFloat(order.billing.deal_discount)
                        .toFixed(2)
                        .substring(1)}
                    </p>
                  </SpecialSpacing>
                )}
              {order.adjustments ? (
                <SpecialSpacing>
                  <p className="is-size-3 has-text-weight-semibold">
                    Price Adjustments
                  </p>
                  <p className="is-size-3 has-text-weight-semibold">
                    {order.adjustments.amount.toFixed(2) < 0
                      ? `- $${Math.abs(order.adjustments.amount).toFixed(2)}`
                      : `$${order.adjustments.amount.toFixed(2)}`}
                  </p>
                </SpecialSpacing>
              ) : (
                <SpecialSpacing>
                  <p className="is-size-3 has-text-weight-semibold">
                    Price Adjustments
                  </p>
                  <p className="is-size-3 has-text-weight-semibold">$0.00</p>
                </SpecialSpacing>
              )}
              <SpecialSpacing>
                <p className="is-size-3 has-text-weight-semibold">Total</p>
                <p className="is-size-3 has-text-weight-semibold">
                  ${order.billing.order_total.toFixed(2)}
                </p>
              </SpecialSpacing>

              <SpecialSpacing>
                <p className="is-size-3 has-text-weight-semibold">
                  Total Quantity
                </p>
                <p className="is-size-3 has-text-weight-semibold">
                  {order.overall_quantity} Items
                </p>
              </SpecialSpacing>
              {order.include_cuttlery && (
                <p
                  className="is-size-2 has-text-weight-semibold"
                  style={{ color: '#ee2a7b' }}
                >
                  Please send cutlery, straws, napkins etc
                </p>
              )}
              <hr />
              <SpecialSpacing>
                <a style={{ color: '#00aeef' }}>
                  <p className="is-size-2 has-text-weight-semibold has-text-primary">
                    Print Order
                  </p>
                </a>
              </SpecialSpacing>
            </DetailsFormWrapper>
          </section>
        </div>
      </Container>
    </>
  );
};

export default Form;
