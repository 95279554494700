import React, { useState, useEffect } from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';

import { forEach, uniq } from 'lodash';
import { ACLSProvider } from '../utils/aclsContext';
import client from '../utils/apolloClient';

import {
  Home,
  NotFound,
  Login,
  Places,
  Events,
  AddPlace,
  UpdatePlace,
  AddEvent,
  UpdateEvent,
  EventDeals,
  EventDealAdd,
  EventDealUpdate,
  EventActivities,
  EventInvitations,
  EmailInvitation,
  EventActivity,
  SellTickets,
  SellTicketsEvents,
  CheckingAttendeeEvents,
  CheckingAttendees,
  EditAttendee,
  // CheckInAttendeeDetails,
  Vendors,
  AddVendor,
  PlaceClaims,
  PlaceClaimAdd,
  PlaceClaimUpdate,
  PlaceDeals,
  PlaceDealAdd,
  PlaceDealUpdate,
  PlacePostings,
  PlaceActivities,
  PlaceActivity,
  ItemActivities,
  ItemActivity,
  AddCompany,
  Companies,
  UpdateCompany,
  PlacePostingAdd,
  PlacePostingUpdate,
  PlaceApproval,
  PlaceApprovalUpdate,
  PlaceEventFeeOverride,
  PlaceReviews,
  PlaceReview,
  ItemDictionaries,
  AddItemDictionary,
  ItemDictionary,
  ItemReviews,
  ItemReview,
  FlaggedReviews,
  FlaggedReview,
  PlacePhotoApprovals,
  PlacePhotoApproval,
  ItemPhotoApprovals,
  ItemPhotoApproval,
  Users,
  UserAdd,
  UserActivities,
  UserActivity,
  UserUpdate,
  AdminUsers,
  AddAdminUser,
  AdminUserUpdate,
  Test,
  EditAttendeeInfo,
  ManageEvents,
  ManageEvent,
  EditAttendeeDetails,
  // Menu Services
  Services,
  ServiceAdd,
  ServiceUpdate,
  // service settings
  ServiceSettings,
  ServiceSettingAdd,
  ServiceSettingUpdate,
  // service settings
  Menus,
  MenuAdd,
  MenuUpdate,
  // service settings
  Sections,
  SectionAdd,
  SectionUpdate,
  // menu items
  MenuItems,
  MenuItemAdd,
  MenuItemUpdate,
  // menu modifiers groups
  MenuModifiersGroups,
  MenuModifiersGroupAdd,
  MenuModifiersGroupUpdate,
  // menu holiday hours
  HolidayHours,
  HolidayHour,
  HolidayHourUpdate,
  // manageRewards
  ManageRewards,
  ManageRewardsUpdate,
  OrdersDashboard,
  Orders,
  OrderHistories,
  OrderHistory,
  ItemAvailability,
  AvailabilityDetail,
  MenuCustomHoursListing,
  MenuCustomHours,
  // Food Order Activity
  FoodOrderActivity,
  EventOrderActivity,
  Payments,
  PaymentsActivity,
  BankAccount,
  FoodCompliance,
  // Financial
  DefaultFoodOrderFee,
  FoodOverridePlaceListing,
  OverridePlaceFoodOrder,
  // other
  BookingDetails,
  ItemImageApprovals,
  ItemImageApproval,
  PlaceImageApprovals,
  PlaceImageApproval,
} from './index';

import Order from './OrderManagement/Order';

const fetchUser = gql`
  query fetchUser($input: NavigateUserInput) {
    fetch_user(input: $input) {
      user_id
      role
      email
      security {
        name
        security_id
        assign_roles
        page_action
        api_action
      }
    }
  }
`;

const mergeSecurities = values => {
  let assignRoles = [];
  let apiActions = [];
  let pageActions = [];

  forEach(values, item => {
    assignRoles = assignRoles.concat(item.assign_roles);
    apiActions = apiActions.concat(item.api_action);
    pageActions = pageActions.concat(item.page_action);
  });
  return {
    assignRoles: uniq(assignRoles),
    apiActions: uniq(apiActions),
    pageActions: uniq(pageActions),
  };
};

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { userId } = useStoreState(state => state.auth);
  const [security, setSecurity] = useState({
    role: [],
    apiActions: [],
    pageActions: [],
    assignRoles: [],
  });
  const isLoggedIn = window.localStorage.getItem('token');

  useEffect(() => {
    client.clientPrivate
      .query({ query: fetchUser, variables: { input: { user_id: userId } } })
      .then(({ data }) => {
        if (data.fetch_user && data.fetch_user.role) {
          const acls = {
            ...security,
            role: data.fetch_user.role,
          };
          setSecurity(acls);
        }
        if (
          data.fetch_user &&
          data.fetch_user.security &&
          data.fetch_user.security.length !== 0
        ) {
          const securities = mergeSecurities(data.fetch_user.security);
          const acls = {
            role: data.fetch_user.role,
            ...securities,
          };
          console.log('acls', acls, data.fetch_user.security);
          setSecurity(acls);
        }
      });
  }, []);

  return (
    <ACLSProvider value={security}>
      <Route
        {...rest}
        render={props => {
          if (!isLoggedIn) {
            return <Redirect to="/login" />;
          }
          return <Component {...props} />;
        }}
      />
    </ACLSProvider>
  );
};

const AppRouter = () => (
  <ACLSProvider value={{}}>
    <BrowserRouter>
      <Switch>
        <PrivateRoute exact path="/" component={Home} />
        <Route path="/login" component={Login} />
        <PrivateRoute path="/vendors" exact component={Vendors} />
        <PrivateRoute path="/vendors/add-vendor" exact component={AddVendor} />
        <PrivateRoute path="/places" component={Places} />
        <PrivateRoute path="/events" component={Events} />
        <PrivateRoute
          path="/update-event/:placeId/:eventId"
          component={UpdateEvent}
        />
        <PrivateRoute path="/add-place" component={AddPlace} />
        <PrivateRoute path="/update-place/:placeId" component={UpdatePlace} />

        {/* Order Management */}
        <PrivateRoute path="/orders-dashboard" component={OrdersDashboard} />
        <PrivateRoute path="/order/:placeId" component={Order} />
        <PrivateRoute path="/orders" component={Orders} />
        <PrivateRoute path="/order-histories" component={OrderHistories} />
        <PrivateRoute path="/order-history/:placeId" component={OrderHistory} />
        <PrivateRoute
          path="/menu-custom-hours"
          component={MenuCustomHoursListing}
        />
        <PrivateRoute
          path="/menu-custom-hour/:placeId"
          component={MenuCustomHours}
        />
        <PrivateRoute path="/item-availability" component={ItemAvailability} />
        <PrivateRoute
          path="/item-availability-detail/:placeId"
          component={AvailabilityDetail}
        />
        <PrivateRoute
          path="/food-order-activity"
          component={FoodOrderActivity}
        />

        {/* Order Management */}

        <PrivateRoute path="/companies" component={Companies} />
        <PrivateRoute path="/add-company" component={AddCompany} />
        <PrivateRoute
          path="/update-company/:companyId"
          component={UpdateCompany}
        />

        <PrivateRoute path="/add-event" component={AddEvent} />
        <PrivateRoute path="/event-activities" component={EventActivities} />
        <PrivateRoute
          path="/event-activity/:eventId"
          component={EventActivity}
        />
        <PrivateRoute path="/email-invitations" component={EventInvitations} />
        <PrivateRoute
          path="/email-invitation/:eventId"
          component={EmailInvitation}
        />
        <PrivateRoute path="/event-deals" component={EventDeals} />
        <PrivateRoute path="/add-event-deal" component={EventDealAdd} />
        <PrivateRoute
          path="/update-event-deal/:placeId/:dealId"
          component={EventDealUpdate}
        />

        <PrivateRoute path="/manage-events" component={ManageEvents} />
        <PrivateRoute path="/manage-event/:eventId/" component={ManageEvent} />
        <PrivateRoute
          path="/ticket/booking-details/:eventId/:bookingId"
          component={BookingDetails}
        />
        <PrivateRoute
          path="/edit-attendee/:ticketId"
          component={EditAttendeeDetails}
        />
        <PrivateRoute
          path="/ticket/booking-detail/edit-attendee-info/:ticketId"
          component={EditAttendeeInfo}
        />

        <PrivateRoute
          path="/select-events-for-sell-tickets"
          component={SellTicketsEvents}
        />
        <PrivateRoute path="/sell-tickets/:eventId" component={SellTickets} />
        <PrivateRoute
          path="/check-in-attendee-events"
          component={CheckingAttendeeEvents}
        />
        <PrivateRoute
          path="/check-in-attendees/:eventId"
          component={CheckingAttendees}
        />
        <PrivateRoute
          path="/edit-attendee-info/:ticketId"
          component={EditAttendee}
        />
        {/* <PrivateRoute
          path="/check-in-attendee-details"
          component={CheckInAttendeeDetails}
        /> */}
        <PrivateRoute path="/place-reviews" component={PlaceReviews} />
        <PrivateRoute
          path="/place-review/:placeId/:reviewId"
          component={PlaceReview}
        />

        <PrivateRoute path="/flagged-reviews" component={FlaggedReviews} />
        <PrivateRoute
          path="/flagged-review/:placeId/:flagId"
          component={FlaggedReview}
        />

        <PrivateRoute path="/item-reviews" component={ItemReviews} />
        <PrivateRoute
          path="/item-review/:itemId/:reviewId"
          component={ItemReview}
        />

        <PrivateRoute path="/item-dictionaries" component={ItemDictionaries} />
        <PrivateRoute
          path="/add-item-dictionary"
          component={AddItemDictionary}
        />
        <PrivateRoute
          path="/update-item-dictionary/:itemDictionaryId"
          component={ItemDictionary}
        />

        <PrivateRoute
          path="/place-photo-approvals"
          component={PlacePhotoApprovals}
        />
        <PrivateRoute
          path="/place-photo-approval/:imageReviewId"
          component={PlacePhotoApproval}
        />

        <PrivateRoute
          path="/item-photo-approvals"
          component={ItemPhotoApprovals}
        />
        <PrivateRoute
          path="/item-photo-approval/:imageReviewId"
          component={ItemPhotoApproval}
        />

        <PrivateRoute
          path="/item-image-approvals"
          component={ItemImageApprovals}
        />
        <PrivateRoute
          path="/item-image-approval/:groupId"
          component={ItemImageApproval}
        />

        <PrivateRoute
          path="/place-image-approvals"
          component={PlaceImageApprovals}
        />
        <PrivateRoute
          path="/place-image-approval/:groupId"
          component={PlaceImageApproval}
        />

        <PrivateRoute path="/place-claims" component={PlaceClaims} />
        <PrivateRoute path="/add-claim" component={PlaceClaimAdd} />
        <PrivateRoute
          path="/update-claim/:placeId/:claimantId"
          component={PlaceClaimUpdate}
        />
        <PrivateRoute path="/place-deals" component={PlaceDeals} />
        <PrivateRoute path="/add-place-deal" component={PlaceDealAdd} />

        <PrivateRoute
          path="/update-place-deal/:placeId/:dealId"
          component={PlaceDealUpdate}
        />
        <PrivateRoute path="/place-postings" component={PlacePostings} />

        <PrivateRoute path="/place-activities" component={PlaceActivities} />
        <PrivateRoute
          path="/place-activity/:placeId"
          component={PlaceActivity}
        />

        <PrivateRoute path="/item-activities" component={ItemActivities} />
        <PrivateRoute
          path="/item-activity/:placeId/:itemId"
          component={ItemActivity}
        />

        <PrivateRoute path="/add-place-posting" component={PlacePostingAdd} />
        <PrivateRoute
          path="/update-place-posting/:placeId/:feedId"
          component={PlacePostingUpdate}
        />
        <PrivateRoute path="/place-approval" component={PlaceApproval} />
        <PrivateRoute
          path="/place-approval-update/:placeId"
          component={PlaceApprovalUpdate}
        />
        <PrivateRoute
          path="/event-fee-override"
          component={PlaceEventFeeOverride}
        />

        <PrivateRoute
          path="/event-order-activity"
          component={EventOrderActivity}
        />
        <PrivateRoute
          path="/default-food-order-fee"
          component={DefaultFoodOrderFee}
        />
        <PrivateRoute
          path="/food-order-override-fees"
          component={FoodOverridePlaceListing}
        />
        <PrivateRoute
          path="/food-order-override-fee/:placeId"
          component={OverridePlaceFoodOrder}
        />
        <PrivateRoute path="/payments-activity" component={PaymentsActivity} />
        <PrivateRoute path="/bank-account" component={BankAccount} />
        <PrivateRoute path="/payments" component={Payments} />
        <PrivateRoute path="/food-compliance" component={FoodCompliance} />

        <PrivateRoute path="/users" component={Users} />
        <PrivateRoute path="/user-activities" component={UserActivities} />
        <PrivateRoute path="/user-activity" component={UserActivity} />
        <PrivateRoute path="/add-user" component={UserAdd} />
        <PrivateRoute path="/user/:userId" component={UserUpdate} />
        <PrivateRoute path="/admin-users" component={AdminUsers} />
        <PrivateRoute
          path="/admin-user-activity/:userId"
          component={AdminUserUpdate}
        />
        <PrivateRoute path="/add-admin-user" component={AddAdminUser} />
        {/* Menu Management => global */}
        <PrivateRoute path="/services" component={Services} />
        <PrivateRoute path="/add-service" component={ServiceAdd} />
        <PrivateRoute path="/service/:serviceId" component={ServiceUpdate} />
        {/* Menu Management => menu settings */}
        <PrivateRoute
          path="/menu-service-settings"
          component={ServiceSettings}
        />
        <PrivateRoute
          path="/add-menu-service-setting"
          component={ServiceSettingAdd}
        />
        <PrivateRoute
          path="/update-menu-service-setting/:placeId/:id"
          component={ServiceSettingUpdate}
        />
        {/* Menu Management => menu  */}
        <PrivateRoute path="/menus" component={Menus} />
        <PrivateRoute path="/add-menu" component={MenuAdd} />
        <PrivateRoute path="/update-menu/:id" component={MenuUpdate} />
        {/* Menu Management => sections  */}
        <PrivateRoute path="/menu-sections" component={Sections} />
        <PrivateRoute path="/add-menu-section" component={SectionAdd} />
        <PrivateRoute
          path="/update-menu-section/:id"
          component={SectionUpdate}
        />
        {/* Menu Management => sections  */}
        <PrivateRoute path="/menu-items" component={MenuItems} />
        <PrivateRoute path="/add-menu-item" component={MenuItemAdd} />
        <PrivateRoute path="/update-menu-item/:id" component={MenuItemUpdate} />
        {/* Menu Management => sections  */}
        <PrivateRoute
          path="/menu-modifiers-groups"
          component={MenuModifiersGroups}
        />
        <PrivateRoute
          path="/add-modifiers-group"
          component={MenuModifiersGroupAdd}
        />
        <PrivateRoute
          path="/update-modifiers-group/:id"
          component={MenuModifiersGroupUpdate}
        />
        {/* Holiday hours */}
        <PrivateRoute path="/custom-hours" component={HolidayHours} />
        <PrivateRoute path="/add-custom-hours" component={HolidayHour} />
        <PrivateRoute
          path="/update-custom-hour/:hourId"
          component={HolidayHourUpdate}
        />
        {/* Holiday hours */}

        {/* Manage Rewards */}
        <PrivateRoute path="/manage-rewards" component={ManageRewards} />
        <PrivateRoute
          path="/update-manage-rewards/:id"
          component={ManageRewardsUpdate}
        />
        {/* Manage Rewards */}

        {/* Other */}
        <PrivateRoute path="/test" component={Test} />
        <PrivateRoute component={NotFound} />
      </Switch>
    </BrowserRouter>
  </ACLSProvider>
);
export default AppRouter;
