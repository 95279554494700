import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { isNull, omit, snakeCase } from 'lodash';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';

import client from '../../../utils/apolloClient';
import { ErrorMessage } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import PlaceSelection from '../../../components/PlaceSelection';
import Form from './Form';
import { getServiceType, reverseDayEstimateValueParser } from './helpers';
import { parseContactValidValue } from '../../../utils/helpers';

const createServiceTypeSettingMutation = gql`
  mutation createServiceTypeSetting($input: ServiceTypeSettingInput) {
    create_service_type_setting(input: $input) {
      service_type_setting_id
      error {
        description
      }
    }
  }
`;

const MenuTypeAdd = ({ history }) => {
  const { userId } = useStoreState(state => state.auth);
  const [place, setPlace] = useState();
  const [placeSelection, setPlaceSelection] = useState(false);
  const [serviceTypes, setServiceTypes] = useState([
    { value: 'custom', label: 'Create new one' },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getServiceType();
      const newData = data.map(item => ({
        ...item,
        value: snakeCase(item.name),
        label: item.name,
      }));
      const custom = [{ value: 'custom', label: 'Create new one' }];
      if (place.claim_status === 'UNCLAIMED') {
        setServiceTypes(custom);
      } else {
        setServiceTypes(custom.concat(newData));
      }
    };
    if (place) {
      console.log('place ', place);
      fetchData();
    }
  }, [place]);

  return (
    <Layout>
      {placeSelection ? (
        <Mutation
          client={client.clientPrivate}
          mutation={createServiceTypeSettingMutation}
          onCompleted={({ create_service_type_setting }) => {
            if (!isNull(create_service_type_setting.error)) {
              create_service_type_setting.error.map(item =>
                toast.error(item.description),
              );
            } else {
              swal(
                'Great!',
                'Service settings configured successfully!',
                'success',
              ).then(() => {
                history.goBack();
              });
            }
          }}
        >
          {(create_service_type_setting, { loading, error }) => (
            <>
              {error && <ErrorMessage message={error.message} />}
              <Form
                userId={userId}
                place={place}
                loading={loading}
                serviceTypes={serviceTypes}
                onSubmit={inputs => {
                  const input = omit(inputs, [
                    'menus',
                    'step',
                    'day_estimate',
                    'primaryEmail',
                    'primaryMobile',
                    'inStore',
                    'curbSide',
                    'carPark',
                    'driveThru',
                  ]);
                  const dayEstimate = reverseDayEstimateValueParser(
                    inputs.day_estimate,
                  );
                  const primaryContacts = [
                    {
                      type: 'email_primary',
                      value: inputs.primaryEmail,
                      display: true,
                      is_primary: true,
                    },
                    {
                      type: 'phone_primary',
                      value: inputs.primaryMobile,
                      display: true,
                      is_primary: true,
                    },
                  ];
                  create_service_type_setting({
                    variables: {
                      input: {
                        ...input,
                        day_estimate: dayEstimate,
                        contact: parseContactValidValue(primaryContacts),
                      },
                    },
                  });
                }}
              />
            </>
          )}
        </Mutation>
      ) : (
        <PlaceSelection
          heading="Select Place to add service setting"
          claimStatus={null}
          handleSelect={value => {
            setPlace(value);
            setPlaceSelection(true);
          }}
        />
      )}
    </Layout>
  );
};

export default MenuTypeAdd;
