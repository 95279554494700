import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Label } from 'rbx';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';

import { useQuery, useMutation } from '@apollo/react-hooks';

import { sortBy } from 'lodash';
import MenuList from './MenuList';

import { Loading, Message } from '../../../../components/elements';
import client from '../../../../utils/apolloClient';

const Container = styled.div`
  .acc_button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
    background-color: ${({ theme }) => theme.lightGreyAlpha};
  }
`;

const searchMenusQuery = gql`
  query searchMenus($input: SearchInput) {
    search_menu(input: $input) {
      menu_listing {
        place_id
        service_type_setting {
          service_type_setting_id
        }
        service_type
        menu_id
        name
        status
        internal_name
        slug
        display_order
        description
      }
    }
  }
`;

const updateMenuMutation = gql`
  mutation updateMenu($input: [MenuInput]) {
    update_menu(input: $input) {
      menu_id
      error {
        description
      }
    }
  }
`;

const Main = ({ menus }) => {
  const { userId } = useStoreState(state => state.auth);
  const [menusData, setMenusData] = useState([]);

  const [updateMenuAction] = useMutation(updateMenuMutation, {
    client: client.clientPrivate,
  });

  const onHandleReorder = values => {
    const input = values.map((value, index) => ({
      menu_id: value.menu_id,
      place_id: value.place_id,
      user_id: userId,
      display_order: index,
    }));
    setMenusData(values);
    updateMenuAction({ variables: { input } });
  };

  useEffect(() => {
    setMenusData(menus);
  }, []);

  return (
    <Container>
      <Label className="title is-size-3">Menus</Label>
      <MenuList onHandleReorder={onHandleReorder} menus={menusData} />
    </Container>
  );
};

const Menus = ({ serviceSettingId }) => {
  const { data, loading, error } = useQuery(searchMenusQuery, {
    variables: {
      input: {
        filter: {
          menu_filter: {
            service_type_setting_id: serviceSettingId,
          },
        },
      },
    },
    client: client.clientPublic,
  });
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Message>{error.message}</Message>;
  }
  const menus = data.search_menu.menu_listing;
  return <Main menus={sortBy(menus, 'display_order')} />;
};

export default Menus;
