import React, { useState, useEffect } from 'react';
import { Tab } from 'rbx';
import { Box, Tabs } from '../../../components/elements';
import Fees from './Fees';

const Details = ({ serviceSettings, setPlaceName }) => {
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (serviceSettings.length !== 0) {
      setActiveTab(serviceSettings[0]);
      setPlaceName(serviceSettings[0].place.name);
    }
  }, []);

  return (
    <Box>
      <Tabs className="tabs is-medium">
        {activeTab &&
          serviceSettings.map(tab => (
            <Tab
              active={
                activeTab.service_type_setting_id ===
                tab.service_type_setting_id
              }
              key={tab.service_type_setting_id}
              onClick={() => setActiveTab(tab)}
            >
              {tab.service_type} override fee
            </Tab>
          ))}
      </Tabs>
      {activeTab && (
        <Fees
          serviceTypeSettingId={activeTab.service_type_setting_id}
          serviceType={activeTab.service_type}
          placeId={activeTab.place.place_id}
        />
      )}
    </Box>
  );
};

export default Details;
