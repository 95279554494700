import React from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';

import { Radio, InputErrorMessage } from '../../../components/elements';

const Container = styled.div``;

const Title = styled.div`
  margin: 1rem 0rem;
`;

const Type = ({ values, setFieldValue, errors, touched, isUpdate }) => (
  <Container>
    <Title>
      <p className="is-size-3 has-text-weight-semibold">Type</p>
    </Title>
    <Column.Group>
      <Column size={2}>
        <Radio
          label="Menu"
          value={values.object_type === 'MENU'}
          onChange={() => {
            setFieldValue('object_type', 'MENU');
          }}
          disabled={isUpdate}
        />
      </Column>
      <Column size={2}>
        <Radio
          label="Place"
          value={values.object_type === 'PLACE'}
          onChange={() => {
            setFieldValue('object_type', 'PLACE');
            setFieldValue('menu_id', []);
          }}
          disabled={isUpdate}
        />
      </Column>
    </Column.Group>

    <InputErrorMessage
      errors={errors.object_type}
      touched={touched.object_type}
      disableToastMessage
    />
  </Container>
);

export default Type;
