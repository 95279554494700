import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'react-accessible-accordion/dist/fancy-example.css';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemState,
} from 'react-accessible-accordion';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 50%;
  &&& {
    .table td {
      border: 1px solid #ffff;
      border-width: 0 0 1px;
      padding: 10px 20px;
      vertical-align: top;
    }
    .table th {
      border: 1px solid #f5f5f5;
      border-width: 0 0 2px;
      padding: 10px 20px;
      vertical-align: top;
    }
  }
`;

const IconWrapper = styled.span`
  padding-right: 1rem;
`;

const Container = styled.div`
  margin: 1rem 0rem;
  background-color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.lightGreyAlpha : theme.white};
`;

const ItemContainer = styled.div`
  background-color: ${({ theme, isDragging }) =>
    isDragging ? theme.lightGreyBeta : theme.white};
`;

const AccordionStyle = styled(Accordion)`
  border: 0;
`;

const AccordionItemStyle = styled(AccordionItem)`
  -moz-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  margin-bottom: 1rem;
`;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const MenuList = ({ menus, onHandleReorder }) => {
  const [menusData, setMenusData] = useState(menus);

  useEffect(() => {
    setMenusData(menus);
  }, [menus]);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const newItems = reorder(
      menus,
      result.source.index,
      result.destination.index,
    );
    onHandleReorder(newItems);
  };

  return (
    <Wrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable2">
          {(provided, snapshot) => (
            <AccordionStyle allowZeroExpanded>
              <Container
                {...provided.droppableProps}
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {menusData.map(
                  (menu, index) =>
                    menu && (
                      <Draggable
                        key={menu.menu_id}
                        draggableId={menu.menu_id}
                        index={index}
                      >
                        {(provided2, snapshot2) => (
                          <ItemContainer
                            ref={provided2.innerRef}
                            {...provided2.draggableProps}
                            {...provided2.dragHandleProps}
                            isDragging={snapshot2.isDragging}
                          >
                            <AccordionItemStyle>
                              <AccordionItemHeading>
                                <AccordionItemButton className="acc_button">
                                  <Item>
                                    <IconWrapper>
                                      <AccordionItemState>
                                        {({ expanded }) =>
                                          expanded ? (
                                            <i className="fas fa-chevron-down" />
                                          ) : (
                                            <i className="fas fa-chevron-right" />
                                          )
                                        }
                                      </AccordionItemState>
                                    </IconWrapper>
                                    <p className="is-capitalized is-size-2 has-text-weight-semibold">
                                      {menu.name}
                                    </p>
                                  </Item>
                                  <Item>
                                    <p className="is-size-2 has-text-weight-regular">
                                      {menu.status}
                                    </p>
                                  </Item>
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              {/* <AccordionItemPanel style={{ padding: 0 }}>
                                <div>details</div>
                              </AccordionItemPanel> */}
                            </AccordionItemStyle>
                          </ItemContainer>
                        )}
                      </Draggable>
                    ),
                )}
              </Container>
            </AccordionStyle>
          )}
        </Droppable>
      </DragDropContext>
    </Wrapper>
  );
};

export default MenuList;
