import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import {
  scheduleTimeText,
  totalTimeDelay,
  scheduleCompletionTimeText,
  totalTimeDue,
} from '../timeHelpers';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.6rem;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.secondaryColor};
  margin-bottom: 1rem;
`;

const Spacing = styled.div`
  padding: 0.05rem 0rem;
`;

const Timing = ({ order, receivedTime }) => {
  const due = totalTimeDue(order.estimates, receivedTime);
  return (
    <Container>
      <div>
        <Spacing>
          <p className="is-size-4">
            Received {moment(receivedTime).format('LT')}{' '}
            {moment(receivedTime).format('DD/MM/YY')}
            {moment().format('DD/MM') ===
              moment(order.audit.created_at).format('DD/MM') && ` ( Today ) `}
          </p>
        </Spacing>
        <Spacing>
          <p className="is-size-4">
            {scheduleTimeText(order.estimates, receivedTime)}{' '}
            <span className="has-text-primary">
              {scheduleCompletionTimeText(order.estimates, receivedTime)}
            </span>
            {due && `, ${due}`}
          </p>
        </Spacing>
        <Spacing>
          <p className="is-size-4" style={{ color: '#ee2a7b' }}>
            {totalTimeDelay(order.estimates, receivedTime)}
          </p>
        </Spacing>
      </div>
    </Container>
  );
};

export default Timing;
