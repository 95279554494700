import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import PlaceActivity from './PlaceActivity';

import Layout from '../../../components/global/Layout';
import { Loading } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const PlaceQuery = gql`
  query searchPlace($placeId: String, $imageFilter: ImageFilter) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      name
      status
      default_image_url
      city
      state
      country
      post_code
      status
      approval_status
      claim_status
    }
    search_images(input: { filter: { image_filter: $imageFilter } }) {
      image_id
      url
      link
      tooltip
      tags
      status
    }
  }
`;
const placeActivityQuery = gql`
  query placeActivity($input: SearchInput) {
    search_place_activity(input: $input) {
      deals {
        deals_total
        deals_active
        deals_past
        deals_future
      }
      feeds {
        feeds_total
        feeds_flagged
      }
      followers
    }
  }
`;

const MyPlaceActivity = ({ match }) => {
  const { placeId } = match.params;

  return (
    <Layout>
      <Query
        query={PlaceQuery}
        variables={{
          placeId,
          imageFilter: {
            object_id: placeId,
            object_type: ['PLACE'],
            parent_id: placeId,
            parent_type: ['PLACE'],
            links_to_id: [placeId],
            links_to_type: ['PLACE'],
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data: placeData, loading: placeLoading, error }) => {
          if (placeLoading) {
            return <Loading />;
          }
          if (error) {
            return 'error';
          }
          const { fetch_place, search_images } = placeData;

          return (
            <Query
              client={client.clientPrivate}
              query={placeActivityQuery}
              variables={{
                input: {
                  filter: {
                    social_filter: {
                      object_id: placeId,
                      object_type: 'PLACE',
                    },
                  },
                },
              }}
              fetchPolicy="network-only"
            >
              {({ data: activityData, loading, error: activityError }) => {
                if (loading) {
                  return <Loading />;
                }

                if (activityError) {
                  return 'error';
                }
                const { search_place_activity } = activityData;
                console.log('search_images', search_images);
                return (
                  <PlaceActivity
                    activity={search_place_activity}
                    place={fetch_place}
                    images={search_images}
                  />
                );
              }}
            </Query>
          );
        }}
      </Query>
    </Layout>
  );
};
export default MyPlaceActivity;
