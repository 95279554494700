import React from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { isNull, omit, isEqual } from 'lodash';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';

import client from '../../../utils/apolloClient';
import { ErrorMessage, Loading } from '../../../components/elements';
import Layout from '../../../components/global/Layout';

import Form from './Form';

const menuQuery = gql`
  query menu($input: SearchInput) {
    search_menu(input: $input) {
      menu_listing {
        place_id
        service_type_setting {
          service_type_setting_id
          service_type
        }
        place_name
        place_slug
        menu_id
        name
        internal_name
        slug
        description
        status
        approval_status
        is_private_menu
        sales_method
        qr_code
        qr_code_url
        menu_hour {
          day
          is_active
          option {
            start
            end
            start_in_sec
            end_in_sec
          }
        }
      }
    }
  }
`;

const updateMenuMutation = gql`
  mutation updateMenu($input: [MenuInput]) {
    update_menu(input: $input) {
      menu_id
      error {
        description
      }
    }
  }
`;

const MenuTypeUpdate = ({ history, match }) => {
  const { userId } = useStoreState(state => state.auth);
  const { id } = match.params;

  return (
    <Layout>
      <Query
        client={client.clientPublic}
        query={menuQuery}
        variables={{
          input: {
            filter: {
              menu_filter: { menu_id: id },
            },
          },
        }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <ErrorMessage message={error.message} />;
          }
          const menu = data.search_menu.menu_listing[0];
          return (
            <Mutation
              client={client.clientPrivate}
              mutation={updateMenuMutation}
              onCompleted={({ update_menu }) => {
                if (!isNull(update_menu[0].error)) {
                  update_menu[0].error.map(item =>
                    toast.error(item.description),
                  );
                } else {
                  swal('Great!', 'Menu updated  successfully!', 'success').then(
                    () => {
                      history.goBack();
                    },
                  );
                }
              }}
            >
              {(
                update_menu,
                { loading: updateLoading, error: updateError },
              ) => (
                <>
                  {updateError && (
                    <ErrorMessage message={updateError.message} />
                  )}
                  <Form
                    isEdit
                    userId={userId}
                    menu={menu}
                    loading={updateLoading}
                    onSubmit={inputs => {
                      const input = omit(inputs, ['menus', 'step']);
                      const inputsArray = {
                        menu_id: input.menu_id,
                        user_id: input.user_id,
                        menu_hour: input.menu_hour,
                        place_id: input.place_id,
                      };

                      Object.assign(
                        inputsArray,
                        !isEqual(
                          input.approval_status,
                          menu.approval_status,
                        ) && {
                          approval_status: input.approval_status,
                        },
                        !isEqual(input.description, menu.description) && {
                          description: input.description,
                        },
                        !isEqual(input.name, menu.name) && {
                          name: input.name,
                        },
                        !isEqual(input.place_id, menu.place_id) && {
                          place_id: input.place_id,
                        },
                        !isEqual(
                          input.service_type_setting_id,
                          menu.service_type_setting.service_type_setting_id,
                        ) && {
                          service_type_setting_id:
                            input.service_type_setting_id,
                        },
                        !isEqual(input.status, menu.status) && {
                          status: input.status,
                        },
                      );
                      update_menu({ variables: { input: [inputsArray] } });
                    }}
                  />
                </>
              )}
            </Mutation>
          );
        }}
      </Query>
    </Layout>
  );
};

export default MenuTypeUpdate;
