import React from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
import { trimStart } from 'lodash';
import { Select, Input, TextArea } from '../../../components/elements';

const Container = styled.div``;

const Details = ({
  values,
  touched,
  errors,
  // handleChange,
  // handleBlur,
  setFieldValue,
}) => (
  <Container>
    <Column.Group multiline vcentered gapSize={8}>
      <Column size="half">
        <Input
          action={[('create_feed', 'update_feed')]}
          label="Posting Title"
          name="title"
          id="PlaceManagement_PlacePosting_PostingTitle"
          value={values.title}
          onChange={event =>
            setFieldValue('title', trimStart(event.target.value))
          }
          optionalText="( Min 3 characters and Max 50 characters )"
          required
          errors={errors.title}
          touched={touched.title}
        />
      </Column>

      <Column size="3">
        <Select
          action={[('create_feed', 'update_feed')]}
          label="Posting Status"
          options={[
            { value: 'ACTIVE', label: 'ACTIVE' },
            { value: 'INACTIVE', label: 'INACTIVE' },
          ]}
          value={[
            {
              value: values.status,
              label: values.status,
            },
          ]}
          onChange={value => setFieldValue('status', value.value)}
          required
        />
      </Column>

      <Column size="full">
        <TextArea
          action={[('create_feed', 'update_feed')]}
          label="Posting Content"
          id="PlaceManagement_PlacePosting_PostingContent"
          required
          name="content"
          value={values.content}
          onChange={event =>
            setFieldValue('content', trimStart(event.target.value))
          }
          optionalText="( Min 20 and Max 800 Characters )"
          errors={errors.content}
          touched={touched.content}
        />
      </Column>
    </Column.Group>
  </Container>
);

export default Details;
