import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import qs from 'querystringify';
import { useStoreState } from 'easy-peasy';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import {
  Loading,
  Table,
  Box,
  ErrorMessage,
  Pagination,
  AesDes,
} from '../../../components/elements';
import client from '../../../utils/apolloClient';

const searchModifierGroupsQuery = gql`
  query searchModifierGroups($input: SearchInput) {
    search_menu_modifier_groups(input: $input) {
      total_size
      total_pages
      menu_modifier_group_listing {
        modifier_group_id
        place_id
        name
        place {
          name
          city
        }
        internal_name
        description
        status
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        created_by_user {
          user_id
          display_name
          email
        }
        updated_by_user {
          user_id
          display_name
          email
        }
      }
    }
  }
`;

const ModifiersGroups = ({ location, history }) => {
  const { userId } = useStoreState(state => state.auth);
  const routeState = qs.parse(location.search);
  const { status, placeName, modifierName, city, page } = routeState;

  const statusFilter = status || 'ACTIVE';
  const placeNameFilter = placeName || null;
  const cityFilter = city || '';
  const modifierNameFilter = modifierName || null;
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;

  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(10);
  const [sort, setSort] = useState('UPDATED_DESC');

  const input = { user_id: userId };
  const menu_modifier_group_filter = {};
  const place_filter = {};
  Object.assign(
    place_filter,
    placeNameFilter !== '' && { place_name: placeNameFilter },
    cityFilter !== '' && { city: cityFilter },
  );
  Object.assign(
    menu_modifier_group_filter,
    { status: statusFilter },
    modifierNameFilter !== '' && { name: modifierNameFilter },
  );
  Object.assign(
    input,
    { sort },
    { from: currentPage * listSize, size: listSize },
    {
      filter: { menu_modifier_group_filter, place_filter },
    },
  );

  return (
    <Layout>
      <Header />
      <Search
        placeName={placeName}
        modifierName={modifierName}
        city={cityFilter}
        status={statusFilter}
        routeState={routeState}
        history={history}
      />
      {totalElements > 0 && (
        <Pagination
          pageFrom={currentPage * listSize}
          pageCount={pageCount}
          listSize={listSize}
          totalElements={totalElements}
          currentPage={currentPage}
          handlePageClick={value =>
            history.push(
              `/menu-modifiers-groups${qs.stringify(
                {
                  ...routeState,
                  page: value.selected + 1,
                },
                true,
              )}`,
            )
          }
        />
      )}
      <Box>
        <Table>
          <thead>
            <tr>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="NAME_ASC"
                  des="NAME_DESC"
                />
                Modifiers Group Name
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="INTERNAL_NAME_ASC"
                  des="INTERNAL_NAME_DESC"
                />
                Internal Name
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="PLACE_NAME_ASC"
                  des="PLACE_NAME_DESC"
                />
                Place name
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="PLACE_CITY_ASC"
                  des="PLACE_CITY_DESC"
                />
                City / Suburb
              </th>

              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="UPDATED_BY_ASC"
                  des="UPDATED_BY_DESC"
                />
                Updated By
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="UPDATED_AT_ASC"
                  des="UPDATED_AT_DESC"
                />
                Updated At
              </th>

              <th>Modifiers</th>
              <th>Items</th>
              <th>Action</th>
            </tr>
          </thead>
          <Query
            query={searchModifierGroupsQuery}
            variables={{ input }}
            fetchPolicy="network-only"
            client={client.clientPrivate}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return <ErrorMessage message={error.message} />;
              }

              return (
                <View
                  data={data}
                  setPageCount={setPageCount}
                  setTotalElements={setTotalElements}
                />
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({ data, setPageCount, setTotalElements }) => {
  const { search_menu_modifier_groups } = data;

  useEffect(() => {
    setPageCount(search_menu_modifier_groups.total_pages);
    setTotalElements(search_menu_modifier_groups.total_size);
  }, [search_menu_modifier_groups.total_pages]);

  if (search_menu_modifier_groups === null) {
    return 'No record found';
  }

  if (search_menu_modifier_groups.menu_modifier_group_listing.length === 0) {
    return 'No record found';
  }
  return (
    <tbody>
      {search_menu_modifier_groups.menu_modifier_group_listing.map(group => (
        <tr key={group.modifier_group_id}>
          <ListItem group={group} />
        </tr>
      ))}
    </tbody>
  );
};

export default ModifiersGroups;
