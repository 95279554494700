import React from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';
import { isEqual } from 'lodash';
import { withRouter } from 'react-router-dom';
import client from '../../../utils/apolloClient';
import { Loading, Message } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import PostForm from '../PlacePostingAdd/PostForm';

const allNeededQuery = gql`
  query fetch_place($placeId: String, $feedId: String!) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      name
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      timezone
      status
      claim_status
    }

    fetch_feed(input: { feed_id: $feedId }) {
      feed_id
      object_id
      object_type
      title
      content
      status
      approval_status
      error {
        code
        description
      }
    }
  }
`;

const updatePostMutation = gql`
  mutation updatePost($input: FeedInput) {
    update_feed(input: $input) {
      feed_id
      error {
        description
      }
    }
  }
`;

const PlacePostingUpdate = ({ match, history }) => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Layout>
      <Query
        query={allNeededQuery}
        variables={{
          placeId: match.params.placeId,
          feedId: match.params.feedId,
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <Message>error</Message>;
          }

          const { fetch_place, fetch_feed } = data;

          return (
            <Mutation
              client={client.clientPrivate}
              mutation={updatePostMutation}
              onCompleted={() => {
                swal('Great!', 'Post Updated succesfully!', 'success').then(
                  () => {
                    history.goBack();
                  },
                );
              }}
            >
              {(update_feed, { loading: feedLoading, error: feedError }) => (
                <PostForm
                  loading={feedLoading}
                  error={feedError}
                  place={fetch_place}
                  feed={fetch_feed}
                  onSubmit={value => {
                    update_feed({ variables: { input: { ...value } } });
                    const final = {};
                    Object.assign(
                      final,
                      {
                        user_id: userId,
                      },

                      { feed_id: match.params.feedId },
                      !isEqual(fetch_feed.title, value.title) && {
                        title: value.title,
                      },
                      !isEqual(fetch_feed.status, value.status) && {
                        status: value.status,
                      },
                      !isEqual(fetch_feed.content, value.content) && {
                        content: value.content,
                      },
                    );

                    update_feed({ variables: { input: final } });
                    console.log('PlacePostingUpdate -> final', final);
                  }}
                  isAddComponent={false}
                />
              )}
            </Mutation>
          );
        }}
      </Query>
    </Layout>
  );
};

export default withRouter(PlacePostingUpdate);
