import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useStoreState } from 'easy-peasy';
import Layout from '../../../components/global/Layout';

import client from '../../../utils/apolloClient';
import { Loading, Message } from '../../../components/elements';
import Details from './Details';
import Header from './Header';

const searchServiceSettingsQuery = gql`
  query searchSettings($input: SearchInput) {
    search_service_type_settings(input: $input) {
      service_type_setting_listing {
        service_type_setting_id
        place {
          place_id
          name
          city
        }
        city
        service_type
        status
        approval_status
      }
    }
  }
`;

const FoodOverridePlaceListing = ({ match }) => {
  const [placeName, setPlaceName] = useState('');
  const { placeId } = match.params;
  const { userId } = useStoreState(state => state.auth);
  const { data, loading, error } = useQuery(searchServiceSettingsQuery, {
    variables: {
      input: {
        user_id: userId,
        sort: 'SERVICE_TYPE_ASC',
        filter: {
          place_filter: { place_id: placeId },
          service_type_setting_filter: { status: 'ACTIVE' },
        },
      },
    },
    client: client.clientPrivate,
  });
  // console.log('data', data);
  return (
    <Layout>
      <Header placeName={placeName} />
      {error && <Message>{error.message}</Message>}
      {loading && <Loading />}
      {!loading && !error && (
        <Details
          setPlaceName={setPlaceName}
          serviceSettings={
            data.search_service_type_settings.service_type_setting_listing
          }
        />
      )}
    </Layout>
  );
};

export default FoodOverridePlaceListing;
