import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { isNull, uniq } from 'lodash';
import moment from 'moment';
import qs from 'querystringify';
import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import Header from './ListingComp/Header';
import Search from './ListingComp/Search';
import ListItem from './ListingComp/ListItem';
import TableHead from './ListingComp/TableHead';
import { Loading, Pagination, Table, Box } from '../../../components/elements';

const searchPlaceImageApprovalsQuery = gql`
  query searchItemImageApprovals($input: SearchInput) {
    search_place_photo_approvals(input: $input) {
      total_pages
      total_size
      photo_approval_listing {
        group_id
        item_name
        place_name
        place_city
        status
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const listSize = 10;

const PhotoApprovals = props => {
  const routeState = qs.parse(props.location.search);
  const { status, placeName, reviewOn, page } = routeState;

  const placeNameFilter = placeName || '';
  const statusFilter = status || 'SUBMITTED';
  const reviewOnFilter = reviewOn || null;
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;

  const [sort, setSort] = useState('UPDATED_DESC');

  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState();

  const input = { sort, from: currentPage * listSize, size: listSize };

  const photo_approval_filter = {
    approval_status: statusFilter,
  };

  Object.assign(
    photo_approval_filter,
    placeNameFilter && { place_name: placeNameFilter },
    reviewOnFilter && {
      date_range: {
        start_date: moment(reviewOnFilter).format('YYYY-MM-DD'),
        end_date: moment(reviewOnFilter)
          .add(1, 'day')
          .format('YYYY-MM-DD'),
      },
    },
  );

  Object.assign(input, {
    filter: {
      photo_approval_filter,
    },
  });
  return (
    <Layout>
      <Header />
      <Box>
        <Search
          status={statusFilter}
          reviewOn={reviewOnFilter}
          placeName={placeNameFilter}
          routeState={routeState}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={currentPage * listSize}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value =>
              props.history.push(
                `/place-image-approvals${qs.stringify(
                  {
                    ...routeState,
                    page: value.selected + 1,
                  },
                  true,
                )}`,
              )
            }
          />
        )}

        <Table>
          <TableHead sort={sort} setSort={setSort} />
          <Query
            client={client.clientPrivate}
            query={searchPlaceImageApprovalsQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_place_photo_approvals } = data;
              if (
                isNull(search_place_photo_approvals) ||
                search_place_photo_approvals.photo_approval_listing.length === 0
              ) {
                return 'NO RECORDS FOUND';
              }

              return (
                <React.Fragment>
                  <View
                    search_place_photo_approvals={search_place_photo_approvals}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

export default PhotoApprovals;

const View = ({
  search_place_photo_approvals,
  setPageCount,
  setTotalElements,
}) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setPageCount(search_place_photo_approvals.total_pages);
    setTotalElements(search_place_photo_approvals.total_size);
    if (search_place_photo_approvals.photo_approval_listing) {
      const userFilter = search_place_photo_approvals.photo_approval_listing.map(
        item => item.audit.created_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');

          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_place_photo_approvals.total_pages]);

  const reviewsData = search_place_photo_approvals.photo_approval_listing;

  return (
    <tbody>
      {reviewsData.map(review => (
        <ListItem review={review} users={users} />
      ))}
    </tbody>
  );
};
