import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import { Column } from 'rbx';
import * as yup from 'yup';
import swal from 'sweetalert';
import moment from 'moment';

import { FaAngleLeft } from 'react-icons/fa';
import { TextArea, Radio, InputAddOn } from '../../../components/elements';
import { parsedAmount } from './helpers';

const Container = styled.form`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 0px);
      position: absolute;
      right: 0;
      width: 35rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1rem 2rem;
      padding-top: 1.5rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: center;
      background: white;
      border-top: 0px;
      .button {
        font-weight: 600;
      }
    }
  }
`;

const DetailsFormWrapper = styled.div`
  padding: 1rem 2rem;
`;

const SpecialSpacing = styled.div`
  padding: 0.5rem 0rem;
  display: flex;
  justify-content: space-between;
  .separator {
    padding: 0rem 1rem;
    padding-top: 7px;
  }
`;

const Form = props => {
  const {
    values,
    isActive,
    onClose,
    handleSubmit,
    resetForm,
    setFieldValue,
    handleChange,
    handleBlur,
    errors,
    touched,
    order,
  } = props;

  const isNegative = amount => {
    const actualAmount = Math.sign(amount);
    if (actualAmount === -1) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Container
        className={`modal ${isActive && 'is-active'}`}
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <FaAngleLeft
              size={30}
              type="button"
              onClick={() => {
                onClose();
                resetForm();
              }}
            />
            <p
              className="modal-card-title is-flex"
              style={{ justifyContent: 'center' }}
            >
              Adjust Price
            </p>

            {/* <NewOrdersButton
              type="button"
              className="button is-primary control"
            >
              New Order
            </NewOrdersButton> */}
          </header>

          <section className="modal-card-body">
            <DetailsFormWrapper>
              <Column.Group multiline vcentered gapSize={8}>
                <Column size={6}>
                  <Radio
                    label="Customer removed item(s)"
                    value={values.type === 'Customer removed item(s)'}
                    onChange={() => {
                      setFieldValue('type', 'Customer removed item(s)');
                    }}
                  />
                </Column>

                <Column size={6}>
                  <Radio
                    label="Item substitution"
                    value={values.type === 'Item substitution'}
                    onChange={() => {
                      setFieldValue('type', 'Item substitution');
                    }}
                  />
                </Column>

                <Column size={6}>
                  <Radio
                    label="Customer added item(s)"
                    value={values.type === 'Customer added item(s)'}
                    onChange={() => {
                      setFieldValue('type', 'Customer added item(s)');
                    }}
                  />
                </Column>

                <Column size={6}>
                  <Radio
                    label="Issue with order"
                    value={values.type === 'Issue with order'}
                    onChange={() => {
                      setFieldValue('type', 'Issue with order');
                    }}
                  />
                </Column>

                <Column size={6}>
                  <Radio
                    label="Out of an item (or items)"
                    value={values.type === 'Out of an item (or items)'}
                    onChange={() => {
                      setFieldValue('type', 'Out of an item (or items)');
                    }}
                  />
                </Column>

                <Column size={6}>
                  <Radio
                    label="Other"
                    value={values.type === 'Other'}
                    onChange={() => {
                      setFieldValue('type', 'Other');
                    }}
                  />
                </Column>
              </Column.Group>

              <SpecialSpacing>
                <p className="is-size-3 has-text-weight-semibold">
                  Current Total
                </p>
                <p className="is-size-3 has-text-weight-semibold">
                  ${order.billing.order_total.toFixed(2)}
                </p>
              </SpecialSpacing>

              <SpecialSpacing>
                <p className="is-size-3 has-text-weight-semibold">
                  Adjustment Amount
                </p>
                <p
                  className="is-size-3 has-text-weight-semibold"
                  style={{ width: '8rem' }}
                >
                  <InputAddOn
                    name="adjust_price"
                    value={values.adjust_price}
                    type="number"
                    isLeft
                    // min={order.billing.order_total}
                    addonsText="-$"
                    onChange={e => {
                      if (parseFloat(e.target.value) >= 0) {
                        handleChange(e);
                      }
                      if (
                        !e.target.value ||
                        (e.target.value && e.target.value.length === 0)
                      ) {
                        setFieldValue('adjust_price', null);
                      }
                    }}
                    onBlur={e =>
                      setFieldValue(
                        'adjust_price',
                        parseFloat(e.target.value).toFixed(2),
                      )
                    }
                  />
                </p>
              </SpecialSpacing>
              {order.billing.deal_discount &&
                order.billing.deal_discount !== 0 && (
                  <SpecialSpacing>
                    <p className="is-size-3 has-text-weight-semibold has-text-primary">
                      Promo Discount
                    </p>
                    <p className="is-size-3 has-text-weight-semibold has-text-primary">
                      {parsedAmount(
                        parseFloat(order.billing.deal_discount).toFixed(2),
                      )}
                    </p>
                  </SpecialSpacing>
                )}

              <SpecialSpacing>
                <p className="is-size-3 has-text-weight-semibold">
                  Updated Total
                </p>
                <p className="is-size-3 has-text-weight-semibold">
                  {parsedAmount(
                    parseFloat(
                      order.billing.order_total -
                        parseFloat(
                          values.adjust_price ? values.adjust_price : 0,
                        ),
                    ).toFixed(2),
                  )}
                </p>
              </SpecialSpacing>
              <br />
              <div>
                <TextArea
                  name="value"
                  label="Note to the customer"
                  value={values.value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  errors={errors.value}
                  touched={touched.value}
                  disableToastMessage
                  // eslint-disable-next-line max-len
                  placeholder="Add a note and mention anything specific such as no nuts or cross contamination, less spicy"
                />
                Characters left ({values.value.length}/144)
              </div>
            </DetailsFormWrapper>
          </section>
          <footer className="modal-card-foot">
            <button
              className="button is-large is-fullwidth is-primary"
              onClick={handleSubmit}
              disabled={
                !values.adjust_price ||
                parseFloat(values.adjust_price) <= 0 ||
                isNegative(
                  parseFloat(
                    order.billing.order_total - parseFloat(values.adjust_price),
                  ).toFixed(2),
                )
              }
            >
              CONFIRM ADJUSTMENT
            </button>
          </footer>
        </div>
      </Container>
    </>
  );
};

const AdjustPriceForm = withFormik({
  mapPropsToValues: () => ({
    adjust_price: 0,
    value: '',
    type: 'Customer removed item(s)',
  }),

  validationSchema: yup.object().shape({
    value: yup
      .string()
      .required('Description is required!')
      .min(5, 'Please enter at least 5 characters')
      .max(144, 'Description cannot be greater than 144 characters'),
  }),

  handleSubmit: (values, { props }) => {
    swal({
      title: 'Are you sure you want to adjust the Price?',
      // eslint-disable-next-line max-len
      text: `Please use price  adjustments as a last resort. Click 'cancel' to go back or click 'confirm' to adjust the price for this order. A notification will been sent to the customer informing them of the price adjustment`,
      buttons: ['Cancel', 'Confirm'],
    }).then(isConfirm => {
      if (isConfirm) {
        const note = {
          type: values.type,
          value: values.value,
          date: moment().toISOString(),
        };
        if (props.order.status === 'CONFIRMED') {
          props.handleAdjustOrderPrice(
            props.order.menu_order_id,
            'adjustconfirmed',
            note,
            values.adjust_price,
          );
          props.onClose();
        }
        if (props.order.status === 'READY') {
          props.handleAdjustOrderPrice(
            props.order.menu_order_id,
            'adjustready',
            note,
            values.adjust_price,
          );
          props.onClose();
        }
      }
    });
  },

  displayName: 'AdjustPriceForm',
})(Form);

export default AdjustPriceForm;
