import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import gql from 'graphql-tag';

import { differenceBy, join, forEach } from 'lodash';
import client from '../../../utils/apolloClient';
import { CheckBox, Loading } from '../../../components/elements';

const Container = styled.div`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 190px);
      position: absolute;
      width: 53rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1rem 1rem;
      padding-top: 1.5rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: flex-end;
      border: 0px;
      padding: 1rem;
      background: white;
    }
    a {
      font-size: 1rem;
    }
  }
`;

const SecondaryHeader = styled.div`
  background: white;
`;
const InputWrapper = styled.div`
  padding: 0.5rem 1rem;
  width: 50%;
`;

const EmptyMessage = styled.div`
  padding: 1rem;
`;

const searchModifierGroupsQuery = gql`
  query searchModifierGroups($input: SearchInput) {
    search_menu_modifier_groups(input: $input) {
      modifier_group_listing {
        modifier_group_id
        place_id
        name
        internal_name
        description
        status
        modifier {
          menu_item {
            menu_item_id
            name
            price
            variant {
              prompt
              prompt_id
              variants {
                variant_name
                variant_id
                variant_price
                availability_status
              }
            }
          }
          override_price
          override_variant {
            prompt
            prompt_id
            variants {
              variant_id
              variant_price
            }
          }
          min_qty
          max_qty
        }
        is_required
        selection_quantity_from
        selection_quantity_to
        selection_quantity
        selection_type
      }
    }
  }
`;
const getCurrentItemInFinalGroup = (values, menuItemId) => {
  let menuGroupId = null;
  forEach(values, item => {
    const filter = item.modifier.filter(
      item2 => item2.menu_item.menu_item_id === menuItemId,
    );
    if (filter.length !== 0) {
      menuGroupId = item.modifier_group_id;
    }
  });
  return menuGroupId;
};
const SearchItemModal = ({
  onSubmit,
  isActive,
  onClose,
  initialsGroups,
  isCreateLinkLoading,
  placeId,
  menuItemId,
}) => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);

  // useEffect(() => {
  //   const finalMenuItems = differenceBy(
  //     selectedItems,
  //     initialsItems,
  //     'menu_item_id',
  //   );
  //   console.log("")
  //   setSelectedItems(finalMenuItems);
  // }, [initialsItems]);
  console.log('group', groups);
  const fetchGroups = value => {
    const menu_modifier_group_filter = {};
    Object.assign(
      menu_modifier_group_filter,
      { place_id: placeId },
      value && { name: value },
    );
    setLoading(true);
    client.clientPublic
      .query({
        query: searchModifierGroupsQuery,
        variables: {
          input: {
            sort: 'NAME_ASC',
            size: 200,
            filter: { menu_modifier_group_filter },
          },
        },
      })
      .then(({ data }) => {
        if (
          data.search_menu_modifier_groups &&
          data.search_menu_modifier_groups.modifier_group_listing
        ) {
          const modifierGroups =
            data.search_menu_modifier_groups.modifier_group_listing;
          const finalModifierGroups = differenceBy(
            modifierGroups,
            initialsGroups,
            'modifier_group_id',
          );
          const currentItemInFinalGroup = getCurrentItemInFinalGroup(
            finalModifierGroups,
            menuItemId,
          );
          setGroups(
            currentItemInFinalGroup
              ? finalModifierGroups.filter(
                  edge => edge.modifier_group_id !== currentItemInFinalGroup,
                )
              : finalModifierGroups,
          );
          console.log('finalModifierGroups', finalModifierGroups);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSearch = value => {
    fetchGroups(value);
  };

  useEffect(() => {
    fetchGroups();
  }, [initialsGroups]);

  const addItem = item => {
    setSelectedGroups(selectedGroups.concat(item));
  };

  const removeItem = id => {
    setSelectedGroups(
      selectedGroups.filter(item => item.modifier_group_id !== id),
    );
  };

  console.log('group', groups);

  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title is-2">Add Menu Modifier Group</p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={onClose}
          />
        </header>
        <SecondaryHeader>
          <InputWrapper>
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <input
                  className="input"
                  type="search"
                  placeholder="Search menu..."
                  onChange={e => onSearch(e.target.value)}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-search" />
                </span>
              </p>
            </div>
          </InputWrapper>
        </SecondaryHeader>

        <section className="modal-card-body">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th />
                <th>
                  <abbr>Modifiers Group Name</abbr>
                </th>
                <th>
                  <abbr>Items</abbr>
                </th>
                <th>
                  <abbr>Modifiers</abbr>
                </th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                groups.map(item => (
                  <tr>
                    <td>
                      <CheckBox
                        value={
                          selectedGroups.filter(
                            edge =>
                              edge.modifier_group_id === item.modifier_group_id,
                          ).length !== 0
                        }
                        onChange={value =>
                          value
                            ? addItem(item)
                            : removeItem(item.modifier_group_id)
                        }
                      />
                    </td>
                    <td>{item.name}</td>
                    <td>-</td>

                    <td>
                      <React.Fragment>
                        <p
                          data-tip={join(
                            item.modifier.map(item2 => item2.menu_item.name),
                            ', ',
                          )}
                        >
                          {join(
                            item.modifier.map(item2 => item2.menu_item.name),
                            ', ',
                          )}
                        </p>
                        <ReactTooltip place="top" type="dark" effect="float" />
                      </React.Fragment>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {loading && <Loading />}
          {!loading && groups.length === 0 && (
            <EmptyMessage>
              <p>No Items Found</p>
            </EmptyMessage>
          )}
        </section>
        <footer className="modal-card-foot">
          <button
            type="button"
            className="button is-grey-light"
            aria-label="close"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`button is-primary ${isCreateLinkLoading &&
              'is-loading'}`}
            aria-label="close"
            onClick={() => {
              onSubmit(selectedGroups);
              setSelectedGroups([]);
            }}
            disabled={selectedGroups.length === 0}
          >
            <p has-text-weight-semibold>Add Item(s)</p>
          </button>
        </footer>
      </div>
    </Container>
  );
};

export default SearchItemModal;
