import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import tzLookup from 'tz-lookup';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';

import client from '../../../utils/apolloClient';
import DealForm from './DealForm';
import Layout from '../../../components/global/Layout';

import PlaceSelection from '../../../components/PlaceSelection';

const createDealMutation = gql`
  mutation createDeal($input: PlaceMenuDealInput) {
    create_place_menu_deal(input: $input) {
      deal_id
      error {
        description
      }
    }
  }
`;

// const createImageMutation = gql`
//   mutation createImage($input: ImageInput) {
//     create_image(input: $input) {
//       image_id
//       url
//       error {
//         description
//       }
//     }
//   }
// `;

// const parseImage = images => {
//   const imageValues = [];
//   forEach(images, item => {
//     if (item.url !== '') {
//       imageValues.push({
//         image_id: item.id,
//         url: item.url,
//         link: item.link,
//         tags: item.tags,
//         tooltip: item.tooltip,
//       });
//     }
//   });
//   return imageValues;
// };

const AddPlaceDeal = ({ history }) => {
  const [place, setPlace] = useState();
  const [placeSelection, setPlaceSelection] = useState(false);
  const [currentTimeZone, setCurrentTimeZone] = useState('');

  const setTimeZone = value => {
    console.log('timeZone', value);
    if (value.latitude && value.longitude) {
      const lat = parseFloat(value.latitude).toFixed(5);
      const long = parseFloat(value.longitude).toFixed(5);
      const timeZone = tzLookup(lat, long);
      setCurrentTimeZone(timeZone);

      moment.tz.setDefault(timeZone);
    }
  };

  return (
    <Layout>
      {placeSelection && place ? (
        <View
          place={place}
          history={history}
          currentTimeZone={currentTimeZone}
        />
      ) : (
        <PlaceSelection
          heading="Select Place to add a Deals"
          claimStatus="NONE"
          hasServiceSetting
          handleSelect={value => {
            setPlace(value);
            setTimeZone(value);
            setPlaceSelection(true);
          }}
        />
      )}
    </Layout>
  );
};

const View = ({ place, history, currentTimeZone }) => {
  const { userId } = useStoreState(state => state.auth);

  return (
    <Mutation
      client={client.clientPrivate}
      mutation={createDealMutation}
      onCompleted={({ create_place_menu_deal }) => {
        if (create_place_menu_deal.error) {
          create_place_menu_deal.error.map(item =>
            toast.error(item.description),
          );
        } else {
          swal(
            'Great!',
            'Place Menu Deal had been created successfully!',
            'success',
          ).then(() => {
            history.goBack();
          });
        }
      }}
    >
      {(
        create_place_deal,
        { loading: createDealLoading, error: createDealError },
      ) => (
        <DealForm
          userId={userId}
          currentTimeZone={currentTimeZone}
          isAddDealForm
          error={createDealError}
          loading={createDealLoading}
          place={place}
          onSubmit={input => {
            create_place_deal({ variables: { input } });
          }}
        />
      )}
    </Mutation>
  );
};

export default withRouter(AddPlaceDeal);
