/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { mergeMenuHours, todayHours } from './helpers';

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const FlexItem1 = styled.div`
  width: 50%;
`;

const FlexItem2 = styled.div`
  width: 40%;
`;

const FlexItem3 = styled.div`
  width: 10%;
`;

const OnButton = styled.button`
  &&& {
    background-color: #65b44b;
    font-weight: 600;
    border-top-left-radius: 290486px;
    border-top-right-radius: 290486px;
    border-bottom-right-radius: 290486px;
    border-bottom-left-radius: 290486px;
    border: none;
  }
`;

const OffButton = styled.button`
  &&& {
    background-color: red;
    font-weight: 600;
    border-top-left-radius: 290486px;
    border-top-right-radius: 290486px;
    border-bottom-right-radius: 290486px;
    border-bottom-left-radius: 290486px;
    border: none;
  }
`;

const OrderStatus = ({ place, menus, customHours }) => (
  <div>
    <h3 className="has-text-weight-semibold is-size-2 has-text-primary">
      Today&apos;s Hours
    </h3>
    <br />
    {menus.map(menu => {
      const menuHoursArry = customHours.filter(
        item => item.menu_id === menu.menu_id,
      );
      const finalMenuHours =
        menuHoursArry.length !== 0
          ? mergeMenuHours(menu.menu_hour, menuHoursArry[0], {
              latitude: place.latitude,
              longitude: place.longitude,
            })
          : menu.menu_hour;
      const todayHour = todayHours(finalMenuHours, {
        latitude: place.latitude,
        longitude: place.longitude,
      });

      return (
        <Flex>
          <FlexItem1>
            <div className="is-flex">
              {menu.service_type}: &nbsp;
              <span className="is-flex" style={{ flexDirection: 'column' }}>
                {todayHour.map(item => (
                  <p>
                    {item
                      ? item.start_in_sec === 0 && item.end_in_sec === 86340
                        ? ' Open 24 Hours'
                        : ` ${item.start} - ${item.end}`
                      : ''}
                  </p>
                ))}
              </span>
            </div>
          </FlexItem1>
          <FlexItem2>
            <p className="has-text-weight-semibold">{menu.name}</p>
          </FlexItem2>
          <FlexItem3>
            <div>
              {menu.hours === 'STOPPED' && (
                <OffButton type="button">
                  <p className="has-text-semibold" style={{ color: '#ffffff' }}>
                    OFF
                  </p>
                </OffButton>
              )}
              {menu.hours === 'OPENED' && (
                <OnButton type="button">
                  <p className="has-text-semibold" style={{ color: '#ffffff' }}>
                    Open
                  </p>
                </OnButton>
              )}
              {menu.hours === 'CLOSED' && (
                <OffButton type="button">
                  <p className="has-text-semibold" style={{ color: '#ffffff' }}>
                    Closed
                  </p>
                </OffButton>
              )}
            </div>
          </FlexItem3>
        </Flex>
      );
    })}
  </div>
);

export default OrderStatus;
