import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import moment from 'moment';
import qs from 'querystringify';
import { useStoreState } from 'easy-peasy';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import ListItem from './ListItem';
import {
  Loading,
  Table,
  Box,
  ErrorMessage,
  Pagination,
} from '../../../components/elements';
import client from '../../../utils/apolloClient';
import Search from './Search';

const searchCustomHoursQuery = gql`
  query searchCustomHours($input: SearchInput) {
    search_custom_hours(input: $input) {
      total_pages
      total_size
      custom_hours_listing {
        custom_hour_id
        place_id
        menu_id
        object_type
        day
        menu
        description
        option {
          start
          start_in_sec
          end
          end_in_sec
        }
        closed_all_day
        status
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        created_by_user {
          user_id
          email
          display_name
        }
        updated_by_user {
          user_id
          email
          display_name
        }
        place {
          place_id
          name
          city
        }
      }
    }
  }
`;

const listSize = 20;
const Menu = ({ location, history }) => {
  const { userId } = useStoreState(state => state.auth);
  const routeState = qs.parse(location.search);
  const { placeName, city, objectType, date, page } = routeState;

  const placeNameFilter = placeName || null;
  const cityFilter = city || null;
  const dateFilter = date || null;
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;
  const objectTypeFilter = objectType === 'ALL' ? null : objectType;
  // Pagination
  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState();

  // Pagination

  // Sort
  const [sort, setSort] = useState('UPDATED_DESC');
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');
  // const [updatedBySort, setUpdatedBySort] = useState('AES');
  const [updatedAtSort, setUpdatedAtSort] = useState('AES');

  // Sort

  const input = { user_id: userId };
  const place_filter = {};
  const custom_hour_filter = {};

  Object.assign(
    place_filter,
    placeNameFilter && {
      place_name: placeNameFilter,
    },
    cityFilter && {
      city: cityFilter,
    },
  );
  Object.assign(
    custom_hour_filter,
    dateFilter && {
      date_range: {
        start_date: moment(dateFilter).format('YYYY-MM-DD'),
        end_date: moment(dateFilter)
          .add(1, 'day')
          .format('YYYY-MM-DD'),
      },
    },
    objectTypeFilter && {
      object_type: objectTypeFilter,
    },
  );

  Object.assign(
    input,
    { sort },
    { from: currentPage * listSize, size: listSize },
    {
      filter: { place_filter, custom_hour_filter },
    },
  );

  return (
    <Layout>
      <Header />
      <Search
        history={history}
        date={dateFilter}
        placeName={placeNameFilter}
        city={cityFilter}
        objectType={objectTypeFilter || 'ALL'}
        routeState={routeState}
      />
      {totalElements > 0 && (
        <Pagination
          pageFrom={currentPage * listSize}
          pageCount={pageCount}
          listSize={listSize}
          totalElements={totalElements}
          currentPage={currentPage}
          handlePageClick={value =>
            history.push(
              `/custom-hours${qs.stringify(
                {
                  ...routeState,
                  page: value.selected + 1,
                },
                true,
              )}`,
            )
          }
        />
      )}
      <Box>
        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a onClick={() => {}}>
                      <i className="fas fa-sort-amount-down" />
                    </a>
                    Date
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_DESC'
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_DESC'
                            : 'PLACE_CITY_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    City / Suburb
                  </span>
                </div>
              </th>
              <th>
                <span>Type</span>
              </th>
              <th>
                <span>Menus</span>
              </th>
              <th>
                <div>
                  <span>Updated By</span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setUpdatedAtSort(
                          updatedAtSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          updatedAtSort === 'AES'
                            ? 'UPDATED_DESC'
                            : 'UPDATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          updatedAtSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Updated At
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>Action</span>
                </div>
              </th>
            </tr>
          </thead>
          <Query
            query={searchCustomHoursQuery}
            variables={{ input }}
            fetchPolicy="network-only"
            client={client.clientPrivate}
          >
            {({ data, loading, error, refetch }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return <ErrorMessage message={error.message} />;
              }

              return (
                <React.Fragment>
                  <View
                    data={data}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                    refetch={refetch}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({ data, setPageCount, setTotalElements, refetch }) => {
  const { search_custom_hours } = data;

  useEffect(() => {
    setPageCount(search_custom_hours.total_pages);
    setTotalElements(search_custom_hours.total_size);
  }, [search_custom_hours.total_pages]);

  if (search_custom_hours === null) {
    return 'No record found';
  }

  if (search_custom_hours.custom_hours_listing.length === 0) {
    return 'No record found';
  }
  return (
    <tbody>
      {search_custom_hours.custom_hours_listing.map(hours => (
        <tr key={hours.custom_hour_id}>
          <ListItem hours={hours} refetch={refetch} />
        </tr>
      ))}
    </tbody>
  );
};

export default Menu;
