import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { filter } from 'lodash';
import swal from 'sweetalert';
import useSound from 'use-sound';
import sound from '../../../assets/sound/sms-alert-5-daniel_simon.wav';
import NewOrders from './NewOrders';

import InProgress from './InProgress';
import { getReceivedOrders } from './helpers';
import { Loading } from '../../../components/elements';
import { totalTimeDueActiveCard, totalTimeDelay } from './timeHelpers';

const Container = styled.div`
  padding: 1rem 1rem;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const View = ({
  isLoading,
  currentSubscriptionData,
  receivedOrders,
  isActiveOrderDetailsModal,
  setIsActiveOrderDetailsModal,
  handleCurrentOrder,
  currentOrder,
  // setCurrentOrder,
  setReceivedOrders,
  addOrder,
  addConfirmationOrderAfterReceived,
  setConfirmedOrders,
  confirmedOrders,
  props,
}) => {
  const [showConfirmedTime, setShowConfirmedTime] = useState(null);
  const [play] = useSound(sound);

  useEffect(() => {
    if (currentSubscriptionData) {
      if (currentSubscriptionData.status === 'RECEIVED') {
        setReceivedOrders(receivedOrders.concat(currentSubscriptionData));
        play();
        swal(
          'You have an new Order!',
          'Please review and confirm your order to inform your customer the order is in progress',
          { buttons: ['Ok'] },
        );
        // .then(onfulfilled => {
        //   if (onfulfilled) {
        //     setIsActiveOrderDetailsModal(true);
        //     setCurrentOrder(currentSubscriptionData);
        //   }
        // });
      }
      if (currentSubscriptionData.status === 'CONFIRMED') {
        const newReceivedOrders =
          receivedOrders &&
          filter(
            receivedOrders,
            item =>
              item.menu_order_id !== currentSubscriptionData.menu_order_id,
          );
        setReceivedOrders(newReceivedOrders || []);
      }
    }
  }, [currentSubscriptionData]);

  return (
    <>
      <Container>
        {isLoading && <Loading />}
        {receivedOrders.length !== 0 &&
          receivedOrders.map(item => {
            const receivedStatusHistory = item.received;
            const receivedTime = receivedStatusHistory
              ? receivedStatusHistory.created_at
              : null;

            const due = totalTimeDueActiveCard(item.estimates, receivedTime);
            const delay = totalTimeDelay(item.estimates, receivedTime);

            return (
              <NewOrders
                type="button"
                isActiveOrderDetailsModal={isActiveOrderDetailsModal}
                setIsActiveOrderDetailsModal={setIsActiveOrderDetailsModal}
                handleCurrentOrder={handleCurrentOrder}
                item={item}
                order={currentOrder}
                reference={item.order_reference}
                showConfirmedTime={showConfirmedTime}
                setShowConfirmedTime={setShowConfirmedTime}
                receivedOrders={receivedOrders}
                setReceivedOrders={setReceivedOrders}
                addOrder={addOrder}
                addConfirmationOrderAfterReceived={
                  addConfirmationOrderAfterReceived
                }
              >
                <Flex>
                  <div>
                    <p className="title has-text-white">
                      {item.service_type === 'Delivery' && 'Delivery'}
                      {item.service_type === 'Dinein' && item.table_number
                        ? `Table ${item.table_number}`
                        : item.service_type === 'Dinein' && 'Dinein'}
                      {item.service_type === 'Pickup' && 'Pickup'}
                      {item.large_order && ` (LARGE ORDER)`}
                    </p>
                  </div>
                  <div className="field has-addons">
                    <div className="control">
                      <p className="title has-text-white">NEW</p>
                    </div>
                  </div>
                </Flex>
                <Flex>
                  <div style={{ paddingTop: '8px' }}>
                    <p className="has-text-white">
                      {item.order_reference} | {item.overall_quantity} items | $
                      {item.billing.order_total.toFixed(2)}
                    </p>
                    <p className="has-text-white" style={{ paddingTop: '8px' }}>
                      {item.service_type === 'Delivery' && item.address.city}{' '}
                      {item.service_type === 'Dinein' &&
                        item.no_of_attendees &&
                        `${item.no_of_attendees} Attendees`}
                    </p>
                  </div>
                  <div className="field has-addons">
                    <div className="control">
                      <p
                        className="has-text-white"
                        style={{ paddingTop: '8px' }}
                      >
                        {due}
                        {delay}
                      </p>
                    </div>
                  </div>
                </Flex>
              </NewOrders>
            );
          })}
        {!isLoading && receivedOrders.length === 0 && (
          <>
            <div
              className="has-text-weight-semibold"
              style={{ justifyContent: 'center' }}
            >
              <div
                className="is-flex has-text-weight-semibold"
                style={{ justifyContent: 'center' }}
              >
                <p>No New Order!</p>
              </div>
              <div
                className="is-flex has-text-weight-medium"
                style={{ justifyContent: 'center', alignItems: 'center' }}
              >
                <p className="has-text-centered">
                  All your new orders will appear here, To stop taking orders
                  temporarily for rest of scheduled orders,
                  <br /> Choose green &quot;On&quot; Icon on top
                </p>
                <br />
                <br />
                <br />
              </div>
            </div>
          </>
        )}
      </Container>
      <InProgress
        {...props}
        currentReceivedOrder={currentOrder}
        setConfirmedOrders={setConfirmedOrders}
        confirmedOrders={confirmedOrders}
      />
    </>
  );
};

const Active = props => {
  const [isActiveOrderDetailsModal, setIsActiveOrderDetailsModal] = useState(
    false,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [receivedOrders, setReceivedOrders] = useState([]);
  const [confirmedOrders, setConfirmedOrders] = useState([]);

  const {
    place,
    currentSubscriptionData,
    setOrders,
    handleDecrementCount,
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getReceivedOrders({
        placeId: place.place_id,
        status: 'RECEIVED',
      });
      setIsLoading(false);
      setReceivedOrders(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setOrders(receivedOrders.length);
  }, [receivedOrders, confirmedOrders]);

  const handleCurrentOrder = id => {
    const current = filter(receivedOrders, item => item.menu_order_id === id);
    setCurrentOrder(current[0]);
  };

  const addOrder = () => {
    setConfirmedOrders([
      ...confirmedOrders,
      { ...currentOrder, status: 'CONFIRMED' },
    ]);
    handleDecrementCount();
  };

  const addConfirmationOrderAfterReceived = confirmedOrder => {
    setConfirmedOrders(confirmedOrders.concat(confirmedOrder));
    handleDecrementCount();
  };

  return (
    <View
      isLoading={isLoading}
      currentSubscriptionData={currentSubscriptionData}
      receivedOrders={receivedOrders}
      isActiveOrderDetailsModal={isActiveOrderDetailsModal}
      setIsActiveOrderDetailsModal={setIsActiveOrderDetailsModal}
      handleCurrentOrder={handleCurrentOrder}
      currentOrder={currentOrder}
      setCurrentOrder={setCurrentOrder}
      setReceivedOrders={setReceivedOrders}
      addOrder={addOrder}
      addConfirmationOrderAfterReceived={addConfirmationOrderAfterReceived}
      setConfirmedOrders={setConfirmedOrders}
      confirmedOrders={confirmedOrders}
      props={props}
    />
  );
};

export default Active;
