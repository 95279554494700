import gql from 'graphql-tag';
import uuid from 'uuid';
import { omit, forEach, first } from 'lodash';

import client from '../../../utils/apolloClient';

const searchServiceSettingsQuery = gql`
  query searchSettings($input: SearchInput) {
    search_service_type_setting(input: $input) {
      service_type_setting_listing {
        service_type_setting_id
        place_id
        service_type
        status
        approval_status
        is_custom_service
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getServiceTypeSettings = ({ placeId }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        client: client.clientPublic,
        query: searchServiceSettingsQuery,
        variables: {
          input: {
            filter: {
              service_type_setting_filter: {
                place_id: placeId,
              },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_service_type_setting &&
          data.search_service_type_setting.service_type_setting_listing
        ) {
          resolve(
            data.search_service_type_setting.service_type_setting_listing,
          );
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const initialTime = {
  id: uuid(),
  start: '',
  end: '',
  end_in_sec: null,
  start_in_sec: null,
};

export const defaultHours = [
  {
    day: 'MON',
    is_active: false,
    open24Hours: false,
    option: [initialTime],
  },
  {
    day: 'TUE',
    is_active: false,
    open24Hours: false,
    option: [initialTime],
  },
  {
    day: 'WED',
    is_active: false,
    open24Hours: false,
    option: [initialTime],
  },
  {
    day: 'THU',
    is_active: false,
    open24Hours: false,
    option: [initialTime],
  },
  {
    day: 'FRI',
    is_active: false,
    open24Hours: false,
    option: [initialTime],
  },
  {
    day: 'SAT',
    is_active: false,
    open24Hours: false,
    option: [initialTime],
  },
  {
    day: 'SUN',
    is_active: false,
    open24Hours: false,
    option: [initialTime],
  },
];

export const parseFinalInputHours = hours => {
  const temp = [];
  forEach(hours, item => {
    const hourFinal = omit(item, ['open24Hours', 'option', '__typename']);
    const temp2 = [];
    forEach(item.option, item2 => {
      const finalOption = omit(item2, ['id', '__typename']);
      if (
        finalOption.start &&
        finalOption.end &&
        finalOption.end.length !== 0 &&
        finalOption.start.length !== 0
      ) {
        temp2.push(finalOption);
      }
    });
    temp.push({ ...hourFinal, is_active: temp2.length !== 0, option: temp2 });
  });
  return temp;
};

const checkOpen24Hours = option => {
  if (option.length === 1) {
    const time = first(option);
    if (time.start_in_sec === 0 && time.end_in_sec === 86340) {
      return true;
    }
  }
  return false;
};

export const addIdInputHours = hours => {
  const temp = [];
  forEach(hours, item => {
    const hourFinal = omit(item, ['option', '__typename']);
    const temp2 = [];
    forEach(item.option, item2 => {
      const finalOption = omit(item2, ['__typename']);
      temp2.push({ id: uuid(), ...finalOption });
    });
    const open24Hours = checkOpen24Hours(temp2);
    temp.push({
      ...hourFinal,
      option: temp2,
      open24Hours,
    });
  });
  const final = temp.map(item => ({
    ...item,
    option:
      item.option.length !== 0
        ? item.option
        : [{ id: uuid(), start: '', end: '' }],
  }));
  return final;
};
