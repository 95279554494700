import gql from 'graphql-tag';
import tzLookup from 'tz-lookup';
import moment from 'moment-timezone';
import { isNull } from 'lodash';
import client from '../../../utils/apolloClient';

const searchOrdersQuery = gql`
  query searchOrders($input: SearchInput) {
    search_menu_orders(input: $input) {
      menu_order_listing {
        menu_order_id
        estimated_completion_time
        no_of_attendees
        status
        table_number
        pickup_method
        vehicle_information {
          car_detail
          license_plate
        }
        payment {
          payment_method
          payment_method_id
          payment_intent_id
          payment_status
          payment_card {
            brand
            country
            last4
          }
          payment_at
        }
        menu {
          name
        }
        place {
          latitude
          longitude
        }
        large_order
        include_cuttlery
        address {
          type
          address_line_1
          address_line_2
          city
          state
          post_code
          country
          delivery_option {
            type
            caption
          }
        }
        place_id
        order_reference
        service_type
        received {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        confirmed {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        ready {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        complete {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        cancelled {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        service_type_setting {
          display_table_number
          allow_pickup_options
          pickup_method
          pickup_instructions
          include_vehicle_information
        }

        overall_quantity
        line_item {
          name
          variant_name
          price
          overall_price
          modifier {
            name
            variant_name
            price
            price_per_item
            net_price_per_item
          }
          special_request
          quantity
        }
        contact {
          contact_name
          contact_phone
        }
        billing {
          sub_total
          order_total
          deal_discount
          adjustment_amount
          order_amount
          delivery_fee
        }
        adjustments {
          name
          amount
          note
          adjusted_at
        }
        estimates {
          type
          value_in_mins
        }
        audit {
          created_at
          created_by
        }
      }
    }
  }
`;

const actionOrderMutation = gql`
  mutation actionOrder($input: NavigateMenuOrderInput) {
    action_menu_order(input: $input) {
      menu_order_id
      estimated_completion_time
      status
      table_number
      pickup_method
      vehicle_information {
        car_detail
        license_plate
      }
      payment {
        payment_method
        payment_method_id
        payment_intent_id
        payment_status
        payment_card {
          brand
          country
          last4
        }
        payment_at
      }
      menu_name
      large_order
      no_of_attendees
      address {
        type
        address_line_1
        address_line_2
        city
        state
        post_code
        country
      }
      place_id
      include_cuttlery
      order_reference
      service_type
      received {
        created_by
        created_at
        status
        user_type
        note {
          type
          value
          date
        }
      }
      confirmed {
        created_by
        created_at
        status
        user_type
        note {
          type
          value
          date
        }
      }
      ready {
        created_by
        created_at
        status
        user_type
        note {
          type
          value
          date
        }
      }
      complete {
        created_by
        created_at
        status
        user_type
        note {
          type
          value
          date
        }
      }
      cancelled {
        created_by
        created_at
        status
        user_type
        note {
          type
          value
          date
        }
      }

      overall_quantity
      line_item {
        name
        variant_name
        price
        overall_price
        modifier {
          name
          variant_name
          price
          price_per_item
          net_price_per_item
        }
        special_request
        quantity
      }
      contact {
        contact_name
        contact_phone
      }
      billing {
        sub_total
        order_total
        deal_discount
        adjustment_amount
        order_amount
        delivery_fee
      }
      adjustments {
        name
        amount
        note
        adjusted_at
      }
      estimates {
        type
        value_in_mins
      }
      audit {
        created_at
        created_by
      }
      error {
        description
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getReceivedOrders = ({ placeId, status, dateRange }) =>
  new Promise((resolve, reject) => {
    const menuFilter = {
      place_id: placeId,
      status,
    };
    Object.assign(
      menuFilter,
      dateRange && {
        date_range: dateRange,
      },
    );
    client.clientPublic
      .query({
        client: client.clientPublic,
        query: searchOrdersQuery,
        variables: {
          input: {
            sort: 'CREATED_ASC',
            filter: {
              menu_order_filter: menuFilter,
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_orders &&
          data.search_menu_orders.menu_order_listing
        ) {
          resolve(data.search_menu_orders.menu_order_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const handleOrderAction = ({
  menuId,
  userId,
  action,
  delay,
  note = null,
  adjustments = null,
  reason,
}) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .mutate({
        mutation: actionOrderMutation,
        variables: {
          input: {
            menu_order_id: menuId,
            action,
            user_id: userId,
            delayed_estimate: delay,
            delayed_reason: reason,
            note,
            adjustments,
          },
        },
      })
      .then(({ data }) => {
        if (!isNull(data.action_menu_order.error)) {
          reject(data.action_menu_order.error);
        } else {
          resolve(data.action_menu_order);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

const searchLinksQuery = gql`
  query searchLinks($input: SearchInput) {
    search_menu_item_links(input: $input) {
      menu_item_link_listing {
        item_link_id
        place_id
        menu_section {
          menu_section_id
        }
        variant {
          prompt
          variants {
            variant_id
            variant_price
            availability_status
          }
        }
        menu_item {
          menu_item_id
          name
          price
          image_url
          description
          calories
          kilojoules
          popular
          variant {
            prompt
            variants {
              variant_id
              variant_name
              variant_price
              availability_status
            }
          }
        }
      }
    }
  }
`;

export const getLinks = ({ menuId }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: searchLinksQuery,
        variables: {
          input: {
            filter: { menu_item_link_filter: { menu_id: menuId } },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_item_links &&
          data.search_menu_item_links.menu_item_link_listing
        ) {
          resolve(data.search_menu_item_links.menu_item_link_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const orderStartStopMutation = gql`
  mutation orderStartStop($input: ActionMenuInput) {
    action_menu(input: $input) {
      error {
        code
        description
      }
    }
  }
`;

export const handleOrderStartStopAction = ({ userId, action, menuId }) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .mutate({
        mutation: orderStartStopMutation,
        variables: {
          input: {
            user_id: userId,
            action,
            menu_id: menuId,
          },
        },
      })
      .then(({ data }) => {
        console.log('data', data);
        resolve(data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

const searchOrderCountQuery = gql`
  query searchOrderCount($input: SearchInput) {
    search_menu_orders(input: $input) {
      menu_order_aggregation {
        total_order_by_status {
          status
          count
        }
      }
    }
  }
`;

export const getOrderCount = ({ placeId }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        client: client.clientPublic,
        query: searchOrderCountQuery,
        variables: {
          input: {
            filter: {
              menu_order_filter: {
                place_id: placeId,
              },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_orders &&
          data.search_menu_orders.menu_order_aggregation
        ) {
          resolve(
            data.search_menu_orders.menu_order_aggregation
              .total_order_by_status,
          );
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const parsedTime = () => {
  const timeZone = tzLookup(-37.8597673, 144.9836167);
  const time = moment.tz('2020-08-16T19:52:21.883Z', timeZone);
  console.log('time', time);
  return 123;
};

export const getMinutes = time => {
  const now = moment();
  const startTime = moment(time);
  const duration = moment.duration(now.diff(startTime));
  const minutes = duration.asMinutes();
  console.log('minutes', minutes);
  return minutes.toFixed(1);
};

export const parsedAmount = amount => {
  const actualAmount = Math.sign(amount);
  if (actualAmount === -1) {
    return `-$${amount.slice(1)}`;
  }
  return `$${amount}`;
};

const searchMenusQuery = gql`
  query searchMenus($input: SearchInput) {
    search_menus(input: $input) {
      menu_listing {
        menu_id
        name
        service_type
        service_type_setting {
          service_type_setting_id
        }
        hours
        stopped_hour {
          start
          end
        }
        status
        menu_hour {
          day
          is_active
          option {
            start
            end
            start_in_sec
            end_in_sec
          }
        }
        holiday_hour {
          day
          is_active
          option {
            start
            end
            start_in_sec
            end_in_sec
          }
        }
      }
    }
  }
`;

export const getMenus = ({ userId, placeId }) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        query: searchMenusQuery,
        variables: {
          input: {
            user_id: userId,
            filter: {
              place_filter: {
                place_id: placeId,
              },
              menu_filter: {
                status: 'ACTIVE',
                service_types: ['Pickup', 'Dinein', 'Delivery'],
                has_hours: true,
              },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data.search_menus && data.search_menus.menu_listing) {
          resolve(data.search_menus.menu_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
