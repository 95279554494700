import React, { useState } from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';

import { capitalize } from 'lodash';
import { Loading, Table } from '../../../components/elements';
import OrderDetailsModal from '../OrderDashboard/OrderDetailsModal';

const ReferenceStyled = styled.div`
  color: #3273dc;
  cursor: pointer;
`;

const StatusStyled = styled.td`
  color: ${({ color }) => color};
  font-size: 1.2rem;
`;

const AesDes = ({ sort, setSort, aes, des }) => (
  <a onClick={() => setSort(sort === des ? aes : des)}>
    <i className={`fas fa-sort-amount-${sort === des ? 'up' : 'down'}`} />
  </a>
);

const All = ({ orders, isLoading, sort, setSort }) => {
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [orderData, setOrderData] = useState();

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="ORDER_REFERENCE_ASC"
                    des="ORDER_REFERENCE_DESC"
                  />
                  Order No.
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="SERVICE_TYPE_ASC"
                    des="SERVICE_TYPE_DESC"
                  />
                  Type
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="CREATED_ASC"
                    des="CREATED_DESC"
                  />
                  Order Date
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>Customer</span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="OVERALL_QUANTITY_ASC"
                    des="OVERALL_QUANTITY_DESC"
                  />
                  Items
                </span>
              </div>
            </th>

            <th>
              <div>
                <div>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="ORDER_TOTAL_ASC"
                    des="ORDER_TOTAL_DESC"
                  />
                  <span>Total</span>
                </div>
              </div>
            </th>

            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="PAYMENT_METHOD_ASC"
                    des="PAYMENT_METHOD_DESC"
                  />
                  Payment
                </span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="STATUS_ASC"
                    des="STATUS_DESC"
                  />
                  Status
                </span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="UPDATED_ASC"
                    des="UPDATED_DESC"
                  />
                  Status Date
                </span>
              </div>
            </th>

            <th>
              <div>
                <span />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <Loading />}
          {!isLoading && orders.length === 0 && <div>No orders found</div>}
          {!isLoading &&
            orders.map(item => {
              const handleTimeLineTime = status => {
                const getStatus = () => {
                  if (status === 'RECEIVED') {
                    return 'received';
                  }
                  if (status === 'CONFIRMED') {
                    return 'confirmed';
                  }
                  if (status === 'READY') {
                    return 'ready';
                  }
                  if (status === 'COMPLETE') {
                    return 'complete';
                  }
                  if (status === 'CANCEllED') {
                    return 'cancelled';
                  }
                  return 'received';
                };
                const orderStatus = item[getStatus()];
                const time =
                  (orderStatus &&
                    moment(orderStatus.created_at).format('LLL')) ||
                  '---';
                return time;
              };
              return (
                <tr>
                  <td>
                    <ReferenceStyled
                      onClick={() => {
                        setOrderData(item);
                        setIsActiveModal(true);
                      }}
                    >
                      {item.order_reference}
                    </ReferenceStyled>
                  </td>
                  <td>{item.service_type}</td>
                  <td>{moment(item.audit.created_at).format('LLL')}</td>
                  <td>
                    {(item.contact && item.contact.contact_name) || '---'}
                  </td>
                  <td>{item.overall_quantity} items</td>
                  <td>${item.billing.order_total || 0}</td>
                  <td>
                    {item.payment && capitalize(item.payment.payment_method)}
                  </td>
                  <td>{item.status}</td>
                  <td>{handleTimeLineTime(item.status)}</td>
                  <StatusStyled
                    color={
                      // eslint-disable-next-line no-nested-ternary
                      item.status === 'CANCELLED'
                        ? 'red'
                        : item.status === 'DRAFT'
                        ? 'grey'
                        : 'green'
                    }
                  >
                    {item.status === 'CANCELLED' ? (
                      <i className="far fa-times-circle" />
                    ) : (
                      <i className="far fa-check-circle" />
                    )}
                  </StatusStyled>
                </tr>
              );
            })}
        </tbody>
      </Table>

      {orderData && isActiveModal && (
        <OrderDetailsModal
          onClose={() => setIsActiveModal(false)}
          isActive={isActiveModal}
          order={orderData}
        />
      )}
    </>
  );
};

export default All;
