import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { Accordion } from 'react-accessible-accordion';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';

import LinkItem from './LinkItem';
import { finalLinkListingItemDataParser } from './helpers';
import client from '../../../utils/apolloClient';

const Container = styled(Accordion)``;

const updateAvailabilityItemLevelMutation = gql`
  mutation updateAvailability($input: AvailabilityInput) {
    update_availability(input: $input) {
      message
      error {
        code
        description
      }
    }
  }
`;

const AvailabilityList = ({ links, place }) => {
  const { userId } = useStoreState(state => state.auth);
  const [updateLinkLevel, { loading }] = useMutation(
    updateAvailabilityItemLevelMutation,
    {
      client: client.clientPrivate,
      onCompleted: ({ update_availability }) => {
        console.log('update_availability', update_availability);
      },
    },
  );

  return (
    <Container>
      {links.map(item => (
        <LinkItem
          loading={loading}
          placeId={place.place_id}
          link={finalLinkListingItemDataParser({ link: item })}
          onHandleLinkSubmission={(date, action, otherValues) => {
            if (action === 'ITEM_LINK') {
              updateLinkLevel({
                variables: {
                  input: {
                    type: action,
                    user_id: userId,
                    item_link_id: item.item_link_id,
                    availability_date: date,
                    availability_status: date ? 'UNAVAILABLE' : 'AVAILABLE',
                  },
                },
              });
            }
            if (action === 'ITEM_LINK_VARIANT') {
              updateLinkLevel({
                variables: {
                  input: {
                    type: action,
                    user_id: userId,
                    item_link_id: item.item_link_id,
                    prompt_id: otherValues.prompt_id,
                    variant_id: otherValues.variant_id,
                    availability_date: date,
                    availability_status: date ? 'UNAVAILABLE' : 'AVAILABLE',
                  },
                },
              });
            }
          }}
          onHandleLinkModifierSubmission={(date, action, otherValues) => {
            if (action === 'MODIFIER_ITEM_LINK') {
              updateLinkLevel({
                variables: {
                  input: {
                    type: action,
                    user_id: userId,
                    modifier_id: otherValues.modifier_id,
                    item_link_id: item.item_link_id,
                    availability_date: date,
                    availability_status: date ? 'UNAVAILABLE' : 'AVAILABLE',
                  },
                },
              });
            }
            if (action === 'MODIFIER_ITEM_LINK_VARIANT') {
              updateLinkLevel({
                variables: {
                  input: {
                    type: action,
                    user_id: userId,
                    modifier_id: otherValues.modifier_id,
                    item_link_id: item.item_link_id,
                    prompt_id: otherValues.prompt_id,
                    variant_id: otherValues.variant_id,
                    availability_date: date,
                    availability_status: date ? 'UNAVAILABLE' : 'AVAILABLE',
                  },
                },
              });
            }
          }}
        />
      ))}
    </Container>
  );
};

export default AvailabilityList;
