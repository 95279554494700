import gql from 'graphql-tag';
import moment from 'moment';

import client from '../../../utils/apolloClient';

const searchOrdersQuery = gql`
  query searchOrders($input: SearchInput) {
    search_menu_orders(input: $input) {
      menu_order_listing {
        menu_order_id
        estimated_completion_time
        no_of_attendees
        status
        table_number
        pickup_method
        vehicle_information {
          car_detail
          license_plate
        }
        payment {
          payment_method
          payment_method_id
          payment_intent_id
          payment_status
          payment_card {
            brand
            country
            last4
          }
          payment_at
        }
        menu {
          name
        }
        place {
          latitude
          longitude
          name
          city
        }
        large_order
        include_cuttlery
        address {
          type
          address_line_1
          address_line_2
          city
          state
          post_code
          country
          delivery_option {
            type
            caption
          }
        }
        place_id
        order_reference
        service_type
        received {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        confirmed {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        ready {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        complete {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        cancelled {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        transitioned_at
        received_at
        overall_quantity
        line_item {
          name
          variant_name
          price
          overall_price
          modifier {
            name
            variant_name
            price
            price_per_item
            net_price_per_item
          }
          special_request
          quantity
        }
        contact {
          contact_name
          contact_phone
        }
        billing {
          sub_total
          order_total
          deal_discount
          adjustment_amount
          order_amount
          delivery_fee
        }
        adjustments {
          name
          amount
          note
          adjusted_at
        }
        estimates {
          type
          value_in_mins
        }
        service_type_setting {
          display_table_number
          allow_pickup_options
          pickup_method
          pickup_instructions
          include_vehicle_information
        }
        audit {
          created_at
          created_by
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getOrders = input =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        client: client.clientPublic,
        query: searchOrdersQuery,
        variables: {
          input,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data.search_menu_orders && data.search_menu_orders) {
          resolve(data.search_menu_orders);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

const searchOrderCountQuery = gql`
  query searchOrderCount($input: SearchInput) {
    search_menu_order_dashboard(input: $input) {
      received
      confirmed
      ready
      complete
      cancelled
      draft
      scheduled
    }
  }
`;

export const getOrderCount = input =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        client: client.clientPublic,
        query: searchOrderCountQuery,
        variables: {
          input,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        resolve(data.search_menu_order_dashboard);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getTimePassed = time => {
  const startDate = moment(time);
  const elapsedDuration = moment.duration(moment().diff(startDate));
  // in minutes
  const elapsedTimeObj = {
    minutes: elapsedDuration.asMinutes().toFixed(),
    hours: elapsedDuration.asHours().toFixed(),
    days: elapsedDuration.asDays().toFixed(),
    months: elapsedDuration.asMonths().toFixed(),
  };

  if (elapsedTimeObj.minutes > '59') {
    return `${elapsedTimeObj.hours} hours`;
  }
  if (elapsedTimeObj.hours > '23') {
    return `${elapsedTimeObj.days} days`;
  }

  if (elapsedTimeObj.days > '31') {
    return `${elapsedTimeObj.months} months`;
  }

  return moment(elapsedDuration).fromNow();
};

export const getMinutes = time => {
  const now = moment(new Date());
  const startTime = moment(time);
  const duration = moment.duration(now.diff(startTime));
  const minutes = duration.asMinutes();

  return minutes.toFixed(1);
};
