import React from 'react';
import AsyncSelect from 'react-select/async';
import { Field, Label, Control } from 'rbx';
import gql from 'graphql-tag';
import client from '../../../utils/apolloClient';

const searchServiceQuery = gql`
  query searchService($input: SearchInput) {
    search_service_type(input: $input) {
      total_size
      total_pages
      service_type_listing {
        service_type_id
        name
        status
        description
      }
    }
  }
`;

const promiseOptions = () =>
  new Promise(resolve => {
    client.clientPublic
      .query({
        query: searchServiceQuery,
        variables: {
          input: { filter: { service_type_filter: { status: 'ACTIVE' } } },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data) {
          resolve(
            data.search_service_type.service_type_listing.map(item => ({
              label: item.name,
              value: item.service_type_id,
            })),
          );
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  });

const customStyles = {
  container: provided => ({
    ...provided,
  }),
  control: provided => ({
    ...provided,
    borderRadius: '3px',
    minHeight: '1px',
    // height: '29px',
    fontSize: '0.8rem',
  }),
  input: provided => ({
    ...provided,
    minHeight: '1px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    minHeight: '1px',
    paddingTop: '0',
    paddingBottom: '0',
    color: '#757575',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    minHeight: '1px',
    height: '11px',
  }),
  clearIndicator: provided => ({
    ...provided,
    minHeight: '1px',
  }),
  valueContainer: provided => ({
    ...provided,
    minHeight: '1px',
    // height: '27px',
    paddingTop: '0',
    paddingBottom: '0',
  }),
  singleValue: provided => ({
    ...provided,
    minHeight: '1px',
    paddingBottom: '2px',
  }),
  option: provided => ({
    ...provided,
    fontSize: '0.8rem',
  }),
};

const ServiceTypeInput = ({ label, required, value, onChange, isDisabled }) => (
  <Field>
    <Label className="is-flex">
      {label}
      {required && <span className="has-text-danger">*</span>}
    </Label>
    <Control>
      <AsyncSelect
        value={value}
        cacheOptions
        styles={customStyles}
        loadOptions={promiseOptions}
        defaultOptions
        onChange={onChange}
        isDisabled={isDisabled}
      />
    </Control>
  </Field>
);

export default ServiceTypeInput;
