/* eslint-disable no-param-reassign */
import { action, thunk } from 'easy-peasy';
import gql from 'graphql-tag';
import { cognitoSignIn } from '../utils/auth';

import client from '../utils/apolloClient';

const fetchUserQuery = gql`
  query fetch_user($input: NavigateUserInput) {
    fetch_user(input: $input) {
      user_id
      first_name
      last_name
      display_name
      email
      color_code
      preferred_username
      role
    }
  }
`;

export default {
  auth: {
    userId: null,
    user: {},
    isLoggedIn: false,
    authTokens: {},
    loading: false,
    isError: { message: '', error: false },

    setAuthTokens: action((state, payload) => {
      state.authTokens = payload;
      state.isLoggedIn = true;
      state.userId = payload.payload['custom:user_id'];
    }),

    setError: action((state, payload) => {
      state.isError = payload;
    }),

    clearError: action(state => {
      state.isError = { message: '', error: false };
    }),

    setLoading: action((state, payload) => {
      state.loading = payload;
    }),

    updateUser: action((state, payload) => {
      state.user = payload;
    }),

    logIn: thunk(async (actions, payload) => {
      const { username, password, history } = payload;
      try {
        actions.setError({ message: '', error: false });
        actions.setLoading(true);
        const data = await cognitoSignIn(username, password);
        await window.localStorage.setItem('token', data.idToken);
        await window.localStorage.setItem('username', username);
        await window.localStorage.setItem('accessToken', data.accessToken);
        await window.localStorage.setItem('refreshToken', data.refreshToken);
        await client.clientPrivate
          .query({
            query: fetchUserQuery,
            variables: { input: { user_id: data.payload['custom:user_id'] } },
            fetchPolicy: 'network-only',
          })
          .then(({ data: result }) => {
            if (result.fetch_user) {
              actions.updateUser(result.fetch_user);
            }
          });
        await actions.setAuthTokens(data);
        await actions.setLoading(false);
        history.push('/');
      } catch (err) {
        const error = {
          message: 'Please Enter valid email address and password',
          error: true,
        };
        await actions.setError(error);
        console.log('err', err);
        await actions.setLoading(false);
      }
    }),
    logout: action((state, payload) => {
      state.user = null;
      state.loading = false;
      state.isError = { message: '', error: false };
      state.isLoggedIn = false;
      state.authTokens = {};
      state.token = null;
      state.userId = null;
      window.localStorage.clear();
      payload.push('/login');
    }),
  },
};
