import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { first } from 'lodash';

import { Select } from '../../../../components/elements';
import List from './List';
import AddEditCustomHour from './AddEditCustomHour';
import { getMenuCustomHours } from './helpers';

const Container = styled.div`
  padding: 0rem 2rem;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 40%;
`;

const FlexItem1 = styled.div`
  width: 20%;
`;

const FlexItem2 = styled.div`
  width: 80%;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const Middle = styled.div`
  padding: 1rem 0rem;
`;

const ButtonText = styled.p`
  font-weight: 600;
  font-size: 12px;
`;

const Button = styled.button`
  &&& {
    background-color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 35%;
    height: 5vh;
  }
`;

const AddEditCustomHours = ({ userId, place, menus }) => {
  const [selectedMenu, setSelectedMenu] = useState();
  const [isActive, setIsActive] = useState(false);

  const [holidays, setHolidays] = useState([]);
  const [holidayLoading, setHolidayLoading] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const fetchHours = useCallback(async input => {
    setHolidayLoading(true);
    const data = await getMenuCustomHours(input);
    setHolidayLoading(false);
    setHolidays(data);
  }, []);

  useEffect(() => {
    if (menus.length !== 0) {
      const filterMenu = selectedMenu
        ? menus.filter(item => item.menu_id === selectedMenu.menu_id)
        : [];
      if (filterMenu.length !== 0) {
        setSelectedMenu(first(filterMenu));
      } else {
        setSelectedMenu(first(menus));
      }
    }
  }, [menus]);

  return (
    <>
      {selectedMenu && isActive && (
        <AddEditCustomHour
          place={place}
          selectedMenu={selectedMenu}
          isActive={isActive}
          setIsActive={setIsActive}
          fetchHours={fetchHours}
          customHour={updateData}
          setUpdateData={setUpdateData}
        />
      )}
      <Container>
        <FlexRow>
          <Flex>
            <FlexItem1>
              <h3 className="has-text-weight-semibold is-size-1">Menu</h3>
            </FlexItem1>
            <FlexItem2>
              <Select
                placeholder="Select Menu"
                value={selectedMenu ? { ...selectedMenu } : null}
                options={menus}
                onChange={value => {
                  setSelectedMenu(value);
                }}
                isLoading={false}
              />
            </FlexItem2>
          </Flex>
          <Button
            className="button is-primary"
            onClick={() => setIsActive(true)}
          >
            <ButtonText>ADD A NEW DATE</ButtonText>
          </Button>
        </FlexRow>
        {selectedMenu && (
          <Middle>
            <p className="has-text-weight-semibold is-size-2">
              Holiday or Custom hours for: {selectedMenu.service_type} -{' '}
              {selectedMenu.name}
            </p>
            <br />
            <p>
              Add a date and set your menu hours if you plan to close for a
              holiday, stay open for a limited hours or even to stay open for
              longer. To make changes to your regular menu hours go to
              &apos;Menu Hours&apos; in the previous tab.
            </p>
          </Middle>
        )}
      </Container>
      {selectedMenu && (
        <List
          holidays={holidays}
          holidayLoading={holidayLoading}
          userId={userId}
          placeId={place.place_id}
          menuId={selectedMenu.menu_id}
          fetchHours={fetchHours}
          onHandleUpdate={values => {
            setUpdateData(values);
            setIsActive(true);
          }}
        />
      )}
    </>
  );
};

export default AddEditCustomHours;
