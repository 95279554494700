import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Tab, Column } from 'rbx';
import uuidv4 from 'uuid/v4';
import { isNull, omit } from 'lodash';
import moment from 'moment-timezone';

import { Box, Tabs, Select } from '../../../components/elements';
import Header from './Header';
import Footer from './Footer';
import ListingOnly from './ListingOnly';
import DealDetailDiscount from './DealDetailDiscount';
import ExtraDetails from './ExtraDetails';
import { getMenuTypeDeals } from '../../../utils/fetchStatic';
import { getMenus } from './helpers';

const Container = styled.div`
  padding: 1rem;
`;

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);
  const [dealTypes, setDealTypes] = useState([]);
  const [menus, setMenus] = useState([]);
  const [menusLoading, setMenusLoading] = useState(false);

  const { handleSubmit, place, values, loading, setFieldValue } = props;

  const prev = () =>
    activeTab === 0 ? setActiveTab(2) : setActiveTab(activeTab - 1);
  const next = () =>
    activeTab === 2 ? setActiveTab(0) : setActiveTab(activeTab + 1);

  const getMenusData = useCallback(async () => {
    setMenusLoading(true);
    const list = await getMenus({ placeId: values.place_id });
    setMenus(
      list.map(item => ({
        label: `${item.service_type} - ${item.name}`,
        value: item.menu_id,
      })),
    );
    setMenusLoading(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const dealTypesData = await getMenuTypeDeals();
      const types = dealTypesData.map(item => ({
        label: item.description,
        value: item.name,
      }));
      setDealTypes(types);
    };

    fetchData();
    getMenusData();
  }, []);

  const dealTypeArray =
    dealTypes.length !== 0
      ? dealTypes.filter(item => item.value === values.deal_type)
      : [];

  const menuArray =
    menus.length !== 0
      ? menus.filter(item => item.value === values.menu_id)
      : [];

  const dealType = dealTypeArray.length !== 0 ? dealTypeArray[0].label : '';
  const menuName = menuArray.length !== 0 ? menuArray[0].label : '';

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Header
        activeTab={activeTab}
        heading="Place and Menu deal"
        placeName={`${place.name} | ${place.city}, ${place.post_code} | ${place.country}`}
        dealName={values.name}
        loading={loading}
        prev={prev}
        next={next}
      />
      {values.step === 1 && (
        <>
          <Container>
            <Column.Group vcentered multiline gapSize={8}>
              <Column size={4}>
                <Select
                  label="Select discount type"
                  options={dealTypes}
                  onChange={value => {
                    console.log('value.value', value.value);
                    setFieldValue('deal_type', value.value);
                    if (value.value === 'listing_only') {
                      setFieldValue('step', 2);
                    }
                    if (value.value === 'delivery_fee_discount') {
                      setFieldValue('free_delivery', true);
                      setFieldValue('name', 'Free Delivery when you spend $0');
                    }
                    if (value.value === 'flat_amount_discount') {
                      setFieldValue('name', 'Spend $0 and Save $0');
                    }
                    if (value.value === 'percentage_discount') {
                      setFieldValue('name', 'Spend of $0 and Save 0%');
                    }
                  }}
                  required
                />
              </Column>
              {values.deal_type && values.deal_type !== 'listing_only' && (
                <Column size={4}>
                  <Select
                    label="Select menu"
                    name="menu"
                    options={menus}
                    onChange={value => {
                      setFieldValue('menu_id', value.value);
                      setFieldValue('step', 2);
                    }}
                    isLoading={menusLoading}
                    required
                  />
                </Column>
              )}
            </Column.Group>
          </Container>
        </>
      )}

      {values.step === 2 && (
        <Box>
          <Tabs className="tabs is-medium">
            {values.deal_type === 'listing_only' && (
              <Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>
                Deal Details
              </Tab>
            )}
            {values.menu_id && (
              <>
                <Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>
                  Deal Details
                </Tab>

                <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
                  Extra Details
                </Tab>
              </>
            )}
          </Tabs>

          <Container>
            {values.deal_type === 'listing_only' && (
              <>
                {activeTab === 0 && (
                  <ListingOnly
                    {...props}
                    dealTypes={dealTypes}
                    dealType={dealType}
                    menuName={menuName}
                  />
                )}
              </>
            )}
            {values.menu_id && (
              <>
                {activeTab === 0 && (
                  <DealDetailDiscount
                    {...props}
                    dealType={dealType}
                    menuName={menuName}
                  />
                )}
                {activeTab === 1 && (
                  <ExtraDetails
                    {...props}
                    dealType={dealType}
                    menuName={menuName}
                  />
                )}
              </>
            )}
          </Container>
        </Box>
      )}
      {values.step !== 1 && (
        <Footer activeTab={activeTab} loading={false} prev={prev} next={next} />
      )}
    </form>
  );
};

const DealForm = withFormik({
  mapPropsToValues: ({ place, deal, userId }) => {
    const startDate = deal && !isNull(deal.start) ? moment(deal.start) : null;
    const endDate = deal && !isNull(deal.end) ? moment(deal.end) : null;
    return {
      step: deal && !isNull(deal.deal_id) ? 2 : 1,
      user_id: userId,
      startDate: deal ? moment(startDate).toISOString() : null,
      startTime: deal ? moment(startDate).toISOString() : null,
      endDate: deal ? moment(endDate).toISOString() : null,
      endTime: deal ? moment(endDate).toISOString() : null,
      deal_id: deal && !isNull(deal.deal_id) ? deal.deal_id : uuidv4(),
      place_id: deal && !isNull(deal.place_id) ? deal.place_id : place.place_id,
      menu_id: deal && !isNull(deal.menu_id) ? deal.menu_id : null,
      menu_item: deal && !isNull(deal.menu_item) ? deal.menu_item : [],
      deal_type: deal && !isNull(deal.deal_type) ? deal.deal_type : null,
      promotion: deal && !isNull(deal.promotion) ? deal.promotion : 'AUTOMATIC',
      promo_code: deal && !isNull(deal.promo_code) ? deal.promo_code : '',
      name: deal && !isNull(deal.name) ? deal.name : '',
      service_type: deal && !isNull(deal.service_type) ? deal.service_type : [],
      slug: deal && !isNull(deal.slug) ? deal.slug : null,
      description: deal && !isNull(deal.description) ? deal.description : '',
      tag: deal && !isNull(deal.tag) ? deal.tag : [],
      is_published:
        deal && !isNull(deal.is_published) ? deal.is_published : true,
      customer_type:
        deal && !isNull(deal.customer_type)
          ? deal.customer_type
          : 'ALL_CUSTOMERS',
      can_be_clubbed:
        deal && !isNull(deal.can_be_clubbed) ? deal.can_be_clubbed : false,
      minimum_purchase_amount:
        deal && !isNull(deal.minimum_purchase_amount)
          ? parseFloat(deal.minimum_purchase_amount).toFixed(2)
          : null,
      value:
        deal && !isNull(deal.value) ? parseFloat(deal.value).toFixed(2) : null,
      maximum_discount_amount:
        deal && !isNull(deal.maximum_discount_amount)
          ? parseFloat(deal.maximum_discount_amount).toFixed(2)
          : null,
      maximum_no_of_discounts:
        deal && !isNull(deal.maximum_no_of_discounts)
          ? deal.maximum_no_of_discounts
          : -1,
      maximum_discount:
        deal && !isNull(deal.maximum_discount)
          ? parseFloat(deal.maximum_discount).toFixed(2)
          : null,
      maximum_no_of_discounts_per_user:
        deal && !isNull(deal.maximum_no_of_discounts_per_user)
          ? deal.maximum_no_of_discounts_per_user
          : -1,
      maximum_discount_per_user:
        deal && !isNull(deal.maximum_discount_per_user)
          ? deal.maximum_discount_per_user
          : null,
      status: deal && !isNull(deal.status) ? deal.status : 'ACTIVE',
      zone_setting:
        deal && !isNull(deal.zone_setting) ? deal.zone_setting : null,
      free_delivery:
        deal && !isNull(deal.free_delivery) ? deal.free_delivery : null,
    };
  },

  validate: values => {
    const errors = {};
    console.log('bfjefwjbf', values);
    if (values.deal_type !== 'listing_only') {
      if (!values.minimum_purchase_amount) {
        errors.minimum_purchase_amount = 'Min Purchase is required';
      }
      if (!values.value) {
        errors.value = 'Discount is required';
      }
      if (
        values.deal_type === 'flat_amount_discount' &&
        values.minimum_purchase_amount
      ) {
        const priceOfSeventyPercent =
          (parseFloat(values.minimum_purchase_amount) * 70) / 100;
        if (values.value > priceOfSeventyPercent) {
          errors.value =
            'Discount amount cannot exceed 70% of minimum purchase';
        }
      }
      if (values.deal_type === 'percentage_discount' && values.value) {
        if (parseFloat(values.value) > 70) {
          errors.value = 'Discount amount cannot exceed 70%';
        }
      }
      if (
        values.deal_type === 'percentage_discount' &&
        !values.maximum_discount_amount
      ) {
        errors.maximum_discount_amount = 'Max Discount is required';
      }
    }

    return errors;
  },

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .min(4, 'At least 4 Characters Required!')
      .max(50, 'Not greater than 50 Characters!')
      .required('Deal tagline is required!'),

    description: yup
      .string()
      .min(26, 'At least 26 Characters Required!')
      .max(1200, 'Not greater than 1200 Characters!'),
    startDate: yup
      .string()
      .transform(v => (v === null ? '' : v))
      .required('Start date is required'),
    endDate: yup
      .string()
      .transform(v => (v === null ? '' : v))
      .required('End date is required'),
  }),

  handleSubmit: (values, { props }) => {
    const input = omit(values, [
      'service_type',
      'startDate',
      'endDate',
      'startTime',
      'endTime',
      'step',
      'promo_code',
      'promotion',
    ]);

    const customStartDate = moment(values.startDate).format('YYYY-MM-DD');
    const startTimeWhenValueIsNull = moment(values.startDate).isSame(moment())
      ? moment().format('HH:mm:ss')
      : '00:00:00';

    const customStartTime = !isNull(values.startTime)
      ? moment(values.startTime).format('HH:mm:ss')
      : startTimeWhenValueIsNull;

    const customEndDate = moment(values.endDate).format('YYYY-MM-DD');
    const customEndTime = !isNull(values.endTime)
      ? moment(values.endTime).format('HH:mm:ss')
      : '23:59:00';

    // const startDate = moment(
    //   `${customStartDate} ${customStartTime}`,
    // ).toISOString();

    // const endDate = moment(`${customEndDate} ${customEndTime}`).toISOString();

    const startDate = moment
      .tz(`${customStartDate} ${customStartTime}`, props.currentTimeZone)
      .add(10, 'minutes')
      .toISOString();

    const endDate = moment
      .tz(`${customEndDate} ${customEndTime}`, props.currentTimeZone)
      .toISOString();

    const inputs = {
      ...input,
    };
    Object.assign(
      inputs,
      props.deal
        ? props.deal.promo_code !== values.promo_code &&
            values.promotion === 'PROMOCODE' && {
              promo_code: values.promo_code,
              promotion: values.promotion,
            }
        : {
            promo_code: values.promo_code,
            promotion: values.promotion,
          },
      props.deal &&
        values.promotion === 'AUTOMATIC' && {
          promo_code: null,
          promotion: values.promotion,
        },
      props.deal
        ? props.deal.start !== startDate && { start: startDate }
        : { start: startDate },
      props.deal
        ? props.deal.end !== endDate && { end: endDate }
        : { end: endDate },
    );

    props.onSubmit(inputs);
  },

  displayName: 'DealForm',
})(Form);

export default DealForm;
