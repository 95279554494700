import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.6rem;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.primaryColor};
  margin-bottom: 1rem;
`;

const Spacing = styled.div`
  padding: 0.05rem 0rem;
`;

const Information = ({ order }) => (
  <Container>
    <div>
      <Spacing>
        {order.menu && <p className="is-size-3">{order.menu.name}</p>}
      </Spacing>
      <Spacing>
        <p className="is-size-4">
          {order.order_reference}, {order.overall_quantity} items, $
          {order.billing.order_total.toFixed(2)}
        </p>
      </Spacing>
      {order.contact && (
        <Spacing>
          <p className="is-size-4 is-capitalized">
            {order.contact.contact_name}, {order.contact.contact_phone}
          </p>
        </Spacing>
      )}
    </div>
    <div>
      <p
        className="is-size-3 has-text-weight-semibold"
        style={{ color: '#ee2a7b' }}
      >
        {order.large_order && 'LARGE ORDER | '}{' '}
        {order.payment && order.payment.payment_method === 'CASH'
          ? 'CASH ORDER'
          : order.payment.payment_method}
      </p>
    </div>
  </Container>
);

export default Information;
