import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { first } from 'lodash';
import DeletePlaceAction from './DeletePlaceAction';

const PlaceList = ({ place, users = [] }) => {
  const userArray = users.filter(
    item => item.user_id === place.audit.updated_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;

  return (
    <React.Fragment>
      <td>
        <Link to={`/update-place-posting/${place.object_id}/${place.feed_id}`}>
          {place.place.name}
        </Link>
      </td>
      <td>{place.place.city}</td>
      <td>{place.title}</td>
      {/* <td>{place.status}</td> */}
      <td>
        {user ? (
          <React.Fragment>
            <p data-tip={`${user.first_name} ${user.last_name}`}>
              {`${user.first_name} ${user.last_name}`}
            </p>
            <ReactTooltip place="top" type="dark" effect="float">
              <span>
                <p>email: {user && user.email}</p>
                <p>objectId: {user && user.user_id}</p>
              </span>
            </ReactTooltip>
          </React.Fragment>
        ) : (
          place.audit.updated_by
        )}
      </td>
      <td>{moment(place.audit.updated_at).format('lll')}</td>{' '}
      <td>
        <a>
          <DeletePlaceAction placeId={place.place_id} />
        </a>
      </td>
    </React.Fragment>
  );
};

export default PlaceList;
