import React from 'react';
import styled from 'styled-components';
import { Field, Label } from 'rbx';
import AsyncReactSelectInput from './AsyncReactSelectInput';
import InputErrorMessage from './InputErrorMessage';

const OptionalText = styled.p`
  &&& {
    color: #737373;
    padding-left: 2px;
  }
`;

const TagInputs = ({
  label,
  value,
  required,
  onChange,
  suggestions,
  errors,
  touched,
  disabled,
  optionalText,
  promiseFunction,
  defaultOptions,
}) => (
  <Field>
    {label && (
      <Label className="is-flex">
        {label}
        {required && <span className="has-text-danger">*</span>}
        {optionalText && <OptionalText>{optionalText}</OptionalText>}
      </Label>
    )}

    <div className="control">
      <AsyncReactSelectInput
        defaultValue={value}
        promiseFunction={promiseFunction}
        defaultOptions={defaultOptions}
        closeMenuOnSelect={false}
        isMulti
        name="colors"
        // options={suggestions}
        // isLoading={suggestions ? suggestions.length === 0 : true}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={onChange}
        isDisabled={disabled}
      />
    </div>
    <InputErrorMessage errors={errors} touched={touched} />
  </Field>
);

export default TagInputs;
