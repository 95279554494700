import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import qs from 'querystringify';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';

import ListItem from './ListItem';
import {
  Loading,
  Table,
  Box,
  ErrorMessage,
  Pagination,
  AesDes,
} from '../../../components/elements';
import apolloClient from '../../../utils/apolloClient';

const searchMenuItemsQuery = gql`
  query searchSettings($input: SearchInput) {
    search_menu_items(input: $input) {
      total_size
      total_pages
      menu_item_listing {
        place_id
        place {
          name
          city
        }
        item_id
        item_dictionary_id
        menu_item_id
        name
        internal_name
        slug
        description
        status
        source_type
        tag
        sell_item_on_its_own
        price
        variant {
          prompt
          prompt_id
          variants {
            variant_name
            variant_price
          }
        }
        popular
        allow_item_review
        availability_status

        price
        tax
        cuisine_style {
          name
          description
          display_order
        }
        item_type {
          name
          description
          display_order
        }
        dietary_information {
          name
          description
          display_order
        }
        item_contains_alcohol
        calories
        kilojoules
        label {
          key
          value
        }
        spice_levels
        item_stored
        sell_item_on_its_own
        popular_addon
        allow_special_instructions
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        created_by_user {
          user_id
          display_name
          email
        }
        updated_by_user {
          user_id
          display_name
          email
        }
      }
    }
  }
`;

const listSize = 20;

const ServiceSettings = ({ location, history }) => {
  const { userId } = useStoreState(state => state.auth);
  const routeState = qs.parse(location.search);
  const {
    status,
    sellItsOwn,
    availability,
    placeName,
    city,
    itemName,
    page,
  } = routeState;

  const statusFilter = status || 'ACTIVE';
  const sellItsOwnFilter = sellItsOwn || 'ALL';
  const availabilityFilter = availability || 'AVAILABLE';
  const placeNameFilter = placeName || null;
  const cityFilter = city || null;
  const itemNameFilter = itemName || null;
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;

  // Pagination
  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState();

  const [sort, setSort] = useState('UPDATED_DESC');

  const input = { user_id: userId };
  const menu_item_filter = {
    status: statusFilter,
    availability_status: availabilityFilter,
  };
  const place_filter = {};
  Object.assign(
    place_filter,
    placeNameFilter && { place_name: placeNameFilter },
    cityFilter && { city: cityFilter },
  );
  Object.assign(
    menu_item_filter,
    sellItsOwnFilter !== 'ALL' && {
      sell_item_on_its_own: sellItsOwnFilter === 'YES',
    },
    itemNameFilter && { name: itemNameFilter },
  );
  Object.assign(
    input,
    { sort },
    { from: currentPage * listSize, size: listSize },
    { filter: { menu_item_filter, place_filter } },
  );
  return (
    <Layout>
      <Header />
      <Search
        history={history}
        status={statusFilter}
        sellItsOwn={sellItsOwnFilter}
        availability={availabilityFilter}
        placeName={placeNameFilter}
        city={cityFilter}
        itemName={itemNameFilter}
        routeState={routeState}
      />
      {totalElements > 0 && (
        <Pagination
          pageFrom={currentPage * listSize}
          pageCount={pageCount}
          listSize={listSize}
          totalElements={totalElements}
          currentPage={currentPage}
          handlePageClick={value =>
            history.push(
              `/menu-Items${qs.stringify(
                {
                  ...routeState,
                  page: value.selected + 1,
                },
                true,
              )}`,
            )
          }
        />
      )}
      <Box>
        <Table>
          <thead>
            <tr>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="NAME_ASC"
                  des="NAME_DESC"
                />
                Item Name
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="PLACE_NAME_ASC"
                  des="PLACE_NAME_DESC"
                />
                Place name
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="PLACE_CITY_ASC"
                  des="PLACE_CITY_DESC"
                />
                City / Suburb
              </th>

              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="PRICE_ASC"
                  des="PRICE_DESC"
                />
                Price
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="VARIANT_ASC"
                  des="VARIANT_DESC"
                />
                V
              </th>

              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="UPDATED_BY_ASC"
                  des="UPDATED_BY_DESC"
                />
                Updated by
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="UPDATED_AT_ASC"
                  des="UPDATED_AT_DESC"
                />
                Updated at
              </th>

              <th>Menu and Section</th>
              <th>Modifier Groups</th>
              <th>Used In</th>
              <th>Action</th>
            </tr>
          </thead>
          <Query
            query={searchMenuItemsQuery}
            variables={{ input }}
            fetchPolicy="network-only"
            client={apolloClient.clientPrivate}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return <ErrorMessage message={error.message} />;
              }

              return (
                <React.Fragment>
                  <View
                    data={data}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({ data, setPageCount, setTotalElements }) => {
  const { search_menu_items } = data;
  useEffect(() => {
    setPageCount(search_menu_items.total_pages);
    setTotalElements(search_menu_items.total_size);
  }, [search_menu_items.total_pages]);

  if (search_menu_items === null) {
    return 'No record found';
  }

  if (search_menu_items.menu_item_listing.length === 0) {
    return 'No record found';
  }
  return (
    <tbody>
      {search_menu_items.menu_item_listing.map(item => (
        <tr key={item.item_id}>
          <ListItem item={item} />
        </tr>
      ))}
    </tbody>
  );
};

export default ServiceSettings;
