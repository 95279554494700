import { isEqual, forEach, omit } from 'lodash';

const removeTypename = values => {
  const final = [];
  forEach(values, item => {
    const custom = omit(item, ['__typename']);
    final.push(custom);
  });
  return final;
};

const dirtyFieldHandler = (finalInput, setting) => {
  const final = {};
  Object.assign(
    final,
    {
      user_id: finalInput.user_id,
      service_type_setting_id: finalInput.service_type_setting_id,
      place_id: finalInput.place_id,
    },
    !isEqual(setting.zone_setting, finalInput.zone_setting) && {
      zone_setting: finalInput.zone_setting,
    },
    !isEqual(setting.status, finalInput.status) && {
      status: finalInput.status,
    },
    !isEqual(setting.service_type, finalInput.service_type) && {
      service_type: finalInput.service_type,
      is_custom_service: finalInput.is_custom_service,
    },
    !isEqual(setting.added_estimate, finalInput.added_estimate) && {
      added_estimate: finalInput.added_estimate,
    },
    !isEqual(setting.allow_pickup_options, finalInput.allow_pickup_options) && {
      allow_pickup_options: finalInput.allow_pickup_options,
    },
    !isEqual(setting.approval_status, finalInput.approval_status) && {
      approval_status: finalInput.approval_status,
    },
    !isEqual(removeTypename(setting.contact), finalInput.contact) && {
      contact: finalInput.contact,
    },
    !isEqual(setting.cut_off_time, finalInput.cut_off_time) && {
      cut_off_time: finalInput.cut_off_time,
    },
    !isEqual(setting.day_estimate, finalInput.day_estimate) && {
      day_estimate: finalInput.day_estimate,
    },
    !isEqual(setting.delivery_fee, finalInput.delivery_fee) && {
      delivery_fee: finalInput.delivery_fee,
    },
    !isEqual(setting.description, finalInput.description) && {
      description: finalInput.description,
    },
    !isEqual(
      setting.display_attendee_options,
      finalInput.display_attendee_options,
    ) && {
      display_attendee_options: finalInput.display_attendee_options,
    },
    !isEqual(setting.display_table_number, finalInput.display_table_number) && {
      display_table_number: finalInput.display_table_number,
    },
    !isEqual(setting.enable_group_order, finalInput.enable_group_order) && {
      enable_group_order: finalInput.enable_group_order,
    },
    !isEqual(setting.food_setup_required, finalInput.food_setup_required) && {
      food_setup_required: finalInput.food_setup_required,
    },
    !isEqual(setting.image_url, finalInput.image_url) && {
      image_url: finalInput.image_url,
    },
    !isEqual(
      setting.include_vehicle_information,
      finalInput.include_vehicle_information,
    ) && {
      include_vehicle_information: finalInput.include_vehicle_information,
    },

    !isEqual(setting.is_private_menu, finalInput.is_private_menu) && {
      is_private_menu: finalInput.is_private_menu,
    },
    !isEqual(
      setting.maximum_no_of_attendees,
      finalInput.maximum_no_of_attendees,
    ) && {
      maximum_no_of_attendees: finalInput.maximum_no_of_attendees,
    },
    !isEqual(
      setting.minimum_no_of_attendees,
      finalInput.minimum_no_of_attendees,
    ) && {
      minimum_no_of_attendees: finalInput.minimum_no_of_attendees,
    },
    !isEqual(
      parseFloat(setting.maximum_order_amount),
      parseFloat(finalInput.maximum_order_amount),
    ) && {
      maximum_order_amount: finalInput.maximum_order_amount,
    },
    !isEqual(
      parseFloat(setting.minimum_order_amount),
      parseFloat(finalInput.minimum_order_amount),
    ) && {
      minimum_order_amount: finalInput.minimum_order_amount,
    },
    !isEqual(setting.payment_method, finalInput.payment_method) && {
      payment_method: finalInput.payment_method,
    },
    !isEqual(setting.pickup_instructions, finalInput.pickup_instructions) && {
      pickup_instructions: finalInput.pickup_instructions,
    },
    !isEqual(setting.pickup_method, finalInput.pickup_method) && {
      pickup_method: finalInput.pickup_method,
    },

    !isEqual(setting.preparation_estimate, finalInput.preparation_estimate) && {
      preparation_estimate: finalInput.preparation_estimate,
    },
    !isEqual(setting.schedule_order, finalInput.schedule_order) && {
      schedule_order: finalInput.schedule_order,
    },
    !isEqual(
      setting.schedule_order_duration,
      finalInput.schedule_order_duration,
    ) && {
      schedule_order_duration: finalInput.schedule_order_duration,
    },
    !isEqual(
      setting.schedule_order_when_closed,
      finalInput.schedule_order_when_closed,
    ) && {
      schedule_order_when_closed: finalInput.schedule_order_when_closed,
    },
    !isEqual(
      setting.schedule_order_when_closed,
      finalInput.schedule_order_when_closed,
    ) && {
      schedule_order_when_closed: finalInput.schedule_order_when_closed,
    },

    !isEqual(setting.tag, finalInput.tag) && {
      tag: finalInput.tag,
    },
  );
  return final;
};

export default dirtyFieldHandler;
