import React from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { isNull } from 'lodash';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';

import client from '../../../utils/apolloClient';
import { ErrorMessage, Loading } from '../../../components/elements';
import Layout from '../../../components/global/Layout';

import Form from './Form';
import dirtyFieldHandler from './dirtyFieldHandler';

const menuModifierGroupQuery = gql`
  query menuModifierGroup($input: NavigateMenuModifierGroupInput) {
    fetch_menu_modifier_group(input: $input) {
      modifier_group_id
      place_id
      name
      internal_name
      description
      status
      modifier {
        menu_item_id
        override_price
        override_variant {
          prompt_id
          prompt
          variants {
            variant_id
            variant_price
            availability_status
          }
        }
      }
      is_required
      selection_type
      selection_quantity
      selection_quantity_from
      selection_quantity_to
    }
  }
`;

const updateMenuModifierGroupMutation = gql`
  mutation updateMenuModifierGroup($input: MenuModifierGroupInput) {
    update_menu_modifier_group(input: $input) {
      modifier_group_id
      error {
        description
      }
    }
  }
`;

const ModifierGroupUpdate = ({ history, match }) => {
  const { userId } = useStoreState(state => state.auth);
  const { id } = match.params;

  return (
    <Layout>
      <Query
        client={client.clientPrivate}
        query={menuModifierGroupQuery}
        variables={{
          input: {
            modifier_group_id: id,
          },
        }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <ErrorMessage message={error.message} />;
          }
          const group = data.fetch_menu_modifier_group;
          return (
            <Mutation
              client={client.clientPrivate}
              mutation={updateMenuModifierGroupMutation}
              onCompleted={({ update_menu_modifier_group }) => {
                if (!isNull(update_menu_modifier_group.error)) {
                  update_menu_modifier_group.error.map(item1 =>
                    toast.error(item1.description),
                  );
                } else {
                  swal(
                    'Great!',
                    'Modifier Group updated successfully!',
                    'success',
                  ).then(() => {
                    history.goBack();
                  });
                }
              }}
            >
              {(update_menu_item, { loading: loading1, error: error1 }) => (
                <>
                  {error1 && <ErrorMessage message={error1.message} />}

                  <Form
                    userId={userId}
                    group={group}
                    loading={loading1}
                    onSubmit={inputs => {
                      const dataF = dirtyFieldHandler(inputs, group);
                      update_menu_item({
                        variables: {
                          input: dataF,
                        },
                      });
                    }}
                  />
                </>
              )}
            </Mutation>
          );
        }}
      </Query>
    </Layout>
  );
};

export default ModifierGroupUpdate;
