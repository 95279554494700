import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import qs from 'querystringify';
import { useStoreState } from 'easy-peasy';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';

import ListItem from './ListItem';
import {
  Loading,
  Table,
  Box,
  ErrorMessage,
  Pagination,
  AesDes,
} from '../../../components/elements';
import client from '../../../utils/apolloClient';

const searchSectionsSettingsQuery = gql`
  query searchSections($input: SearchInput) {
    search_menu_sections(input: $input) {
      total_size
      total_pages
      menu_section_listing {
        menu_section_id
        place {
          name
          city
        }
        city
        place_id
        menu_id
        name
        description
        status
        tag
        menu
        menu_item
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        created_by_user {
          user_id
          display_name
          email
        }
        updated_by_user {
          user_id
          display_name
          email
        }
      }
    }
  }
`;

const ServiceSettings = ({ location, history }) => {
  const { userId } = useStoreState(state => state.auth);
  const routeState = qs.parse(location.search);
  const { serviceType, placeName, page, status, city } = routeState;

  const statusFilter = status || 'ACTIVE';
  const placeNameFilter = placeName || '';
  const serviceTypeFilter = serviceType || '';
  const cityFilter = city || '';
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;

  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(30);

  const [sort, setSort] = useState('UPDATED_DESC');

  const input = { user_id: userId };
  const menu_section_filter = {};
  const place_filter = {};
  Object.assign(
    place_filter,
    placeNameFilter !== '' && { place_name: placeNameFilter },
    cityFilter && { city: cityFilter },
  );
  Object.assign(
    menu_section_filter,
    { status: statusFilter },
    serviceTypeFilter !== '' && { name: serviceTypeFilter },
  );
  Object.assign(
    input,
    { sort },
    { from: currentPage * listSize, size: listSize },
    {
      filter: {
        menu_section_filter,
        place_filter,
      },
    },
  );

  return (
    <Layout>
      <Header />
      <Search
        serviceType={serviceTypeFilter}
        placeName={placeNameFilter}
        city={city}
        status={statusFilter}
        history={history}
        routeState={routeState}
      />
      {totalElements > 0 && (
        <Pagination
          pageFrom={currentPage * listSize}
          pageCount={pageCount}
          listSize={listSize}
          totalElements={totalElements}
          currentPage={currentPage}
          handlePageClick={value =>
            history.push(
              `/menu-sections${qs.stringify(
                {
                  ...routeState,
                  page: value.selected + 1,
                },
                true,
              )}`,
            )
          }
        />
      )}
      <Box>
        <Table>
          <thead>
            <tr>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="NAME_ASC"
                  des="NAME_DESC"
                />
                Category Name
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="PLACE_NAME_ASC"
                  des="PLACE_NAME_DESC"
                />
                Place name
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="PLACE_CITY_ASC"
                  des="PLACE_CITY_DESC"
                />
                City / Suburb
              </th>
              <th>Menu</th>
              <th>Items</th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="UPDATED_BY_ASC"
                  des="UPDATED_BY_DESC"
                />
                Updated by
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="UPDATED_AT_ASC"
                  des="UPDATED_AT_DESC"
                />
                Updated at
              </th>

              <th>Action</th>
            </tr>
          </thead>
          <Query
            query={searchSectionsSettingsQuery}
            variables={{ input }}
            fetchPolicy="network-only"
            client={client.clientPrivate}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return <ErrorMessage message={error.message} />;
              }

              return (
                <React.Fragment>
                  <View
                    data={data}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({ data, setPageCount, setTotalElements }) => {
  const { search_menu_sections } = data;

  useEffect(() => {
    setPageCount(search_menu_sections.total_pages);
    setTotalElements(search_menu_sections.total_size);
  }, [search_menu_sections.total_pages]);

  if (search_menu_sections === null) {
    return 'No record found';
  }

  if (search_menu_sections.menu_section_listing.length === 0) {
    return 'No record found';
  }
  return (
    <tbody>
      {search_menu_sections.menu_section_listing.map(section => (
        <tr key={section.menu_section_id}>
          <ListItem section={section} />
        </tr>
      ))}
    </tbody>
  );
};

export default ServiceSettings;
