import React, { useState } from 'react';
import { Column } from 'rbx';
import styled from 'styled-components';
import { intersectionBy, startCase } from 'lodash';
import {
  Select,
  Input,
  TextArea,
  CheckBox,
  TagInputs,
  CreatableSelectInputs,
  MobileInput,
  BoxWithHeader,
  SingleImageUpload,
  InputErrorMessage,
} from '../../../components/elements';

import options from '../../../utils/optionsHelpers';

const ChangeWrapper = styled.div`
  padding-left: 500%;
  &&& {
    a {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
`;

const Details = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  errors,
  touched,
}) => {
  const [changeServiceUrl, setChangeServiceUrl] = useState(false);

  return (
    <>
      <Column.Group>
        <Column size="5">
          <BoxWithHeader title="Photos">
            <SingleImageUpload
              s3UploadPath={`service_setting/${values.place_id}/`}
              imageValue={values.image_url}
              onChange={image => setFieldValue('image_url', image.url)}
              onDelete={() => setFieldValue('image_url', null)}
            />
            <InputErrorMessage
              errors={errors.image_url}
              touched={touched.image_url}
            />
          </BoxWithHeader>
        </Column>
        <Column size="7">
          <Column.Group multiline gapSize={4}>
            <Column size="7">
              <Input
                label="Service Type Name"
                id="AddServiceType_ServiceTypeName"
                name="service_type"
                value={startCase(values.service_type)}
                onChange={handleChange}
                onBlur={handleBlur}
                optionalText="( Max 26 characters )"
                maxLength="26"
                required
                errors={errors.service_type}
                touched={touched.service_type}
                disableToastMessage
                disabled={!values.is_custom_service}
              />
            </Column>
            <Column size="5">
              <Select
                label="Service Type Status"
                options={options.statusOptions}
                value={[
                  {
                    value: values.status,
                    label: values.status,
                  },
                ]}
                onChange={value => setFieldValue('status', value.value)}
                disableToastMessage
                required
              />
            </Column>
            <Column size="7">
              <Input
                label="Service Type URL"
                id="AddServiceType_ServiceTypeURL"
                value={values.slug}
                disabled={!changeServiceUrl}
              >
                <ChangeWrapper>
                  <a onClick={() => setChangeServiceUrl(true)}>Change</a>
                </ChangeWrapper>
              </Input>
            </Column>
            <Column size="5">
              <br />
              {values.is_custom_service && (
                <CheckBox
                  label="Private Menu"
                  value={values.is_private_menu}
                  onChange={value => setFieldValue('is_private_menu', value)}
                />
              )}
            </Column>
            <Column size="7">
              <Input
                label="Primary Contact Email"
                id="AddServiceType_PrimaryContactEmail"
                name="primaryEmail"
                value={values.primaryEmail}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.primaryEmail}
                touched={touched.primaryEmail}
                disableToastMessage
                required
              />
            </Column>
            <Column size="5">
              <MobileInput
                name="primaryMobile"
                label="Primary Contact Phone"
                value={values.primaryMobile}
                onChange={value => setFieldValue('primaryMobile', value)}
                required
              />
              <InputErrorMessage
                errors={errors.primaryMobile}
                touched={touched.primaryMobile}
              />
            </Column>
            <Column size="full">
              {!values.is_custom_service && (
                <TagInputs
                  label="Payment Method"
                  value={intersectionBy(
                    options.paymentOptions,
                    values.payment_method.map(item => ({
                      label: item,
                      value: item,
                    })),
                    'value',
                  )}
                  suggestions={options.paymentOptions}
                  onChange={value => {
                    const input = value.filter(
                      item => item.value !== 'credit-card',
                      item => item.value,
                    );
                    const newValue = input.map(item => item.value);
                    const fixed = ['credit-card'];

                    setFieldValue('payment_method', fixed.concat(newValue));
                  }}
                />
              )}
            </Column>
          </Column.Group>
        </Column>
      </Column.Group>
      <Column.Group multiline gapSize={4}>
        <Column size="full">
          <TextArea
            label="Service Type Description"
            id="AddServiceType_ServiceTypeDescription"
            name="description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            optionalText="( Min 5 and Max 288 Characters )"
            errors={errors.description}
            touched={touched.description}
            disableToastMessage
          />
        </Column>
        <Column size="full">
          <CreatableSelectInputs
            label="Service Type Keywords / Tags"
            name="description"
            value={values.tag.map(item => ({ label: item, value: item }))}
            onChange={value => {
              const newValue = value.map(item => item.value);
              setFieldValue('tag', newValue);
            }}
            onBlur={handleBlur}
          />
        </Column>
      </Column.Group>
    </>
  );
};

export default Details;
