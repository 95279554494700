import React, { useState } from 'react';
import styled from 'styled-components';
import QRCode from 'react-qr-code';
import { toJpeg } from 'html-to-image';
import { kebabCase } from 'lodash';

const Container = styled.div`
  padding-top: 1rem;
  width: 17rem;
`;

const FlexRow = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;
const QRCodeWrapper = styled.div`
  border: 1px solid #bfbdbd;
  padding: 0.5rem;
  background-color: #ffffff;
`;

const MenuQRCode = ({ menuName, qrCodeValue }) => {
  const [loading, setLoading] = useState(false);

  const onDownload = () => {
    setLoading(true);
    const node = document.getElementById('qr-code');
    toJpeg(node, { quality: 1 }).then(dataUrl => {
      const link = document.createElement('a');
      link.download = `${kebabCase(menuName)}-qr-code.png`;
      link.href = dataUrl;
      link.click();
      setLoading(false);
    });
  };

  return (
    <Container>
      <QRCodeWrapper id="qr-code">
        <QRCode value={qrCodeValue} />
      </QRCodeWrapper>
      <FlexRow>
        <button
          type="button"
          className={`button is-primary ${loading && 'is-loading'}`}
          onClick={onDownload}
        >
          Download
        </button>
      </FlexRow>
    </Container>
  );
};

export default MenuQRCode;
