import React, { useState, useCallback, useEffect } from 'react';
import { withFormik } from 'formik';
import styled from 'styled-components';
import { Tab } from 'rbx';
import uuidv4 from 'uuid/v4';
import { isNull, omit } from 'lodash';
import * as yup from 'yup';
import PlacePageHeader from '../../../components/PlacePageHeader';
import { Box, Tabs } from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';
import Details from './Details';
import ModifiersLinkItems from './ModifiersLinkItems';
import { parseInputModifier, getMenuItems } from './helpers';

const Container = styled.div`
  padding: 1rem;
`;

const Form = props => {
  const [isActiveTab, setIsActiveTab] = useState('details');
  const { values, loading, dirty, setFieldValue, handleSubmit } = props;

  const fetchData = useCallback(async () => {
    const list = await getMenuItems({
      modifier_group_id: [values.modifier_group_id],
    });
    setFieldValue('linkedMenuItem', list);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <form
        onSubmit={event => {
          if (event.keyCode !== 13) {
            event.preventDefault();
            handleSubmit();
          }
        }}
      >
        <Header
          values={values}
          loading={loading}
          dirty={dirty}
          groupName={values.name}
        >
          <PlacePageHeader place={props.place} placeId={values.place_id} />
        </Header>
        <Box>
          <Tabs className="tabs is-medium">
            <Tab
              active={isActiveTab === 'details'}
              onClick={() => setIsActiveTab('details')}
            >
              Modifier Details
            </Tab>
            <Tab
              active={isActiveTab === 'link-item'}
              onClick={() => setIsActiveTab('link-item')}
            >
              Items with this modifiers
            </Tab>
          </Tabs>
          <Container>
            {isActiveTab === 'details' && <Details {...props} />}
            {isActiveTab === 'link-item' && <ModifiersLinkItems {...props} />}
          </Container>
        </Box>
        <hr />
        <Footer values={values} loading={loading} dirty={dirty} />
      </form>
    </>
  );
};

const MenuItemForm = withFormik({
  mapPropsToValues: ({ place, userId, group }) => ({
    user_id: userId,
    modifier_group_id:
      group && !isNull(group.modifier_group_id)
        ? group.modifier_group_id
        : uuidv4(),
    place_id:
      group && !isNull(group.place_id) ? group.place_id : place.place_id,

    name: group && !isNull(group.name) ? group.name : '',
    internal_name:
      group && !isNull(group.internal_name) ? group.internal_name : '',

    description: group && !isNull(group.description) ? group.description : '',
    status: group && !isNull(group.status) ? group.status : 'ACTIVE',
    modifier: [],
    modifierHelpers: group && !isNull(group.modifier) ? group.modifier : [],
    is_required:
      group && !isNull(group.is_required) ? group.is_required : false,
    selection_type:
      group && !isNull(group.selection_type) ? group.selection_type : 'CUSTOM',
    selection_quantity:
      group && !isNull(group.selection_quantity)
        ? group.selection_quantity
        : null,
    selection_quantity_from:
      group && !isNull(group.selection_quantity_from)
        ? group.selection_quantity_from
        : null,
    selection_quantity_to:
      group && !isNull(group.selection_quantity_to)
        ? group.selection_quantity_to
        : null,
    linkedMenuItem: [],
  }),

  validate: values => {
    const errors = {};

    if (values.selection_type === 'CUSTOM') {
      if (!values.selection_quantity) {
        errors.selection_quantity = 'Value is required!';
      }
      if (values.selection_quantity > values.modifier.length) {
        errors.selection_quantity =
          'Maximum permitted per item should not be greater than maximum number of modifiers permitted total';
      }
    } else if (values.selection_type === 'RANGE') {
      if (!values.selection_quantity_from) {
        errors.selection_quantity_from = 'Value is required!';
      }
      if (!values.selection_quantity_to) {
        errors.selection_quantity_to = 'Value is required!';
      }
      if (
        values.selection_quantity_from &&
        values.selection_quantity_from < 0
      ) {
        errors.selection_quantity_from = 'Value must me positive integer';
      }
      if (values.selection_quantity_to && values.selection_quantity_to < 0) {
        errors.selection_quantity_to = 'Value must me positive integer';
      }
      if (values.selection_quantity_from > values.selection_quantity_to) {
        errors.selection_quantity_to =
          'Maximum value should be more than the minimum value';
      }
      if (values.selection_quantity_to > values.modifier.length) {
        errors.selection_quantity_to =
          'Maximum Value should be less than or equal to the total number of items selected';
      }
    }

    return errors;
  },

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .min(3, 'Please enter at least 3 characters')
      .max(50, 'Please enter not greater than 50 characters')
      .required('Modifier group name is required!'),
    internal_name: yup
      .string()
      .min(3, 'Please enter at least 3 characters')
      .max(50, 'Please enter not greater than 50 characters')
      .required('Modifier group name is required!'),
  }),

  handleSubmit: (values, { props }) => {
    const input = omit(values, ['linkedMenuItem', 'modifierHelpers']);
    const inputs = {
      ...input,
    };

    if (props.group) {
      if (values.name === props.group.name) {
        delete inputs.name;
      }
      if (values.internal_name === props.group.internal_name) {
        delete inputs.internal_name;
      }
    }

    props.onSubmit({
      ...inputs,
      modifier: parseInputModifier(values.modifier),
    });
  },

  displayName: 'MenuItemForm',
})(Form);

export default MenuItemForm;
