import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 0.6rem;
  border-radius: 10px;
  border: 2px solid #dbdbdb;
  margin-bottom: 1rem;
  background: rgba(255, 200, 65, 0.1);
  margin-bottom: 1rem;
`;

const Cutlery = ({ order }) => (
  <Container>
    <p
      className="is-size-2 has-text-weight-semibold"
      style={{ color: '#ee2a7b' }}
    >
      {order.payment &&
        order.payment.payment_method === 'CASH' &&
        'CASH ORDER - CHARGE THE CUSTOMER'}
    </p>
    {order.include_cuttlery ? (
      <p className="is-size-3" style={{ color: '#05aeef' }}>
        Please send cutlery, straws, napkins etc
      </p>
    ) : (
      <p className="is-size-3" style={{ color: '#05aeef' }}>
        No Cutlery
      </p>
    )}
  </Container>
);

export default Cutlery;
