import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';
import { ACLSConsumer } from '../../../utils/aclsContext';
import Can from '../../../utils/Can';

const Wrapper = styled.div`
  padding: 1rem;
  padding-bottom: 1rem;
  /* position: fixed; */
  /* bottom: 0; */
  /* width: 84%; */
  background: #ffff;
`;

const Footer = ({ loading, dirty, history, isAssignActive }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button">
            <span>Cancel</span>
          </a>
        </Level.Item>
      </Level.Item>
      {isAssignActive && (
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={['create_menu_section', 'update_menu_section']}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <Level.Item align="right">
                  <Level.Item>
                    <button
                      type="submit"
                      className={`button is-primary ${loading && 'is-loading'}`}
                      disabled={!dirty}
                    >
                      <span>Submit</span>
                    </button>
                  </Level.Item>
                </Level.Item>
              )}
            />
          )}
        </ACLSConsumer>
      )}
    </Level>
  </Wrapper>
);

export default withRouter(Footer);
