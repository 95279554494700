import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { isNull, omit, isEqual } from 'lodash';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';
import client from '../../../../../utils/apolloClient';
import Form from './Form';
import { ErrorMessage } from '../../../../../components/elements';

const createCustomHourMutation = gql`
  mutation createCustomHour($input: CreateCustomHourInput) {
    create_custom_hour(input: $input) {
      custom_hour_id
      error {
        description
      }
    }
  }
`;

const updateCustomHourMutation = gql`
  mutation updateCustomHour($input: UpdateCustomHourInput) {
    update_custom_hour(input: $input) {
      custom_hour_id
      error {
        description
      }
    }
  }
`;

const AddEditHour = ({
  place,
  isActive,
  setIsActive,
  selectedMenu,
  fetchHours,
  customHour,
  setUpdateData,
}) => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <div>
      <Mutation
        client={client.clientPrivate}
        mutation={updateCustomHourMutation}
        onCompleted={({ update_custom_hour }) => {
          if (!isNull(update_custom_hour.error)) {
            update_custom_hour.error.map(item => toast.error(item.description));
          } else {
            swal('Great!', 'Custom Hour updated successfully!', 'success').then(
              () => {
                setIsActive(false);
                fetchHours({
                  placeId: place.place_id,
                  menuId: selectedMenu.menu_id,
                  userId,
                });
                setUpdateData(null);
              },
            );
          }
        }}
      >
        {(
          update_custom_hour,
          { loading: updateLoading, error: updateError },
        ) => (
          <>
            {updateError && <ErrorMessage message={updateError.message} />}
            <Mutation
              client={client.clientPrivate}
              mutation={createCustomHourMutation}
              onCompleted={({ create_custom_hour }) => {
                if (create_custom_hour && create_custom_hour.length !== 0) {
                  if (!isNull(create_custom_hour[0].error)) {
                    create_custom_hour[0].error.map(() =>
                      toast.error('This Custom Hour is already exits!'),
                    );
                  } else {
                    swal(
                      'Great!',
                      'Custom Hour created successfully!',
                      'success',
                    ).then(() => {
                      setIsActive(false);
                      fetchHours({
                        placeId: place.place_id,
                        menuId: selectedMenu.menu_id,
                        userId,
                      });
                      setUpdateData(null);
                    });
                  }
                }
              }}
            >
              {(create_custom_hour, { loading, error }) => (
                <>
                  {error && <ErrorMessage message={error.message} />}
                  <Form
                    isActive={isActive}
                    isUpdate={customHour}
                    selectedMenu={selectedMenu}
                    onClose={() => {
                      setIsActive(false);
                      setUpdateData(null);
                    }}
                    userId={userId}
                    place={place}
                    hour={customHour}
                    loading={loading || updateLoading}
                    onSubmit={input => {
                      if (customHour) {
                        const value = omit(input, [
                          'place_id',
                          'object_type',
                          'day',
                        ]);
                        const inputArry = {
                          custom_hour_id: customHour.custom_hour_id,
                          user_id: userId,
                        };

                        Object.assign(
                          input,
                          !isEqual(value.option, customHour.option) && {
                            option: value.option,
                          },
                          !isEqual(
                            value.description,
                            customHour.description,
                          ) && {
                            description: value.description,
                          },
                        );
                        update_custom_hour({
                          variables: {
                            input: inputArry,
                          },
                        });
                      } else {
                        create_custom_hour({
                          variables: {
                            input: {
                              ...input,
                              menu_id: [selectedMenu.menu_id],
                              user_id: userId,
                            },
                          },
                        });
                      }
                    }}
                  />
                </>
              )}
            </Mutation>
          </>
        )}
      </Mutation>
    </div>
  );
};

export default AddEditHour;
