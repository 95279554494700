import { forEach, toNumber } from 'lodash';
import gql from 'graphql-tag';

import client from '../../../utils/apolloClient';

const searchMenusQuery = gql`
  query searchMenus($input: SearchInput) {
    search_menu(input: $input) {
      menu_listing {
        place_id
        service_type_setting {
          service_type_setting_id
        }
        service_type
        menu_id
        name
        internal_name
        slug
        description
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getMenus = ({ placeId }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: searchMenusQuery,
        variables: {
          input: {
            filter: {
              menu_filter: { place_id: placeId, status: 'ACTIVE' },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data.search_menu && data.search_menu.menu_listing) {
          resolve(data.search_menu.menu_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const convertModifierPriceInDecimals = modifiers => {
  const data = [];
  forEach(modifiers, item => {
    const temp = [];
    forEach(item.override_variant, item1 => {
      const temp2 = [];
      forEach(item1.variants, item2 => {
        temp2.push({
          ...item2,
          variant_price: parseFloat(item2.variant_price).toFixed(2),
        });
      });
      temp.push({ ...item1, variants: temp2 });
    });
    data.push({
      ...item,
      override_price: parseFloat(item.override_price).toFixed(2),
      override_variant: temp,
    });
  });
  return data;
};

export const parseInputModifier = modifier => {
  const finalModifier = modifier.map(item => {
    const data = [];
    forEach(item.override_variant, item2 => {
      const temp = [];
      forEach(item2.variants, items3 => {
        temp.push({
          variant_name: items3.variant_name,
          variant_price: toNumber(items3.variant_price),
        });
      });
      data.push({ prompt: item2.prompt, variants: temp });
    });
    return {
      menu_item_id: item.menu_item_id,
      override_price: toNumber(item.override_price),
      override_variant: data,
    };
  });
  return finalModifier;
};

export const addNameInModifier = (modifier, fetchItems) => {
  const data = [];
  forEach(modifier, item => {
    const temp = [];
    const currentModifiers = fetchItems.filter(
      edge => edge.menu_item_id === item.menu_item_id,
    )[0];

    forEach(item.override_variant, item1 => {
      const temp2 = [];
      forEach(item1.variants, item2 => {
        temp2.push({
          ...item2,
          variant_price: parseFloat(item2.variant_price).toFixed(2),
        });
      });
      temp.push({ ...item1, variants: temp2 });
    });
    data.push({
      ...item,
      name: currentModifiers.name,
      override_price: parseFloat(item.override_price).toFixed(2),
      override_variant: temp,
    });
  });
  return data;
};
