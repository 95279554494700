import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import qs from 'querystringify';
import { omit } from 'lodash';

import { Input, ReactDateTimePicker } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

// const options = [
//   { value: 'card', label: 'Card' },
//   { value: 'cash', label: 'Cash' },
// ];

const PlaceSearch = ({
  placeName,
  city,
  startDate,
  endDate,
  routeState,
  history,
}) => {
  const handlePush = (args, key) => {
    const newRoutState = omit(routeState, key);
    history.push(
      `/orders-dashboard${qs.stringify(
        {
          ...newRoutState,
          ...args,
          page: 1,
        },
        true,
      )}`,
    );
  };
  return (
    <Wrapper>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-4">
            <Input
              label="Place Name"
              type="text"
              value={placeName}
              placeholder="Start entering place name..."
              onChange={event =>
                handlePush({ placeName: event.target.value }, 'placeName')
              }
            />
          </div>

          <div className="column is-3">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city || ''}
                  onSelect={value => {
                    handlePush({ city: value.name }, 'city');
                  }}
                  onHandleClear={() => handlePush({ city: '' }, 'city')}
                  placeholder="Start entering company name..."
                />
              </div>
            </div>
          </div>
          <div className="column">
            <ReactDateTimePicker
              value={
                startDate ||
                moment(
                  `${moment().format('YYYY-MM-DD')} 12:00 AM`,
                ).toISOString()
              }
              maxDate={moment(endDate).toDate()}
              label="From"
              onChange={date =>
                handlePush(
                  {
                    from: moment(
                      `${moment(date).format('YYYY-MM-DD')} 12:00 AM`,
                    ).toISOString(),
                  },
                  'from',
                )
              }
              clear={false}
            />
          </div>
          <div className="column">
            <ReactDateTimePicker
              minDate={moment(startDate).toDate()}
              value={endDate || moment().toISOString()}
              label="To"
              onChange={date =>
                handlePush(
                  {
                    to: moment(date).toISOString(),
                  },
                  'to',
                )
              }
              clear={false}
            />
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default withRouter(PlaceSearch);
