import React from 'react';
import { Link } from 'react-router-dom';
import { first, isNull } from 'lodash';

const ListItem = ({ user }) => (
  <tr>
    <td>
      <Link to={`/user/${user.user_id}`}>{user.email}</Link>
    </td>
    <td>{user.first_name}</td>
    <td>{user.last_name}</td>
    <td>{user.display_name}</td>
    <td>
      {!isNull(user.contact) && user.contact.length !== 0
        ? first(user.contact.filter(item => item.type === 'mobile')).value
        : '-'}
    </td>
  </tr>
);

export default ListItem;
