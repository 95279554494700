import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 0.6rem;
  border-radius: 10px;
  border: 2px solid #dbdbdb;
  margin-bottom: 1rem;
  background: rgba(255, 200, 65, 0.1);
  margin-bottom: 1rem;
`;

const FlexItem = styled.div`
  display: flex;
  padding: 5px 0rem;
`;

const SpecialText = styled.p`
  color: ${({ theme }) => theme.coolGrey};
  font-size: 0.8rem;
`;

const SpecialSpacing = styled.div`
  padding: 0rem 0rem;
  display: flex;
  justify-content: space-between;
  &&.time-line {
    align-items: flex-end;
  }
  .separator {
    padding: 0rem 1rem;
    padding-top: 7px;
  }
`;

const LineItems = ({ order }) => (
  <Container>
    {order.line_item.map(item => (
      <FlexItem className="d-flex">
        <div style={{ paddingTop: '2.5px' }}>
          <p className="is-size-3 has-text-weight-semibold mr-4">
            {item.quantity}
          </p>
        </div>
        <div className="full-width">
          <SpecialSpacing>
            <p className="is-size-3 has-text-weight-semibold">{item.name}</p>
            <p className="is-size-3 has-text-weight-semibold">
              ${item.overall_price.toFixed(2)}
            </p>
          </SpecialSpacing>
          {item.variant_name && (
            <SpecialText>
              {item.variant_name} (${item.price.toFixed(2)} )
            </SpecialText>
          )}
          {item.modifier &&
            item.modifier.map(modifier => (
              <SpecialText>
                {modifier.name} ($
                {modifier.price_per_item.toFixed(2)})
              </SpecialText>
            ))}
          {item.special_request && (
            <SpecialText>Special Request: {item.special_request}</SpecialText>
          )}
        </div>
      </FlexItem>
    ))}
  </Container>
);

export default LineItems;
