import { forEach, toNumber, isNull } from 'lodash';
import gql from 'graphql-tag';

import client from '../../../utils/apolloClient';

export const convertModifierPriceInDecimals = modifiers => {
  const data = [];
  forEach(modifiers, item => {
    const temp = [];
    forEach(item.override_variant, item1 => {
      const temp2 = [];
      forEach(item1.variants, item2 => {
        temp2.push({
          ...item2,
          variant_price: parseFloat(item2.variant_price).toFixed(2),
        });
      });
      temp.push({ ...item1, variants: temp2 });
    });
    data.push({
      ...item,
      override_price: parseFloat(item.override_price).toFixed(2),
      override_variant: temp,
    });
  });
  return data;
};

export const parseInputModifier = modifier => {
  const finalModifier = modifier.map(item => {
    const data = [];
    forEach(item.override_variant, item2 => {
      const temp = [];
      forEach(item2.variants, items3 => {
        temp.push({
          variant_id: items3.variant_id,
          variant_name: items3.variant_name,
          variant_price: toNumber(items3.variant_price),
          variant_tax: items3.variant_tax,
        });
      });
      if (temp.length !== 0) {
        data.push({
          prompt_id: item2.prompt_id,
          prompt: item2.prompt,
          variants: temp,
        });
      }
    });
    return {
      menu_item_id: item.menu_item_id,
      override_price: toNumber(item.override_price),
      override_variant: data,
    };
  });
  return finalModifier;
};

export const mergeItemWithCurrentModifiers = (modifier, fetchItems) => {
  const data = [];
  forEach(fetchItems, item => {
    const temp = [];
    const currentModifiers = modifier.filter(
      edge => edge.menu_item_id === item.menu_item_id,
    );

    forEach(item.variant, item2 => {
      const temp2 = [];
      const currentItemVariant =
        currentModifiers.length !== 0 &&
        currentModifiers[0].override_variant &&
        currentModifiers[0].override_variant.length !== 0
          ? currentModifiers[0].override_variant
          : null;
      const overrideVariant = currentItemVariant
        ? currentItemVariant[0].variants
        : [];
      forEach(item2.variants, item3 => {
        const currentItemVariants =
          overrideVariant && overrideVariant.length !== 0
            ? overrideVariant.filter(
                item4 => item4.variant_id === item3.variant_id,
              )
            : [];
        temp2.push({
          variant_id: item3.variant_id,
          basePrice: parseFloat(item3.variant_price).toFixed(2),
          variant_price:
            currentItemVariants.length !== 0
              ? parseFloat(currentItemVariants[0].variant_price).toFixed(2)
              : parseFloat(item3.variant_price).toFixed(2),
          variant_name: item3.variant_name,
        });
      });
      temp.push({ ...item2, variants: temp2 });
    });
    const itemPrice = item.price ? parseFloat(item.price).toFixed(2) : null;
    data.push({
      menu_item_id: item.menu_item_id,
      name: item.name,
      basePrice: itemPrice,
      override_price:
        currentModifiers.length !== 0 &&
        currentModifiers[0].override_price !== null
          ? parseFloat(currentModifiers[0].override_price).toFixed(2)
          : itemPrice,
      override_variant: temp,
    });
  });

  return data;
};
export const addNameInModifier = (modifier, fetchItems) => {
  const data = [];
  forEach(modifier, item => {
    const temp = [];
    const currentModifiers = fetchItems.filter(
      edge => edge.menu_item_id === item.menu_item_id,
    )[0];

    forEach(item.override_variant, item1 => {
      const temp2 = [];
      forEach(item1.variants, item2 => {
        const currentItemVariant = currentModifiers.variant[0].variants;
        const currentItemVariants = currentItemVariant.filter(
          item4 => item4.variant_id === item2.variant_id,
        )[0];
        temp2.push({
          ...item2,
          basePrice: parseFloat(currentItemVariants.variant_price).toFixed(2),
          variant_price: !isNull(item2.variant_price)
            ? parseFloat(item2.variant_price).toFixed(2)
            : parseFloat(currentItemVariants.variant_price).toFixed(2),
          variant_name: currentItemVariants.variant_name,
        });
      });
      temp.push({ ...item1, variants: temp2 });
    });
    data.push({
      ...item,
      name: currentModifiers.name,
      basePrice: parseFloat(currentModifiers.price).toFixed(2),
      override_price: !isNull(item.override_price)
        ? parseFloat(item.override_price).toFixed(2)
        : parseFloat(currentModifiers.price).toFixed(2),
      override_variant: temp,
    });
  });
  return data;
};

const searchMenuItemsQuery = gql`
  query searchMenuItems($input: SearchInput) {
    search_menu_items(input: $input) {
      menu_item_listing {
        place_id
        menu_item_id
        name
      }
    }
  }
`;

export const getMenuItems = filter =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: searchMenuItemsQuery,
        variables: {
          input: {
            filter: {
              menu_item_filter: filter,
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_items &&
          data.search_menu_items.menu_item_listing
        ) {
          resolve(data.search_menu_items.menu_item_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
