import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { first, kebabCase } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';

import { upload_image_s3 } from '../../utils/s3';

const Container = styled.div`
  :focus {
    outline: none;
  }
`;

const Figure = styled.figure`
  &&& {
    display: flex;
    justify-content: center;
  }
  img {
    object-fit: contain;
    width: 350px;
    height: 200px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
`;

const MyDropzone = ({
  imageValue,
  s3UploadPath,
  onChange,
  metaData,
  onDelete,
}) => {
  const [imagePreview, setImagePreview] = useState(imageValue);
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(async acceptedFiles => {
    setLoading(true);
    const file = acceptedFiles[0];
    setImagePreview(URL.createObjectURL(file));
    const imageName = file.name.split('.');
    const name = `${kebabCase(first(imageName))}-${moment().format(
      'YYYYMMDD_hhmmss',
    )}.${file.type.substring(6)}`;
    const acceptImageList = ['image/jpeg', 'image/png', 'image/jpg'];
    const imageAcceptable = acceptImageList.filter(
      item1 => item1 === file.type,
    );
    if (imageAcceptable.length !== 0) {
      const { Location: url } = await upload_image_s3(
        file,
        `${s3UploadPath}${name}`,
        metaData,
      );
      const response = { path: name, url };

      onChange(response);
      setLoading(false);
    } else {
      toast.error('Only png, jpeg, jpg image are accepted!');
      setLoading(false);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg',
    onDrop,
  });

  useEffect(() => {
    setImagePreview(imageValue);
  }, [imageValue]);

  return (
    <Container>
      {onDelete && imageValue && imageValue.length !== 0 && (
        <FlexRow>
          <button type="button" className="button is-danger" onClick={onDelete}>
            <i className="fas fa-trash-alt" />
          </button>
        </FlexRow>
      )}
      <div {...getRootProps()}>
        {imagePreview && (
          <Figure>
            <img
              src={imagePreview}
              alt="image-upload"
              style={{ opacity: loading ? '0.5' : '1' }}
            />
          </Figure>
        )}
        <br />
        <div className="field">
          <label>
            <input
              className="file-input is-loading"
              name="image-upload"
              {...getInputProps()}
              disabled={loading}
            />

            <button type="button" className="button is-primary is-fullwidth">
              <span className="is-size-4 has-text-weight-semibold">
                Add / Change photo
              </span>
            </button>
          </label>
        </div>
      </div>
      <br />
      <p className="is-size-4 has-text-weight-semibold">
        Upload a high quality photo for this service type, Accepted file types
        are JPG, PNG or WEBP up to 10 MB. Recommended image resolution is
        minimum pixels of 550 for width, 440 for height
      </p>
      <br />
      {loading && (
        <progress className="progress is-small is-success" max="100">
          15%
        </progress>
      )}
    </Container>
  );
};

MyDropzone.defaultProps = {
  imageValue: 'https://bulma.io/images/placeholders/256x256.png',
  s3UploadPath: 'dummy/',
  metaData: {},
  onChange: () => {},
  handleRemoveCurrentImage: () => {},
  defaultImage: '',
  setDefaultImage: () => {},
};

MyDropzone.propTypes = {
  imageValue: PropTypes.string,
  s3UploadPath: PropTypes.string,
  defaultImage: PropTypes.string,
  metaData: PropTypes.object,
  onChange: PropTypes.func,
  handleRemoveCurrentImage: PropTypes.func,
  setDefaultImage: PropTypes.func,
};

export default MyDropzone;
