import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding-bottom: 0.6rem;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 1rem;
`;

const Head = styled.div`
  padding: 0.1rem 0rem;
`;

const Content = styled.div`
  padding: 0.05rem 0rem;
`;

const SpecialInstruction = ({ notes }) => {
  if (!notes || (!notes && !notes.value)) {
    return null;
  }

  return (
    <Container>
      <Head>
        <p className="is-size-4">Special instructions</p>
      </Head>
      <Content>
        <p className="is-size-3">{notes.value}</p>
      </Content>
    </Container>
  );
};

export default SpecialInstruction;
