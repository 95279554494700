import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Tab } from 'rbx';
import moment from 'moment';
import qs from 'querystringify';

import { useStoreState } from 'easy-peasy';
import Layout from '../../../components/global/Layout';
import { Box, Tabs } from '../../../components/elements';
import Header from './Header';

import OrderList from './OrderList';
import { getOrders, getOrderCount } from './helpers';
import Search from './Search';

const Container = styled.div``;

const Orders = props => {
  const { userId } = useStoreState(state => state.auth);
  const routeState = qs.parse(props.location.search);
  const { placeName, city, from, to, tab } = routeState;

  const placeNameFilter = placeName || '';
  const cityFilter = city || '';
  const fromFilter = from || null;
  const toFilter = to || null;
  const activeTab = tab || 'new';

  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderCount, setOrderCount] = useState({
    receivedOrder: 0,
    confirmOrder: 0,
    readyOrder: 0,
    completeOrder: 0,
    cancelOrder: 0,
    draftOrder: 0,
  });

  const [sort, setSort] = useState('CREATED_DESC');

  const fetchData = async ({ status }) => {
    setOrders([]);
    setIsLoading(true);
    const menu_order_filter = {
      status,
      date_range: {
        start_date:
          fromFilter ||
          moment(`${moment().format('YYYY-MM-DD')} 12:00 AM`).toISOString(),
        end_date: toFilter || moment().toISOString(),
      },
    };
    const menu_order_filter_for_count = {
      date_range: {
        start_date:
          fromFilter ||
          moment(`${moment().format('YYYY-MM-DD')} 12:00 AM`).toISOString(),
        end_date: toFilter || moment().toISOString(),
      },
    };

    const place_filter = {};
    Object.assign(
      place_filter,
      placeNameFilter &&
        placeNameFilter.length !== 0 && {
          place_name: placeNameFilter,
        },
      cityFilter &&
        cityFilter.length !== 0 && {
          city: cityFilter,
        },
    );

    const data = await getOrders({
      sort,
      from: 0,
      size: 200,
      filter: {
        menu_order_filter,
        place_filter,
      },
    });

    const count = await getOrderCount({
      user_id: userId,
      filter: {
        menu_order_filter: menu_order_filter_for_count,
        place_filter,
      },
    });

    setOrders(data.menu_order_listing);

    setOrderCount({
      receivedOrder: count.received,
      confirmOrder: count.confirmed,
      readyOrder: count.ready,
      completeOrder: count.ready,
      cancelOrder: count.cancelled,
      draftOrder: count.draft,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (activeTab === 'new') {
      fetchData({ status: ['RECEIVED'] });
    }
    if (activeTab === 'confirmed') {
      fetchData({ status: ['CONFIRMED'] });
    }
    if (activeTab === 'ready') {
      fetchData({ status: ['READY'] });
    }
    if (activeTab === 'completed') {
      fetchData({ status: ['COMPLETE'] });
    }
    if (activeTab === 'cancelled') {
      fetchData({ status: ['CANCELLED'] });
    }
    if (activeTab === 'draft') {
      fetchData({ status: ['DRAFT'] });
    }
  }, [toFilter, fromFilter, sort, placeNameFilter, cityFilter, activeTab]);

  const handleTab = value => {
    props.history.push(
      `/orders-dashboard${qs.stringify(
        {
          ...routeState,
          page: 1,
          tab: value,
        },
        true,
      )}`,
    );
    // props.history.push(`/orders-dashboard?tab=${value}`);
  };

  return (
    <Layout>
      <Header />
      <Search
        placeName={placeNameFilter}
        city={cityFilter}
        startDate={fromFilter}
        endDate={toFilter}
        routeState={routeState}
      />

      <Box>
        <Tabs className="tabs is-medium is-fullwidth">
          <Tab active={activeTab === 'new'} onClick={() => handleTab('new')}>
            New ({orderCount.receivedOrder})
          </Tab>

          <Tab
            active={activeTab === 'confirmed'}
            onClick={() => handleTab('confirmed')}
          >
            CONFIRMED ({orderCount.confirmOrder})
          </Tab>

          <Tab
            active={activeTab === 'ready'}
            onClick={() => handleTab('ready')}
          >
            READY ({orderCount.readyOrder})
          </Tab>

          <Tab
            active={activeTab === 'completed'}
            onClick={() => handleTab('completed')}
          >
            COMPLETED ({orderCount.completeOrder})
          </Tab>

          <Tab
            active={activeTab === 'scheduled'}
            onClick={() => handleTab('scheduled')}
          >
            SCHEDULED (0)
          </Tab>

          <Tab
            active={activeTab === 'cancelled'}
            onClick={() => handleTab('cancelled')}
          >
            CANCELLED ({orderCount.cancelOrder})
          </Tab>
          <Tab
            active={activeTab === 'draft'}
            onClick={() => handleTab('draft')}
          >
            DRAFTS ({orderCount.draftOrder})
          </Tab>
        </Tabs>
        <Container>
          <OrderList
            {...props}
            orders={orders}
            isLoading={isLoading}
            isActiveTab={activeTab}
            sort={sort}
            setSort={setSort}
          />
        </Container>
      </Box>
    </Layout>
  );
};

export default Orders;
