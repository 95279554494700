import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';

import { Query } from 'react-apollo';

import Layout from '../../../components/global/Layout';
import apolloSubscriptionClient from '../../../utils/apolloSubscriptionClient';
import Form from './Form';

import { ErrorMessage, Loading } from '../../../components/elements';

const searchQuery = gql`
  query search($placeId: [String]) {
    search_places(input: { filter: { place_filter: { place_id: $placeId } } }) {
      place_listing {
        place_id
        display_order
        name
        address_line_1
        address_line_2
        city
        hours
        hours_pickup
        hours_dinein
        hours_delivery
        state
        country
        post_code
        status
        claim_status
        latitude
        longitude
        service_type {
          description
          name
          display_order
        }
      }
    }
  }
`;

const orderSubscription = gql`
  subscription subscribe_to_menu_order($place_id: String) {
    subscribe_to_menu_order(place_id: $place_id) {
      menu_order_id
      status
      place_id
      order_reference
      overall_quantity
      large_order
      service_type
      large_order

      payment {
        payment_method
        payment_method_id
        payment_intent_id
        payment_status
        payment_card {
          brand
          country
          last4
        }
        payment_at
      }
      contact {
        contact_name
        contact_phone
      }
      billing {
        sub_total
        order_total
        deal_discount
        adjustment_amount
        order_amount
        delivery_fee
      }
      estimates {
        type
        value_in_mins
      }
      audit {
        created_at
        created_by
      }
    }
  }
`;

const Order = ({ match }) => {
  const [currentSubscriptionData, setCurrentSubscriptionData] = useState();
  const [orders, setOrders] = useState(0);

  const { placeId } = match.params;

  useEffect(() => {
    apolloSubscriptionClient
      .hydrated()
      .then(clientSubscription => {
        // Now subscribe to results
        const observable = clientSubscription.subscribe({
          query: orderSubscription,
          variables: {
            place_id: placeId,
            status: 'RECEIVED',
          },
        });
        const realtimeResults = function realtimeResults(data) {
          setCurrentSubscriptionData(data.data.subscribe_to_menu_order);
          console.log(
            'data.data.subscribe_to_menu_order',
            data.data.subscribe_to_menu_order,
          );
        };
        observable.subscribe({
          next: realtimeResults,
          complete: console.log,
          error: console.log,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <Layout>
      <Query query={searchQuery} variables={{ placeId: [placeId] }}>
        {({ data, loading, error, refetch }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <ErrorMessage message={error.message} />;
          }
          const {
            search_places: { place_listing },
          } = data;
          return (
            <Form
              place={place_listing[0]}
              currentSubscriptionData={currentSubscriptionData}
              orders={orders}
              setOrders={setOrders}
              refetchPlace={refetch}
            />
          );
        }}
      </Query>
    </Layout>
  );
};

export default Order;
