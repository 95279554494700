import React from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import { withRouter } from 'react-router-dom';
import { omit } from 'lodash';
import qs from 'querystringify';
import { Input, CheckBox, Radio } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const PlaceSearch = ({
  placeName,
  listingStatus,
  itemReviewPlace,
  eventBusiness,
  city,
  vendorName,
  routeState,
  history,
}) => {
  const handlePush = (args, key) => {
    const newRoutState = omit(routeState, key);
    history.push(
      `/places${qs.stringify(
        {
          ...newRoutState,
          ...args,
          page: 1,
        },
        true,
      )}`,
    );
  };

  return (
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Listing Type</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="APPROVED"
                name="Type"
                value={listingStatus === 'APPROVED'}
                onChange={() =>
                  handlePush({ listingStatus: 'APPROVED' }, 'listingStatus')
                }
              />
            </div>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="UNLISTED"
                name="Type"
                value={listingStatus === 'UNLISTED'}
                onChange={() =>
                  handlePush({ listingStatus: 'UNLISTED' }, 'listingStatus')
                }
              />
            </div>
          </Level.Item>
        </Level.Item>
        <Level.Item align="right">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Additional Criteria</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <CheckBox
              label="Event organizer only"
              name="event_business"
              value={eventBusiness === 'true'}
              onChange={value => {
                history.push(
                  `/places${qs.stringify(
                    {
                      listingStatus,
                      itemReviewPlace: false,
                      eventBusiness: value,
                      placeName,
                    },
                    true,
                  )}`,
                );
              }}
            />
          </Level.Item>
          <Level.Item>
            <CheckBox
              label="User added place via item review"
              value={itemReviewPlace === 'true'}
              name="item_review_place"
              onChange={value => {
                history.push(
                  `/places${qs.stringify(
                    {
                      listingStatus,
                      itemReviewPlace: value,
                      eventBusiness: false,
                      placeName,
                    },
                    true,
                  )}`,
                );
              }}
            />
          </Level.Item>
        </Level.Item>
      </Level>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-4">
            <Input
              label="Place Name"
              type="text"
              id="PlaceManagement_AddEditPlace_SearchPlaceName"
              value={placeName}
              onChange={place => {
                handlePush({ placeName: place.target.value }, 'placeName');
              }}
              placeholder="Start entering place name..."
            />
          </div>
          <div className="column is-4">
            <Input
              label="Company Name"
              type="text"
              id="PlaceManagement_AddEditPlace_SearchCompanyName"
              value={vendorName}
              onChange={place => {
                handlePush({ vendorName: place.target.value }, 'vendorName');
              }}
              placeholder="Start entering company name..."
            />
          </div>

          <div className="column is-4">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city || ''}
                  onSelect={value => {
                    handlePush({ city: value.name }, 'city');
                  }}
                  onHandleClear={() => handlePush({ city: '' }, 'city')}
                  placeholder="Start entering company name..."
                />
              </div>
            </div>
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default withRouter(PlaceSearch);
