import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import tzLookup from 'tz-lookup';
import moment from 'moment-timezone';

import { Table, Loading } from '../../../components/elements';

import OrderDetailsModal from './OrderDetailsModal';

const ReferenceStyled = styled.div`
  color: #3273dc;
  cursor: pointer;
`;

const ItemRow = ({ item, onClick, isActive }) => {
  useEffect(() => {
    const lat = parseFloat(item.place.latitude).toFixed(5);
    const long = parseFloat(item.place.longitude).toFixed(5);
    const timeZone = tzLookup(lat, long);
    moment.tz.setDefault(timeZone);
  }, []);

  return (
    <tbody>
      <tr>
        <td>
          <ReferenceStyled onClick={onClick}>
            {item.order_reference}
          </ReferenceStyled>
        </td>
        <td>{item.place.name}</td>
        <td>{item.place.city}</td>
        <td>{item.service_type}</td>
        {console.log('item', item)}
        {isActive === 'draft' ? (
          <td>{moment(item.audit.created_at).format('LLL')}</td>
        ) : (
          <td>{moment(item.received_at).format('LLL')}</td>
        )}

        <td>-</td>
        <td>{item.overall_quantity} items</td>
        <td>${parseFloat(item.billing.order_total).toFixed(2) || 0}</td>
        <td>{item.payment && capitalize(item.payment.payment_method)}</td>
        {isActive === 'new' ? (
          <td>{moment(item.transitioned_at).fromNow()}</td>
        ) : (
          <>
            <td>
              {isActive !== 'draft'
                ? moment(item.transitioned_at).from(item.received_at)
                : '-'}
            </td>
          </>
        )}
        <td>{item.contact && capitalize(item.contact.contact_name)}</td>
      </tr>
    </tbody>
  );
};

const AesDes = ({ sort, setSort, aes, des }) => (
  <a onClick={() => setSort(sort === des ? aes : des)}>
    <i className={`fas fa-sort-amount-${sort === des ? 'up' : 'down'}`} />
  </a>
);

const New = ({ sort, setSort, orders, isLoading, isActiveTab }) => {
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [orderData, setOrderData] = useState(null);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>
              <div>
                <span>Order No.</span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="PLACE_NAME_ASC"
                    des="PLACE_NAME_DESC"
                  />
                  Place Name
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="PLACE_CITY_ASC"
                    des="PLACE_CITY_DESC"
                  />
                  City / Suburb
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="SERVICE_TYPE_ASC"
                    des="SERVICE_TYPE_DESC"
                  />
                  Service Type
                </span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="RECEIVED_ASC"
                    des="RECEIVED_DESC"
                  />
                  {isActiveTab === 'draft' ? 'Draft Date' : 'Order Date'}
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>Scheduled</span>
              </div>
            </th>

            <th>
              <div>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="OVERALL_QUANTITY_ASC"
                  des="OVERALL_QUANTITY_DESC"
                />
                <span>Items</span>
              </div>
            </th>

            <th>
              <div>
                <span>Total</span>
              </div>
            </th>

            <th>
              <div>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="PAYMENT_METHOD_ASC"
                  des="PAYMENT_METHOD_DESC"
                />
                <span>Payment</span>
              </div>
            </th>

            <th>
              <div>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="TRANSITIONED_ASC"
                  des="TRANSITIONED_DESC"
                />
                <span>Duration</span>
              </div>
            </th>
            <th>
              <div>
                <span>Created By</span>
              </div>
            </th>
          </tr>
        </thead>

        {isLoading && <Loading />}
        {orders.length !== 0
          ? orders.map(item => (
              <ItemRow
                isActive={isActiveTab}
                item={item}
                onClick={() => {
                  setOrderData(item);
                  setIsActiveModal(true);
                }}
              />
            ))
          : !isLoading && <div className="">No Orders here</div>}
      </Table>
      {orderData && isActiveModal && (
        <OrderDetailsModal
          onClose={() => setIsActiveModal(false)}
          isActive={isActiveModal}
          order={orderData}
        />
      )}
    </>
  );
};

export default New;
