import gql from 'graphql-tag';
import client from '../../../utils/apolloClient';

const searchMenusQuery = gql`
  query searchMenus($input: SearchInput) {
    search_menu(input: $input) {
      menu_listing {
        place_id
        service_type_setting {
          service_type_setting_id
        }
        service_type
        menu_id
        name
        internal_name
        slug
        description
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getMenus = ({ placeId }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        client: client.clientPublic,
        query: searchMenusQuery,
        variables: {
          input: {
            filter: {
              menu_filter: { place_id: placeId, status: 'ACTIVE' },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data.search_menu && data.search_menu.menu_listing) {
          resolve(data.search_menu.menu_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
