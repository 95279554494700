import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';
import { first } from 'lodash';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import client from '../../../utils/apolloClient';

import { Loading, Message } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import ClaimForm from '../PlaceClaimAdd/ClaimForm';

const allNeededQuery = gql`
  query fetch_place($placeId: String) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      name
      listing_status
      claim_status
      # status
      # approval_status
      description
      address_line_1
      city
      state
      country
      post_code
      timezone
      latitude
      longitude
      contact {
        type
        value
        display
        display_order
        is_primary
      }
      social {
        type
        logo
        description
        value
        display
      }

      business_hour {
        day
        is_active
        option {
          type
          start
          end
        }
      }
      business_type {
        name
        description
        display_order
      }

      service_category {
        name
        description
        display_order
      }

      feature {
        name
        description
        logo
        display_order
      }
      cuisine_style {
        name
        description
        display_order
      }
      good_for {
        name
        description
        display_order
      }

      dining_style {
        name
        description
        display_order
      }
      listing_audit {
        listing_contact_user_id
        listing_contact_name
        listing_contact_phone
        listing_contact_email
        listing_contact_role
        listing_approved_by
        listing_approved_at
      }
    }
  }
`;

const fetchClaimQuery = gql`
  query fetch_place($claimantId: String!) {
    fetch_place_claimant(input: { claimant_id: $claimantId }) {
      claimant_id
      object_id
      status
      object_type
      contact_user_id
      contact_full_name
      contact_phone
      contact_email
      contact_role
      contact_business_url
      contact_linked_in_url
      contact_facebook_url
      service_type {
        name
        description
        display_order
      }
      agree_terms_and_conditions
      verification_mode
      verification_note {
        type
        value
        date
      }
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
      place_claimant_audit {
        claim_approved_by
        claim_approved_at
        claim_rejected_by
        claim_rejected_at
      }
      error {
        code
        description
      }
    }
  }
`;

const actionOnPlaceClaimMutation = gql`
  mutation actionOnPlaceClaim($input: ActionPlaceClaimantInput) {
    action_place_claimant(input: $input) {
      claimant_id
      status
      error {
        code
        description
      }
    }
  }
`;

const updatePlaceClaimant = gql`
  mutation updatePlaceClaimant($input: PlaceClaimantInput) {
    update_place_claimant(input: $input) {
      user_id
      claimant_id
      verification_note {
        type
        value
      }
      error {
        code
        description
      }
    }
  }
`;

const PlaceClaimUpdate = ({ match, history }) => {
  const [action, setAction] = useState('');
  const [claim, setClaim] = useState();
  const { placeId } = match.params;
  const { claimantId } = match.params;
  const { userId } = useStoreState(state => state.auth);

  useEffect(() => {
    client.clientPrivate
      .query({
        query: fetchClaimQuery,
        variables: {
          placeId,
          claimantId,
        },
      })
      .then(({ data: { fetch_place_claimant } }) => {
        if (fetch_place_claimant) {
          setClaim(first(fetch_place_claimant));
        }
      });
  }, []);

  return (
    <Layout>
      <Query
        query={allNeededQuery}
        variables={{
          placeId,
          claimantId,
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading || !claim) {
            return <Loading />;
          }
          if (error) {
            return <Message>error</Message>;
          }

          const { fetch_place } = data;

          return (
            <Mutation
              client={client.clientPrivate}
              mutation={updatePlaceClaimant}
              onCompleted={({ update_place_claimant }) => {
                if (update_place_claimant === null) {
                  toast.error('something wrong in backend, its not responding');
                } else if (update_place_claimant.error !== null) {
                  update_place_claimant.error.map(item =>
                    toast.error(item.description),
                  );
                } else {
                  swal(
                    'Great!',
                    'Claim has been updated successfully!',
                    'success',
                  ).then(() => {
                    history.goBack();
                  });
                }
              }}
            >
              {(
                update_place_claimant,
                { loading: updateLoading, error: updateError },
              ) => (
                <Mutation
                  client={client.clientPrivate}
                  mutation={actionOnPlaceClaimMutation}
                  onCompleted={({ action_place_claimant }) => {
                    if (action_place_claimant === null) {
                      toast.error(
                        'something wrong in backend, its not responding',
                      );
                    } else if (action_place_claimant.error !== null) {
                      action_place_claimant.error.map(item =>
                        toast.error(item.description),
                      );
                    } else if (action === 'approve-claim') {
                      swal(
                        'Great!',
                        'Claim has been approved successfully!',
                        'success',
                      ).then(() => {
                        history.goBack();
                      });
                    } else {
                      swal(
                        'Great!',
                        'Claim has been rejected successfully!',
                        'success',
                      ).then(() => {
                        history.goBack();
                      });
                    }
                  }}
                >
                  {(
                    action_place_claimant,
                    { loading: actionLoading, error: actionError },
                  ) => (
                    <ClaimForm
                      loading={actionLoading || updateLoading}
                      error={actionError || updateError}
                      place={{ ...fetch_place }}
                      claim={{ ...claim }}
                      createForm={false}
                      action={action}
                      setAction={setAction}
                      onSubmit={value => {
                        console.log('value', action, value);
                        if (action === 'approve-claim') {
                          action_place_claimant({
                            variables: {
                              input: {
                                user_id: userId,
                                place_id: value.place_id,
                                claimant_id: value.claimant_id,

                                status: 'APPROVED',
                              },
                            },
                          });
                        }
                        if (action === 'reject-claim') {
                          action_place_claimant({
                            variables: {
                              input: {
                                user_id: userId,
                                place_id: value.place_id,
                                claimant_id: value.claimant_id,
                                // note: value.verification_note.map(value),
                                status: 'REJECTED',
                              },
                            },
                          });
                        }

                        if (action === 'update-claim') {
                          update_place_claimant({
                            variables: {
                              input: {
                                user_id: userId,
                                object_id: value.place_id,
                                claimant_id: value.claimant_id,
                              },
                            },
                          });
                        }
                      }}
                    />
                  )}
                </Mutation>
              )}
            </Mutation>
          );
        }}
      </Query>
    </Layout>
  );
};

export default withRouter(PlaceClaimUpdate);
