import { isEqual, omit, forEach } from 'lodash';

export const removeTypeName = values => {
  const data = [];
  forEach(values, item => {
    const itemData = omit(item, ['id', '__typename']);
    data.push(itemData);
  });
  return data;
};

const removeTypeVariantsInput = variants => {
  const data = [];
  forEach(variants, item => {
    const temp = [];
    const variant = omit(item, ['__typename']);
    forEach(variant.variants, item2 => {
      const variant2 = omit(item2, ['__typename']);
      temp.push({
        ...variant2,
        variant_price: parseFloat(variant2.variant_price)
          .toFixed(2)
          .toString(),
      });
    });
    data.push({ ...variant, variants: temp });
  });
  console.log('addIdInVariantsInput', data);
  return data;
};

const dirtyFieldHandler = (finalInput, item) => {
  console.log(finalInput, item);
  const final = {};
  Object.assign(
    final,
    {
      user_id: finalInput.user_id,
      menu_item_id: finalInput.menu_item_id,
      place_id: finalInput.place_id,
    },
    !isEqual(item.allow_item_review, finalInput.allow_item_review) && {
      allow_item_review: finalInput.allow_item_review,
    },
    !isEqual(
      item.allow_special_instructions,
      finalInput.allow_special_instructions,
    ) && {
      allow_special_instructions: finalInput.allow_special_instructions,
    },
    !isEqual(item.description, finalInput.description) && {
      description: finalInput.description,
    },
    !isEqual(item.approval_status, finalInput.approval_status) && {
      approval_status: finalInput.approval_status,
    },
    !isEqual(item.availability_status, finalInput.availability_status) && {
      availability_status: finalInput.availability_status,
    },
    !isEqual(item.calories, finalInput.calories) && {
      calories: finalInput.calories,
    },
    !isEqual(removeTypeName(item.cuisine_style), finalInput.cuisine_style) && {
      cuisine_style: finalInput.cuisine_style,
    },
    !isEqual(
      removeTypeName(item.dietary_information),
      finalInput.dietary_information,
    ) && {
      dietary_information: finalInput.dietary_information,
    },
    !isEqual(item.image_url, finalInput.image_url) && {
      image_url: finalInput.image_url,
    },
    !isEqual(item.internal_name, finalInput.internal_name) && {
      internal_name: finalInput.internal_name,
    },
    !isEqual(item.item_contains_alcohol, finalInput.item_contains_alcohol) && {
      item_contains_alcohol: finalInput.item_contains_alcohol,
    },
    !isEqual(item.item_stored, finalInput.item_stored) && {
      item_stored: finalInput.item_stored,
    },
    !isEqual(removeTypeName(item.item_type), finalInput.item_type) && {
      item_type: finalInput.item_type,
    },
    !isEqual(item.kilojoules, finalInput.kilojoules) && {
      kilojoules: finalInput.kilojoules,
    },
    !isEqual(item.label, finalInput.label) && {
      label: finalInput.label,
    },
    !isEqual(item.modifier_group_id, finalInput.modifier_group_id) && {
      modifier_group_id: finalInput.modifier_group_id,
    },
    !isEqual(item.name, finalInput.name) && {
      name: finalInput.name,
    },
    !isEqual(item.popular, finalInput.popular) && {
      popular: finalInput.popular,
    },
    !isEqual(item.popular_addon, finalInput.popular_addon) && {
      popular_addon: finalInput.popular_addon,
    },
    !isEqual(parseFloat(item.price), parseFloat(finalInput.price)) && {
      price: finalInput.price,
    },
    !isEqual(item.sell_item_on_its_own, finalInput.sell_item_on_its_own) && {
      sell_item_on_its_own: finalInput.sell_item_on_its_own,
    },
    !isEqual(item.slug, finalInput.slug) && {
      slug: finalInput.slug,
    },
    !isEqual(item.source_type, finalInput.source_type) && {
      source_type: finalInput.source_type,
    },
    !isEqual(item.spice_levels, finalInput.spice_levels) && {
      spice_levels: finalInput.spice_levels,
    },
    !isEqual(item.status, finalInput.status) && {
      status: finalInput.status,
    },
    !isEqual(item.tag, finalInput.tag) && {
      tag: finalInput.tag,
    },
    !isEqual(item.tax, finalInput.tax) && {
      tax: finalInput.tax,
    },
    !isEqual(removeTypeVariantsInput(item.variant), finalInput.variant) && {
      variant: finalInput.variant,
    },
  );
  return final;
};

export default dirtyFieldHandler;
