import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { isNull, omit } from 'lodash';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';

import client from '../../../utils/apolloClient';
import { ErrorMessage } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import PlaceSelection from '../../../components/PlaceSelection';
import Form from './Form';

const createMenuMutation = gql`
  mutation createMenu($input: MenuInput) {
    create_menu(input: $input) {
      menu_id
      error {
        description
      }
    }
  }
`;

const MenuTypeAdd = ({ history }) => {
  const { userId } = useStoreState(state => state.auth);
  const [place, setPlace] = useState({});
  const [placeSelection, setPlaceSelection] = useState(false);

  return (
    <Layout>
      {placeSelection ? (
        <Mutation
          client={client.clientPrivate}
          mutation={createMenuMutation}
          onCompleted={({ create_menu }) => {
            if (!isNull(create_menu.error)) {
              create_menu.error.map(item => toast.error(item.description));
            } else {
              swal('Great!', 'Menu created successfully!', 'success').then(
                () => {
                  history.goBack();
                },
              );
            }
          }}
        >
          {(create_menu, { loading, error }) => (
            <>
              {error && <ErrorMessage message={error.message} />}
              <Form
                userId={userId}
                place={place}
                loading={loading}
                onSubmit={inputs => {
                  const input = omit(inputs, ['menus', 'step']);
                  create_menu({ variables: { input } });
                }}
              />
            </>
          )}
        </Mutation>
      ) : (
        <PlaceSelection
          claimStatus="NONE"
          hasServiceSetting
          heading="Select Place to add menus"
          handleSelect={value => {
            setPlace(value);
            setPlaceSelection(true);
          }}
        />
      )}
    </Layout>
  );
};

export default MenuTypeAdd;
