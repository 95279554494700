import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { SubHeading } from '../../../components/elements';
import Can from '../../../utils/Can';
import { ACLSConsumer } from '../../../utils/aclsContext';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Header = ({
  heading,
  loading,
  onSubmit,
  action,
  status,
  subHeading,
  history,
  prev,
  next,
  activeTab,
  location,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title size={2}>{heading}</Title>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-light">
            <span>Cancel</span>
          </a>
        </Level.Item>
        {activeTab !== 0 && (
          <Level.Item>
            <button onClick={prev} className="button is-primary">
              <span>Prev Tab</span>
            </button>
          </Level.Item>
        )}

        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/place-photo-approval'
                  ? 'update_image_review'
                  : 'update_image_review'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <>
                  {status !== 'REJECTED' && (
                    <Level.Item>
                      <button
                        className={`button is-danger ${action === 'REJECTED' &&
                          loading &&
                          'is-loading'}`}
                        onClick={() => onSubmit('REJECTED')}
                        disabled={status === 'REJECTED'}
                      >
                        <span>Reject</span>
                      </button>
                    </Level.Item>
                  )}
                </>
              )}
            />
          )}
        </ACLSConsumer>

        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/place-photo-approval'
                  ? 'update_image_review'
                  : 'update_image_review'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <>
                  {status !== 'APPROVED' && (
                    <Level.Item>
                      <button
                        className={`button is-primary ${action === 'APPROVED' &&
                          loading &&
                          'is-loading'}`}
                        onClick={() => onSubmit('APPROVED')}
                        disabled={status === 'APPROVED'}
                      >
                        <span>Approve and Submit</span>
                      </button>
                    </Level.Item>
                  )}
                </>
              )}
            />
          )}
        </ACLSConsumer>

        {activeTab !== 3 && (
          <Level.Item>
            <button onClick={next} className="button is-primary">
              <span>Next Tab</span>
            </button>
          </Level.Item>
        )}
      </Level.Item>
    </Level>
    <SubHeading className="is-size-2">{subHeading || ''}</SubHeading>
  </Wrapper>
);

export default withRouter(Header);
// update_image_review
