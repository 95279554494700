import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Column, Label } from 'rbx';
import { forEach, isNaN, range } from 'lodash';
import styled from 'styled-components';

import {
  Input,
  CheckBox,
  BoxWithHeader,
  TagInputs,
  CreatableSelectInputs,
  InputAddOn,
  Radio,
  SingleImageUpload,
  Select,
} from '../../../components/elements';
import {
  getCuisines,
  getItemTypes,
  getDietaryInformation,
} from '../../../utils/fetchStatic';

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
`;

const parseData = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push({ value: item.name, label: item.description });
  });
  return final;
};

const parseInput = parseValue => {
  const final = [];
  forEach(parseValue, (item, index) => {
    final.push({
      name: item.value,
      description: item.label,
      display_order: (index + 1).toString(),
    });
  });
  return final;
};

const tagInput = tagsValue => {
  const tags = [];
  tagsValue.map(item => {
    tags.push(item.value);
    return null;
  });
  return tags;
};

const Details = ({ values, setFieldValue, errors, touched }) => {
  const [cuisines, setCuisines] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  const [dietary, setDietary] = useState([]);

  const fetchCmsData = useCallback(async () => {
    const cuisinesData = await getCuisines();
    setCuisines(parseData(cuisinesData));
    const itemTypesData = await getItemTypes();
    setItemTypes(parseData(itemTypesData));
    const dietaryData = await getDietaryInformation();
    setDietary(parseData(dietaryData));
  }, []);

  useEffect(() => {
    fetchCmsData();
  }, []);

  return (
    <Fragment>
      <Column.Group>
        <Column size="5">
          <BoxWithHeader title="Photos">
            <SingleImageUpload
              imageValue={values.image_url}
              onChange={image => setFieldValue('image_url', image.url)}
            />
          </BoxWithHeader>
        </Column>
        <Column size="7">
          <Column.Group multiline>
            <Column size="full">
              <TagInputs
                label="Cuisines Styles"
                value={parseData(values.cuisine_style)}
                suggestions={cuisines}
                onChange={value =>
                  setFieldValue('cuisine_style', parseInput(value))
                }
                errors={errors.cuisine_style}
                touched={touched.cuisine_style}
              />
            </Column>
            <Column size="full">
              <TagInputs
                label="Item Types"
                value={parseData(values.item_type)}
                suggestions={itemTypes}
                onChange={value =>
                  setFieldValue('item_type', parseInput(value))
                }
                errors={errors.item_type}
                touched={touched.item_type}
              />
            </Column>
            <Column size="full">
              <TagInputs
                label="Dietary Information"
                suggestions={dietary}
                value={parseData(values.dietary_information)}
                onChange={value =>
                  setFieldValue('dietary_information', parseInput(value))
                }
                errors={errors.dietary_information}
                touched={touched.dietary_information}
              />
            </Column>
            <Column size="full">
              <CreatableSelectInputs
                label="Item Tags / Keywords"
                placeholder="eg, nice place, awesome food here, ...."
                value={values.tag.map(item => ({ value: item, label: item }))}
                onChange={value => {
                  if (value) {
                    setFieldValue('tag', tagInput(value));
                  }
                }}
              />
            </Column>
            <Column size="half">
              <Label>Alcohol Information</Label>
              <CheckBox
                label="This item contain alcohol"
                value={values.item_contains_alcohol}
                onChange={value =>
                  setFieldValue('item_contains_alcohol', value)
                }
              />
            </Column>
            <Column size="half">
              <Label>Item Review Information</Label>
              <CheckBox
                label="Allow item review"
                value={values.allow_item_review}
                onChange={value => setFieldValue('allow_item_review', value)}
              />
            </Column>
          </Column.Group>
        </Column>
      </Column.Group>
      <BoxWithHeader title="Nutrition and Energy Information">
        <Column.Group>
          <Column size="3">
            <InputAddOn
              label="Calories"
              optionalText1="Number of calories for this item"
              isRight
              addonsText="Cal"
              type="number"
              min="0"
              step="0"
              value={values.calories}
              onChange={e => {
                if (e.target.value === '') {
                  setFieldValue('calories', null);
                } else if (!isNaN(parseFloat(e.target.value))) {
                  setFieldValue('calories', parseFloat(e.target.value));
                }
              }}
            />
          </Column>
          <Column size="3">
            <InputAddOn
              label="Kilojoules"
              optionalText1="Number of kilojoules for this item"
              isRight
              addonsText="KJ"
              type="number"
              min="0"
              step="0"
              value={values.kilojoules}
              onChange={e => {
                if (e.target.value === '') {
                  setFieldValue('kilojoules', null);
                } else if (!isNaN(parseFloat(e.target.value))) {
                  setFieldValue('kilojoules', parseFloat(e.target.value));
                }
              }}
            />
          </Column>
          <Column size="2">
            <Select
              label="Spice levels"
              options={range(1, 6).map(item => ({ label: item, value: item }))}
              value={{ label: values.spice_levels, value: values.spice_levels }}
              onChange={value => {
                setFieldValue('spice_levels', value.value);
              }}
            />
          </Column>
          <Column size="3">
            <Label>How is this item served?</Label>
            <Flex>
              <Radio
                label="Heated"
                value={values.item_stored === 'HEATED'}
                onChange={() => setFieldValue('item_stored', 'HEATED')}
              />
              <Radio
                label="Unheated"
                value={values.item_stored === 'UNHEATED'}
                onChange={() => setFieldValue('item_stored', 'UNHEATED')}
              />
              <Radio
                label="Cold"
                value={values.item_stored === 'COLD'}
                onChange={() => setFieldValue('item_stored', 'COLD')}
              />
            </Flex>
          </Column>
        </Column.Group>
      </BoxWithHeader>
      <BoxWithHeader title="Catering Information">
        <Column.Group>
          <Column size="3">
            <Input
              label="Order Minimum"
              type="number"
              min="0"
              // onChange={e => {
              //   if (e.target.value === '') {
              //     setFieldValue('price', null);
              //   } else if (!isNaN(parseFloat(e.target.value))) {
              //     setFieldValue('price', parseFloat(e.target.value));
              //   }
              // }}
              disabled
            />
          </Column>
          <Column size="3">
            <Input
              label="Serving Size"
              type="number"
              min="0"
              onChange={e => {
                if (e.target.value === '') {
                  setFieldValue('price', null);
                } else if (!isNaN(parseFloat(e.target.value))) {
                  setFieldValue('price', parseFloat(e.target.value));
                }
              }}
              disabled
            />
          </Column>
        </Column.Group>
      </BoxWithHeader>
      <BoxWithHeader title="Additional Information">
        <Column.Group>
          <Column size="3">
            <Input label="Label" disabled />
          </Column>
          <Column size="3">
            <Input label="Description" disabled />
          </Column>
        </Column.Group>
      </BoxWithHeader>
    </Fragment>
  );
};

export default Details;
