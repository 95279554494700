import React from 'react';
import styled from 'styled-components';
import OrderDetails from './OrderDetails';

const Container = styled.div`
  -moz-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  margin-bottom: 1rem;
  background-color: ${({ theme }) => theme.primaryColor};
  cursor: pointer;
`;

const Content = styled.div`
  padding: 1rem;
`;

const NewOrder = ({
  children,
  isActiveOrderDetailsModal,
  setIsActiveOrderDetailsModal,
  handleCurrentOrder,
  setReceivedOrders,
  receivedOrders,
  setShowConfirmedTime,
  showConfirmedTime,
  addOrder,
  addConfirmationOrderAfterReceived,
  item,
  order,
  reference,
}) => (
  <>
    <Container
      onClick={() => {
        setIsActiveOrderDetailsModal(true);
        handleCurrentOrder(item.menu_order_id);
      }}
    >
      <Content>{children}</Content>
    </Container>
    {order &&
      reference === order.order_reference &&
      isActiveOrderDetailsModal && (
        <OrderDetails
          isActiveOrderDetailsModal={isActiveOrderDetailsModal}
          setIsActiveOrderDetailsModal={setIsActiveOrderDetailsModal}
          setReceivedOrders={setReceivedOrders}
          receivedOrders={receivedOrders}
          addOrder={addOrder}
          addConfirmationOrderAfterReceived={addConfirmationOrderAfterReceived}
          order={order}
          showConfirmedTime={showConfirmedTime}
          setShowConfirmedTime={setShowConfirmedTime}
        />
      )}
  </>
);
export default NewOrder;
