import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title, Column } from 'rbx';
import styled from 'styled-components';
import moment from 'moment-timezone';

import { SubHeading, ReactDateTimePicker } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:first-child) {
    margin-bottom: 0;
  }
`;

const Header = ({
  placeName,
  placeLocation,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const audio = new Audio('./Alert-notification.mp3');
  const start = () => {
    audio.play();
  };
  return (
    <Wrapper>
      <Title size={2}>Order History</Title>
      <Level>
        <div>
          <SubHeading className="is-size-2 has-text-weight-medium">
            {placeName}
          </SubHeading>
          <SubHeading className="is-size-2">{placeLocation}</SubHeading>
        </div>

        <Level.Item>
          <Title size={3} style={{ paddingRight: '8px', paddingTop: '0.3rem' }}>
            Date
          </Title>
          <Column.Group style={{ paddingBottom: '1.5rem' }}>
            <Column size={6}>
              <ReactDateTimePicker
                maxDate={moment(endDate).toDate()}
                value={startDate}
                label="From"
                onChange={date => setStartDate(date)}
                clear={false}
              />
            </Column>
            <Column size={6}>
              <ReactDateTimePicker
                minDate={moment(startDate).toDate()}
                value={endDate}
                label="To"
                onChange={date => setEndDate(date)}
                clear={false}
              />
            </Column>
          </Column.Group>
        </Level.Item>
      </Level>
    </Wrapper>
  );
};

export default withRouter(Header);
