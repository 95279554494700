import React from 'react';
import styled from 'styled-components';
import qs from 'querystringify';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withRouter } from 'react-router-dom';
import 'react-accessible-accordion/dist/fancy-example.css';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';

import { ACLSConsumer } from '../../utils/aclsContext';
import CanPage from '../../utils/CanPage';

const Wrapper = styled.div`
  height: 92vh;
  overflow-y: scroll;
  .right-span {
    padding-left: 0.6rem;
  }
  .left-span {
    padding-right: 1.1rem;
  }
  .accordion__panel {
    padding: 0rem;
  }
  .sidebar {
    :focus {
      outline: none;
    }
  }
  .accordion {
    border: 0;
  }
  .sidebar-button {
    border: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #e0e0e0;
    padding: 0.9rem 1rem;
    cursor: pointer;
    width: 100%;
    font-weight: 600;
    color: #505050;
    @media screen and (max-width: 1023px) {
      font-size: 0.7rem;
    }
    @media screen and (min-width: 1024px) {
      font-size: 0.6rem;
    }
    @media screen and (min-width: 1216px) {
      font-size: 0.7rem;
    }
    @media screen and (min-width: 1408px) {
      font-size: 0.8rem;
    }
    white-space: nowrap;
    :hover {
      background: #d0d0d0;
    }
    :focus {
      outline: none;
    }
  }
`;

const SideNavItemStyle = styled.div`
  && {
    /* background: #e0e0e0; */
    border: 0px;
    padding: 0.6rem 1rem;
    font-weight: 600;
    white-space: nowrap;
    a {
      color: #505050;
      @media screen and (max-width: 1023px) {
        font-size: 0.6rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 0.5rem;
      }
      @media screen and (min-width: 1216px) {
        font-size: 0.6rem;
      }
      @media screen and (min-width: 1408px) {
        font-size: 0.7rem;
      }
    }
    :hover {
      background: #e8e8e8;
    }
  }
`;

const SideNavItem = ({ action, title, active, onClick }) => {
  if (!action) {
    return (
      <SideNavItemStyle onClick={onClick}>
        <a className={active ? 'has-text-primary' : ''}>
          <span className="icon is-small left-span">
            <i className="far fa-dot-circle" />
          </span>
          {title}
        </a>
      </SideNavItemStyle>
    );
  }

  return (
    <ACLSConsumer>
      {({ apiActions, pageActions }) => (
        <CanPage
          action={action}
          apiActions={apiActions}
          pageActions={pageActions}
          yes={() => (
            <SideNavItemStyle onClick={onClick}>
              <a className={active ? 'has-text-primary	' : ''}>
                <span className="icon is-small left-span">
                  <i className="far fa-dot-circle" />
                </span>
                {title}
              </a>
            </SideNavItemStyle>
          )}
        />
      )}
    </ACLSConsumer>
  );
};

const SideNav = ({ history }) => {
  const route = useStoreState(state => state.global.currentRoute);
  const updateCurrentRoute = useStoreActions(
    state => state.global.updateCurrentRoute,
  );
  const preExpanded = route.main;
  const preSubExpanded = route.sub;
  return (
    <Wrapper>
      <Accordion preExpanded={[preExpanded]} allowZeroExpanded>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton className="sidebar">
              <div
                className="sidebar-button"
                onClick={() => {
                  history.push('/');
                  updateCurrentRoute({ main: 'dashboard', sub: '' });
                }}
              >
                <div>
                  <span className="icon is-small left-span">
                    <i className="fa fa-desktop" />
                  </span>
                  Dashboard
                </div>
                <span className="icon is-small right-span ">
                  <i className="fa fa-angle-right" />
                </span>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
        </AccordionItem>
        <AccordionItem uuid="place">
          <AccordionItemHeading
            onClick={() => {
              updateCurrentRoute({ main: 'place', sub: '' });
            }}
          >
            <AccordionItemButton className="sidebar-button">
              <div>
                <span className="icon is-small left-span">
                  <i className="fa fa-map-marker" />
                </span>
                Place Management
              </div>
              <span className="icon is-small right-span ">
                <i
                  className={
                    preExpanded === 'place'
                      ? 'fa fa-angle-down'
                      : 'fa fa-angle-right'
                  }
                />
              </span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <SideNavItem
              action={[
                'add_place',
                'view_place',
                'approve_place_updates',
                'edit_place',
              ]}
              title="Add / Edit Place"
              active={preSubExpanded === 'addEditPlace'}
              onClick={() => {
                history.push(`/places/${qs.stringify({}, true)}`);
                updateCurrentRoute({ main: 'place', sub: 'addEditPlace' });
              }}
            />
            <SideNavItem
              action={['approve_place_listing', 'edit_place_listing']}
              title="List your place approval"
              active={preSubExpanded === 'listPlaceApproval'}
              onClick={() => {
                history.push(`/place-approval`);
                updateCurrentRoute({ main: 'place', sub: 'listPlaceApproval' });
              }}
            />
            <SideNavItem
              action={[
                'approve_place_claim',
                'add_place_claim',
                'edit_place_claim',
              ]}
              title="Claim your Place Approvals"
              active={preSubExpanded === 'placeClaim'}
              onClick={() => {
                history.push(
                  `/place-claims/${qs.stringify(
                    {
                      status: 'SUBMITTED',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({ main: 'place', sub: 'placeClaim' });
              }}
            />
            <SideNavItem
              action={[
                'add_posting',
                'edit_posting',
                'view_posting',
                'approve_posting',
              ]}
              title="Place Posting"
              active={preSubExpanded === 'placePosting'}
              onClick={() => {
                history.push(
                  `/place-postings/${qs.stringify(
                    {
                      status: 'ACTIVE',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({ main: 'place', sub: 'placePosting' });
              }}
            />

            <SideNavItem
              action="view_place_activity"
              title="Place Activity"
              active={preSubExpanded === 'placeActivities'}
              onClick={() => {
                history.push('/place-activities');
                updateCurrentRoute({ main: 'place', sub: 'placeActivities' });
              }}
            />

            <SideNavItem
              action={['view_item_activity']}
              title="Item Activity"
              active={preSubExpanded === 'itemActivities'}
              onClick={() => {
                history.push('/item-activities');
                updateCurrentRoute({ main: 'place', sub: 'itemActivities' });
              }}
            />

            {/* <SideNavItem
              action={[
                'view_company_details',
                'add_company_details',
                'edit_company_details',
                'delete_company_details',
                'approve_company_details',
              ]}
              title="Company Details"
              active={preSubExpanded === 'companyDetails'}
              onClick={() => {
                history.push('/companies');
                updateCurrentRoute({ main: 'place', sub: 'companyDetails' });
              }}
            /> */}

            {/* <SideNavItem
              title=" Place and Menu Deals"
              active={preSubExpanded === 'placeDeals'}
              onClick={() => {
                history.push('/place-deals');
                updateCurrentRoute({ main: 'place', sub: 'placeDeals' });
              }}
            /> */}
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="orders">
          <AccordionItemHeading
            onClick={() => {
              updateCurrentRoute({ main: 'orders', sub: '' });
            }}
          >
            <AccordionItemButton className="sidebar-button">
              <div>
                <span className="icon is-small left-span">
                  <i className="fas fa-clipboard-list" />
                </span>
                Manage Food Orders
              </div>
              <span className="icon is-small right-span ">
                <i
                  className={
                    preExpanded === 'orders'
                      ? 'fa fa-angle-down'
                      : 'fa fa-angle-right'
                  }
                />
              </span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <SideNavItem
              title="Orders Dashboard"
              action={['view_orderdashboard']}
              active={preSubExpanded === 'ordersDashboard'}
              onClick={() => {
                history.push('/orders-dashboard');
                updateCurrentRoute({ main: 'orders', sub: 'ordersDashboard' });
              }}
            />
            <SideNavItem
              title="Orders"
              action={['edit_orders']}
              active={preSubExpanded === 'orders'}
              onClick={() => {
                history.push('/orders');
                updateCurrentRoute({ main: 'orders', sub: 'orders' });
              }}
            />

            <SideNavItem
              title="Orders History"
              action={['view_order_history']}
              active={preSubExpanded === 'order-histories'}
              onClick={() => {
                history.push('/order-histories');
                updateCurrentRoute({ main: 'orders', sub: 'order-histories' });
              }}
            />

            <SideNavItem
              title="Item Availability"
              action={['view_item_availability']}
              active={preSubExpanded === 'item-availability'}
              onClick={() => {
                history.push('/item-availability');
                updateCurrentRoute({
                  main: 'orders',
                  sub: 'item-availability',
                });
              }}
            />

            <SideNavItem
              title="Menu & Custom Hours"
              action={['edit_menu_holiday_hours']}
              active={preSubExpanded === 'menu-custom-hours'}
              onClick={() => {
                history.push('/menu-custom-hours');
                updateCurrentRoute({
                  main: 'orders',
                  sub: 'menu-custom-hours',
                });
              }}
            />
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="menu">
          <AccordionItemHeading
            onClick={() => {
              updateCurrentRoute({ main: 'menu', sub: '' });
            }}
          >
            <AccordionItemButton className="sidebar-button">
              <div>
                <span className="icon is-small left-span">
                  <i className="fas fa-utensils" />
                </span>
                Menu Management
              </div>
              <span className="icon is-small right-span ">
                <i
                  className={
                    preExpanded === 'event'
                      ? 'fa fa-angle-down'
                      : 'fa fa-angle-right'
                  }
                />
              </span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <SideNavItem
              action={[
                'add_menu_service_type',
                'view_menu_service_type',
                'edit_menu_service_type',
              ]}
              title="Menu Service Types"
              active={preSubExpanded === 'services'}
              onClick={() => {
                history.push('/services');
                updateCurrentRoute({ main: 'menu', sub: 'services' });
              }}
            />
            <SideNavItem
              title="Menu Service Setting"
              action={[
                'add_menu_service_settings',
                'view_menu_service_settings',
                'edit_menu_service_settings',
              ]}
              active={preSubExpanded === 'menu-service-settings'}
              onClick={() => {
                history.push('/menu-service-settings');
                updateCurrentRoute({
                  main: 'menu',
                  sub: 'menu-service-settings',
                });
              }}
            />
            <SideNavItem
              title="Menu"
              action={[
                'add_menu_designer',
                'view_menu_designer',
                'edit_menu_designer',
              ]}
              active={preSubExpanded === 'addMenu'}
              onClick={() => {
                history.push('/menus');
                updateCurrentRoute({ main: 'menu', sub: 'addMenu' });
              }}
            />
            <SideNavItem
              title="Menu Category"
              action={[
                'add_menu_category',
                'view_menu_category',
                'edit_menu_category',
              ]}
              active={preSubExpanded === 'menu-sections'}
              onClick={() => {
                history.push('/menu-sections');
                updateCurrentRoute({
                  main: 'menu',
                  sub: 'menu-sections',
                });
              }}
            />
            <SideNavItem
              title="Menu Items"
              action={['add_menu_item', 'view_menu_item', 'edit_menu_item']}
              active={preSubExpanded === 'menu-items'}
              onClick={() => {
                history.push('/menu-Items');
                updateCurrentRoute({
                  main: 'menu',
                  sub: 'menu-items',
                });
              }}
            />
            <SideNavItem
              title="Modifiers Groups"
              action={[
                'add_modifier_groups',
                'view_modifier_groups',
                'edit_modifier_groups',
              ]}
              active={preSubExpanded === 'menu-modifiers-groups'}
              onClick={() => {
                history.push('/menu-modifiers-groups');
                updateCurrentRoute({
                  main: 'menu',
                  sub: 'menu-modifiers-groups',
                });
              }}
            />

            <SideNavItem
              title="Place and Menu Deals"
              action={[
                'add_place_menu_deals',
                'view_place_menu_deals',
                'edit_place_menu_deals',
              ]}
              active={preSubExpanded === 'manageMenuDeals'}
              onClick={() => {
                history.push('/place-deals');
                updateCurrentRoute({ main: 'menu', sub: 'manageMenuDeals' });
              }}
            />

            <SideNavItem
              title="Custom Hours"
              action={[
                'add_holiday_hours',
                'view_holiday_hours',
                'edit_holiday_hours',
              ]}
              onClick={() => {
                history.push('/custom-hours');
                updateCurrentRoute({ main: 'menu', sub: 'customHours' });
              }}
            />
            <SideNavItem
              title="Manage Rewards"
              active={preSubExpanded === 'manageRewards'}
              action={['add_rewards']}
              onClick={() => {
                history.push('/manage-rewards');
                updateCurrentRoute({
                  main: 'menu',
                  sub: 'manageRewards',
                });
              }}
            />
            {/* <SideNavItem
              title="Delivery Zones"
              active={preSubExpanded === 'deliveryZones'}
              onClick={() => {
                history.push('/delivery-zones');
                updateCurrentRoute({ main: 'menu', sub: 'deliveryZones' });
              }}
            /> */}
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="event">
          <AccordionItemHeading
            onClick={() => {
              updateCurrentRoute({ main: 'event', sub: '' });
            }}
          >
            <AccordionItemButton className="sidebar-button">
              <div>
                <span className="icon is-small left-span">
                  <i className="fa fa-calendar" />
                </span>
                Event Management
              </div>
              <span className="icon is-small right-span ">
                <i
                  className={
                    preExpanded === 'event'
                      ? 'fa fa-angle-down'
                      : 'fa fa-angle-right'
                  }
                />
              </span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <SideNavItem
              action={[
                'add_event',
                'edit_event',
                'delete_event',
                'approve_event',
                'post_event',
                'view_event',
              ]}
              title="Add/ Edit Events"
              active={preSubExpanded === 'addEditEvent'}
              onClick={() => {
                history.push(
                  `/events/${qs.stringify(
                    {
                      eventStatus: 'ACTIVE',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({ main: 'event', sub: 'addEditEvent' });
              }}
            />
            <SideNavItem
              action={['edit_manage_events', 'view_manage_events']}
              title="Manage Events"
              active={preSubExpanded === 'manageEvents'}
              onClick={() => {
                history.push(
                  `/manage-events/${qs.stringify(
                    {
                      eventStatus: 'ACTIVE',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({ main: 'event', sub: 'manageEvents' });
              }}
            />
            <SideNavItem
              action="view_event_activity"
              title="Event Activity"
              active={preSubExpanded === 'eventActivities'}
              onClick={() => {
                history.push(
                  `/event-activities/${qs.stringify(
                    {
                      eventStatus: 'ACTIVE',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({ main: 'event', sub: 'eventActivities' });
              }}
            />
            <SideNavItem
              action={[
                'add_event_deal',
                'edit_event_deal',
                'delete_event_deal',
                'approve_event_deal',
                'post_event_deal',
                'view_event_deal',
              ]}
              title="Manage Event Deals"
              active={preSubExpanded === 'eventDeals'}
              onClick={() => {
                history.push(
                  `/event-deals/${qs.stringify(
                    {
                      eventStatus: 'ACTIVE',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({ main: 'event', sub: 'eventDeals' });
              }}
            />
            <SideNavItem
              action="event_email_invitations"
              title="Email Invitation"
              active={preSubExpanded === 'emailInvitations'}
              onClick={() => {
                history.push(
                  `/email-invitations/${qs.stringify(
                    { eventStatus: 'ACTIVE' },
                    true,
                  )}`,
                );
                updateCurrentRoute({ main: 'event', sub: 'emailInvitations' });
              }}
            />
            <SideNavItem
              action="sell_tickets"
              title="Sell Tickets"
              active={preSubExpanded === 'sellTickets'}
              onClick={() => {
                history.push(
                  `/select-events-for-sell-tickets/${qs.stringify(
                    { eventStatus: 'ACTIVE' },
                    true,
                  )}`,
                );
                updateCurrentRoute({ main: 'event', sub: 'sellTickets' });
              }}
            />
            <SideNavItem
              action={[
                'check_in_action_attendees',
                'edit_check_in_attendees',
                'view_check_in_attendees',
                'check_in_action_attendees',
              ]}
              title="Check In Attendees"
              active={preSubExpanded === 'checkInAttendeeEvents'}
              onClick={() => {
                history.push(
                  `/check-in-attendee-events/${qs.stringify(
                    { eventStatus: 'ACTIVE' },
                    true,
                  )}`,
                );
                updateCurrentRoute({
                  main: 'event',
                  sub: 'checkInAttendeeEvents',
                });
              }}
            />
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="review">
          <AccordionItemHeading
            onClick={() => {
              updateCurrentRoute({ main: 'review', sub: '' });
            }}
          >
            <AccordionItemButton className="sidebar-button">
              <div>
                <span className="icon is-small left-span">
                  <i className="fas fa-external-link-alt" />
                </span>
                Review Management
              </div>
              <span className="icon is-small right-span ">
                <i
                  className={
                    preExpanded === 'review'
                      ? 'fa fa-angle-down'
                      : 'fa fa-angle-right'
                  }
                />
              </span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <SideNavItem
              title="Place Reviews"
              action={[
                'edit_place_review',
                'approve_place_review',
                'view_place_review',
              ]}
              active={preSubExpanded === 'placeReviews'}
              onClick={() => {
                history.push(
                  `/place-reviews/${qs.stringify(
                    {
                      status: 'SUBMITTED',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({
                  main: 'review',
                  sub: 'placeReviews',
                });
              }}
            />

            <SideNavItem
              action={[
                'view_item_dictionary',
                'edit_item_dictionary',
                'delete_item_dictionary',
              ]}
              title="Item Dictionary"
              active={preSubExpanded === 'itemDictionaries'}
              onClick={() => {
                history.push('/item-dictionaries');
                updateCurrentRoute({
                  main: 'review',
                  sub: 'itemDictionaries',
                });
              }}
            />

            <SideNavItem
              action={[
                'view_item_review',
                'edit_item_review',
                'approve_item_review',
              ]}
              title="Item Reviews"
              active={preSubExpanded === 'itemReviews'}
              onClick={() => {
                history.push(
                  `/item-reviews/${qs.stringify(
                    {
                      status: 'SUBMITTED',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({
                  main: 'review',
                  sub: 'itemReviews',
                });
              }}
            />

            <SideNavItem
              action={['approve_flag_review', 'view_flag_review']}
              title="Flagged Reviews"
              active={preSubExpanded === 'flaggedReviews'}
              onClick={() => {
                history.push(
                  `/flagged-reviews/${qs.stringify(
                    {
                      status: 'SUBMITTED',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({
                  main: 'review',
                  sub: 'flaggedReviews',
                });
              }}
            />

            {/* <SideNavItem
              action={['view_place_photos', 'approve_place_photos']}
              title="Place Photo Approvals"
              active={preSubExpanded === 'photoApprovals'}
              onClick={() => {
                history.push(
                  `/place-photo-approvals${qs.stringify(
                    {
                      approval_status: 'SUBMITTED',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({
                  main: 'review',
                  sub: 'photoApprovals',
                });
              }}
            />

            <SideNavItem
              action={['approve_item_photos', 'view_item_photos']}
              title="Item Photo Approvals"
              active={preSubExpanded === 'itemPhotoApprovals'}
              onClick={() => {
                history.push(
                  `/item-photo-approvals${qs.stringify(
                    {
                      approval_status: 'SUBMITTED',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({
                  main: 'review',
                  sub: 'itemPhotoApprovals',
                });
              }}
            /> */}

            <SideNavItem
              action={['view_place_photos', 'approve_place_photos']}
              title="Place Image Approvals"
              active={preSubExpanded === 'placeImageApprovals'}
              onClick={() => {
                history.push(
                  `/place-image-approvals${qs.stringify(
                    {
                      approval_status: 'SUBMITTED',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({
                  main: 'review',
                  sub: 'placeImageApprovals',
                });
              }}
            />
            <SideNavItem
              action={['approve_item_photos', 'view_item_photos']}
              title="Item Image Approvals"
              active={preSubExpanded === 'imageApprovals'}
              onClick={() => {
                history.push(
                  `/item-image-approvals${qs.stringify(
                    {
                      approval_status: 'SUBMITTED',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({
                  main: 'review',
                  sub: 'imageApprovals',
                });
              }}
            />
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="fee">
          <AccordionItemHeading
            onClick={() => {
              updateCurrentRoute({ main: 'fee', sub: '' });
            }}
          >
            <AccordionItemButton className="sidebar-button">
              <div>
                <span className="icon is-small left-span">
                  <i className="fas fa-utensils" />
                </span>
                Financial Management
              </div>
              <span className="icon is-small right-span">
                <i
                  className={
                    preExpanded === 'fee'
                      ? 'fa fa-angle-down'
                      : 'fa fa-angle-right'
                  }
                />
              </span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <SideNavItem
              title="Default Food Order Fee"
              active={preSubExpanded === 'default-food-order-fee'}
              onClick={() => {
                history.push('/default-food-order-fee');
                updateCurrentRoute({
                  main: 'fee',
                  sub: 'default-food-order-fee',
                });
              }}
            />
            <SideNavItem
              title="Food Order Override Fee"
              active={preSubExpanded === 'food-order-override-fees'}
              onClick={() => {
                history.push('/food-order-override-fees');
                updateCurrentRoute({
                  main: 'fee',
                  sub: 'food-order-override-fees',
                });
              }}
            />
            <SideNavItem
              title="Payments"
              active={preSubExpanded === 'payments'}
              onClick={() => {
                history.push('/payments');
                updateCurrentRoute({
                  main: 'fee',
                  sub: 'payments',
                });
              }}
            />

            <SideNavItem
              title="Bank Account"
              active={preSubExpanded === 'account'}
              onClick={() => {
                history.push('/bank-account');
                updateCurrentRoute({
                  main: 'fee',
                  sub: 'account',
                });
              }}
            />

            <SideNavItem
              title="Food Compliance"
              active={preSubExpanded === 'compliance'}
              onClick={() => {
                history.push('/food-compliance');
                updateCurrentRoute({
                  main: 'fee',
                  sub: 'compliance',
                });
              }}
            />

            <SideNavItem
              title="Food Order Activity"
              active={preSubExpanded === 'foodOrderActivity'}
              onClick={() => {
                history.push('/food-order-activity');
                updateCurrentRoute({
                  main: 'fee',
                  sub: 'foodOrderActivity',
                });
              }}
            />

            <SideNavItem
              title="Events Order Activity"
              active={preSubExpanded === 'eventOrderActivity'}
              onClick={() => {
                history.push('/event-order-activity');
                updateCurrentRoute({
                  main: 'fee',
                  sub: 'eventOrderActivity',
                });
              }}
            />

            <SideNavItem
              action={['edit_override_event_fee', 'view_override_event_fee']}
              title="Place Event Override Fee"
              active={preSubExpanded === 'placeEventOvrdFee'}
              onClick={() => {
                history.push('/event-fee-override');
                updateCurrentRoute({ main: 'fee', sub: 'placeEventOvrdFee' });
              }}
            />

            <SideNavItem
              action={[
                'view_company_details',
                'add_company_details',
                'edit_company_details',
                'delete_company_details',
                'approve_company_details',
              ]}
              title="Company Details"
              active={preSubExpanded === 'companyDetails'}
              onClick={() => {
                history.push('/companies');
                updateCurrentRoute({ main: 'fee', sub: 'companyDetails' });
              }}
            />
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="users">
          <AccordionItemHeading
            onClick={() => {
              updateCurrentRoute({ main: 'users', sub: '' });
            }}
          >
            <AccordionItemButton className="sidebar-button">
              <div>
                <span className="icon is-small left-span">
                  <i className="fa fa-user" />
                </span>
                User Management
              </div>
              <span className="icon is-small right-span ">
                <i
                  className={
                    preExpanded === 'users'
                      ? 'fa fa-angle-down'
                      : 'fa fa-angle-right'
                  }
                />
              </span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <SideNavItem
              action={[
                'add_user',
                'view_user',
                'edit_user',
                'delete_user',
                'approve_user_profilephotos',
              ]}
              title="Users"
              active={preSubExpanded === 'allUsers'}
              onClick={() => {
                history.push('/users');
                updateCurrentRoute({ main: 'users', sub: 'allUsers' });
              }}
            />
            <SideNavItem
              action={['view_user_activity', 'edit_user_activity']}
              title="User Activity"
              active={preSubExpanded === 'userActivity'}
              onClick={() => {
                history.push('/user-activities');
                updateCurrentRoute({ main: 'users', sub: 'userActivity' });
              }}
            />
            <SideNavItem
              action={[
                'add_admin_user',
                'view_admin_user',
                'edit_admin_user',
                'delete_admin_user',
              ]}
              title="Admin Users"
              active={preSubExpanded === 'adminUsers'}
              onClick={() => {
                history.push('/admin-users');
                updateCurrentRoute({ main: 'users', sub: 'adminUsers' });
              }}
            />
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </Wrapper>
  );
};

export default withRouter(SideNav);
