import gql from 'graphql-tag';
import { omit, forEach, sortBy } from 'lodash';

import client from '../../../utils/apolloClient';

const searchMenusQuery = gql`
  query searchMenus($input: SearchInput) {
    search_menu(input: $input) {
      menu_listing {
        place_id
        service_type_setting {
          service_type_setting_id
        }
        service_type
        menu_id
        name
        internal_name
        slug
        description
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getMenus = ({ placeId }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: searchMenusQuery,
        variables: {
          input: {
            filter: {
              menu_filter: { place_id: placeId, status: 'ACTIVE' },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data.search_menu && data.search_menu.menu_listing) {
          resolve(data.search_menu.menu_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

const searchItemLinkQuery = gql`
  query searchItemsLink($input: SearchInput) {
    search_menu_item_links(input: $input) {
      menu_item_link_listing {
        service_type_setting {
          service_type_setting_id
          service_type
        }
        item_link_id
        place_id
        menu {
          menu_id
          name
        }
        menu_section {
          menu_section_id
          name
        }
        display_order
        menu_item {
          menu_item_id
          name
          price
          availability_status
          variant {
            prompt_id
            prompt
            variants {
              variant_name
              variant_price
            }
          }
        }
      }
    }
  }
`;

export const getAllItemLink = filter =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        client: client.clientPublic,
        query: searchItemLinkQuery,
        variables: {
          input: {
            filter: {
              menu_item_link_filter: filter,
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        console.log('data', data);
        if (
          data.search_menu_item_links &&
          data.search_menu_item_links.menu_item_link_listing
        ) {
          resolve(
            sortBy(
              data.search_menu_item_links.menu_item_link_listing,
              'display_order',
            ),
          );
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const removeIdFromVariantsInput = variants => {
  const data = [];
  forEach(variants, item => {
    const temp = [];
    const variant = omit(item, ['__typename']);
    forEach(variant.variants, item2 => {
      const variant2 = omit(item2, ['__typename']);
      temp.push({ ...variant2 });
    });
    data.push({ ...variant, variants: temp });
  });
  return data;
};
