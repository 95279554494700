import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'react-accessible-accordion/dist/fancy-example.css';
import { capitalize } from 'lodash';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { mergeItemWithCurrentModifiers } from './helpers';

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 50%;
  &&& {
    .table td {
      border: 1px solid #ffff;
      border-width: 0 0 1px;
      padding: 10px 20px;
      vertical-align: top;
    }
    .table th {
      border: 1px solid #f5f5f5;
      border-width: 0 0 2px;
      padding: 10px 20px;
      vertical-align: top;
    }
  }
`;

const IconWrapper = styled.span`
  padding-right: 1rem;
`;

const Container = styled.div`
  margin: 1rem 0rem;
  background-color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.lightGreyAlpha : theme.white};
`;

const ItemContainer = styled.div`
  background-color: ${({ theme, isDragging }) =>
    isDragging ? theme.lightGreyBeta : theme.white};
`;

const AccordionStyle = styled(Accordion)`
  border: 0;
`;

const AccordionItemStyle = styled(AccordionItem)`
  -moz-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  margin-bottom: 1rem;
`;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const MenuList = ({ modifier }) => {
  const newModifier = mergeItemWithCurrentModifiers(
    modifier.map(item => ({
      menu_item_id: item.menu_item.menu_item_id,
      override_variant: item.override_variant,
    })),
    modifier.map(item => ({
      ...item.menu_item,
    })),
  );

  return newModifier.map(item => (
    <>
      <tr key={item.menu_item_id}>
        <td>
          <i className="fas fa-arrows-alt" /> &nbsp;{item.name}
        </td>

        <td>
          {item.override_variant && item.override_variant.length !== 0
            ? '-'
            : `$${item.override_price}`}
        </td>
        <td>-</td>
        <td>-</td>
      </tr>
      {item.override_variant &&
        item.override_variant.length !== 0 &&
        item.override_variant.map(
          item2 =>
            item2.variants &&
            item2.variants.length !== 0 &&
            item2.variants.map(item3 => (
              <tr>
                <td>
                  <p className="is-capitalized">
                    &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;{item3.variant_name}
                  </p>
                </td>
                <td>${parseFloat(item3.variant_price).toFixed(2)}</td>
                <td>-</td>
                <td>-</td>
              </tr>
            )),
        )}
    </>
  ));
};
const Modifiers = ({ modifierGroups, deleteMenuGroup, setFieldValue }) => {
  const [modifierGroupsData, setModifierGroupsData] = useState(modifierGroups);

  useEffect(() => {
    setModifierGroupsData(modifierGroups);
  }, [modifierGroups]);
  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const newItems = reorder(
      modifierGroups,
      result.source.index,
      result.destination.index,
    );
    setFieldValue('modifierGroups', newItems);
  };

  return (
    <Wrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable2">
          {(provided, snapshot) => (
            <AccordionStyle allowZeroExpanded>
              <Container
                {...provided.droppableProps}
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {modifierGroupsData.map(
                  (group, index) =>
                    group && (
                      <Draggable
                        key={group.modifier_group_id}
                        draggableId={group.modifier_group_id}
                        index={index}
                      >
                        {(provided2, snapshot2) => (
                          <ItemContainer
                            ref={provided2.innerRef}
                            {...provided2.draggableProps}
                            {...provided2.dragHandleProps}
                            isDragging={snapshot2.isDragging}
                          >
                            <AccordionItemStyle>
                              <AccordionItemHeading>
                                <AccordionItemButton className="acc_button">
                                  <Item>
                                    <IconWrapper>
                                      <AccordionItemState>
                                        {({ expanded }) =>
                                          expanded ? (
                                            <i className="fas fa-chevron-down" />
                                          ) : (
                                            <i className="fas fa-chevron-right" />
                                          )
                                        }
                                      </AccordionItemState>
                                    </IconWrapper>
                                    <p className="is-capitalized is-size-2 has-text-weight-semibold">
                                      {group.name}
                                    </p>
                                    &nbsp; &nbsp; ({' '}
                                    <p className="is-size-2 has-text-weight-regular">
                                      {group.is_required
                                        ? 'Required'
                                        : 'Optional'}{' '}
                                      / {capitalize(group.selection_type)} -{' '}
                                      {group.selection_type === 'RANGE'
                                        ? `${group.selection_quantity_from} to ${group.selection_quantity_from} Options`
                                        : `${group.selection_quantity} Options`}
                                    </p>{' '}
                                    )
                                  </Item>
                                  <Item>
                                    <button
                                      type="button"
                                      className="button"
                                      onClick={() =>
                                        deleteMenuGroup(group.modifier_group_id)
                                      }
                                    >
                                      <i className="far fa-trash-alt" />
                                    </button>
                                  </Item>
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel style={{ padding: 0 }}>
                                <table className="table is-fullwidth">
                                  <thead style={{ background: 'white' }}>
                                    <tr>
                                      <th>
                                        <abbr>Modifiers Name</abbr>
                                      </th>
                                      <th>
                                        <abbr>Price</abbr>
                                      </th>
                                      <th>
                                        <abbr>Min Qty</abbr>
                                      </th>
                                      <th>
                                        <abbr>Max Qty</abbr>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <MenuList modifier={group.modifier} />
                                  </tbody>
                                </table>
                              </AccordionItemPanel>
                            </AccordionItemStyle>
                          </ItemContainer>
                        )}
                      </Draggable>
                    ),
                )}
              </Container>
            </AccordionStyle>
          )}
        </Droppable>
      </DragDropContext>
    </Wrapper>
  );
};

export default Modifiers;
