import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { join, truncate } from 'lodash';

import Delete from './Delete';
import { ACLSConsumer } from '../../../utils/aclsContext';
import Can from '../../../utils/Can';

const CheckInList = ({ setting }) => (
  <React.Fragment>
    <td>
      <Link
        to={`/update-menu-service-setting/${setting.place.place_id}/${setting.service_type_setting_id}`}
      >
        {setting.service_type}
      </Link>
    </td>
    <td> {setting.place.name}</td>
    <td> {setting.place.city}</td>
    <td>
      <p data-tip={join(setting.menu)} data-html>
        {truncate(join(setting.menu), { length: 20 })}
      </p>
    </td>
    <td>{setting.is_private_menu === true ? 'Yes' : 'No'}</td>
    <td>
      <ReactTooltip html place="top" type="dark" effect="float" />
      <p
        data-tip={`<span>
          <p>email: ${setting.updated_by_user.email}</p>
          <p>objectId: ${setting.updated_by_user.user_id}</p>
        </span>`}
        data-html
      >
        {setting.updated_by_user.display_name}
      </p>
    </td>
    <td>
      {setting.audit === null
        ? '-'
        : moment(setting.audit.updated_at).format('lll')}
    </td>

    <td>
      <ACLSConsumer>
        {({ apiActions, pageActions }) => (
          <Can
            action="delete_service_type_setting"
            apiActions={apiActions}
            pageActions={pageActions}
            yes={() => <Delete serviceId={setting.service_type_setting_id} />}
          />
        )}
      </ACLSConsumer>
    </td>
  </React.Fragment>
);

export default CheckInList;
