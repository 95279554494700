import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Table } from 'rbx';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useStoreState } from 'easy-peasy';
import { isNull } from 'lodash';
import { toast } from 'react-toastify';
import uuidv4 from 'uuid/v4';
import swal from 'sweetalert';

import { Table as CustomTable, Box } from '../../../components/elements';

import Header from './Header';
import List from './List';
import AddEditForm from './AddEditForm';
import client from '../../../utils/apolloClient';

const Container = styled.div`
  .table-container {
    min-height: 30rem;
  }
`;

const createFeeMutation = gql`
  mutation createFee($input: OrderFeeDefaultInput) {
    create_order_fee_default(input: $input) {
      fee_id
      error {
        description
      }
    }
  }
`;

const updateFeeMutation = gql`
  mutation updateFee($input: OrderFeeDefaultInput) {
    update_order_fee_default(input: $input) {
      fee_id
      error {
        description
      }
    }
  }
`;
const deleteFeeMutation = gql`
  mutation deleteFee($input: OrderFeeDefaultInput) {
    delete_order_fee_default(input: $input) {
      fee_id
      error {
        description
      }
    }
  }
`;
const Details = ({ fees: feeValues, refetch }) => {
  const { userId } = useStoreState(state => state.auth);
  const [fees, setFees] = useState([]);
  const [fee, setFee] = useState(null);
  const [isActiveModal, setIsActiveModal] = useState(false);

  useEffect(() => {
    const data = feeValues.map(item => ({
      fee_id: item.order_fee.fee_id,
      service_type_id: item.service_type_id,
      service_type_name: item.name,
      name: item.order_fee.name,
      display: item.order_fee.display,
      sale_method: item.order_fee.sale_method,
      applies_to: item.order_fee.applies_to,
      type: item.order_fee.type,
      value: item.order_fee.value,
      status: item.order_fee.status,
    }));
    setFees(data);
  }, [feeValues]);

  const [createFee, { loading }] = useMutation(createFeeMutation, {
    client: client.clientPrivate,
    onCompleted: ({ create_order_fee_default }) => {
      if (!isNull(create_order_fee_default.error)) {
        create_order_fee_default.error.map(item =>
          toast.error(item.description),
        );
      } else {
        setIsActiveModal(false);
        refetch();
      }
    },
  });

  const [updateFee, { loading: loading2 }] = useMutation(updateFeeMutation, {
    client: client.clientPrivate,
    onCompleted: ({ update_order_fee_default }) => {
      if (!isNull(update_order_fee_default.error)) {
        update_order_fee_default.error.map(item =>
          toast.error(item.description),
        );
      } else {
        setIsActiveModal(false);
        refetch();
        setFee(null);
      }
    },
  });

  const [deleteFee] = useMutation(deleteFeeMutation, {
    client: client.clientPrivate,
    onCompleted: ({ delete_order_fee_default }) => {
      if (!isNull(delete_order_fee_default.error)) {
        delete_order_fee_default.error.map(item =>
          toast.error(item.description),
        );
      } else {
        refetch();
      }
    },
  });

  return (
    <React.Fragment>
      <Header setIsActiveModal={setIsActiveModal} />
      <Box>
        <Container>
          <CustomTable>
            <Table.Head>
              <Table.Row>
                <Table.Heading>Service Type</Table.Heading>
                <Table.Heading>Fee Name</Table.Heading>
                <Table.Heading>Fee Display Label</Table.Heading>
                <Table.Heading>Sale Method</Table.Heading>
                <Table.Heading>Fee Type</Table.Heading>
                <Table.Heading>Fee</Table.Heading>
                <Table.Heading>Applies To</Table.Heading>
                <Table.Heading>Action</Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <List
                fees={fees}
                onEdit={value => {
                  setFee(value);
                  setIsActiveModal(true);
                }}
                onDelete={value => {
                  swal('Are you sure you want to delete the fee?', {
                    buttons: ['Cancel', true],
                  }).then(willDelete => {
                    if (willDelete) {
                      deleteFee({
                        variables: {
                          input: {
                            user_id: userId,
                            fee_id: value.fee_id,
                            service_type_id: value.service_type_id,
                          },
                        },
                      });
                    }
                  });
                }}
              />
            </Table.Body>
          </CustomTable>
        </Container>
      </Box>
      {isActiveModal && (
        <AddEditForm
          fee={fee}
          loading={loading || loading2}
          isActive={isActiveModal}
          onClose={() => setIsActiveModal(false)}
          onSubmit={values => {
            if (fee) {
              updateFee({
                variables: {
                  input: {
                    user_id: userId,
                    service_type_id: values.service_type_id,
                    fee_id: fee.fee_id,
                    value: values.value,
                  },
                },
              });
            } else {
              createFee({
                variables: {
                  input: {
                    user_id: userId,
                    service_type_id: values.service_type_id,
                    fee_id: uuidv4(),
                    name: values.name,
                    display: values.display,
                    sale_method: values.sale_method,
                    applies_to: values.applies_to,
                    type: values.type,
                    value: values.value,
                    status: values.status,
                  },
                },
              });
            }
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Details;
