import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';
import { Select, Input } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const approvePhotoMutation = gql`
  mutation approvePhotosAction(
    $user_id: String
    $image_review_id: String
    $image_review_status: String
  ) {
    update_image_review(
      input: {
        user_id: $user_id
        image_review_id: $image_review_id
        approval_status: $image_review_status
      }
    ) {
      status
      image_id
      url
      error {
        description
      }
    }
  }
`;

const handleImageApprove = input =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .mutate({
        mutation: approvePhotoMutation,
        variables: {
          ...input,
        },
      })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });

const options = [
  { label: 'ACTIVE', value: 'ACTIVE' },
  { label: 'INACTIVE', value: 'INACTIVE' },
];

const ImageItem = ({
  reviewStatus,
  imageReviewId,
  imageSrc,
  imageName,
  status,
  handleApprovalStatus,
  approvalStatus,
}) => {
  const { userId } = useStoreState(state => state.auth);
  const [loading, setLoading] = useState(false);

  const handleApproveOrReject = async value => {
    try {
      setLoading(true);
      await handleImageApprove({
        user_id: userId,
        image_review_id: imageReviewId,
        image_review_status: value,
      });
      handleApprovalStatus(value);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div>
      <Select
        label="Status"
        options={options}
        value={{ label: status, value: status }}
        required
        disabled
      />
      <figure className="image is-square">
        <img src={imageSrc} alt={imageName} />
      </figure>
      <br />
      <Input label="Approval Status" value={approvalStatus} required disabled />
      {approvalStatus === 'APPROVED' && (
        <a
          onClick={() =>
            reviewStatus === 'APPROVED'
              ? handleApproveOrReject('REJECTED')
              : handleApprovalStatus('REJECTED')
          }
          className={`button is-danger is-fullwidth ${loading && 'is-loading'}`}
        >
          REJECT
        </a>
      )}
      {approvalStatus === 'SUBMITTED' && (
        <a
          onClick={() =>
            reviewStatus === 'APPROVED'
              ? handleApproveOrReject('REJECTED')
              : handleApprovalStatus('REJECTED')
          }
          className={`button is-danger is-fullwidth ${loading && 'is-loading'}`}
        >
          REJECT
        </a>
      )}
      {approvalStatus === 'REJECTED' && (
        <a
          onClick={() =>
            reviewStatus === 'APPROVED'
              ? handleApproveOrReject('APPROVED')
              : handleApprovalStatus('APPROVED')
          }
          className={`button is-primary is-fullwidth ${loading &&
            'is-loading'}`}
        >
          APPROVE
        </a>
      )}
    </div>
  );
};

export default ImageItem;
