import React from 'react';
import styled from 'styled-components';

import { Field, Label, Control, Input as RbxInput } from 'rbx';
import InputErrorMessage from './InputErrorMessage';
import { ACLSConsumer } from '../../utils/aclsContext';
import Can from '../../utils/Can';

const Span = styled.span`
  padding-left: 2px;
`;
const OptionalText = styled.p`
  &&& {
    color: #737373;
    padding-left: 2px;
  }
`;
const Input = ({
  label,
  value,
  placeholder,
  optionalText,
  required,
  children,
  errors,
  touched,
  disableToastMessage,
  action,
  ...props
}) => (
  <Field>
    {label && (
      <Label className="is-flex">
        {label}
        {required && <span className="has-text-danger">*</span>}
        {optionalText && <OptionalText>{optionalText}</OptionalText>}
        <Span>{children}</Span>
      </Label>
    )}
    <Control>
      {action ? (
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={action}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <RbxInput
                  value={value === null ? value : value}
                  placeholder={placeholder || null}
                  {...props}
                />
              )}
              no={() => (
                <RbxInput
                  value={value === null ? value : value}
                  placeholder={placeholder || null}
                  disabled
                  {...props}
                />
              )}
            />
          )}
        </ACLSConsumer>
      ) : (
        <RbxInput
          value={value === null ? value : value}
          placeholder={placeholder || null}
          {...props}
        />
      )}
    </Control>
    <InputErrorMessage
      errors={errors}
      touched={touched}
      disableToastMessage={disableToastMessage}
    />
  </Field>
);

export default Input;
