import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { isNull } from 'lodash';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import client from '../../../utils/apolloClient';

const deleteCustomHourMutation = gql`
  mutation deleteCustomHour($input: DeleteCustomHourInput) {
    delete_custom_hour(input: $input) {
      custom_hour_id
      error {
        description
      }
    }
  }
`;

const Delete = ({
  customHourId,
  placeName,
  onHandleComplete = () => {},
  children,
}) => {
  const { userId } = useStoreState(state => state.auth);

  return (
    <Mutation
      client={client.clientPrivate}
      mutation={deleteCustomHourMutation}
      onCompleted={({ delete_custom_hour }) => {
        if (!isNull(delete_custom_hour.error)) {
          delete_custom_hour.error.map(item => toast.error(item.description));
        } else {
          toast.success('Custom Hour deleted successfully!');
          onHandleComplete();
        }
      }}
    >
      {(delete_menu, { loading }) => (
        <button
          type="button"
          className={`button ${loading && 'is-loading'}`}
          onClick={() => {
            swal({
              title: 'Are you sure?',
              // eslint-disable-next-line max-len
              text: `Are you sure you would like to remove and delete the custom hours for ${placeName}`,
              buttons: ['Cancel', 'Yes'],
              dangerMode: true,
            }).then(willDelete => {
              if (willDelete) {
                delete_menu({
                  variables: {
                    input: {
                      user_id: userId,
                      custom_hour_id: customHourId,
                    },
                  },
                });
              }
            });
          }}
        >
          {children}
        </button>
      )}
    </Mutation>
  );
};

export default Delete;
