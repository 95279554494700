import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';

import { toast } from 'react-toastify';

import tzLookup from 'tz-lookup';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';

import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import DealForm from '../PlaceDealAdd/DealForm';
import { Loading, Message } from '../../../components/elements';

const allNeededQuery = gql`
  query fetch_place($placeId: String, $dealId: String!) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      name
      city
      post_code
      country
      latitude
      longitude
    }
    fetch_place_menu_deal(input: { deal_id: $dealId }) {
      deal_id
      place_id
      menu_id
      menu_item
      deal_type
      promotion
      promo_code
      name
      service_type
      start
      end
      slug
      description
      tag
      is_published
      customer_type
      can_be_clubbed
      minimum_purchase_amount
      value
      maximum_discount_amount
      maximum_no_of_discounts
      maximum_discount
      maximum_no_of_discounts_per_user
      maximum_discount_per_user
      status
      zone_setting {
        id
        override_delivery_fee
        savings
      }
      free_delivery
    }
  }
`;

const updateDealMutation = gql`
  mutation updateDeal($input: PlaceMenuDealInput) {
    update_place_menu_deal(input: $input) {
      deal_id
      error {
        description
      }
    }
  }
`;

const PlaceClaimUpdate = ({ match, history }) => (
  <Layout>
    <Query
      query={allNeededQuery}
      variables={{
        placeId: match.params.placeId,
        dealId: match.params.dealId,
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <Message>error</Message>;
        }

        const { fetch_place: place, fetch_place_menu_deal } = data;
        return (
          <View history={history} place={place} deal={fetch_place_menu_deal} />
        );
      }}
    </Query>
  </Layout>
);

const View = ({ place, deal, history }) => {
  const { userId } = useStoreState(state => state.auth);
  const [currentTimeZone, setCurrentTimeZone] = useState('');

  useEffect(() => {
    const lat = parseFloat(place.latitude).toFixed(5);
    const long = parseFloat(place.longitude).toFixed(5);
    const timeZone = tzLookup(lat, long);
    setCurrentTimeZone(timeZone);
    moment.tz.setDefault(timeZone);
  }, []);

  return (
    <Mutation
      client={client.clientPrivate}
      mutation={updateDealMutation}
      onCompleted={({ update_place_menu_deal }) => {
        if (update_place_menu_deal.error) {
          update_place_menu_deal.error.map(item =>
            toast.error(item.description),
          );
        } else {
          swal(
            'Great!',
            'Place Deal had been updated successfully!',
            'success',
          ).then(() => {
            history.goBack();
          });
        }
      }}
    >
      {(update_place_menu_deal, { loading: dealLoading, error: dealError }) => (
        <DealForm
          userId={userId}
          currentTimeZone={currentTimeZone}
          loading={dealLoading}
          error={dealError}
          place={place}
          deal={deal}
          onSubmit={input => {
            console.log('input', input);
            update_place_menu_deal({ variables: { input } });
          }}
        />
      )}
    </Mutation>
  );
};

export default withRouter(PlaceClaimUpdate);
