import { forEach, filter } from 'lodash';
import moment from 'moment-timezone';

const calculateMinutes = estimates => {
  let min = 0;
  forEach(estimates, item2 => {
    min += item2.value_in_mins;
  });
  return min;
};

const textHelper = values => {
  if (values > 0) {
    if (values > 59 && values < 1440) {
      const hours = parseInt(values / 60, 10);
      const mins = values % 60;
      return `${hours} ${hours === 1 ? 'hour' : 'hours'} ${mins}  ${
        mins === 1 ? 'min' : 'mins'
      }`;
    }
    if (values > 1439 && values < 43200) {
      const day = parseInt(values / 1440, 10);
      return `${day} ${day === 1 ? 'day' : 'days'}`;
    }
    if (values > 43199 && values < 518400) {
      const month = parseInt(values / 43199, 10);
      return `${month} ${month === 1 ? 'month' : 'months'}`;
    }

    if (values > 518400) {
      const year = parseInt(values / 518400, 10);
      return `${year} ${year === 1 ? 'year' : 'years'}`;
    }
    return `${values} ${values === 1 ? 'min' : 'mins'}`;
  }
  return '';
};

export const scheduleTime = (estimates, receivedAt) => {
  const finalEstimates = filter(
    estimates,
    edge => edge.type !== 'delayed_estimate',
  );
  return moment(receivedAt).add(calculateMinutes(finalEstimates), 'minutes');
};

export const scheduleCompletionTime = (estimates, receivedAt) =>
  moment(receivedAt).add(calculateMinutes(estimates), 'minutes');

const delayInMins = (estimates, receivedAt) => {
  const allEstimate = scheduleCompletionTime(estimates, receivedAt);
  const inMins = moment().diff(moment(allEstimate), 'minutes');
  return inMins;
};

export const totalTimeDelay = (estimates, receivedAt) => {
  const timeInMin = delayInMins(estimates, receivedAt);
  if (timeInMin > 0) {
    return `Delayed by ${textHelper(timeInMin)}`;
  }
  return null;
};

export const totalTimeDue = (estimates, receivedAt) => {
  const timeInMin = delayInMins(estimates, receivedAt);
  if (timeInMin < 0) {
    return `Due in ${textHelper(Math.abs(timeInMin))}`;
  }
  return null;
};

export const totalTimeDueActiveCard = (estimates, receivedAt) => {
  const timeInMin = delayInMins(estimates, receivedAt);
  if (timeInMin < 0) {
    return `(ASAP) Due at ${moment(receivedAt)
      .add(Math.abs(timeInMin), 'minutes')
      .format('hh:mm A')} in ${textHelper(Math.abs(timeInMin))}`;
  }
  return null;
};

export const scheduleTimeText = (estimates, receivedAt) => {
  const time = scheduleTime(estimates, receivedAt);
  return `Scheduled ${moment(time).format('LT')}`;
};

export const scheduleCompletionTimeText = (estimates, receivedAt) => {
  const delayEstimates = filter(
    estimates,
    edge => edge.type === 'delayed_estimate',
  );
  const time = scheduleCompletionTime(estimates, receivedAt);
  if (delayEstimates.length !== 0) {
    return delayEstimates[0].value_in_mins > 0
      ? `(${moment(time).format('LT')})`
      : '';
  }
  return ``;
};

// delay and due

export const scheduleTimeWithDelay = (estimates, receivedAt) => {
  const delayedEstimatesArr = filter(
    estimates,
    edge => edge.type === 'delayed_estimate',
  );
  const delayedBtRecCurrent =
    moment().diff(moment(receivedAt), 'minutes') +
    calculateMinutes(delayedEstimatesArr);

  return moment(receivedAt).add(delayedBtRecCurrent, 'minutes');
};

export const delayTime = (estimates, receivedAt) =>
  moment(scheduleTimeWithDelay(estimates, receivedAt)).diff(
    moment(scheduleTime(estimates, receivedAt)),
    'minutes',
  );

export const scheduleTimeWithDelayText = (estimates, receivedAt) => {
  if (delayTime(estimates, receivedAt) > 0) {
    return `${moment(scheduleTimeWithDelay(estimates, receivedAt)).format(
      'LT',
    )}`;
  }
  return '';
};

export const delayTimeText = (estimates, receivedAt) => {
  const timeInMin = delayTime(estimates, receivedAt);
  return `Delayed by ${textHelper(timeInMin)}`;
};

export const dueTime = (estimates, receivedAt) =>
  moment().diff(moment(scheduleTime(estimates, receivedAt)), 'minutes');

export const dueTimeText = (estimates, receivedAt) => {
  const timeInMin = dueTime(estimates, receivedAt);
  return `Due in ${textHelper(timeInMin)}`;
};

export const dueTimeTextAlt = (estimates, receivedAt) => {
  const timeInMin = dueTime(estimates, receivedAt);

  return textHelper(timeInMin);
};
