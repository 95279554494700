import React from 'react';
import { Level } from 'rbx';
import { PageHeader } from '../../../components/elements';

const Header = ({ setIsActiveModal }) => (
  <PageHeader title="Default Food Order Fee">
    <Level.Item align="right">
      <Level.Item>
        <button
          type="submit"
          className="button is-primary"
          onClick={() => setIsActiveModal(true)}
        >
          <span>Add</span>
        </button>
      </Level.Item>
    </Level.Item>
  </PageHeader>
);

export default Header;
