import React from 'react';
import { Table, Button } from 'rbx';
import styled from 'styled-components';

const Element = styled.div``;
const Element2 = styled.div``;

const List = ({ fees, onHandleOverride, onResetToDefault }) => (
  <>
    {fees.map(item => (
      <Table.Row>
        <Table.Cell>{item.order_fee.name}</Table.Cell>
        <Table.Cell>{item.order_fee.display}</Table.Cell>
        <Table.Cell>
          <Element>{item.order_fee.sale_method}</Element>
        </Table.Cell>
        <Table.Cell>
          <Element>{item.order_fee.type}</Element>
        </Table.Cell>
        <Table.Cell>
          $
          {item.order_fee_override && item.order_fee_override.override
            ? parseFloat(item.order_fee_override.value).toFixed(2)
            : parseFloat(item.order_fee.value).toFixed(2)}
        </Table.Cell>
        <Table.Cell>
          <Element>
            {item.order_fee_override && item.order_fee_override.override
              ? parseFloat(
                  item.order_fee_override.minimum_order_amount,
                ).toFixed(2)
              : '-'}
          </Element>
        </Table.Cell>
        <Table.Cell>
          <Element>{item.order_fee.applies_to}</Element>
        </Table.Cell>
        <Table.Cell>
          <Element2>
            <Button.Group hasAddons>
              <Button color="primary" onClick={() => onHandleOverride(item)}>
                Override
              </Button>
              {item.order_fee_override && item.order_fee_override.override && (
                <Button
                  onClick={() =>
                    onResetToDefault({ fee_id: item.order_fee.fee_id })
                  }
                >
                  Reset
                </Button>
              )}
            </Button.Group>
          </Element2>
        </Table.Cell>
      </Table.Row>
    ))}
  </>
);

export default List;
