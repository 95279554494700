import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import gql from 'graphql-tag';

import Items from './Items';
import SearchItemModal from './SearchItemModal';
import client from '../../../utils/apolloClient';
import { Loading } from '../../../components/elements';
import { mergeItemWithCurrentModifiers } from './helpers';

const AddItemsButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor} !important;
    border-width: 2px !important;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 40%;
  }
`;

const searchMenuItemsQuery = gql`
  query searchMenuItems($input: SearchInput) {
    search_menu_items(input: $input) {
      menu_item_listing {
        menu_item_id
        name
        price
        availability_status

        variant {
          prompt_id
          prompt
          variants {
            variant_id
            variant_name
            variant_price
          }
        }
      }
    }
  }
`;

const ModifierItems = props => {
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchItems = useCallback(({ menuItemIds }) => {
    setLoading(true);
    client.clientPublic
      .query({
        query: searchMenuItemsQuery,
        variables: {
          input: {
            filter: { menu_item_filter: { menu_item_id: menuItemIds } },
          },
        },
      })
      .then(({ data }) => {
        if (
          data.search_menu_items &&
          data.search_menu_items.menu_item_listing
        ) {
          const menuItems = data.search_menu_items.menu_item_listing;
          const newModifiers = mergeItemWithCurrentModifiers(
            props.values.modifierHelpers,
            menuItems,
          );

          props.setFieldValue('modifier', newModifiers);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const menuItemIds = props.values.modifierHelpers.map(
      item => item.menu_item_id,
    );
    if (menuItemIds.length !== 0 && props.values.modifier.length === 0) {
      fetchItems({ menuItemIds });
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <FieldArray
      name="modifier"
      render={helpers => {
        const { values, setFieldValue } = helpers.form;
        return (
          <>
            <AddItemsButton
              type="button"
              className="button is-medium"
              onClick={() => setIsActiveModal(true)}
            >
              Add Items
            </AddItemsButton>

            <Items items={values.modifier} setFieldValue={setFieldValue} />
            <SearchItemModal
              placeId={props.values.place_id}
              isCreateLinkLoading={false}
              initialsItems={values.modifier}
              isActive={isActiveModal}
              onClose={() => setIsActiveModal(false)}
              onSubmit={inputs => {
                setFieldValue('modifier', values.modifier.concat(inputs));
                setIsActiveModal(false);
              }}
            />
          </>
        );
      }}
    />
  );
};

export default ModifierItems;
