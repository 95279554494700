import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { omit, capitalize } from 'lodash';
import gql from 'graphql-tag';
import styled from 'styled-components';

import client from '../../../utils/apolloClient';

const searchMenusQuery = gql`
  query searchMenus($input: SearchInput) {
    search_menu(input: $input) {
      total_size
      total_pages
      menu_listing {
        place_id
        place_name
        city
        menu_id
        name
        internal_name
        slug
        description
        status
        approval_status
        is_private_menu
        sales_method
        menu_hour {
          day
          is_active
          option {
            type
            start
            end
          }
        }
      }
    }
  }
`;
const SuggestionsContainer = styled.div`
  max-height: 20rem;
  overflow-y: scroll;
`;
const renderInputComponent = inputProps => {
  const props = omit(inputProps, ['className', 'ref']);
  return (
    <div className="field">
      <div className="control has-icons-left has-icons-right">
        <input className="input" {...props} />
        <span className="icon is-small is-left">
          <i className="fas fa-search" />
        </span>
      </div>
    </div>
  );
};

const renderSuggestion = suggestion => (
  <p className="is-size-4 has-text-weight-medium is-capitalized">
    {capitalize(suggestion.name)}
  </p>
);

const renderSuggestionsContainer = ({ containerProps, children }) =>
  children && (
    <SuggestionsContainer {...containerProps}>{children}</SuggestionsContainer>
  );

const MenuSuggestionInput = ({
  label,
  initialValue,
  onSelect,
  onHandleClear,
}) => {
  const [value, setValue] = React.useState('');
  const [suggestions, setSuggestions] = React.useState([]);

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    onHandleClear(newValue.length);
  };

  const onBlur = () => {
    if (initialValue && initialValue !== value) {
      setValue(initialValue);
    }
  };

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const getSuggestionValue = suggestion => {
    onSelect(suggestion);
    return suggestion.internal_name;
  };

  const onSuggestionsFetchRequested = inputValue => {
    client.clientPublic
      .query({
        query: searchMenusQuery,
        variables: {
          input: {
            filter: {
              menu_filter: {
                internal_name: inputValue.value,
              },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data) {
          setSuggestions(data.search_menu.menu_listing);
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: 'Search menus',
    value,
    onChange,
    onBlur,
    type: 'search',
  };

  return (
    <div className="field">
      {label && <label className="label">{label}</label>}
      <div className="control">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderInputComponent={renderInputComponent}
          renderSuggestionsContainer={renderSuggestionsContainer}
          inputProps={inputProps}
          focusInputOnSuggestionClick={false}
        />
      </div>
    </div>
  );
};

MenuSuggestionInput.defaultProps = {
  label: '',
  initialValue: '',
  onSelect: () => {},
  onHandleClear: () => {},
};

MenuSuggestionInput.propTypes = {
  label: PropTypes.string,
  initialValue: PropTypes.string,
  onSelect: PropTypes.func,
  onHandleClear: PropTypes.func,
};

export default MenuSuggestionInput;
