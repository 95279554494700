import React, { useEffect, useState } from 'react';
import { forEach, times, range, intersectionBy } from 'lodash';

import moment from 'moment';
import {
  TagInputs,
  Input,
  TextArea,
  Select,
  CheckBox,
} from '../../../components/elements';
import EventTimeDatePicker from './EventTimeDatePicker';
import options from '../../../utils/optionsHelpers';

import {
  getDressCodes,
  getFeatures,
  getEventTypes,
  getEventRestrictions,
  getEventCategories,
} from '../../../utils/fetchStatic';

const EventDetailForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const [data, setData] = useState([
    {
      features: [],
      dressCodes: [],
      restriction: [],
      eventCategories: [],
      eventTypes: [],
    },
  ]);

  const [disableStartDateHoursArray, setDisableStartDateHoursArray] = useState(
    [],
  );
  const [
    disableStartDateMinutesArray,
    setDisableStartDateMinutesArray,
  ] = useState([]);
  const [
    disableStartDateMinutesHelper,
    setDisableStartDateMinutesHelper,
  ] = useState(0);
  // const usedLocation = useStoreState(state => state.global.usedLocation);
  const [disableHoursArray, setDisableHoursArray] = useState([]);
  const [disableMinutesArray, setDisableMinutesArray] = useState([]);
  const [disableMinutesHelper, setDisableMinutesHelper] = useState(0);

  useEffect(() => {
    const getData = async () => {
      const features = await getFeatures();
      const dressCodes = await getDressCodes();
      const restriction = await getEventRestrictions();
      const eventCategories = await getEventCategories();
      const eventTypes = await getEventTypes();

      setData({
        features,
        dressCodes,
        restriction,
        eventCategories,
        eventTypes,
      });
    };
    getData();
  }, []);

  const parseData = parseValue => {
    const final = [];
    forEach(parseValue, item => {
      final.push({ value: item.name, label: item.description });
    });
    return final;
  };

  const parseInput = parseValue => {
    const final = [];
    forEach(parseValue, (item, index) => {
      final.push({
        name: item.value,
        description: item.label,
        display_order: index,
      });
    });
    return final;
  };

  const disabledStartDateHours = startDate => {
    if (moment(new Date()).isSame(startDate)) {
      console.log('working2', moment(new Date()).isSame());
      const hours = moment().hours();
      const min = moment().minutes();
      setDisableStartDateMinutesHelper(hours);
      setDisableStartDateHoursArray(range(0, hours));
      setDisableStartDateMinutesArray(range(min));
      return [];
    }
    setDisableStartDateMinutesHelper(0);
    setDisableStartDateHoursArray([]);
    setDisableStartDateMinutesArray([]);
    return [];
  };

  const disabledHours = (startDate, endDate, startTime) => {
    const eventStartDate = moment(startDate).format('YYYY-MM-DD');
    const eventEndDate = moment(endDate).format('YYYY-MM-DD');
    if (moment(eventStartDate).isSame(eventEndDate)) {
      const hours = moment(startTime).hours();
      console.log('hours', hours);
      const min = moment(startTime).minutes();
      setDisableMinutesHelper(hours);
      setDisableHoursArray(range(0, hours));
      setDisableMinutesArray(range(min + 10));
      return [];
    }
    setDisableMinutesHelper(0);
    setDisableHoursArray([]);
    setDisableMinutesArray([]);
    return [];
  };

  const disabledStartDateMinutes = h => {
    if (h === disableStartDateMinutesHelper) {
      return disableStartDateMinutesArray;
    }

    return [];
  };

  const disabledMinutes = h => {
    const h2 = disableHoursArray.filter(item => item === h);
    if (h === disableMinutesHelper) {
      return disableMinutesArray;
    }
    if (h2.length !== 0) {
      return range(0, 60);
    }

    return [];
  };

  return (
    <div>
      <div className="label">Event dates and times</div>
      <div className="columns is-multiline">
        <div className="column is-4">
          <EventTimeDatePicker
            label="Start Date"
            timeLabel="Start Time"
            id="EventManagement_AddEditEvent_StartDate"
            date={values.startDate}
            time={values.startTime}
            onDateChange={value => {
              setFieldValue('startDate', value);
              setFieldValue('endDate', value);
              disabledHours(value, value, new Date());
              disabledStartDateHours(value);
            }}
            onTimeChange={value => {
              setFieldValue('startTime', value);
              setFieldValue('endTime', null);
              disabledHours(values.startDate, values.endDate, value);
            }}
            minDate={new Date()}
            timeProps={{
              disabledHours: () => disableStartDateHoursArray,
              disabledMinutes: disabledStartDateMinutes,
            }}
            errors={errors.startDate}
            touched={touched.startDate}
            required
          />
        </div>

        <div className="column is-4">
          <EventTimeDatePicker
            label="End Date"
            timeLabel="End Time"
            date={values.endDate}
            time={values.endTime}
            onDateChange={value => {
              setFieldValue('endDate', value);
              disabledHours(
                values.startDate,
                value,
                values.startTime,
                values.endTime,
              );
            }}
            onTimeChange={value => {
              setFieldValue('endTime', value);
            }}
            minDate={new Date(values.startDate)}
            timeProps={{
              disabledHours: () => disableHoursArray,
              disabledMinutes,
            }}
            errors={errors.startEnd}
            touched={touched.startEnd}
            disabled={values.startDate === null}
            required
          />
        </div>

        <div className="column is-4" />
        {values.listing !== 'event-listing' && (
          <React.Fragment>
            <div className="column is-4 ">
              <Select
                label="Ticket Holding Time Limit Status (Mins)"
                value={[
                  {
                    value: values.ticket_holding_time_limit,
                    label: values.ticket_holding_time_limit,
                  },
                ]}
                options={times(60, item => ({
                  value: item + 1,
                  label: item + 1,
                }))}
                onChange={value => {
                  setFieldValue('ticket_holding_time_limit', value.value);
                  console.log(value);
                }}
                errors={errors.ticket_holding_time_limit}
                touched={touched.ticket_holding_time_limit}
              />
            </div>
            <div className="column is-4">
              <Input
                action={['create_event', 'update_event']}
                label="Total Capacity"
                id="EventManagement_AddEditEvent_TotalCapacity"
                value={values.total_capacity !== 0 ? values.total_capacity : ''}
                type="number"
                name="total_capacity"
                min="1"
                onChange={event =>
                  setFieldValue(
                    'total_capacity',
                    event.target.value === ''
                      ? 0
                      : parseInt(event.target.value, 10),
                  )
                }
                onBlur={handleBlur}
                errors={errors.total_capacity}
                touched={touched.total_capacity}
                required
              />
            </div>
            <div className="column is-4">
              <div className="field">
                <label className="label">Remaining tickets</label>
                <div className="control ">
                  <CheckBox
                    action={['create_event', 'update_event']}
                    label="Display the remaining number of tickets"
                    value={values.display_remaining_ticket}
                    onChange={value =>
                      setFieldValue('display_remaining_ticket', value)
                    }
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        <div className="column is-12">
          <Input
            action={['create_event', 'update_event']}
            label="Tagline"
            type="text"
            name="tagline"
            id="EventManagement_AddEditEvent_Tagline"
            value={values.tagline}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            optionalText="( Min 10 and Max 50 )"
            errors={errors.tagline}
            touched={touched.tagline}
          />
        </div>
        <div className="column is-12">
          <TextArea
            action={['create_event', 'update_event']}
            label="Description"
            type="text"
            id="EventManagement_AddEditEvent_Description"
            name="description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.description}
            touched={touched.description}
          />
        </div>
        <div className="column is-half">
          <TagInputs
            action={['create_event', 'update_event']}
            label="Event Type"
            optionalText="( Select any 1 Type )"
            value={parseData(values.event_type)}
            suggestions={
              values.event_type.length === 1 ? [] : parseData(data.eventTypes)
            }
            onChange={value => setFieldValue('event_type', parseInput(value))}
            errors={errors.event_type}
            touched={touched.event_type}
            required
          />
        </div>
        <div className="column is-half">
          <TagInputs
            action={['create_event', 'update_event']}
            label="Event category"
            optionalText="( Select upto 3 Categories )"
            value={parseData(values.event_category)}
            suggestions={
              values.event_category.length === 3
                ? []
                : parseData(data.eventCategories)
            }
            onChange={value =>
              setFieldValue('event_category', parseInput(value))
            }
          />
        </div>
        <div className="column is-half">
          <TagInputs
            action={['create_event', 'update_event']}
            label="Feature"
            value={parseData(values.feature)}
            suggestions={parseData(data.features)}
            onChange={value => setFieldValue('feature', parseInput(value))}
          />
        </div>
        <div className="column is-half">
          <TagInputs
            action={['create_event', 'update_event']}
            label="Age Restriction"
            value={parseData(values.restriction)}
            optionalText="( Select upto 2 restrictions )"
            suggestions={
              values.restriction.length === 2 ? [] : parseData(data.restriction)
            }
            onChange={value => setFieldValue('restriction', parseInput(value))}
          />
        </div>

        <div className="column is-half">
          <TagInputs
            action={['create_event', 'update_event']}
            label="Dress code"
            value={parseData(values.dress_code)}
            suggestions={
              values.dress_code.length === 2 ? [] : parseData(data.dressCodes)
            }
            optionalText="(Select upto 2 dress codes)"
            onChange={value => setFieldValue('dress_code', parseInput(value))}
          />
        </div>
        <div className="column is-half">
          <TagInputs
            label="Payment Method"
            value={intersectionBy(
              options.paymentOptions,
              values.payment_method.map(item => ({
                label: item,
                value: item,
              })),
              'value',
            )}
            suggestions={options.paymentOptions}
            onChange={value => {
              const input = value.filter(
                item => item.value !== 'credit-card',
                item => item.value,
              );
              const newValue = input.map(item => item.value);
              const fixed = ['credit-card'];

              setFieldValue('payment_method', fixed.concat(newValue));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EventDetailForm;
