import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { isNull } from 'lodash';
import { useStoreState } from 'easy-peasy';
import client from '../../../utils/apolloClient';

import Layout from '../../../components/global/Layout';
import PlaceSelection from '../../../components/PlaceSelection';
import { ErrorMessage } from '../../../components/elements';
import Form from './Form';

const createCustomHourMutation = gql`
  mutation createCustomHour($input: CreateCustomHourInput) {
    create_custom_hour(input: $input) {
      custom_hour_id
      error {
        description
      }
    }
  }
`;

const Add = ({ history }) => {
  const { userId } = useStoreState(state => state.auth);
  const [place, setPlace] = useState({});
  const [placeSelection, setPlaceSelection] = useState(false);

  return (
    <Layout>
      {placeSelection ? (
        <>
          <Mutation
            client={client.clientPrivate}
            mutation={createCustomHourMutation}
            onCompleted={({ create_custom_hour }) => {
              if (create_custom_hour && create_custom_hour.length !== 0) {
                if (!isNull(create_custom_hour[0].error)) {
                  create_custom_hour[0].error.map(item =>
                    toast.error(item.description),
                  );
                } else {
                  swal(
                    'Great!',
                    'Custom Hour created successfully!',
                    'success',
                  ).then(() => {
                    history.goBack();
                  });
                }
              }
            }}
          >
            {(create_custom_hour, { loading, error }) => (
              <>
                {error && <ErrorMessage message={error.message} />}
                <Form
                  userId={userId}
                  place={place}
                  loading={loading}
                  onSubmit={input => {
                    create_custom_hour({
                      variables: {
                        input,
                      },
                    });
                  }}
                />
              </>
            )}
          </Mutation>
        </>
      ) : (
        <PlaceSelection
          claimStatus="NONE"
          // hasServiceSetting
          heading="Select Place to add Custom Hours"
          handleSelect={value => {
            setPlace(value);
            setPlaceSelection(true);
          }}
        />
      )}
    </Layout>
  );
};

export default Add;
