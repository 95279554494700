import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import Delete from './Delete';
import { ACLSConsumer } from '../../../utils/aclsContext';
import Can from '../../../utils/Can';

const CheckInList = ({ menu }) => (
  <>
    <td>
      <Link to={`/update-menu/${menu.menu_id}`}>{menu.name}</Link>
    </td>
    {/* <td>{menu.internal_name}</td> */}
    <td>{menu.service_type}</td>
    <td>{menu.place.name}</td>
    <td>{menu.place.city}</td>
    <td>
      <ReactTooltip html place="top" type="dark" effect="float" />
      {menu.updated_by_user ? (
        <p
          data-tip={`<span>
          <p>email: ${menu.updated_by_user.email}</p>
          <p>objectId: ${menu.updated_by_user.user_id}</p>
        </span>`}
          data-html
        >
          {menu.updated_by_user.display_name}
        </p>
      ) : (
        menu.audit.updated_by
      )}
    </td>
    <td> {moment(menu.audit.updated_at).format('lll')} </td>

    <td>
      <ACLSConsumer>
        {({ apiActions, pageActions }) => (
          <Can
            action="delete_menu"
            apiActions={apiActions}
            pageActions={pageActions}
            yes={() => <Delete menuId={menu.menu_id} />}
          />
        )}
      </ACLSConsumer>
    </td>
  </>
);

export default CheckInList;
