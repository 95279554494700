import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import Can from '../../../utils/Can';
import { ACLSConsumer } from '../../../utils/aclsContext';
import Delete from '../HolidayHours/Delete';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = ({
  customHourId,
  isUpdate,
  history,
  location,
  loading,
  dirty,
  placeName,
  children,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title className="is-size-2">
          {isUpdate ? 'Update' : 'Add'} custom hours
        </Title>
      </Level.Item>
      <Level.Item align="right">
        {isUpdate && (
          <Level.Item>
            <Delete
              placeName={placeName}
              customHourId={customHourId}
              onHandleComplete={() => history.goBack()}
            >
              <i className="fas fa-trash has-text-grey" />
            </Delete>
          </Level.Item>
        )}
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-grey">
            <span className="has-text-weight-semibold">Cancel</span>
          </a>
        </Level.Item>
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/update-holiday-hour'
                  ? 'update_custom_hour'
                  : 'create_custom_hour'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <Level.Item>
                  <button
                    type="submit"
                    className={`button is-primary ${loading && 'is-loading'}`}
                    disabled={!dirty}
                  >
                    <span className="has-text-weight-semibold">Submit</span>
                  </button>
                </Level.Item>
              )}
            />
          )}
        </ACLSConsumer>
      </Level.Item>
    </Level>
    {children}
  </Wrapper>
);

export default withRouter(Header);
