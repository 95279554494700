import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { isNull } from 'lodash';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import client from '../../../utils/apolloClient';

const deleteItemMutation = gql`
  mutation deleteItemMenu($input: NavigateMenuItemInput) {
    delete_menu_item(input: $input) {
      menu_item_id
      name
      error {
        description
      }
    }
  }
`;

const GlobalMenuUpdate = ({ itemId }) => {
  const { userId } = useStoreState(state => state.auth);

  return (
    <Mutation
      client={client.clientPrivate}
      mutation={deleteItemMutation}
      onCompleted={({ delete_menu_item }) => {
        if (!isNull(delete_menu_item.error)) {
          delete_menu_item.error.map(item => toast.error(item.description));
        } else {
          toast.success('Menu Item deleted successfully!');
          window.location.reload(true);
        }
      }}
    >
      {(delete_menu_item, { loading }) => (
        <button
          className={`button ${loading && 'is-loading'}`}
          onClick={() => {
            swal({
              title: 'Are you sure?',
              // eslint-disable-next-line max-len
              text: `Are you sure you want to delete this Menu Item?`,
              buttons: ['Cancel', 'Yes'],
              dangerMode: true,
            }).then(willDelete => {
              if (willDelete) {
                delete_menu_item({
                  variables: {
                    input: {
                      user_id: userId,
                      menu_item_id: itemId,
                    },
                  },
                });
              }
            });
          }}
        >
          <i className="fas fa-trash has-text-grey" />
        </button>
      )}
    </Mutation>
  );
};

export default GlobalMenuUpdate;
