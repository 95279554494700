import React from 'react';

import Layout from '../../components/global/Layout';

const Home = () => (
  <Layout>
    <div className="is-hidden-touch">landing page content</div>
  </Layout>
);

export default Home;
