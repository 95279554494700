/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import client from '../../../utils/apolloClient';

const placesQuery = gql`
  query searchPlaces($input: SearchInput) {
    search_places(input: $input) {
      place_listing {
        place_id
        display_order
        name
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        status
        claim_status
      }
    }
  }
`;

export const getPlaces = input =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        query: placesQuery,
        variables: {
          input,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        console.log('data places', data);
        if (
          data &&
          data.search_places &&
          data.search_places.place_listing &&
          data.search_places.place_listing.length !== 0
        ) {
          resolve(data.search_places.place_listing);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
