import React from 'react';
import styled from 'styled-components';
import Menu from './Menu';
import Table from './Table';

const Container = styled.div`
  padding: 1rem;
`;

const QRCode = ({ menuName, placeName, qrCodeValue }) => (
  <Container className="columns">
    <div className="column is-4">
      <p>Menu QR Code</p>
      <Menu menuName={menuName} qrCodeValue={qrCodeValue} />
    </div>
    <div className="column">
      <p>Table Menu QR Code</p>
      <Table
        placeName={placeName}
        qrCodeValue={qrCodeValue}
        menuName={menuName}
      />
    </div>
  </Container>
);

export default QRCode;
