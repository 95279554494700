import React from 'react';
import { Link } from 'react-router-dom';
import { first, isNull } from 'lodash';

const ListItem = ({ user }) => (
  <tr>
    <td>
      <Link to={`/admin-user-activity/${user.user_id}`}>{user.email}</Link>
    </td>
    <td>
      {user.first_name} {user.last_name}
    </td>
    <td>
      {user.role && user.role.length !== 0
        ? user.role.map(
            (item, index) =>
              `${item} ${user.role.length - 1 === index ? '' : ','}`,
          )
        : '--'}
    </td>
    <td>-</td>
    <td>
      {!isNull(user.contact) && user.contact.length !== 0
        ? first(user.contact.filter(item => item.type === 'mobile')).value
        : '-'}
    </td>
    <td> - </td>

    <td>
      {/* <Link>
        <i className="fas fa-trash has-text-grey" />
      </Link> */}
    </td>
  </tr>
);

export default ListItem;
