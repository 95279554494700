import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
// import { forEach } from 'lodash';
import { useStoreState } from 'easy-peasy';
import { MdClose } from 'react-icons/md';
import Information from './Information';
import OrderStatus from './OrderStatus';
import Menu from './Menu';
import { getCustomHours } from './helpers';
import { Loading } from '../../../../components/elements';

const Container = styled.form`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 1rem 1.5rem;
    }
    .modal-card {
      max-height: calc(100vh - 0px);
      position: absolute;
      right: 0;
      width: 35rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1rem 1.5rem;
      padding-top: 1.5rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: space-between;
      background: white;
      border-top: 0px;
    }
  }
`;

const Section = styled.div`
  padding: 1rem;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

// const filterOpenAndStart = menus => {
//   const data = [];
//   forEach(menus, menu => {
//     if (menu.hours === 'OPENED' || menu.hours === 'STOPPED') {
//       data.push(menu);
//     }
//   });
//   return data;
// };

const StartStop = ({
  isActive,
  onClose,
  menus,
  fetchMenus,
  refetchPlace,
  place,
}) => {
  const { userId } = useStoreState(state => state.auth);
  const [customHours, setCustomHours] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getCustomHour = useCallback(async () => {
    setLoading(true);
    const data = await getCustomHours({
      userId,
      menuIds: menus.map(item => item.menu_id),
    });
    setCustomHours(data.custom_hours_listing);
    setLoading(false);
  }, []);

  useEffect(() => {
    getCustomHour();
  }, [getCustomHour]);

  console.log('customHours', customHours);

  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <div>
            <p className="modal-card-title">
              Temporarily Stop / Start taking orders
            </p>
          </div>
          <CloseButtonWrapper>
            <MdClose size={30} type="button" onClick={onClose} />
          </CloseButtonWrapper>
        </header>
        <Section className="modal-card-body">
          <Information />
          <br />
          {loading && <Loading />}
          {!loading && (
            <>
              <OrderStatus
                place={place}
                menus={menus}
                customHours={customHours}
              />
              <br />
              <Menu
                place={place}
                menus={menus}
                fetchMenus={fetchMenus}
                customHours={customHours}
                refetchPlace={refetchPlace}
              />
            </>
          )}
        </Section>
      </div>
    </Container>
  );
};

export default StartStop;
