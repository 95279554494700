import React from 'react';
import styled from 'styled-components';
import { parsedAmount } from '../helpers';

const Container = styled.div`
  padding: 0.6rem;
  border-radius: 10px;
  border: 2px solid #dbdbdb;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
`;

const SpecialSpacing = styled.div`
  padding: 0.5rem 0rem;
  display: flex;
  justify-content: space-between;
  &&.time-line {
    align-items: flex-end;
  }
  .separator {
    padding: 0rem 1rem;
    padding-top: 7px;
  }
`;

const SpecialSpacingHr = styled(SpecialSpacing)`
  border-bottom: 1px solid #dbdbdb;
`;

const SpecialSpacingTopHr = styled(SpecialSpacing)`
  border-top: 1px solid #dbdbdb;
`;

const Pricing = ({ order }) => (
  <Container>
    <SpecialSpacingHr>
      <p className="is-size-3 has-text-weight-semibold">SubTotal</p>
      <p className="is-size-3 has-text-weight-semibold">
        ${order.billing.sub_total.toFixed(2)}
      </p>
    </SpecialSpacingHr>
    {order.service_type === 'Delivery' && (
      <SpecialSpacing>
        <p className="is-size-3 has-text-weight-semibold">Delivery Fee</p>
        <p className="is-size-3 has-text-weight-semibold">
          ${order.billing.delivery_fee.toFixed(2)}
        </p>
      </SpecialSpacing>
    )}
    {order.billing.deal_discount && order.billing.deal_discount !== 0 && (
      <SpecialSpacing>
        <p className="is-size-3 has-text-weight-semibold has-text-primary">
          Promo
        </p>
        <p className="is-size-3 has-text-weight-semibold has-text-primary">
          - $
          {parseFloat(order.billing.deal_discount)
            .toFixed(2)
            .substring(1)}
        </p>
      </SpecialSpacing>
    )}
    {order.billing.adjustment_amount && order.billing.adjustment_amount !== 0 && (
      <SpecialSpacing>
        <p className="is-size-3 has-text-weight-semibold has-text-primary">
          Price Adjustments
        </p>
        <p className="is-size-3 has-text-weight-semibold has-text-primary">
          {order.billing.adjustment_amount < 0
            ? `- $${Math.abs(order.billing.adjustment_amount).toFixed(2)}`
            : `$${order.billing.adjustment_amount.toFixed(2)}`}
        </p>
      </SpecialSpacing>
    )}
    <SpecialSpacingTopHr>
      <p className="is-size-3 has-text-weight-semibold">Total Amount</p>
      <p className="is-size-3 has-text-weight-semibold">
        {parsedAmount(order.billing.order_total.toFixed(2))}
      </p>
    </SpecialSpacingTopHr>
  </Container>
);

export default Pricing;
