import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { capitalize } from 'lodash';
import { parsedAmount } from '../helpers';

const Container = styled.div`
  padding: 0.6rem;
  border-radius: 10px;
  border: 2px solid #dbdbdb;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
`;

const SpecialSpacing = styled.div`
  padding: 0.5rem 0rem;
  display: flex;
  justify-content: space-between;
  &&.time-line {
    align-items: flex-end;
  }
  .separator {
    padding: 0rem 1rem;
    padding-top: 7px;
  }
`;

const SpecialSpacingTopHr = styled(SpecialSpacing)``;

const CardDetails = ({ order }) => {
  if (order.payment.payment_method === 'CARD') {
    return (
      <Container>
        <SpecialSpacingTopHr>
          <div>
            <p className="is-size-3 has-text-weight-semibold">
              {capitalize(order.payment.payment_card.brand)} ending in{' '}
              {order.payment.payment_card.last4}
            </p>
            <p className="is-size-4">
              Paid - {moment(order.payment_at).format('llll')}
            </p>
          </div>
          <p className="is-size-3 has-text-weight-semibold">
            {parsedAmount(order.billing.order_total.toFixed(2))}
          </p>
        </SpecialSpacingTopHr>
      </Container>
    );
  }
  return null;
};

export default CardDetails;
