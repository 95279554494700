import React, { useState, useEffect } from 'react';
import { Tab } from 'rbx';
import styled from 'styled-components';
import tzLookup from 'tz-lookup';
import moment from 'moment-timezone';

import { Box, Tabs } from '../../../components/elements';
import { getOrders } from '../OrderDashboard/helpers';
import OrderList from './OrderList';
import Header from './Header';

const Container = styled.div``;

const MainForm = props => {
  const [isActiveTab, setIsActiveTab] = useState('all');
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [sort, setSort] = useState('CREATED_DESC');

  const [startDate, setStartDate] = useState(
    moment()
      .startOf('day')
      .toISOString(),
  );
  const [endDate, setEndDate] = useState(
    moment()
      .endOf('day')
      .toISOString(),
  );
  const { place } = props;

  const fetchData = async ({ serviceType }) => {
    setIsLoading(true);
    const menu_order_filter = {
      place_id: place.place_id,
      status: ['COMPLETE', 'CANCELLED'],
      date_range: { start_date: startDate, end_date: endDate },
    };
    Object.assign(
      menu_order_filter,
      serviceType && { service_type: serviceType },
    );
    const data = await getOrders({
      sort,
      from: 0,
      size: 200,
      filter: {
        menu_order_filter,
      },
    });
    setOrders(data.menu_order_listing ? data.menu_order_listing : []);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isActiveTab === 'all') {
      fetchData({ serviceType: null });
    } else {
      fetchData({ serviceType: isActiveTab });
    }
  }, [startDate, endDate, sort]);

  const handleTab = value => {
    setOrders([]);
    setIsActiveTab(value);
    if (value === 'all') {
      fetchData({ serviceType: null });
    } else {
      fetchData({ serviceType: value });
    }
  };
  useEffect(() => {
    const lat = parseFloat(place.latitude).toFixed(5);
    const long = parseFloat(place.longitude).toFixed(5);
    const timeZone = tzLookup(lat, long);
    moment.tz.setDefault(timeZone);
  }, []);
  return (
    <>
      <Header
        placeName={place.name}
        placeLocation={`${place.city}, ${place.post_code} | ${place.country} `}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      <Box>
        <Tabs className="tabs  is-fullwidth">
          <Tab active={isActiveTab === 'all'} onClick={() => handleTab('all')}>
            All
          </Tab>

          <Tab
            active={isActiveTab === 'Pickup'}
            onClick={() => handleTab('Pickup')}
          >
            Pickup
          </Tab>

          <Tab
            active={isActiveTab === 'Delivery'}
            onClick={() => handleTab('Delivery')}
          >
            Delivery
          </Tab>

          <Tab
            active={isActiveTab === 'Dinein'}
            onClick={() => handleTab('Dinein')}
          >
            Dinein
          </Tab>
        </Tabs>
        <Container>
          <OrderList
            orders={orders}
            isLoading={isLoading}
            sort={sort}
            setSort={setSort}
          />
        </Container>
      </Box>
    </>
  );
};

export default MainForm;
