import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { capitalize, reverse } from 'lodash';

import ActiveCards from './ActiveCard';
import OrderDetails from './OrderDetails';
import { getReceivedOrders } from './helpers';
import { Loading } from '../../../components/elements';
import {
  scheduleTimeText,
  scheduleCompletionTimeText,
  totalTimeDelay,
} from './timeHelpers';

const Container = styled.div`
  padding: 1rem 1rem;
`;

const DetailsButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
  }
`;

const Progress = styled.p`
  display: flex;
  padding-top: 9px;
  padding-left: 10px;
  &&& {
    background-color: #66b44b;
    color: #ffffff;
    font-weight: 600;
    width: 100%;
    height: 6vh;
  }
`;

const CompleteBtn = styled.button`
  border: 2px solid grey;
  border-radius: 20px;
  color: ${({ theme }) => theme.secondaryColor};
  font-weight: 600;
  :hover {
    color: ${({ theme }) => theme.secondaryColor};
  }
`;

const CompletedOrders = props => {
  const [isActiveOrderDetailsModal, setIsActiveOrderDetailsModal] = useState(
    false,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const { place, setCompletedOrders, completedOrders } = props;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getReceivedOrders({
        placeId: place.place_id,
        status: 'COMPLETE',
        dateRange: {
          start_date: moment(
            `${moment().format('YYYY-MM-DD')} 12:00 AM`,
          ).toISOString(),
          end_date: moment().toISOString(),
        },
      });
      setIsLoading(false);
      setCompletedOrders(reverse(data));
    };
    fetchData();
  }, []);

  return (
    <>
      <Progress>
        Completed{' '}
        <i
          className="fas fa-angle-up"
          style={{ right: '-80px', paddingLeft: '5px', paddingTop: '4px' }}
        />
      </Progress>
      <Container>
        <br />
        {isLoading && <Loading />}
        {completedOrders !== 0 &&
          completedOrders.map(item => {
            const handleTimeLineTime = status => {
              const getStatus = () => {
                if (status === 'RECEIVED') {
                  return 'received';
                }
                if (status === 'CONFIRMED') {
                  return 'confirmed';
                }
                if (status === 'READY') {
                  return 'ready';
                }
                if (status === 'COMPLETE') {
                  return 'complete';
                }
                if (status === 'CANCEllED') {
                  return 'cancelled';
                }
                return 'received';
              };

              const orderStatus = item[getStatus()];

              const time = orderStatus
                ? moment(orderStatus.created_at).format('LT')
                : null;
              return time;
            };

            const orderStatus = () => {
              if (item.service_type === 'Pickup') {
                return 'Picked Up';
              }
              if (item.service_type === 'Dinein') {
                return 'Served';
              }
              if (item.service_type === 'Delivery') {
                return 'Delivered';
              }
              return null;
            };

            const receivedStatusHistory = item.received;

            const receivedTime = receivedStatusHistory
              ? receivedStatusHistory.created_at
              : null;

            const title = () => (
              <>
                {item.service_type === 'Delivery' && 'Delivery'}
                {item.service_type === 'Dinein' && item.table_number
                  ? `Table ${item.table_number}`
                  : item.service_type === 'Dinein' && 'Dinein'}
                {item.service_type === 'Pickup' && 'Pickup'}
                {item.large_order && ` (LARGE ORDER)`}
              </>
            );

            return (
              <ActiveCards
                title={title()}
                schedule={scheduleTimeText(item.estimates, receivedTime)}
                scheduleWithDelay={scheduleCompletionTimeText(
                  item.estimates,
                  receivedTime,
                )}
                ready={totalTimeDelay(item.estimates, receivedTime)}
              >
                <div className="columns">
                  <div className="column">
                    <p
                      className="is-size-4 has-text-weight-semibold"
                      style={{ padingLeft: '0.8rem' }}
                    >
                      {item.order_reference} | {item.overall_quantity} items | $
                      {item.billing.order_total.toFixed(2)}
                    </p>
                    <p className="is-size-4" style={{ paddingTop: '8px' }}>
                      {item.contact && item.contact.contact_name}
                      {item.service_type === 'Delivery' &&
                        item.address &&
                        ` ( ${capitalize(item.address.city)} )`}
                    </p>
                  </div>
                  <div className="column">
                    <DetailsButton
                      type="button"
                      className="button is-large is-fullwidth control"
                      onClick={() => {
                        setIsActiveOrderDetailsModal(true);
                        setCurrentOrder(item);
                      }}
                    >
                      Details
                    </DetailsButton>
                  </div>
                  <div className="column">
                    <CompleteBtn
                      type="button"
                      className="button is-large is-fullwidth control"
                    >
                      {`${orderStatus()} at ${handleTimeLineTime('COMPLETE')}`}
                    </CompleteBtn>
                  </div>
                </div>
                <div />
              </ActiveCards>
            );
          })}
      </Container>
      {currentOrder && isActiveOrderDetailsModal && (
        <OrderDetails
          isActiveOrderDetailsModal={isActiveOrderDetailsModal}
          setIsActiveOrderDetailsModal={setIsActiveOrderDetailsModal}
          order={currentOrder}
        />
      )}
    </>
  );
};

export default CompletedOrders;
