import React from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { isNull, omit } from 'lodash';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';

import client from '../../../utils/apolloClient';
import { ErrorMessage, Loading } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import { reverseDayEstimateValueParser } from './helpers';

import Form from './Form';
import dirtyFieldHandler from './dirtyFieldHandler';

const placeQuery = gql`
  query place($input: SearchInput) {
    search_places(input: $input) {
      place_listing {
        place_id
        display_order
        name
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        status
        claim_status
        latitude
        longitude
        service_type {
          description
          name
          display_order
        }
      }
    }
  }
`;

const settingQuery = gql`
  query setting($input: NavigateServiceTypeSettingInput) {
    fetch_service_type_setting(input: $input) {
      service_type_setting_id
      place_id
      service_type
      status
      is_custom_service
      description
      tag
      url {
        uri
        display_order
      }
      is_private_menu
      image_url
      approval_status
      display_attendee_options
      display_table_number
      allow_pickup_options
      include_vehicle_information
      pickup_method
      pickup_instructions
      zone_setting {
        id
        name
        color_code
        delivery_area {
          area_type
          area_circle {
            location {
              latitude
              longitude
            }
            radius
          }
          area_polygon {
            latitude
            longitude
          }
        }
        delivery_fee
        delivery_estimate
      }
      day_estimate {
        day
        start_hour
        end_hour
        preparation_estimate
        delivery_estimate
      }
      contact {
        type
        value
        display
        is_primary
      }
      delivery_fee
      preparation_estimate
      delivery_estimate
      added_estimate
      cut_off_time
      food_setup_required
      minimum_no_of_attendees
      maximum_no_of_attendees
      minimum_order_amount
      maximum_order_amount
      payment_method
      enable_group_order
      schedule_order
      schedule_order_duration
      image_url
    }
  }
`;

const updateServiceTypeSettingMutation = gql`
  mutation updateServiceTypeSetting($input: ServiceTypeSettingInput) {
    update_service_type_setting(input: $input) {
      service_type_setting_id
      error {
        description
      }
    }
  }
`;

const MenuTypeUpdate = ({ history, match }) => {
  const { userId } = useStoreState(state => state.auth);
  const { placeId, id } = match.params;

  return (
    <Layout>
      <Query
        query={placeQuery}
        variables={{
          input: {
            filter: {
              place_filter: {
                place_id: placeId,
              },
            },
          },
        }}
      >
        {({ data: placeData, loading: placeLoading, error: placeError }) => {
          if (placeLoading) {
            return <Loading />;
          }
          if (placeError) {
            return <ErrorMessage message={placeError.message} />;
          }

          const [place] = placeData.search_places.place_listing;
          return (
            <Query
              client={client.clientPrivate}
              query={settingQuery}
              variables={{
                input: {
                  service_type_setting_id: id,
                },
              }}
            >
              {({ data, loading, error }) => {
                if (loading) {
                  return <Loading />;
                }
                if (error) {
                  return <ErrorMessage message={error.message} />;
                }

                const setting = data.fetch_service_type_setting;

                return (
                  <Mutation
                    client={client.clientPrivate}
                    mutation={updateServiceTypeSettingMutation}
                    onCompleted={({ update_service_type_setting }) => {
                      if (!isNull(update_service_type_setting.error)) {
                        update_service_type_setting.error.map(item =>
                          toast.error(item.description),
                        );
                      } else {
                        swal(
                          'Great!',
                          'Service settings configured successfully!',
                          'success',
                        ).then(() => {
                          history.goBack();
                        });
                      }
                    }}
                  >
                    {(
                      update_service_type_setting,
                      { loading: updateLoading, error: updateError },
                    ) => (
                      <>
                        {updateError && (
                          <ErrorMessage message={updateError.message} />
                        )}
                        <Form
                          place={place}
                          userId={userId}
                          step={2}
                          setting={setting}
                          loading={updateLoading}
                          serviceTypes={[]}
                          onSubmit={inputs => {
                            const input = omit(inputs, [
                              'menus',
                              'step',
                              'day_estimate',
                              'primaryEmail',
                              'primaryMobile',
                              'status',
                              'inStore',
                              'curbSide',
                              'carPark',
                              'driveThru',
                            ]);
                            const dayEstimate = reverseDayEstimateValueParser(
                              inputs.day_estimate,
                            );
                            const primaryContacts = [
                              {
                                type: 'email_primary',
                                value: inputs.primaryEmail,
                                display: true,
                                is_primary: true,
                              },
                              {
                                type: 'phone_primary',
                                value: inputs.primaryMobile,
                                display: true,
                                is_primary: true,
                              },
                            ];
                            const finalInput = {
                              ...input,
                              day_estimate: dayEstimate,
                              contact: primaryContacts,
                            };
                            Object.assign(
                              finalInput,
                              inputs.status !== setting.status && {
                                status: inputs.status,
                              },
                            );
                            const dataF = dirtyFieldHandler(
                              finalInput,
                              setting,
                            );
                            update_service_type_setting({
                              variables: {
                                input: dataF,
                              },
                            });
                          }}
                        />
                      </>
                    )}
                  </Mutation>
                );
              }}
            </Query>
          );
        }}
      </Query>
    </Layout>
  );
};

export default MenuTypeUpdate;
