import React from 'react';
import styled from 'styled-components';
import { mergeMenuHours } from './helpers';

const Container = styled.div`
  padding-right: 40%;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0rem;
`;

const sorter = {
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6,
  SUN: 7,
};

const sort = hours => {
  hours.sort((a, b) => {
    const day1 = a.day;
    const day2 = b.day;
    return sorter[day1] - sorter[day2];
  });
  return hours;
};

const WeeklyHours = ({ menu, customHours, place }) => {
  const menuHoursArry = customHours.filter(
    item => item.menu_id === menu.menu_id,
  );
  const finalMenuHours =
    menuHoursArry.length !== 0
      ? mergeMenuHours(menu.menu_hour, menuHoursArry[0], {
          latitude: place.latitude,
          longitude: place.longitude,
        })
      : menu.menu_hour;
  const hours = sort(finalMenuHours);
  return (
    <Container>
      <h3 className="has-text-weight-semibold is-size-1">Weekly Hours</h3>
      <br />
      {hours.map(item => (
        <Flex>
          <p className="is-size-4 has-text-weight-semibold">{item.day}</p>
          <div className="is-size-4 ">
            {item.option
              ? item.option.map(option => {
                  if (
                    option.start_in_sec === 0 &&
                    option.end_in_sec === 86340
                  ) {
                    return <p className="is-size-4">Open 24 Hours</p>;
                  }
                  return (
                    <p className="is-size-4 is-uppercase">
                      {option.start.length !== 0
                        ? `${option.start} - ${option.end}`
                        : 'Hour not available'}
                    </p>
                  );
                })
              : 'Closed'}
            {item.option.length === 0 && 'Closed'}
          </div>
        </Flex>
      ))}
    </Container>
  );
};

export default WeeklyHours;
