import React from 'react';
import styled from 'styled-components';

const SpecialSpacing = styled.div`
  padding: 0.2rem 0rem;
  display: flex;
  justify-content: space-between;
  &&.time-line {
    align-items: flex-end;
  }
  .separator {
    padding: 0rem 1rem;
    padding-top: 7px;
  }
`;

const AddressView = ({ order }) => {
  if (order.service_type === 'Pickup') {
    return (
      <React.Fragment>
        {order.service_type_setting.allow_pickup_options && (
          <SpecialSpacing>
            <p className="is-size-4">
              {order.service_type_setting.include_vehicle_information &&
                order.vehicle_information &&
                `${order.vehicle_information.car_detail}, ${order.vehicle_information.license_plate}`}
            </p>
            <p className="is-size-4">{order.pickup_method}</p>
          </SpecialSpacing>
        )}
      </React.Fragment>
    );
  }

  if (order.service_type === 'Dinein') {
    return (
      <React.Fragment>
        <SpecialSpacing>
          <p className="is-size-4" style={{ float: 'right' }}>
            {order.service_type_setting &&
              order.service_type_setting.display_table_number &&
              order.table_number &&
              `Table ${order.table_number}`}
          </p>
        </SpecialSpacing>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <>
        <p className="is-size-4">
          {`${order.address.address_line_1}, ${order.address.city} ${order.address.state} ${order.address.post_code}`}
        </p>
        <p className="is-size-4">{`${order.address.address_line_2}`}</p>
        {order.address.delivery_option && (
          <p className="is-size-4">
            {order.address.delivery_option.type === 'DELIVERY_TO_DOOR'
              ? 'Deliver to door'
              : 'Pickup Outside'}
            {order.address.delivery_option.caption &&
              order.address.delivery_option.caption.length !== 0 &&
              ' | '}
            {order.address.delivery_option.caption &&
              order.address.delivery_option.caption.map((value, index) => {
                let text = '';
                if (value === 'please_do_not_ring_the_bell') {
                  text = 'Please do not ring the bell';
                } else if (value === 'call_upon_reaching_my_location') {
                  text = 'Call upon reaching my location';
                }
                return `${text} ${
                  index + 1 === order.address.delivery_option.caption.length
                    ? ''
                    : '|'
                } `;
              })}
          </p>
        )}
      </>
    </React.Fragment>
  );
};

export default AddressView;
