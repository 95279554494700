const variables = {
  DEV1: {
    region: 'us-east-1',
    authEnvironmentPrefix: 'dev1',
    apiPrefix: 'dev1.',
    userPoolId: 'us-east-1_9Xkdly93x',
    clientId: '3p8hpefpgqd2vri5jpkg7pceu1',
    identityPoolId: 'us-east-1:c8290d25-a498-49cf-a5a7-b08100615c9b',
    awsAppSyncApiKey: 'da2-bl4u5k6tqvbk5m5meuclwbxbb4',
    analyticsKey: 'HTjBdAzlMKtWou728rDVZULxUIidOB2S',
    redirectUri: `https://dev1.admin.portal.kravein.com.au/`,
    authUrl: `https://dev1auth.kravein.com.au/login?response_type=token`,
    graphqlApiPublic:
      'https://yprnasc5uzbwbhmalokgv4mb5e.appsync-api.us-east-1.amazonaws.com/graphql',
    graphqlApiPrivate:
      'https://5cbu346zj5cvdanh32oqbwuncy.appsync-api.us-east-1.amazonaws.com/graphql',
    bugsnagReleaseStage: 'development',
  },
  TEST: {
    region: 'us-east-1',
    authEnvironmentPrefix: 'test',
    apiPrefix: 'test.',
    userPoolId: 'us-east-1_JvKtjTImD',
    clientId: '32gr3s5n85rfbi10top0dtv9fc',
    identityPoolId: 'us-east-1:a3176c3e-c65d-447a-83fd-fe9a94accb85',
    awsAppSyncApiKey: 'da2-cceejclsjjbavb526roab337nu',
    analyticsKey: 'HTjBdAzlMKtWou728rDVZULxUIidOB2S',
    redirectUri: `https://test.admin.portal.kravein.com.au/`,
    authUrl: `https://testauth.kravein.com.au/login?response_type=token`,
    graphqlApiPublic:
      'https://5xqzzybbszdlbfxwsessvntyfm.appsync-api.us-east-1.amazonaws.com/graphql',
    graphqlApiPrivate:
      'https://7b4wbbkp7rag3o6d7homsca4bu.appsync-api.us-east-1.amazonaws.com/graphql',
    bugsnagReleaseStage: 'development',
  },
  PROD: {
    region: 'ap-southeast-2',
    authEnvironmentPrefix: '',
    apiPrefix: '',
    userPoolId: 'ap-southeast-2_c8F9LERhE',
    clientId: '208v1fvnfd62v498681jm0ps3f',
    identityPoolId: 'ap-southeast-2:72359d05-cd81-4864-bb4d-bff49c76fda6',
    awsAppSyncApiKey: 'da2-awyn5sobezhvnetz33b5jhxcry',
    analyticsKey: 'BxldpnJvwhNuQp9Sr0cOjBvVNUJiCPD7',
    redirectUri: `https://admin.portal.kravein.com.au/`,
    authUrl: `https://auth.kravein.com.au/login?response_type=token`,
    graphqlApiPublic:
      'https://qgtoy7k6czhwphjijchmmchmky.appsync-api.ap-southeast-2.amazonaws.com/graphql',
    graphqlApiPrivate:
      'https://qewbeq22onh5jbiduerqe2bjha.appsync-api.ap-southeast-2.amazonaws.com/graphql',
    bugsnagReleaseStage: 'production',
  },
};

// defaultVariables is for local development
// const config = variables.PROD;
const config = process.env.REACT_APP_ENV
  ? variables[process.env.REACT_APP_ENV]
  : variables.DEV1;

export default {
  public: {
    aws_appsync_graphqlEndpoint: config.graphqlApiPublic,
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: config.awsAppSyncApiKey,
  },
  secured: {
    aws_appsync_graphqlEndpoint: config.graphqlApiPrivate,
    aws_appsync_region: 'us-east-1',
  },

  userPoolConfig: {
    region: config.region,
    UserPoolId: config.userPoolId,
    ClientId: config.clientId,
    IdentityPoolId: config.identityPoolId,
  },

  analyticsKey: config.analyticsKey,
  redirectUri: config.redirectUri,
  apiPrefix: config.apiPrefix,
  enableAcls: true,
  authUrl: `${config.authUrl}&client_id=${config.clientId}&redirect_uri=${config.redirectUri}`,
  bugsnagApiKey: '6ff3bbd705951db33dc04a13eb49faa8',
  bugsnagReleaseStage: config.bugsnagReleaseStage,
  emojiRegex:
    '/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/',
  googleApiKey: 'AIzaSyARq3lIWaDnlepdOd2alXdFzuTcd-PyGdY',
};
