import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tab, Column } from 'rbx';
import { withFormik } from 'formik';
import * as yup from 'yup';
import uuidv4 from 'uuid/v4';
import { isNull, omit } from 'lodash';
import {
  getServiceTypeSettings,
  defaultHours,
  addIdInputHours,
  parseFinalInputHours,
} from './helper';
import {
  placeBusinessHoursHandler,
  menuHourSorter,
} from '../../../utils/helpers';
import PlacePageHeader from '../../../components/PlacePageHeader';
import {
  Box,
  Tabs,
  Select,
  Input,
  InputErrorMessage,
} from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';
import Hours from './Hours';
import MenuSection from './MenuSection';

import QrCodes from './QRCodes';
import WeeklyHours from './WeeklyHours';

const Container = styled.div`
  padding: 1rem;
`;

const Form = props => {
  const [settings, setSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isActiveHourModal, setIsActiveHourModal] = useState(false);
  const [tab, setTab] = useState(0);

  const {
    loading,
    dirty,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    menu,
    isEdit = false,
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getServiceTypeSettings({ placeId: values.place_id });
      setIsLoading(false);
      const newData = data.map(item => ({
        ...item,
        value: item.service_type_setting_id,
        label: item.service_type,
      }));

      setSettings(newData);
    };
    fetchData();
  }, []);

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <Header loading={loading} dirty={dirty} menuName={values.name}>
        <PlacePageHeader place={props.place} placeId={values.place_id} />
      </Header>
      <Box>
        <Tabs className="tabs is-medium">
          <Tab active={tab === 0} onClick={() => setTab(0)}>
            Menu Designer
          </Tab>
          <Tab active={tab === 1} onClick={() => setTab(1)}>
            Menu Categories
          </Tab>
          <Tab active={tab === 2} onClick={() => setTab(2)}>
            QR Codes
          </Tab>
        </Tabs>
        {tab === 0 && (
          <Container>
            <Hours
              serviceType={settings.filter(
                item => item.value === values.service_type_setting_id,
              )}
              isActive={isActiveHourModal}
              onClose={() => {
                setIsActiveHourModal(false);
              }}
              {...props}
            />
            <Column.Group multiline gapSize={8}>
              <Column size="4">
                <Input
                  label="Menu Name"
                  id="MenuDesigner_Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  optionalText="( Max 26 characters )"
                  maxLength="26"
                  required
                  errors={errors.name}
                  touched={touched.name}
                  disableToastMessage
                />
              </Column>
              <Column size="4">
                <Select
                  label="Service Type"
                  name="service_type_setting_id"
                  options={settings}
                  value={settings.filter(
                    item => item.value === values.service_type_setting_id,
                  )}
                  onChange={value => {
                    setFieldValue('service_type_setting_id', value.value);
                    setFieldValue('isCustom', value.is_custom_service);
                  }}
                  disableToastMessage
                  errors={errors.service_type_setting_id}
                  touched={touched.service_type_setting_id}
                  required
                  isLoading={isLoading}
                  disabled={isEdit}
                />
              </Column>
              <Column size="4">
                <Select
                  label="Menu Status"
                  options={[
                    { value: 'ACTIVE', label: 'ACTIVE' },
                    { value: 'INACTIVE', label: 'INACTIVE' },
                  ]}
                  value={[
                    {
                      value: values.status,
                      label: values.status,
                    },
                  ]}
                  onChange={value => setFieldValue('status', value.value)}
                  disableToastMessage
                  required
                />
              </Column>
              <Column size="6">
                <WeeklyHours
                  hours={values.hours}
                  onClick={() => setIsActiveHourModal(true)}
                />
                <InputErrorMessage
                  errors={errors.hours}
                  touched={errors.hours}
                />
              </Column>
            </Column.Group>
            <br />
          </Container>
        )}
        {tab === 1 && (
          <Container>
            <MenuSection placeId={values.place_id} menuId={values.menu_id} />
          </Container>
        )}
        {tab === 2 && menu && (
          <QrCodes
            menuName={values.name}
            placeName={menu.place_name}
            // eslint-disable-next-line max-len
            qrCodeValue={`http://kravein.com.au/food-order/${menu.service_type_setting.service_type}/${menu.place_slug}?menuId=${menu.menu_id}`}
          />
        )}
      </Box>
      <Footer loading={loading} dirty={dirty} />
    </form>
  );
};

const MenuForm = withFormik({
  mapPropsToValues: ({ menu, place, userId }) => ({
    user_id: userId,
    place_id: menu && !isNull(menu.place_id) ? menu.place_id : place.place_id,
    service_type_setting_id:
      menu && !isNull(menu.service_type_setting.service_type_setting_id)
        ? menu.service_type_setting.service_type_setting_id
        : '',
    menu_id: menu && !isNull(menu.menu_id) ? menu.menu_id : uuidv4(),
    name: menu && !isNull(menu.name) ? menu.name : '',
    approval_status:
      menu && !isNull(menu.status) ? menu.approval_status : 'APPROVED',
    status: menu && !isNull(menu.status) ? menu.status : 'INACTIVE',
    description: menu && !isNull(menu.description) ? menu.description : '',
    isCustom: false,
    hours:
      menu && !isNull(menu.menu_hour) && menu.menu_hour.length !== 0
        ? placeBusinessHoursHandler(addIdInputHours(menu.menu_hour))
        : defaultHours,
    menu_hour:
      menu && !isNull(menu.menu_hour) && menu.menu_hour.length !== 0
        ? placeBusinessHoursHandler(addIdInputHours(menu.menu_hour))
        : defaultHours,
    isHoursDirty: false,
  }),

  validate: values => {
    const errors = {};
    if (values.hours === defaultHours && !values.isCustom) {
      errors.hours = 'Hours is required, Please put atleast one day hours ';
    }

    return errors;
  },

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .min(3, 'Please enter at least 3 characters')
      .max(26, 'Menu Name should not be greater than 26 characters')
      .required('Menu Name is required!'),
    description: yup
      .string()
      .min(5, 'Please enter at least 5 characters')
      .max(288, 'Menu Description cannot be greater than 288 characters'),
    service_type_setting_id: yup.string().required('Service Type is required!'),
  }),

  handleSubmit: (values, { props }) => {
    const input = omit(values, [
      'hours',
      'menu_hour',
      'isCustom',
      'isHoursDirty',
    ]);
    if (props.menu) {
      Object.assign(
        input,
        values.isHoursDirty && {
          menu_hour: menuHourSorter(parseFinalInputHours(values.menu_hour)),
        },
      );
    } else {
      Object.assign(input, {
        menu_hour: menuHourSorter(parseFinalInputHours(values.menu_hour)),
      });
    }

    props.onSubmit({
      ...input,
    });
  },

  displayName: 'GlobalMenuForm',
})(Form);

export default MenuForm;
