import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { reverse } from 'lodash';
import moment from 'moment-timezone';

import { BoxWithHeader, Loading } from '../../../components/elements';
import { getReceivedOrders } from './helpers';
import OrderDetails from './OrderDetails';

const Container = styled.div`
  padding: 1rem 1rem;
`;

const DetailsButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px !important;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
  }
`;

const Cancelled = ({ place }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [isActiveOrderDetailsModal, setIsActiveOrderDetailsModal] = useState(
    false,
  );
  const [currentOrder, setCurrentOrder] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getReceivedOrders({
        placeId: place.place_id,
        status: 'CANCELLED',
      });
      setIsLoading(false);
      setCancelledOrders(reverse(data));
    };
    fetchData();
  }, []);

  return (
    <Container>
      {isLoading && <Loading />}
      {cancelledOrders !== 0 &&
        cancelledOrders.map(item => {
          const handleTimeLineTime = () => {
            const orderStatus = item.cancelled;
            const time = orderStatus
              ? moment(orderStatus.created_at).format('LT')
              : null;
            return time;
          };

          const cancelledBy = () => {
            const orderStatus = item.cancelled;
            const user = orderStatus ? orderStatus.user_type : null;
            return user === 'USER' ? 'Customer' : user;
          };

          return (
            <BoxWithHeader
              title={item.service_type}
              cancelTime={`Cancelled at ${handleTimeLineTime()}`}
              cancelledBy="Cancelled by"
              key={item.menu_order_item}
              className="cancel-header"
            >
              <div className="columns v-centered">
                <div className="column is-4">
                  <p className="is-size-4 has-text-weight-semibold">
                    {item.order_reference} | {item.overall_quantity} items | $
                    {item.billing.sub_total.toFixed(2)}
                  </p>
                  <p>
                    {item.contact && item.contact.contact_name}(
                    {item.address && item.address.city})
                  </p>
                </div>

                <div className="column is-4">
                  <DetailsButton
                    type="button"
                    className="button is-large is-fullwidth control"
                    onClick={() => {
                      setIsActiveOrderDetailsModal(true);
                      setCurrentOrder(item);
                    }}
                  >
                    Details
                  </DetailsButton>
                </div>
                <div className="column is-4 d-flex is-align-items-center">
                  <p className="is-size-4 has-text-weight-semibold">
                    {`Cancelled by ${cancelledBy()}`}
                  </p>
                </div>
              </div>
              <div />
            </BoxWithHeader>
          );
        })}
      {currentOrder && isActiveOrderDetailsModal && (
        <OrderDetails
          isActiveOrderDetailsModal={isActiveOrderDetailsModal}
          setIsActiveOrderDetailsModal={setIsActiveOrderDetailsModal}
          order={currentOrder}
        />
      )}
    </Container>
  );
};

export default Cancelled;
