import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { isNull, uniq } from 'lodash';
import qs from 'querystringify';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import { Loading, Pagination, Table, Box } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const placesQuery = gql`
  query searchPlaces($input: SearchInput) {
    search_feeds(input: $input) {
      total_pages
      total_size
      feed_listing {
        object_id
        feed_id
        title
        content
        # status
        place {
          name
          city
        }
        audit {
          updated_by
          updated_at
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchQuery($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
      }
    }
  }
`;

const listSize = 10;

const PlacePostings = props => {
  const routeState = qs.parse(props.location.search);
  const { status, placeName, vendorName, city, page } = routeState;

  const placeNameFilter = placeName || '';
  const statusFilter = status || 'SUBMITTED';
  const vendorNameFilter = vendorName || '';
  const cityFilter = city || null;
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;

  const [sort, setSort] = useState('UPDATED_DESC');
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');
  const [titleSort, setTitleSort] = useState('AES');
  const [updatedAtSort, setUpdatedAtSort] = useState('AES');

  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const input = { from: currentPage * listSize, size: listSize, sort };
  const feed_filter = { object_type: 'PLACE' };
  const place_filter = {};

  Object.assign(feed_filter, status && { status: statusFilter });

  Object.assign(
    place_filter,
    placeNameFilter && { place_name: placeNameFilter },
    vendorNameFilter && { vendor_name: vendorNameFilter },
    cityFilter && { city: cityFilter },
  );

  Object.assign(input, {
    filter: {
      feed_filter,
      place_filter,
    },
  });
  return (
    <Layout>
      <Header />
      <Box>
        <Search
          status={status}
          placeName={placeNameFilter}
          vendorName={vendorNameFilter}
          city={city}
          routeState={routeState}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={currentPage * listSize}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value =>
              props.history.push(
                `/place-postings${qs.stringify(
                  {
                    ...routeState,
                    page: value.selected + 1,
                  },
                  true,
                )}`,
              )
            }
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_DESC'
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_DESC'
                            : 'PLACE_CITY_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    City / Suburb
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setTitleSort(titleSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          titleSort === 'AES' ? 'TITLE_DESC' : 'TITLE_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          titleSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>
                    Title
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <a>/></a> Updated By
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setUpdatedAtSort(
                          updatedAtSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          updatedAtSort === 'AES'
                            ? 'UPDATED_DESC'
                            : 'UPDATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          updatedAtSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Updated At
                  </span>
                </div>
              </th>

              <th>Actions</th>
            </tr>
          </thead>

          <Query
            client={client.clientPrivate}
            query={placesQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_feeds } = data;

              if (
                isNull(search_feeds) ||
                search_feeds.feed_listing.length === 0
              ) {
                return <tbody>NO RECORDS FOUND</tbody>;
              }

              return (
                <React.Fragment>
                  <View
                    search_feeds={search_feeds}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({ search_feeds, setPageCount, setTotalElements }) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setPageCount(search_feeds.total_pages);
    setTotalElements(search_feeds.total_size);

    if (search_feeds.feed_listing) {
      const userFilter = search_feeds.feed_listing.map(
        item => item.audit.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_feeds.total_pages]);
  const postings = search_feeds.feed_listing;

  return (
    <tbody>
      {postings.map(place => (
        <tr key={place.object_id}>
          <ListItem place={place} users={users} />
        </tr>
      ))}
    </tbody>
  );
};

export default PlacePostings;
