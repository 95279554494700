import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { uniq } from 'lodash';
import qs from 'querystringify';
import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import {
  Loading,
  Pagination,
  Table,
  Box,
  Message,
} from '../../../components/elements';

const placesQuery = gql`
  query searchPlaceClaim($input: SearchInput) {
    search_place_claimant(input: $input) {
      total_pages
      total_size
      place_claim_listing {
        place_id
        claimant_id
        name
        city
        status
        claim_status
        audit {
          updated_by
          updated_at
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const listSize = 10;

const PlaceClaims = props => {
  const routeState = qs.parse(props.location.search);
  const { status, placeName, city, vendorName, page } = routeState;

  const placeNameFilter = placeName || '';
  const statusFilter = status || 'SUBMITTED';
  const vendorNameFilter = vendorName || '';
  const cityFilter = city || null;
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;

  const [sort, setSort] = useState('UPDATED_DESC');
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');
  const [claimStatusSort, setClaimStatusSort] = useState('AES');
  const [updatedBySort, setUpdatedBySort] = useState('AES');
  const [updatedAtSort, setUpdatedAtSort] = useState('AES');

  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const input = { from: currentPage * listSize, size: listSize, sort };
  const place_filter = { status: statusFilter };

  Object.assign(
    place_filter,
    vendorNameFilter && { vendor_name: vendorNameFilter },
    cityFilter && { city: cityFilter },
    placeNameFilter && { place_name: placeNameFilter },
  );

  Object.assign(input, {
    filter: {
      place_filter,
    },
  });

  return (
    <Layout>
      <Header />
      <Box>
        <Search
          status={statusFilter}
          placeName={placeNameFilter}
          vendorName={vendorNameFilter}
          city={cityFilter}
          routeState={routeState}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={currentPage * listSize}
            pageCount={pageCount}
            listSize={listSize}
            currentPage={currentPage}
            totalElements={totalElements}
            handlePageClick={value =>
              props.history.push(
                `/place-claims${qs.stringify(
                  {
                    ...routeState,
                    page: value.selected + 1,
                  },
                  true,
                )}`,
              )
            }
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_DESC'
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_DESC'
                            : 'PLACE_CITY_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    City / Suburb
                  </span>
                </div>
              </th>

              <th>
                <span>
                  <a
                    onClick={() => {
                      setClaimStatusSort(
                        claimStatusSort === 'AES' ? 'DES' : 'AES',
                      );
                      setSort(
                        claimStatusSort === 'AES'
                          ? 'CLAIM_STATUS_DESC'
                          : 'CLAIM_STATUS_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        claimStatusSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up '
                      }
                    />
                  </a>{' '}
                  Claim Status
                </span>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setUpdatedBySort(
                          updatedBySort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          updatedBySort === 'AES'
                            ? 'UPDATED_DESC'
                            : 'UPDATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          updatedBySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Updated By
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setUpdatedAtSort(
                          updatedAtSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          updatedAtSort === 'AES'
                            ? 'UPDATED_DESC'
                            : 'UPDATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          updatedAtSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Updated At
                  </span>
                </div>
              </th>

              <th>Actions</th>
            </tr>
          </thead>
          <Query
            client={client.clientPrivate}
            query={placesQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return <Message>{error.description}</Message>;
              }

              const { search_place_claimant } = data;

              if (search_place_claimant.place_claim_listing.length === 0) {
                return <tbody>NO RECORDS FOUND</tbody>;
              }
              return (
                <View
                  search_place_claim={search_place_claimant}
                  setTotalElements={setTotalElements}
                  setPageCount={setPageCount}
                />
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

export default PlaceClaims;

const View = ({ search_place_claim, setPageCount, setTotalElements }) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setPageCount(search_place_claim.total_pages);
    setTotalElements(search_place_claim.total_size);
    if (search_place_claim.place_claim_listing) {
      const userFilter = search_place_claim.place_claim_listing.map(
        item => item.audit.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_place_claim.total_pages]);

  const placeData = search_place_claim.place_claim_listing;
  return (
    <tbody>
      {placeData.map(claim => (
        <ListItem
          claim={claim}
          totalPages={search_place_claim.total_pages}
          totalElements={search_place_claim.total_size}
          setPageCount={() => setPageCount(search_place_claim.total_pages)}
          setTotalElements={() =>
            setTotalElements(search_place_claim.total_size)
          }
          users={users}
        />
      ))}
    </tbody>
  );
};
