import React, { useEffect } from 'react';
import moment from 'moment';
import { join } from 'lodash';
import { Table, Box, Loading } from '../../../../components/elements';

import { ACLSConsumer } from '../../../../utils/aclsContext';
import Can from '../../../../utils/Can';
import Delete from '../../../MenuManagement/HolidayHours/Delete';

const List = ({
  placeId,
  menuId,
  userId,
  holidays,
  fetchHours,
  holidayLoading,
  onHandleUpdate,
}) => {
  useEffect(() => {
    fetchHours({ placeId, userId, menuId });
  }, [fetchHours, placeId, userId, menuId]);

  return (
    <Box>
      <Table>
        <thead>
          <tr>
            <th>
              <div>
                <span>Date</span>
              </div>
            </th>
            <th>
              <div>
                <span>Place Name</span>
              </div>
            </th>
            <th>
              <div>
                <span>City / Suburb</span>
              </div>
            </th>
            <th>
              <div>
                <span>Menu</span>
              </div>
            </th>
            <th>
              <div>
                <span>Updated By</span>
              </div>
            </th>
            <th>
              <div>
                <span>Updated At</span>
              </div>
            </th>
            <th>
              <div>
                <span>Action</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {!holidayLoading &&
            holidays.map(hours => (
              <tr key={hours.custom_hour_id}>
                <td>
                  <a onClick={() => onHandleUpdate(hours)}>
                    {moment(hours.day, 'YYYY-MM-DD').format('MMM, DD YYYY')}
                  </a>
                </td>
                <td>{hours.place.name}</td>
                <td>{hours.place.city}</td>
                <td>{join(hours.menu, ',')}</td>
                <td>{hours.audit ? hours.audit.updated_by : '-'}</td>
                <td>
                  {hours.audit
                    ? moment(hours.audit.updated_at).format('lll')
                    : '-'}{' '}
                </td>
                <td>
                  <ACLSConsumer>
                    {({ apiActions, pageActions }) => (
                      <Can
                        action="delete_custom_hour"
                        apiActions={apiActions}
                        pageActions={pageActions}
                        yes={() => (
                          <Delete
                            customHourId={hours.custom_hour_id}
                            onHandleComplete={() =>
                              fetchHours({ placeId, userId, menuId })
                            }
                          >
                            <i className="fas fa-trash has-text-grey" />
                          </Delete>
                        )}
                      />
                    )}
                  </ACLSConsumer>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      {holidayLoading && <Loading />}
      {holidays.length === 0 && !holidayLoading && (
        <p style={{ textAlign: 'center' }}>No Data Found</p>
      )}
    </Box>
  );
};

export default List;
