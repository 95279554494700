import React, { useEffect } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

import uuidv4 from 'uuid/v4';
import { isNull, omit, first } from 'lodash';
import { withRouter } from 'react-router-dom';

import findDirtyFields from '../../../../utils/findDirtyFields';
import Details from './Details';
import Footer from './Footer';

const Container = styled.form``;

const Form = props => {
  const { setFieldValue, values, handleSubmit, loading } = props;
  useEffect(() => {
    setFieldValue('initialValue', values);
  }, []);

  return (
    <>
      <Container>
        <Details {...props} />
      </Container>
      <Footer loading={loading} handleSubmit={handleSubmit} />
    </>
  );
};

const UserForm = withFormik({
  mapPropsToValues: ({ user }) => {
    const filterPrimaryContact =
      user && !isNull(user.contact)
        ? user.contact.filter(item => item.is_primary === true)
        : [];
    const primaryContact =
      filterPrimaryContact.length !== 0
        ? first(filterPrimaryContact).value
        : '';

    return {
      mobile: primaryContact,
      user_id: user && !isNull(user.user_id) ? user.user_id : uuidv4(),
      object_id: user && !isNull(user.object_id) ? user.object_id : uuidv4(),
      first_name: user && !isNull(user.first_name) ? user.first_name : '',
      last_name: user && !isNull(user.last_name) ? user.last_name : '',
      display_name: user && !isNull(user.display_name) ? user.display_name : '',
      preferred_username:
        user && !isNull(user.preferred_username) ? user.preferred_username : '',
      email: user && !isNull(user.email) ? user.email : '',
      date_of_birth:
        user &&
        !isNull(user.date_of_birth) &&
        user.date_of_birth.length !== 0 &&
        user.date_of_birth !== 'Invalid date'
          ? user.date_of_birth
          : null,
      gender: user && !isNull(user.gender) ? user.gender : 'MALE',
      description: user && !isNull(user.description) ? user.description : '',
      status: user && !isNull(user.status) ? user.status : 'ACTIVE',
      contact: [],
      social: [],
      user_handle: user && !isNull(user.user_handle) ? user.user_handle : '',
      image_url: user && !isNull(user.image_url) ? user.image_url : null,
    };
  },

  validate: values => {
    const errors = {};
    if (values.mobile && values.mobile.length !== 0) {
      const check = isPossiblePhoneNumber(values.mobile);
      if (!check) {
        errors.mobile = 'Please enter valid mobile number';
      }
    }
    return errors;
  },

  validationSchema: yup.object().shape({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    display_name: yup.string().required('Display name is required'),
    user_handle: yup.string().required('User handle is required'),
    // mobile: yup.string().required('Primary number is required'),
    email: yup
      .string()
      .email('Please enter valid email!')
      .required('Email is required'),
  }),

  handleSubmit: (values, { props }) => {
    const input = omit(values, 'initialValue');
    const finalValues = findDirtyFields(input, values.initialValue);
    const data = omit(finalValues, ['mobile']);
    const inputs = {
      user_id: values.user_id,
      object_id: values.object_id,
      ...data,
    };

    Object.assign(
      inputs,
      finalValues.mobile
        ? {
            contact: [
              {
                type: 'mobile',
                is_primary: true,
                value: finalValues.mobile,
                display: true,
              },
            ],
          }
        : { contact: [] },
    );
    props.onSubmit(inputs);
  },

  displayName: 'UserForm',
})(Form);

export default withRouter(UserForm);
