export const statusEnum = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

export const approvalStatusEnum = [
  { value: 'APPROVED', label: 'APPROVED' },
  { value: 'UNAPPROVED', label: 'UNAPPROVED' },
  { value: 'SUBMITTED', label: 'SUBMITTED' },
];
