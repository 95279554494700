import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { isNull } from 'lodash';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';
import { withRouter } from 'react-router-dom';

import client from '../../../utils/apolloClient';

import Layout from '../../../components/global/Layout';
import PlaceSelection from '../../../components/PlaceSelection';
import PostForm from './PostForm';

const createPostMutation = gql`
  mutation createPost($input: FeedInput) {
    create_feed(input: $input) {
      feed_id
      error {
        description
      }
    }
  }
`;

const PlacePostingAdd = ({ history }) => {
  const { userId } = useStoreState(state => state.auth);
  const [place, setPlace] = useState({});
  const [placeSelection, setPlaceSelection] = useState(false);
  return (
    <Layout>
      {placeSelection ? (
        <Mutation
          client={client.clientPrivate}
          mutation={createPostMutation}
          onCompleted={({ create_feed }) => {
            if (!isNull(create_feed.error)) {
              create_feed.error.map(item => {
                toast.error(item.description);
              });
            } else {
              swal(
                'Great!',
                'Posting has been successfully published!',
                'success',
              ).then(() => {
                history.goBack();
              });
            }
          }}
        >
          {(create_feed, { loading, error }) => (
            <PostForm
              userId={userId}
              error={error}
              loading={loading}
              place={place}
              createForm
              onSubmit={value => {
                create_feed({ variables: { input: { ...value } } });
              }}
              isAddComponent
            />
          )}
        </Mutation>
      ) : (
        <PlaceSelection
          heading="Select Place to add postings"
          handleSelect={value => {
            setPlace(value);
            setPlaceSelection(true);
          }}
        />
      )}
    </Layout>
  );
};

export default withRouter(PlacePostingAdd);
