import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 0.6rem;
  border-radius: 10px;
  border: 2px solid #dbdbdb;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  background-color: ${({ theme }) => theme.lightGreyAlpha};
`;

const SpecialSpacing = styled.div`
  padding: 0rem 0rem;
  display: flex;
  justify-content: space-between;
  &&.time-line {
    align-items: flex-end;
  }
  .separator {
    padding: 0rem 1rem;
    padding-top: 7px;
  }
`;

const CompleteStatus = ({ order, handleTimeLineTime, trackPath }) => (
  <Container>
    {order.status !== 'CANCELLED' && (
      <>
        <SpecialSpacing className="time-line">
          <p className="is-size-4 has-text-weight-medium">
            <p className="is-size-4 has-text-weight-bold">
              {handleTimeLineTime('RECEIVED') && (
                <>
                  <i className="fa fa-check" /> {handleTimeLineTime('RECEIVED')}
                </>
              )}
            </p>
            <span>Placed</span>
          </p>
          <p className="is-size-4 has-text-weight-medium">
            <p className="is-size-4 has-text-weight-bold">
              {handleTimeLineTime('CONFIRMED') && (
                <>
                  <i className="fa fa-check" />{' '}
                  {handleTimeLineTime('CONFIRMED')}
                </>
              )}
            </p>
            <span>Confirmed</span>
          </p>
          <p className="is-size-4 has-text-weight-medium">
            <p className="is-size-4 has-text-weight-bold">
              {handleTimeLineTime('READY') && (
                <>
                  <i className="fa fa-check" />
                  {handleTimeLineTime('READY')}
                </>
              )}
            </p>
            <span>Ready</span>
          </p>
          <p className="is-size-4 has-text-weight-medium">
            <p className="is-size-4 has-text-weight-bold">
              {handleTimeLineTime('COMPLETE') && (
                <>
                  <i className="fa fa-check" /> {handleTimeLineTime('COMPLETE')}
                </>
              )}
            </p>
            <span>{trackPath()}</span>
          </p>
        </SpecialSpacing>
      </>
    )}
  </Container>
);

export default CompleteStatus;
