import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import Layout from '../../../components/global/Layout';
import { ErrorMessage, Loading } from '../../../components/elements';
import Main from './Main';

const searchQuery = gql`
  query search($placeId: [String]) {
    search_places(input: { filter: { place_filter: { place_id: $placeId } } }) {
      place_listing {
        place_id
        display_order
        name
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        status
        claim_status
        latitude
        longitude
        service_type {
          description
          name
          display_order
        }
      }
    }
  }
`;

const MenuCustomHours = ({ match }) => {
  const { placeId } = match.params;
  return (
    <Layout>
      <Query query={searchQuery} variables={{ placeId: [placeId] }}>
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <ErrorMessage message={error.message} />;
          }
          const {
            search_places: { place_listing },
          } = data;
          return <Main place={place_listing[0]} />;
        }}
      </Query>
    </Layout>
  );
};

export default MenuCustomHours;
