import React from 'react';
import styled from 'styled-components';
import qs from 'querystringify';
import { Level, Title } from 'rbx';
import { omit } from 'lodash';

import { Input, Radio } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const Search = ({
  city,
  placeName,
  serviceType,
  status,
  history,
  routeState,
}) => {
  const handlePush = (args, key) => {
    const newRoutState = omit(routeState, key);
    history.push(
      `/menu-service-settings${qs.stringify(
        {
          ...newRoutState,
          ...args,
          page: 1,
        },
        true,
      )}`,
    );
  };
  return (
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Status</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="ACTIVE"
                value={status === 'ACTIVE'}
                onChange={() => {
                  handlePush({ status: 'ACTIVE' }, 'status');
                }}
              />
            </div>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="INACTIVE"
                value={status === 'INACTIVE'}
                onChange={() => {
                  handlePush({ status: 'INACTIVE' }, 'status');
                }}
              />
            </div>
          </Level.Item>
        </Level.Item>
      </Level>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-4">
            <Input
              label="Place Name"
              type="text"
              id="MenuServiceSetting_PlaceName"
              value={placeName}
              onChange={event => {
                handlePush({ placeName: event.target.value }, 'placeName');
              }}
              placeholder="Start entering place name..."
            />
          </div>

          <div className="column is-4">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city}
                  onSelect={data => {
                    handlePush({ city: data.name }, 'city');
                  }}
                  onHandleClear={() => handlePush({ city: '' }, 'city')}
                />
              </div>
            </div>
          </div>

          <div className="column is-4">
            <Input
              label="Service Type"
              type="text"
              id="MenuServiceSetting_ServiceType"
              value={serviceType}
              onChange={event => {
                handlePush({ serviceType: event.target.value }, 'serviceType');
              }}
              placeholder="Start entering Service Type..."
            />
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default Search;
