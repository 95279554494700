import React from 'react';
import styled from 'styled-components';

import ScheduleCard from './ScheduleCard';

const Container = styled.div`
  padding: 1rem 1rem;
`;

const Flex = styled.div`
  display: flex;
`;

const DetailsButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 20%;
    height: 6vh;
  }
`;

const Scheduled = () => (
  <Container>
    <div className="is-flex">
      <i className="fa fa fa-clock-o" style={{ fontSize: '24px' }} />
      <p className="is-size-2" style={{ color: '#ee2a7b', paddingLeft: '8px' }}>
        Friday Apr 23, 2020
      </p>
    </div>
    <br />
    <ScheduleCard title="Pickup" schedule="Scheduled Fri 23 Apr 04:30pm">
      <Flex>
        <div style={{ paddingRight: '17%' }}>
          <p
            className="is-size-4 has-text-weight-semibold"
            style={{ padingLeft: '0.8rem' }}
          >
            465730W343 | 4 items | $230
          </p>
          <p className="is-size-4" style={{ paddingTop: '8px' }}>
            David Atkinson(St Kilda)
          </p>
        </div>

        <DetailsButton
          type="button"
          className="button control"
          // onClick={() => setIsActiveOrderDetailsModal(true)}
        >
          Details
        </DetailsButton>
      </Flex>
    </ScheduleCard>
  </Container>
);

export default Scheduled;
