import React, { useEffect, useState } from 'react';
import { forEach, intersectionBy, sortBy } from 'lodash';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';
import moment from 'moment-timezone';
import client from '../../../utils/apolloClient';

import {
  Input,
  AsyncTagInputs,
  TagInputs,
  Select,
} from '../../../components/elements';
import { getUserRoles } from '../../../utils/fetchStatic';
import { ACLSConsumer } from '../../../utils/aclsContext';

import { getPlaces } from './helpers';

const status = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const parseRolesWithLabels = (userCmsRoles, assign_roles) => {
  const rolesWithLabels = [];

  forEach(assign_roles, item => {
    const filteredRoles = userCmsRoles.filter(edge => edge.value === item);
    if (filteredRoles.length !== 0) {
      rolesWithLabels.push({
        value: filteredRoles[0].value,
        label: `${filteredRoles[0].label} (${filteredRoles[0].value})`,
      });
    }
  });

  return sortBy(rolesWithLabels, 'label');
};

const AssignRoles = ({ assign_roles, userCmsRoles, role, setFieldValue }) => {
  const roleValues = intersectionBy(
    userCmsRoles,
    role.map(item => ({ value: item })),
    'value',
  );

  const userRoles = parseRolesWithLabels(userCmsRoles, assign_roles);

  return (
    <TagInputs
      label="Role"
      required
      suggestions={userRoles}
      value={roleValues}
      onChange={value => {
        if (value === null) {
          setFieldValue('role', []);
        } else {
          const roleInput = value.map(item => item.value);
          setFieldValue('role', roleInput);
        }
      }}
    />
  );
};

const AssignPlaces = ({
  places,
  setFieldValue,
  placesFromIds,
  fetchPlaces,
  access,
}) => {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (access && access.place_id && access.place_id.length !== 0) {
      const filter = access.place_id.filter(item => item === 'ALL');
      if (filter.length !== 0) {
        setDisabled(true);
      }
    }
  }, [access]);

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      if (!inputValue) {
        fetchPlaces(null, resolve);
        // resolve(places);
      } else {
        fetchPlaces(inputValue, resolve);
        // resolve(places);
      }
    });

  return (
    <AsyncTagInputs
      label="Places"
      required
      value={disabled ? [{ value: 'ALL', label: 'All' }] : placesFromIds}
      suggestions={disabled ? [] : places}
      promiseFunction={promiseOptions}
      defaultOptions={disabled ? [] : places}
      onChange={value => {
        // setValues(value);

        if (value) {
          const isAllExist =
            value.filter(item => item.label === 'All').length !== 0;
          setDisabled(isAllExist);
          const placeIds = isAllExist ? ['ALL'] : value.map(item => item.value);
          setFieldValue('access', { place_id: placeIds });
          setFieldValue(
            'places',
            isAllExist ? [{ value: 'ALL', label: 'All' }] : value,
          );
        } else {
          setFieldValue('places', []);
          setDisabled(false);
          fetchPlaces(null);
        }
      }}
    />
  );
};

const PlaceDetails = ({
  values,
  touched,
  errors,
  setFieldValue,
  handleChange,
  user: userValue,
}) => {
  const [userCmsRoles, setUserCMSRoles] = useState([]);
  const [places, setPlaces] = useState([]);
  const [users, setUsers] = useState([]);
  const { userId } = useStoreState(state => state.auth);

  const parseData = parseValue => {
    const final = [];
    forEach(parseValue, item => {
      final.push({ value: item.name, label: item.description });
    });
    return final;
  };

  const fetchPlaces = async (inputValue, resolve) => {
    const place_filter = {
      listing_status: 'APPROVED',
      event_business: false,
      item_review_place: false,
    };

    Object.assign(place_filter, inputValue && { place_name: inputValue });

    const allPlaces = await getPlaces({
      sort: 'PLACE_NAME_ASC',
      from: 0,
      size: 100,
      user_id: userId,
      filter: {
        place_filter,
      },
    });

    if (allPlaces.length !== 0) {
      const filteredPlaces = allPlaces.map(item => ({
        value: item.place_id,
        label: `${item.name}, ${item.city}, ${item.state}`,
      }));

      filteredPlaces.unshift({ value: 'ALL', label: 'All' });
      setPlaces(filteredPlaces);
      if (resolve) {
        resolve(filteredPlaces);
      }
    }
  };

  const getUsers = () => {
    const userFilter = userValue.audit.updated_by
      ? [userValue.audit.created_by, userValue.audit.updated_by]
      : [userValue.audit.created_by];
    client.clientPrivate
      .query({
        query: usersQuery,
        variables: {
          input: { filter: { user_filter: { user_id: userFilter } } },
        },
      })
      .then(({ data: { search_users } }) => {
        if (search_users) {
          setUsers(search_users.user_listing);
        }
      });
  };

  useEffect(() => {
    const getData = async () => {
      const roles = await getUserRoles();
      setUserCMSRoles(parseData(roles));
    };
    fetchPlaces(null);
    getData();
    getUsers();
  }, []);

  const role = values.role ? values.role : [];

  const createdBy =
    users.length !== 0 &&
    users.filter(item => item.user_id === userValue.audit.created_by);
  const updatedBy =
    users.length !== 0 &&
    users.filter(item => item.user_id === userValue.audit.updated_by);

  console.log('role', values);

  return (
    <div>
      <div className="columns is-multiline">
        <div className="column is-half">
          <Input label="Login" value={values.email} required disabled />
        </div>
        <div className="column is-half">
          <Select
            label="Status"
            options={status}
            value={[{ value: values.status, label: values.status }]}
            onChange={({ value }) => setFieldValue('status', value)}
            errors={errors.status}
            touched={touched.status}
            required
          />
        </div>
        <div className="column is-half">
          <Input
            label="Name"
            value={`${values.first_name} ${values.last_name}`}
            required
            disabled
          />
        </div>
        <div className="column is-half">
          <Input
            label="Display Name"
            required
            value={values.display_name}
            disabled
          />
        </div>
        <div className="column is-half">
          <Input label="Contact Phone" />
        </div>
        <div className="column is-half">
          <Input
            label="Contact Email"
            value={values.email}
            name="email"
            onChange={handleChange}
            placeholder="Email"
          />
        </div>

        <div className="column is-half">
          <ACLSConsumer>
            {({ assignRoles }) => (
              <AssignRoles
                assign_roles={assignRoles}
                role={role}
                userCmsRoles={userCmsRoles}
                setFieldValue={setFieldValue}
              />
            )}
          </ACLSConsumer>
        </div>
        <div className="column is-half">
          <AssignPlaces
            placesFromIds={values.places}
            access={values.access}
            places={places}
            setPlaces={setPlaces}
            setFieldValue={setFieldValue}
            fetchPlaces={fetchPlaces}
          />
        </div>
        <div className="column is-half">
          <TagInputs label="Companies" />
        </div>
        <div className="column is-half">
          <TagInputs label="Events" />
        </div>

        <div className="column is-half">
          <TagInputs label="Countries" />
        </div>
        <div className="column is-half">
          <TagInputs label="States" />
        </div>
        <div className="column is-half">
          <Input
            label="Created By"
            disabled
            value={
              (createdBy &&
                createdBy.length !== 0 &&
                createdBy[0].display_name) ||
              '---'
            }
          />
        </div>
        <div className="column is-half">
          <Input
            label="Created At"
            disabled
            value={moment(userValue.audit.created_at).format('LLL')}
          />
        </div>
        <div className="column is-half">
          <Input
            label="Updated By"
            disabled
            value={
              (updatedBy &&
                updatedBy.length !== 0 &&
                updatedBy[0].display_name) ||
              '---'
            }
          />
        </div>
        <div className="column is-half">
          <Input
            label="Updated At"
            disabled
            value={
              userValue.audit.updated_at &&
              moment(userValue.audit.updated_at).format('LLL')
            }
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(PlaceDetails);
