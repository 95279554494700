import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import QRCode from 'react-qr-code';
import ReactToPdf from 'react-to-pdf';
import { toPng } from 'html-to-image';
import useOnClickOutside from './useOnClickOutside';
import { Input, TextArea } from '../../../../components/elements';

const Container = styled.div``;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const FlexRow2 = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
`;

const Card = styled.div`
  border: 1px solid #e6e6e6;
  padding: 2rem 1rem;
  background-color: #ffffff;
`;

const TopContent = styled.div`
  border: 1px solid #000000;
  padding: 1rem;
  margin: 2rem 0rem;
  display: flex;
  border-radius: 6px;
`;

const BottomContent = styled.div`
  padding: 0rem 1rem;
`;
const QRCodeWrapper = styled.div`
  background-color: #ffffff;
`;

const Centered = styled.p`
  text-align: center;
`;

const TableQRCode = ({ menuName, placeName, qrCodeValue }) => {
  const popRef = useRef();
  const [isActivePop, setIsActivePop] = useState(false);
  useOnClickOutside(popRef, () => setIsActivePop(false));
  const [count, setCount] = useState(null);
  const [loading, setLoading] = useState(false);

  const [siteLabel, setSiteLabel] = useState('Table');
  const [topContent, setTopContent] = useState(
    'Order from your device and we will deliver to you',
  );
  const [bottomContent, setBottomContent] = useState(
    'Point your phone camera to scan the code and open our menu.',
  );

  const ref = React.createRef();

  const onDownloadPNG = () => {
    setLoading(true);
    setIsActivePop(false);
    const node = document.getElementById('qr-code-panel');
    toPng(node, { quality: 1 }).then(dataUrl => {
      const link = document.createElement('a');
      link.download = `qr-code-${count}.png`;
      link.href = dataUrl;
      link.click();
      setLoading(false);
    });
  };

  const onDownloadQRCodePNG = () => {
    setLoading(true);
    setIsActivePop(false);
    const node = document.getElementById('qr-code');
    toPng(node, { quality: 1 }).then(dataUrl => {
      const link = document.createElement('a');
      link.download = `qr-code-${count}.png`;
      link.href = dataUrl;
      link.click();
      setLoading(false);
    });
  };

  return (
    <Container>
      <p>
        Generate unique QR codes as per your need such as table or booth for
        your menu. For example, You can generate a QR Code that will open your
        menu with the table number. Printed size is 4&quot; x 6&quot;
      </p>
      <br />
      <div className="columns is-8">
        <div className="column is-6">
          <FlexRow>
            <Input
              type="text"
              label="Site Label"
              optionalText="(Max 10 chars)"
              value={siteLabel}
              onChange={e => {
                if (e.target.value.length <= 10) {
                  setSiteLabel(e.target.value);
                }
              }}
            />
            &nbsp;&nbsp;
            <Input
              type="number"
              label="Site No"
              optionalText="(Max 5 chars)"
              onChange={e => {
                if (e.target.value.length <= 5) {
                  setCount(e.target.value);
                }
              }}
              min="1"
            />
          </FlexRow>
          <TextArea
            label="Top Content"
            value={topContent}
            rows={3}
            onChange={e => {
              setTopContent(e.target.value);
            }}
          />
          <TextArea
            label="Bottom Content"
            value={bottomContent}
            onChange={e => {
              setBottomContent(e.target.value);
            }}
            rows={3}
          />
          <FlexRow>
            <div
              ref={popRef}
              className={`dropdown is-up ${isActivePop && 'is-active'}`}
            >
              <div className="dropdown-trigger">
                <button
                  type="button"
                  className={`button is-primary ${loading && 'is-loading'}`}
                  aria-haspopup="true"
                  aria-controls="dropdown-menu7"
                  onClick={() => setIsActivePop(true)}
                  disabled={!count}
                >
                  <span>Download</span>
                </button>
              </div>
              <div className="dropdown-menu " id="dropdown-menu7" role="menu">
                <div className="dropdown-content">
                  <ReactToPdf
                    targetRef={ref}
                    filename={`table-${count}.pdf`}
                    onComplete={() => setLoading(false)}
                    scale={2.4}
                    x={7}
                    y={7}
                  >
                    {({ toPdf }) => (
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          toPdf();
                          setLoading(true);
                          setIsActivePop(false);
                        }}
                      >
                        Download as pdf
                      </a>
                    )}
                  </ReactToPdf>

                  <a className="dropdown-item" onClick={onDownloadPNG}>
                    Download as png
                  </a>
                  <a className="dropdown-item" onClick={onDownloadQRCodePNG}>
                    Download QR code
                  </a>
                </div>
              </div>
            </div>
          </FlexRow>
        </div>
        <div className="column is-5">
          <Card id="qr-code-panel" ref={ref}>
            <Centered className="is-size-5 has-text-weight-bold is-uppercase">
              {placeName}
            </Centered>
            <Centered className="is-size-3 has-text-weight-semibold">
              {menuName}
            </Centered>
            <TopContent>
              <Centered className="has-text-weight-semibold">
                {topContent}
              </Centered>
            </TopContent>

            <FlexRow2>
              <QRCodeWrapper id="qr-code">
                <QRCode value={`${qrCodeValue}&table=${count}`} size={100} />
              </QRCodeWrapper>
              <BottomContent className="has-text-weight-semibold is-size-4">
                {bottomContent}
              </BottomContent>
            </FlexRow2>
            <p className="has-text-weight-semibold">
              {siteLabel} {count}
            </p>
          </Card>
        </div>
      </div>
    </Container>
  );
};

export default TableQRCode;
