import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { filter, sortBy, capitalize } from 'lodash';

import ReadyCard from './ReadyCard';
import { getReceivedOrders, handleOrderAction } from './helpers';
import { Loading } from '../../../components/elements';
import OrderDetails from './OrderDetails';
import CompletedOrders from './CompletedOrders';
import {
  scheduleTimeText,
  scheduleCompletionTimeText,
  totalTimeDelay,
} from './timeHelpers';

const Container = styled.div`
  padding: 1rem 1rem;
`;

const OrderButton = styled.button`
  &&& {
    font-weight: 600;
  }
`;

const DetailsButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
  }
`;
const Ready = ({ place }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [readyOrders, setReadyOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [completeLoading, setCompleteLoading] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState(null);

  const [currentOrder, setCurrentOrder] = useState(null);
  const [isActiveOrderDetailsModal, setIsActiveOrderDetailsModal] = useState(
    false,
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getReceivedOrders({
        placeId: place.place_id,
        status: 'READY',
      });
      setIsLoading(false);
      setReadyOrders(data);
    };
    fetchData();
  }, []);

  const handleCompleteOrder = async value => {
    setCompleteLoading(true);
    setCurrentOrderId(value.menu_order_id);
    const data = await handleOrderAction({
      menuId: value.menu_order_id,
      userId: value && value.audit.created_by,
      action: 'done',
    });
    setCompleteLoading(false);
    const newReadyOrders = filter(
      readyOrders,
      item => item.menu_order_id !== value.menu_order_id,
    );
    setReadyOrders(newReadyOrders);
    const newCompletedOrders = [data];
    setCompletedOrders(newCompletedOrders.concat(completedOrders));
  };

  const addCompleteOrderAfterReady = updateOrder => {
    const newCompletedOrders = [updateOrder];
    setCompletedOrders(newCompletedOrders.concat(completedOrders));
  };

  const handleAfterDelayAndAdjustOrder = updateOrder => {
    const newReadyOrders = readyOrders.filter(
      item => item.menu_order_id !== updateOrder.menu_order_id,
    );
    setReadyOrders(
      sortBy(newReadyOrders.concat(updateOrder), 'audit.created_at'),
    );
  };

  return (
    <>
      {isLoading && <Loading />}
      <Container>
        {readyOrders.length !== 0 &&
          readyOrders.map(item => {
            const showReadyStatus = () => {
              if (item.service_type === 'Pickup') {
                return 'Order Picked Up';
              }
              if (item.service_type === 'Dinein') {
                return 'Order Served';
              }
              if (item.service_type === 'Delivery') {
                return 'Order Delivered';
              }
              return null;
            };

            const receivedStatusHistory = item.received;

            const receivedTime = receivedStatusHistory
              ? receivedStatusHistory.created_at
              : null;
            const title = () => (
              <>
                {item.service_type === 'Delivery' && 'Delivery'}
                {item.service_type === 'Dinein' && item.table_number
                  ? `Table ${item.table_number}`
                  : item.service_type === 'Dinein' && 'Dinein'}
                {item.service_type === 'Pickup' && 'Pickup'}
                {item.large_order && ` (LARGE ORDER)`}
              </>
            );
            return (
              <ReadyCard
                title={title()}
                schedule={scheduleTimeText(item.estimates, receivedTime)}
                scheduleWithDelay={scheduleCompletionTimeText(
                  item.estimates,
                  receivedTime,
                )}
                ready={totalTimeDelay(item.estimates, receivedTime)}
                key={item.menu_order_id}
              >
                <div className="columns">
                  <div className="column is-4">
                    <div>
                      <p className="is-size-4 has-text-weight-semibold">
                        {item.order_reference} | {item.overall_quantity} items |
                        {item.billing.order_total.toFixed(2)}
                      </p>
                      <p className="is-size-4" style={{ paddingTop: '8px' }}>
                        {item.contact && item.contact.contact_name}
                        {item.service_type === 'Delivery' &&
                          item.address &&
                          ` ( ${capitalize(item.address.city)} )`}
                      </p>
                    </div>
                  </div>
                  <div className="column">
                    <DetailsButton
                      type="button"
                      className="button is-large is-fullwidth control"
                      onClick={() => {
                        setIsActiveOrderDetailsModal(true);
                        setCurrentOrder(item);
                      }}
                    >
                      Details
                    </DetailsButton>
                  </div>
                  <div className="column">
                    <OrderButton
                      type="button"
                      className={`button is-large is-primary control is-fullwidth ${completeLoading &&
                        item.menu_order_id === currentOrderId &&
                        'is-loading'}`}
                      onClick={() => handleCompleteOrder(item)}
                    >
                      {showReadyStatus()}
                    </OrderButton>
                  </div>
                </div>
              </ReadyCard>
            );
          })}
      </Container>
      {!isLoading && readyOrders.length === 0 && (
        <Container>
          <div
            className="is-flex has-text-weight-semibold"
            style={{ justifyContent: 'center' }}
          >
            <p>No Orders Ready yet!</p>
          </div>
          <div className="is-flex" style={{ justifyContent: 'center' }}>
            <p>
              To View active orders, choose the &apos;ACTIVE&apos; tab or to the
              review the previous orders , go to Order history
            </p>
          </div>
        </Container>
      )}
      <br />
      <CompletedOrders
        place={place}
        currentReadyOrder={currentOrder}
        setCompletedOrders={setCompletedOrders}
        completedOrders={completedOrders}
      />
      {currentOrder && isActiveOrderDetailsModal && (
        <OrderDetails
          isActiveOrderDetailsModal={isActiveOrderDetailsModal}
          setIsActiveOrderDetailsModal={setIsActiveOrderDetailsModal}
          order={currentOrder}
          setReadyOrders={setReadyOrders}
          readyOrders={readyOrders}
          addCompleteOrderAfterReady={addCompleteOrderAfterReady}
          handleAfterDelayOrder={handleAfterDelayAndAdjustOrder}
          handleAfterAdjustment={handleAfterDelayAndAdjustOrder}
        />
      )}
    </>
  );
};

export default Ready;
