import React, { useState, useCallback, useEffect } from 'react';
import { Tab } from 'rbx';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components';
import { forEach } from 'lodash';
import tzLookup from 'tz-lookup';
import moment from 'moment-timezone';

import { Box, Tabs } from '../../../components/elements';
import Active from './Active';
import Ready from './Ready';
import Scheduled from './Scheduled';
import Cancelled from './Cancelled';
import Header from './Header';

import StartStopModal from './StartStopModal';

import { getOrderCount, getMenus } from './helpers';

const Container = styled.div``;

const calculateIsOpen = ({ place }) => {
  let isPlaceOpen = false;
  const uniqHours = [
    place.hours_pickup,
    place.hours_dinein,
    place.hours_delivery,
  ];
  forEach(uniqHours, item => {
    if (item === 'OPENED') {
      isPlaceOpen = true;
    }
  });

  return isPlaceOpen;
};

const MainForm = props => {
  const { userId } = useStoreState(state => state.auth);
  const [isActiveTab, setIsActiveTab] = useState('new');

  const [isActiveStartStopModal, setIsActiveStartStopModal] = useState(false);
  const [newOrderNotificationCount, setNewOrderNotificationCount] = useState(0);

  const [orderCount, setOrderCount] = useState({
    receivedOrder: 0,
    readyOrder: 0,
    cancelOrder: 0,
  });

  const [menus, setMenus] = useState([]);
  const [takeOrder, setTakeOrder] = useState();
  const { place, currentSubscriptionData, setOrders, refetchPlace } = props;

  const fetchCount = useCallback(async () => {
    try {
      const count = await getOrderCount({ placeId: place.place_id });
      const receivedOrderArr = count.filter(item => item.status === 'RECEIVED');
      const readyOrderArr = count.filter(item => item.status === 'READY');
      const cancelOrderArr = count.filter(item => item.status === 'CANCELLED');
      setOrderCount({
        receivedOrder:
          receivedOrderArr.length !== 0 ? receivedOrderArr[0].count : 0,
        readyOrder: readyOrderArr.length !== 0 ? readyOrderArr[0].count : 0,
        cancelOrder: cancelOrderArr.length !== 0 ? cancelOrderArr[0].count : 0,
      });
      setNewOrderNotificationCount(
        receivedOrderArr.length !== 0 ? receivedOrderArr[0].count : 0,
      );
    } catch (err) {
      console.error(err);
    }
  }, []);

  const fetchMenus = useCallback(async () => {
    try {
      const menuList = await getMenus({
        placeId: place.place_id,
        userId,
      });

      const IsOpen = calculateIsOpen({ place });
      setTakeOrder(IsOpen);
      setMenus(
        menuList.map(item => ({
          ...item,
          label: `${item.service_type} - ${item.name}`,
          value: item.menu_id,
        })),
      );
    } catch (err) {
      console.log('err', err);
    }
  }, []);

  useEffect(() => {
    const lat = parseFloat(place.latitude).toFixed(5);
    const long = parseFloat(place.longitude).toFixed(5);
    const timeZone = tzLookup(lat, long);
    moment.tz.setDefault(timeZone);
    fetchMenus();
  }, []);

  useEffect(() => {
    fetchCount();
  }, [isActiveTab, currentSubscriptionData]);

  return (
    <>
      {isActiveStartStopModal && (
        <StartStopModal
          isActive={isActiveStartStopModal}
          onClose={() => setIsActiveStartStopModal(false)}
          placeId={place.place_id}
          place={place}
          menus={menus}
          fetchMenus={fetchMenus}
          refetchPlace={refetchPlace}
        />
      )}
      <Header
        takeOrder={takeOrder}
        receivedOrdersLength={newOrderNotificationCount}
        placeName={place.name}
        placeLocation={`${place.city}, ${place.post_code} | ${place.country} `}
        setIsActiveStartStopModal={setIsActiveStartStopModal}
      />

      <Box>
        <Tabs className="tabs is-large is-fullwidth">
          <Tab
            active={isActiveTab === 'new'}
            onClick={() => setIsActiveTab('new')}
          >
            ACTIVE ( {orderCount.receivedOrder} )
          </Tab>

          <Tab
            style={{ paddingLeft: '4rem' }}
            active={isActiveTab === 'ready'}
            onClick={() => setIsActiveTab('ready')}
          >
            READY ( {orderCount.readyOrder} )
          </Tab>

          <Tab
            style={{ paddingLeft: '4rem' }}
            active={isActiveTab === 'scheduled'}
            onClick={() => setIsActiveTab('scheduled')}
          >
            SCHEDULED (1)
          </Tab>

          <Tab
            style={{ paddingLeft: '4rem' }}
            active={isActiveTab === 'cancelled'}
            onClick={() => setIsActiveTab('cancelled')}
          >
            CANCELLED ( {orderCount.cancelOrder} )
          </Tab>
        </Tabs>
        <Container>
          <>
            {isActiveTab === 'new' && (
              <Active
                {...props}
                currentSubscriptionData={currentSubscriptionData}
                setOrders={setOrders}
                handleDecrementCount={() =>
                  setNewOrderNotificationCount(newOrderNotificationCount - 1)
                }
              />
            )}
            {isActiveTab === 'ready' && <Ready {...props} />}
            {isActiveTab === 'scheduled' && <Scheduled />}
            {isActiveTab === 'cancelled' && <Cancelled {...props} />}
          </>
        </Container>
      </Box>
    </>
  );
};

export default MainForm;
