import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { join, truncate } from 'lodash';
import ReactTooltip from 'react-tooltip';

import Delete from './Delete';
import { ACLSConsumer } from '../../../utils/aclsContext';
import Can from '../../../utils/Can';

const CheckInList = ({ section }) => (
  <React.Fragment>
    <td>
      <Link to={`/update-menu-section/${section.menu_section_id}`}>
        {section.name}
      </Link>
    </td>
    <td>{section.place.name}</td>
    <td>{section.place.city}</td>
    <td>
      <p data-tip={join(section.menu)} data-html>
        {truncate(join(section.menu), { length: 20 })}
      </p>
    </td>
    <td>
      <p data-tip={join(section.menu_item)} data-html>
        {truncate(join(section.menu_item), { length: 20 })}
      </p>
    </td>
    <td>
      <ReactTooltip html place="top" type="dark" effect="float" />
      {section.updated_by_user ? (
        <p
          data-tip={`<span>
          <p>email: ${section.updated_by_user.email}</p>
          <p>objectId: ${section.updated_by_user.user_id}</p>
        </span>`}
          data-html
        >
          {section.updated_by_user.display_name}
        </p>
      ) : (
        section.audit.updated_by
      )}
    </td>

    <td> {moment(section.audit.updated_at).format('lll')} </td>

    <td>
      <ACLSConsumer>
        {({ apiActions, pageActions }) => (
          <Can
            action="delete_menu_section"
            apiActions={apiActions}
            pageActions={pageActions}
            yes={() => <Delete sectionId={section.menu_section_id} />}
          />
        )}
      </ACLSConsumer>
    </td>
  </React.Fragment>
);

export default CheckInList;
