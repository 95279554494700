import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';

import { isNull, omit, forEach } from 'lodash';
import moment from 'moment-timezone';
import { Column } from 'rbx';
import Hours from './Hours';

import {
  ReactDateTimePicker,
  Input,
  InputErrorMessage,
} from '../../../../../components/elements';

const Container = styled.form`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 0px);
      position: absolute;
      right: 0;
      width: 35rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1rem 2rem;
      padding-top: 1.5rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: space-between;
      background: white;
      border-top: 0px;
    }
    .column {
      padding: 0.8rem 0.5rem;
    }
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

const DetailsFormWrapper = styled.div`
  padding: 1rem 2rem;
`;

const calCulateOpen24Hours = option => {
  let open24Hours = false;
  if (
    option.length !== 0 &&
    option[0].start_in_sec === 0 &&
    option[0].end_in_sec === 86340
  ) {
    open24Hours = true;
  }

  return open24Hours;
};
const MainForm = props => {
  const {
    isActive,
    onClose,
    handleSubmit,
    loading,
    isUpdate,
    values,
    setFieldValue,
    errors,
    touched,
    selectedMenu,
  } = props;
  const minDate = new Date();
  // minDate.setDate(minDate.getDate() + 1);
  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <div>
            <p className="modal-card-title">
              Custom Hours for {selectedMenu.service_type} - {selectedMenu.name}
            </p>
          </div>
          <CloseButtonWrapper>
            <button
              type="button"
              className="delete"
              aria-label="close"
              onClick={onClose}
            />
          </CloseButtonWrapper>
        </header>
        <section className="modal-card-body">
          <DetailsFormWrapper>
            <Column.Group>
              <Column size="6">
                {isUpdate ? (
                  <Input
                    label="Date"
                    value={moment(values.day, 'YYYY-MM-DD').format(
                      'MMM, DD YYYY',
                    )}
                    disabled
                  />
                ) : (
                  <ReactDateTimePicker
                    label="Date"
                    value={values.day}
                    onChange={value => {
                      setFieldValue('day', value);
                    }}
                    minDate={minDate}
                  />
                )}

                <InputErrorMessage
                  errors={errors.day}
                  touched={touched.day}
                  disableToastMessage
                />
              </Column>

              <Column size="6">
                <Input
                  label="Description"
                  name="description"
                  value={values.description}
                  onChange={e => {
                    if (e.target.value && e.target.value.length <= 35) {
                      setFieldValue('description', e.target.value);
                    }
                  }}
                  row={1}
                />
              </Column>
            </Column.Group>
            <Hours {...props} />
          </DetailsFormWrapper>
        </section>

        <footer className="modal-card-foot">
          <button
            type="button"
            className="button"
            aria-label="close"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`button is-primary ${loading && 'is-loading'}`}
            aria-label="close"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </footer>
      </div>
    </Container>
  );
};

const Form = withFormik({
  mapPropsToValues: ({ hour, place }) => {
    let hoursType = 'CUSTOM';
    const open24Hours = hour ? calCulateOpen24Hours(hour.option) : false;
    if (open24Hours) {
      hoursType = 'OPEN_24_HOUR';
    }
    if (hour && hour.closed_all_day) {
      hoursType = 'CLOSED_ALL';
    }

    return {
      place_id: hour && !isNull(hour.place_id) ? hour.place_id : place.place_id,
      object_type: 'MENU',
      description: hour && !isNull(hour.description) ? hour.description : '',
      closedAllDay:
        hour && !isNull(hour.closed_all_day) ? hour.closed_all_day : false,
      day: hour && !isNull(hour.day) ? hour.day : null,
      option: hour && !isNull(hour.option) ? hour.option : [],
      hoursType,
    };
  },
  handleSubmit: (values, { props }) => {
    const input = omit(values, [
      'closedAllDay',
      'is_active',
      'option',
      'hoursType',
    ]);
    const parseOption = option => {
      const final = [];
      forEach(option, item => {
        const data = omit(item, 'id', '__typename');
        final.push(data);
      });
      return final;
    };
    const option = parseOption(values.option);
    props.onSubmit({
      ...input,
      day: moment(values.day).format('YYYY-MM-DD'),
      closed_all_day: values.closedAllDay,
      option,
      status: 'ACTIVE',
    });
  },
})(MainForm);

export default Form;
