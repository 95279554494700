/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import client from '../../../../utils/apolloClient';

const searchCustomHoursQuery = gql`
  query searchCustomHours($input: SearchInput) {
    search_custom_hours(input: $input) {
      total_pages
      total_size
      custom_hours_listing {
        custom_hour_id
        place_id
        menu_id
        menu
        object_type
        day
        description
        option {
          start
          start_in_sec
          end
          end_in_sec
        }
        closed_all_day
        status
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        created_by_user {
          user_id
          email
          display_name
        }
        updated_by_user {
          user_id
          email
          display_name
        }
        place {
          place_id
          name
          city
        }
      }
    }
  }
`;

export const getMenuCustomHours = ({ userId, menuId, placeId }) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        query: searchCustomHoursQuery,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            user_id: userId,
            filter: {
              custom_hour_filter: {
                place_id: [placeId],
                menu_id: [menuId],
                object_type: 'MENU',
              },
            },
          },
        },
      })
      .then(({ data }) => {
        if (
          data.search_custom_hours &&
          data.search_custom_hours.custom_hours_listing
        ) {
          resolve(data.search_custom_hours.custom_hours_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
