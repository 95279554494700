import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { isNull, omit } from 'lodash';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';

import client from '../../../utils/apolloClient';
import { ErrorMessage, Loading } from '../../../components/elements';
import Layout from '../../../components/global/Layout';

import Form from './Form';
import dirtyFieldHandler from './dirtyFieldHandler';

const menuItemQuery = gql`
  query menuItem($input: NavigateMenuItemInput) {
    fetch_menu_item(input: $input) {
      place_id
      menu_item_id
      item_dictionary_id
      modifier_group_id
      name
      internal_name
      slug
      description
      status
      approval_status
      source_type
      tag
      variant {
        prompt_id
        prompt
        variants {
          variant_id
          variant_name
          variant_price
          availability_status
        }
      }

      popular
      allow_item_review
      availability_status

      price
      tax
      cuisine_style {
        name
        description
        display_order
      }
      item_type {
        name
        description
        display_order
      }
      dietary_information {
        name
        description
        display_order
      }
      item_contains_alcohol
      calories
      kilojoules
      label {
        key
        value
      }
      spice_levels
      item_stored
      sell_item_on_its_own
      popular_addon
      allow_special_instructions
      image_url
    }
  }
`;

const updateMenuItemMutation = gql`
  mutation updateMenuItem($input: MenuItemInput) {
    update_menu_item(input: $input) {
      menu_item_id
      error {
        description
      }
    }
  }
`;

const createItemLinkMutation = gql`
  mutation createItemLink($input: [MenuItemLinkInput]) {
    create_menu_item_link(input: $input) {
      item_link_id
      place_id
      error {
        description
      }
    }
  }
`;

const updateItemLinkMutation = gql`
  mutation updateItemLink($input: [MenuItemLinkInput]) {
    update_menu_item_link(input: $input) {
      item_link_id
      error {
        description
      }
    }
  }
`;

const deleteItemLinkMutation = gql`
  mutation deleteItemLink($input: [NavigateMenuItemLinkInput]) {
    delete_menu_item_link(input: $input) {
      item_link_id
      error {
        description
      }
    }
  }
`;

const MenuTypeUpdate = ({ history, match }) => {
  const { userId } = useStoreState(state => state.auth);
  const [updateLinks, setUpdateLinks] = useState([]);
  const [createLinks, setCreateLinks] = useState([]);
  const [deleteLinks, setDeleteLinks] = useState([]);

  const { id } = match.params;

  return (
    <Layout>
      <Query
        client={client.clientPrivate}
        query={menuItemQuery}
        variables={{
          input: {
            menu_item_id: id,
          },
        }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <ErrorMessage message={error.message} />;
          }
          const item = data.fetch_menu_item;
          return (
            <Mutation
              client={client.clientPrivate}
              mutation={deleteItemLinkMutation}
              variables={{ input: deleteLinks }}
              onCompleted={() => {
                swal(
                  'Great!',
                  'Menu Item updated successfully!',
                  'success',
                ).then(() => {
                  history.goBack();
                });
              }}
            >
              {(delete_menu_item_link, { loading: deleteLinkLoading }) => (
                <Mutation
                  client={client.clientPrivate}
                  mutation={updateItemLinkMutation}
                  variables={{ input: updateLinks }}
                  onCompleted={() => {
                    if (deleteLinks.length !== 0) {
                      delete_menu_item_link();
                    } else {
                      swal(
                        'Great!',
                        'Menu Item updated successfully!',
                        'success',
                      ).then(() => {
                        history.goBack();
                      });
                    }
                  }}
                >
                  {(update_menu_item_link, { loading: updateLinkLoading }) => (
                    <Mutation
                      client={client.clientPrivate}
                      mutation={createItemLinkMutation}
                      variables={{ input: createLinks }}
                      onCompleted={() => {
                        if (updateLinks.length !== 0) {
                          update_menu_item_link();
                        } else if (deleteLinks.length !== 0) {
                          delete_menu_item_link();
                        } else {
                          swal(
                            'Great!',
                            'Menu Item updated successfully!',
                            'success',
                          ).then(() => {
                            history.goBack();
                          });
                        }
                      }}
                    >
                      {(
                        create_menu_item_link,
                        { loading: createLinkLoading },
                      ) => (
                        <Mutation
                          client={client.clientPrivate}
                          mutation={updateMenuItemMutation}
                          onCompleted={({ update_menu_item }) => {
                            if (!isNull(update_menu_item.error)) {
                              update_menu_item.error.map(item1 =>
                                toast.error(item1.description),
                              );
                            } else if (createLinks.length !== 0) {
                              create_menu_item_link();
                            } else if (updateLinks.length !== 0) {
                              update_menu_item_link();
                            } else if (deleteLinks.length !== 0) {
                              delete_menu_item_link();
                            } else {
                              swal(
                                'Great!',
                                'Menu Item updated successfully!',
                                'success',
                              ).then(() => {
                                history.goBack();
                              });
                            }
                          }}
                        >
                          {(
                            update_menu_item,
                            { loading: loading1, error: error1 },
                          ) => (
                            <>
                              {error1 && (
                                <ErrorMessage message={error1.message} />
                              )}

                              <>
                                <Form
                                  userId={userId}
                                  item={item}
                                  loading={
                                    loading1 ||
                                    updateLinkLoading ||
                                    createLinkLoading ||
                                    deleteLinkLoading
                                  }
                                  onSubmit={inputs => {
                                    setUpdateLinks(
                                      inputs.updateLinks.map(items => ({
                                        ...items,
                                        menu_item_id: inputs.menu_item_id,
                                      })),
                                    );
                                    setCreateLinks(inputs.createLinks);
                                    setDeleteLinks(inputs.deleteLinks);
                                    const input = omit(
                                      inputs,
                                      'modifierGroups',
                                      'updateLinks',
                                      'createLinks',
                                      'deleteLinks',
                                    );
                                    const dataF = dirtyFieldHandler(
                                      input,
                                      item,
                                    );
                                    console.log('dataF', dataF);
                                    update_menu_item({
                                      variables: {
                                        input: dataF,
                                      },
                                    });
                                  }}
                                />
                              </>
                            </>
                          )}
                        </Mutation>
                      )}
                    </Mutation>
                  )}
                </Mutation>
              )}
            </Mutation>
          );
        }}
      </Query>
    </Layout>
  );
};

export default MenuTypeUpdate;
