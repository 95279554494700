import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import qs from 'querystringify';

import { useStoreState } from 'easy-peasy';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';

import ListItem from './ListItem';
import {
  Loading,
  Table,
  Box,
  ErrorMessage,
  Pagination,
  AesDes,
} from '../../../components/elements';
import client from '../../../utils/apolloClient';

const searchServiceSettingsQuery = gql`
  query searchSettings($input: SearchInput) {
    search_service_type_settings(input: $input) {
      total_size
      total_pages
      service_type_setting_listing {
        service_type_setting_id
        place_id
        place {
          place_id
          name
          city
        }
        menu
        city
        service_type
        status
        approval_status
        is_private_menu
        zone_setting {
          delivery_fee
          delivery_estimate
        }
        day_estimate {
          day
          start_hour
          end_hour
          preparation_estimate
          delivery_estimate
        }
        delivery_fee
        preparation_estimate
        delivery_estimate
        added_estimate
        cut_off_time
        food_setup_required
        minimum_order_amount
        maximum_order_amount
        payment_method
        enable_group_order
        schedule_order
        schedule_order_duration
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        created_by_user {
          user_id
          display_name
          email
        }
        updated_by_user {
          user_id
          display_name
          email
        }
      }
    }
  }
`;

const ServiceSettings = ({ location, history }) => {
  const { userId } = useStoreState(state => state.auth);

  const routeState = qs.parse(location.search);
  const { placeName, serviceType, city, page, status } = routeState;

  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;
  const placeNameFilter = placeName || null;
  const serviceTypeFilter = serviceType || null;
  const cityFilter = city || '';
  const statusFilter = status || 'ACTIVE';

  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(10);

  const [sort, setSort] = useState('UPDATED_AT_DESC');

  const input = { user_id: userId };
  const service_type_setting_filter = {};
  const place_filter = {};
  Object.assign(
    place_filter,
    placeNameFilter && { place_name: placeNameFilter },
    cityFilter && { city: cityFilter },
  );
  Object.assign(
    service_type_setting_filter,
    { status: statusFilter },
    serviceTypeFilter && { service_type: serviceTypeFilter },
  );
  Object.assign(
    input,
    { sort },
    { from: currentPage * listSize, size: listSize },
    {
      filter: {
        service_type_setting_filter,
        place_filter,
      },
    },
  );

  return (
    <Layout>
      <Header />
      <Search
        city={cityFilter}
        placeName={placeNameFilter}
        serviceType={serviceTypeFilter}
        status={statusFilter}
        history={history}
        routeState={routeState}
      />
      {totalElements > 0 && (
        <Pagination
          pageFrom={currentPage * listSize}
          pageCount={pageCount}
          listSize={listSize}
          totalElements={totalElements}
          currentPage={currentPage}
          handlePageClick={value =>
            history.push(
              `/menu-service-settings${qs.stringify(
                {
                  ...routeState,
                  page: value.selected + 1,
                },
                true,
              )}`,
            )
          }
        />
      )}
      <Box>
        <Table>
          <thead>
            <tr>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="SERVICE_TYPE_ASC"
                  des="SERVICE_TYPE_DESC"
                />
                Service Type
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="PLACE_NAME_DESC"
                  des="PLACE_NAME_ASC"
                />
                Place name
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="PLACE_CITY_ASC"
                  des="PLACE_CITY_DESC"
                />
                City / Suburb
              </th>
              <th>Menu name</th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="PRIVATE_MENU_ASC"
                  des="PRIVATE_MENU_DESC"
                />
                Private Menu
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="UPDATED_BY_ASC"
                  des="UPDATED_BY_DESC"
                />
                <span>Updated by</span>
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="UPDATED_AT_ASC"
                  des="UPDATED_AT_DESC"
                />
                Updated at
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <Query
            query={searchServiceSettingsQuery}
            variables={{ input }}
            fetchPolicy="network-only"
            client={client.clientPrivate}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return <ErrorMessage message={error.message} />;
              }

              return (
                <React.Fragment>
                  <View
                    data={data}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({ data, setPageCount, setTotalElements }) => {
  const { search_service_type_settings } = data;

  useEffect(() => {
    setPageCount(search_service_type_settings.total_pages);
    setTotalElements(search_service_type_settings.total_size);
  }, [search_service_type_settings.total_pages]);

  if (search_service_type_settings === null) {
    return 'No record found';
  }

  if (search_service_type_settings.service_type_setting_listing.length === 0) {
    return 'No record found';
  }
  return (
    <tbody>
      {search_service_type_settings.service_type_setting_listing.map(
        setting => (
          <tr key={setting.setting_id}>
            <ListItem setting={setting} />
          </tr>
        ),
      )}
    </tbody>
  );
};

export default ServiceSettings;
