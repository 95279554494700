import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 1rem;
  background-color: ${({ theme }) => theme.lightGreyAlpha};
`;

const Header = styled.div`
  padding: 0.5rem 1rem;
`;
const Content = styled.div`
  padding: 1rem;
`;

const OrderTracking = ({ children }) => (
  <Container onClick={() => console.log('dfdf')}>
    <Content>{children}</Content>
  </Container>
);
export default OrderTracking;
