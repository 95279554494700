/* eslint-disable max-len */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';
import { isNull } from 'lodash';

import apolloClient from '../../../../utils/apolloClient';
import Details from './Details';
import { Loading } from '../../../../components/elements';

const Container = styled.div`
  width: 70%;
`;

const linkAccountMutation = gql`
  mutation create_connected_account($input: ConnectedAccountInput) {
    create_connected_account(input: $input) {
      account_id
      account_link {
        url
      }
      error {
        description
      }
    }
  }
`;

const linkUpdateAccountMutation = gql`
  mutation update_connected_account($input: ConnectedAccountInput) {
    update_connected_account(input: $input) {
      account_id
      account_link {
        url
      }
      error {
        description
      }
    }
  }
`;

const getAccountQuery = gql`
  query fetch_connected_account($user_id: String!, $object_id: String!) {
    fetch_connected_account(
      input: { user_id: $user_id, object_id: $object_id, object_type: "PLACE" }
    ) {
      account_id
      account_link {
        url
      }
      account {
        id
        capabilities {
          card_payments
          transfers
        }
        business_profile {
          name
          url
        }
        country
        charges_enabled
        default_currency
        details_submitted
        email
        payouts_enabled
      }
      error {
        description
      }
    }
  }
`;

const Payouts = ({ place }) => {
  const [stripId, setStripId] = useState(null);
  const [stripOnBoardingLink, setStripOnBoardingLink] = useState(null);
  const [detailsSubmitted, setDetailsSubmitted] = useState(false);
  const [editAccount, setEditAccount] = useState(false);

  const { userId } = useStoreState(state => state.auth);
  const { data, loading: fetchLoading, refetch } = useQuery(getAccountQuery, {
    client: apolloClient.clientPrivate,
    variables: {
      user_id: userId,
      object_id: place.place_id,
    },
  });
  const [action, { loading }] = useMutation(linkAccountMutation, {
    client: apolloClient.clientPrivate,
    onCompleted: ({ create_connected_account }) => {
      setStripId(create_connected_account.account_id);
      setStripOnBoardingLink(create_connected_account.account_link.url);
      window.open(
        create_connected_account.account_link.url,
        'popup',
        'width=600,height=600',
      );
      return false;
    },
  });

  const [updateAction, { loading: updateLoading }] = useMutation(
    linkUpdateAccountMutation,
    {
      client: apolloClient.clientPrivate,
      onCompleted: ({ update_connected_account }) => {
        setStripId(update_connected_account.account_id);
        if (!isNull(update_connected_account.error)) {
          update_connected_account.error.map(item =>
            toast.error(item.description),
          );
        } else {
          refetch();
          setEditAccount(false);
        }
      },
    },
  );

  if (fetchLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <h1 className="is-size-2 has-text-weight-semibold">
        Payment Information
      </h1>
      <p className="is-size-3">
        We use Stripe&apos;s globally trusted payment platform to make your
        payouts faster and and keep your details secure
      </p>
      <br />
      <a
        className="SiteHeader__logoLink"
        data-js-controller="AnalyticsButton"
        data-analytics-category="Navigation"
        data-analytics-action="Clicked"
        data-analytics-label="Stripe Logo"
      >
        <svg
          viewBox="0 0 60 25"
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="25"
          className="UserLogo variant-- "
        >
          <title>Stripe logo</title>
          <path
            fill="var(--userLogoColor, #0A2540)"
            d="M59.64 14.28h-8.06c.19 1.93 1.6 2.55 3.2 2.55 1.64 0 2.96-.37 4.05-.95v3.32a8.33 8.33 0 0 1-4.56 1.1c-4.01 0-6.83-2.5-6.83-7.48 0-4.19 2.39-7.52 6.3-7.52 3.92 0 5.96 3.28 5.96 7.5 0 .4-.04 1.26-.06 1.48zm-5.92-5.62c-1.03 0-2.17.73-2.17 2.58h4.25c0-1.85-1.07-2.58-2.08-2.58zM40.95 20.3c-1.44 0-2.32-.6-2.9-1.04l-.02 4.63-4.12.87V5.57h3.76l.08 1.02a4.7 4.7 0 0 1 3.23-1.29c2.9 0 5.62 2.6 5.62 7.4 0 5.23-2.7 7.6-5.65 7.6zM40 8.95c-.95 0-1.54.34-1.97.81l.02 6.12c.4.44.98.78 1.95.78 1.52 0 2.54-1.65 2.54-3.87 0-2.15-1.04-3.84-2.54-3.84zM28.24 5.57h4.13v14.44h-4.13V5.57zm0-4.7L32.37 0v3.36l-4.13.88V.88zm-4.32 9.35v9.79H19.8V5.57h3.7l.12 1.22c1-1.77 3.07-1.41 3.62-1.22v3.79c-.52-.17-2.29-.43-3.32.86zm-8.55 4.72c0 2.43 2.6 1.68 3.12 1.46v3.36c-.55.3-1.54.54-2.89.54a4.15 4.15 0 0 1-4.27-4.24l.01-13.17 4.02-.86v3.54h3.14V9.1h-3.13v5.85zm-4.91.7c0 2.97-2.31 4.66-5.73 4.66a11.2 11.2 0 0 1-4.46-.93v-3.93c1.38.75 3.1 1.31 4.46 1.31.92 0 1.53-.24 1.53-1C6.26 13.77 0 14.51 0 9.95 0 7.04 2.28 5.3 5.62 5.3c1.36 0 2.72.2 4.09.75v3.88a9.23 9.23 0 0 0-4.1-1.06c-.86 0-1.44.25-1.44.9 0 1.85 6.29.97 6.29 5.88z"
            fillRule="evenodd"
          />
        </svg>
      </a>
      <div className="columns">
        <div className="column is-10">
          <p>
            Accept payments from customers and receive payouts directly to your
            preferred bank account
          </p>
        </div>
        <div className="column is-2">
          <a
            className={`button ${
              stripId && detailsSubmitted ? 'is-success' : 'is-primary'
            } ${loading && 'is-loading'}`}
            onClick={() => {
              action({
                variables: {
                  input: {
                    user_id: userId,
                    object_id: place.place_id,
                    object_type: 'PLACE',
                  },
                },
              });
            }}
            disabled={stripId && detailsSubmitted}
          >
            <p className="has-text-weight-semibold">
              {stripId && detailsSubmitted ? 'Connected' : 'Connect'}
            </p>
          </a>
        </div>
      </div>
      <Details
        data={data}
        stripId={stripId}
        setStripId={setStripId}
        stripOnBoardingLink={stripOnBoardingLink}
        setStripOnBoardingLink={setStripOnBoardingLink}
        setDetailsSubmitted={setDetailsSubmitted}
        updateLoading={updateLoading}
        editAccount={editAccount}
        setEditAccount={setEditAccount}
        onUpdate={accountId => {
          updateAction({
            variables: {
              input: {
                user_id: userId,
                object_id: place.place_id,
                object_type: 'PLACE',
                account_id: accountId,
              },
            },
          });
        }}
      />
      <br />
      {stripId && detailsSubmitted && (
        <button className="button is-primary is-flex">
          <span>
            <i className="fab fa-stripe-s" />
          </span>
          &nbsp;&nbsp;
          <p className="has-text-weight-semibold">Open Stripe Connect</p>
        </button>
      )}
    </Container>
  );
};

export default Payouts;
