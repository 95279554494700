import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
import { intersectionBy, isArray } from 'lodash';
import {
  Radio,
  CreatableSelectInputs,
  InputErrorMessage,
} from '../../../components/elements';
import { getMenus } from './helper';

const Container = styled.div``;

const Title = styled.div`
  margin: 1rem 0rem;
`;

const MenuSelection = ({
  values,
  setFieldValue,
  errors,
  touched,
  isUpdate,
}) => {
  const [menuSuggestions, setMenuSuggestions] = useState([]);

  const [menuLoading, setMenuLoading] = useState(false);
  const fetchMenusData = useCallback(async () => {
    setMenuLoading(true);
    try {
      const list = await getMenus({ placeId: values.place_id });
      setMenuSuggestions(
        list.map(item => ({
          ...item,
          label: `${item.service_type} - ${item.name}`,
          value: item.menu_id,
        })),
      );
      setMenuLoading(false);
    } catch (error) {
      console.error(error);
      setMenuLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchMenusData();
  }, []);

  const menuIdList = isArray(values.menu_id)
    ? values.menu_id.map(item => ({ menu_id: item }))
    : [{ menu_id: values.menu_id }];

  const menuInput = intersectionBy(menuSuggestions, menuIdList, 'menu_id');
  console.log('menuIdList', menuInput);
  return (
    <Container>
      <Title>
        <p className="is-size-3 has-text-weight-semibold">Applies to</p>
      </Title>

      <Column.Group>
        <Column size={2}>
          <Radio
            label="All menus"
            value={values.menu_id.length === menuSuggestions.length}
            onChange={() => {
              setFieldValue(
                `menu_id`,
                menuSuggestions.map(item => item.menu_id),
              );
            }}
            disabled={isUpdate}
          />
        </Column>
        <Column size={2}>
          <Radio
            label="Select Menus"
            value={values.menu_id.length !== menuSuggestions.length}
            onChange={() => {
              setFieldValue(`menu_id`, []);
            }}
            disabled={isUpdate}
          />
        </Column>
      </Column.Group>

      <Column.Group>
        <Column size={6}>
          <CreatableSelectInputs
            label="Select Menus"
            suggestions={menuSuggestions}
            value={menuInput}
            onChange={value => {
              if (value) {
                setFieldValue(
                  `menu_id`,
                  value.map(item => item.menu_id),
                );
              } else {
                setFieldValue(`menu_id`, []);
              }
            }}
            isLoading={menuLoading}
            disabled={isUpdate}
          />
        </Column>
      </Column.Group>
      <InputErrorMessage
        errors={errors.menu_id}
        touched={touched.menu_id}
        disableToastMessage
      />
    </Container>
  );
};

export default MenuSelection;
